<template>
  <div class="card">
    <div class="card__header flex justify-between items-center space-x-4">
      <p>Company Suppliers</p>

      <button
        type="button"
        class="btn btn--secondary-blue"
        data-test="add-supplier"
        @click.prevent="addSupplier"
      >
        Add Supplier
      </button>
    </div>

    <div class="flex w-full p-4 space-x-4">
      <ul
        v-if="projectSuppliers.length"
        class="overflow-auto space-y-4 h-64 flex-1"
      >
        <li
          v-for="(projectSupplier) in projectSuppliers"
          :key="projectSupplier.id"
          class="card__list-item"
          data-test="order-item"
        >
          <div class="flex justify-between">
            <div class="flex flex-col">
              <p>
                {{ projectSupplier.supplier?.name }}
                <span
                  class="px-2 py-1 rounded-lg text-sm capitalize font-medium"
                  :class="[colourForType(projectSupplier.type)]"
                >
                  {{ supplierTypes.find((types) => types.value === projectSupplier.type)?.label }}
                </span>
              </p>

              <p v-if="projectSupplier.address">
                {{ projectSupplier.address }}
              </p>

              <p
                v-else
                class="text-red-600 text-sm"
              >
                No address supplied
              </p>

              <p
                v-if="!projectSupplier.contact_name && !projectSupplier.contact_email && !projectSupplier.contact_number"
                class="text-red-600 text-sm"
              >
                No contact supplied
              </p>

              <p
                v-if="projectSupplier.contact_name"
                class="text-sm"
              >
                Contact: {{ projectSupplier.contact_name }}
              </p>

              <p
                v-if="projectSupplier.contact_email"
                class="text-sm"
              >
                Email: {{ projectSupplier.contact_email }}
              </p>

              <p
                v-if="projectSupplier.contact_number"
                class="text-sm"
              >
                Phone: {{ projectSupplier.contact_number }}
              </p>
            </div>

            <div class="flex justify-end row-span-2 items-center">
              <Icon
                name="trash"
                class="w-6 h-6 hover:stroke-red-600 cursor-pointer"
                data-test="trash"
                @click="removeSupplier(projectSupplier.id)"
              />

              <button
                type="button"
                data-test="edit"
                class="mr-2"
                @click="editSupplier(projectSupplier)"
              >
                <Icon
                  name="edit"
                  class="btn-icon h-5 w-5"
                />
              </button>
            </div>
          </div>
        </li>
      </ul>

      <div
        v-else
        class="text-center font-semibold w-full"
      >
        No Suppliers Found.
      </div>
    </div>

    <ProjectSupplierModal
      v-if="show"
      :project-supplier="selectedProjectSupplier"
      :company-id="companyId"
      @close="show = false"
    />
  </div>
</template>

<script setup lang="ts">
import ProjectSupplier, { SupplierType } from '@/models/ProjectSupplier';
import { useCompanyProjectSupplierStore } from '@/store/companyProjectSupplier';
import { useProjectSupplierStore } from '@/store/projectSupplier';
import Swal from 'sweetalert2';
import { computed, ref } from 'vue';
import ProjectSupplierModal from './CompanyProjectSupplierModal.vue';

const props = defineProps<{
  companyId: number;
}>();

const projectSuppliersStore = useCompanyProjectSupplierStore();

const removeSupplier = (id: Id) => {
  Swal.fire({
    icon: 'warning',
    title: 'Remove Supplier?',
    text: 'This will remove the association with this supplier',
    showConfirmButton: true,
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      projectSuppliersStore.deleteProjectSupplier(id).catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Failed to delete',
        });
      });
    }
  });
};

const show = ref(false);
const selectedProjectSupplier = ref();

const editSupplier = (projectSupplier: ProjectSupplier) => {
  selectedProjectSupplier.value = projectSupplier;
  show.value = true;
};

const projectSuppliers = computed(() => {
  return projectSuppliersStore.models.where('company_id', props.companyId).with('supplier').get();
});

const addSupplier = () => {
  selectedProjectSupplier.value = undefined;
  show.value = true;
};

const supplierTypes = computed(() => {
  const types = [];

  for(const type in SupplierType) {
    const value = SupplierType[type];

    types.push({ value, label: type.replace('_', ' ') });
  }

  return types;
});

const colourForType = (type: ObjectValues<typeof SupplierType>) => {
  switch (type) {
    case SupplierType.labour:
      return 'bg-ccm-blue-500 text-white';

    case SupplierType.material:
      return 'bg-green-500 text-white';

    case SupplierType.plant:
      return 'bg-orange-500 text-white';

    default:
      return 'bg-ccm-blue-500 text-white';
  }
};
</script>

<style scoped></style>
