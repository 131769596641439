<template>
  <div class="!pt-0">
    <div class="flex flex-col space-y-2.5 max-h-screen pt-16 pb-2.5">
      <div class="flex items-center justify-between space-x-4 mt-2.5">
        <div class="flex items-center space-x-4 element-island">
          <h1 class="font-semibold text-3xl">
            Progress Claims
          </h1>

          <div v-if="claim">
            <h2>
              Claim {{ claim.claim_number }}
              -
              {{ ClaimStatusTitles[enumKeyFromValue(ClaimStatus, claim.status)] }}
              <span v-if="claim.status === ClaimStatus.certified">
                - {{ claim.stat_dec_received ? 'Stat Dec Received' : 'No Stat Dec' }}
              </span>
            </h2>

            <p>
              {{ new Date(claim.start).toDateString() }} - {{ new Date(claim.end).toDateString() }}
            </p>

            <div v-if="typingRetention">
              <label class="flex items-center">
                Retention

                <AppInput
                  v-model="updateForm.form.retention"
                  class="form-input ml-4 w-1/2"
                  type="text"
                  name="retention"
                  @keyup.esc="typingRetention = false"
                  @blur="updateClaim"
                />
              </label>
            </div>

            <div
              v-else
              class="flex items-center space-x-2 cursor-pointer"
              @click="typingRetention = true"
            >
              <p>
                Retention {{ australianCurrency(claim.retention) }}
              </p>

              <Icon
                name="edit"
                class="btn-icon h-5 w-5 inline-block"
              />
            </div>
          </div>

          <button
            v-if="claim?.status === ClaimStatus.draft"
            type="button"
            class="btn btn--secondary-blue"
            @click="sendClaim()"
          >
            Send Claim
          </button>
        </div>

        <div
          v-if="!latestClaim || claim?.status === ClaimStatus.draft || latestClaim?.status === ClaimStatus.certified"
          class="element-island flex space-x-4"
        >
          <button
            v-if="claim?.status === ClaimStatus.draft"
            type="button"
            class="btn btn--secondary-blue"
            @click.prevent="deleteClaim"
          >
            Delete Claim
          </button>

          <button
            v-if="!latestClaim || latestClaim.status === ClaimStatus.certified"
            type="button"
            class="btn btn--secondary-blue"
            @click.prevent="show = true"
          >
            New Claim
          </button>
        </div>
      </div>



      <div class="element-island overflow-y-auto">
        <ClaimTable
          :project-id="projectId"
          :claim-id="claimId"
        />
      </div>

      <CreateClaimModal
        v-if="show"
        :project-id="$props.projectId"
        @close="show = false"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import ClaimTable from '@/components/ProgressClaims/ClaimTable.vue';
import CreateClaimModal from '@/components/ProgressClaims/CreateClaimModal.vue';
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, enumKeyFromValue } from '@/helpers';
import { ClaimStatus, ClaimStatusTitles } from '@/models/Claim';
import { useClaimsStore } from '@/store/claims';
import Swal from 'sweetalert2';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps<{
  projectId: string;
  claimId?: string;
}>();

const claimsStore = useClaimsStore();
const router = useRouter();

watch(() => [props.projectId, props.claimId], () => {
  if(props.claimId) {
    claimsStore.fetchClaim(props.claimId);
  } else {
    claimsStore.fetchClaims(props.projectId);
  }
}, { immediate: true });

const claim = computed(() => {
  // TODO: 10/11/23 constrain variation orders to only those with cost for the current claim
  if(props.claimId) {
    const claimQuery = claimsStore.models.with('trades', (query) => {
      query.with('trade_items');
    }).with('trade_item_costs').with('variation_orders').with('variation_order_costs').where(
      'project_id',
      parseInt(props.projectId),
    );

    return claimQuery.find(parseInt(props.claimId));
  }

  return undefined;
});

const show = ref(false);

const sendClaim = () => {
  Swal.fire({
    icon: 'warning',
    title: 'Send Claim To',
    input: 'select',
    inputOptions: {
      // [ClaimStatus.draft_contractor]: 'Contractor',
      [ClaimStatus.draft_pqs]: 'PQS Firm',
    },

    inputPlaceholder: 'Select a Contact',
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      claimsStore.sendClaim(claim.value.id, { status: result.value });
    }
  });
};

const claims = computed(() => {
  return claimsStore.models.where('project_id', parseInt(props.projectId)).orderBy('claim_number', 'desc').orderBy(
    'claim_number',
    'desc',
  ).get();
});

const latestClaim = computed(() => {
  if(claims.value.length) {
    return claims.value[0];
  }

  return undefined;
});

const updateForm = useFormData({
  retention: 0,
});

const typingRetention = ref(false);

watch(claim, (newValue) => {
  if(newValue) {
    updateForm.setData(newValue);
  }
});

const updateClaimAction = useStoreApiAction(claimsStore.updateClaim);

const updateClaim = () => {
  updateClaimAction.request(claim.value.id, updateForm.form).then(() => {
    typingRetention.value = false;
  }).catch((error) => {
    console.log(error.data);

    Swal.fire({
      icon: 'error',
      title: 'Failed to update retention',
      text: error.data?.message,
    });
  });
};

const deleteClaim = () => {
  Swal.fire({
    icon: 'warning',
    title: 'Delete Claim?',
    text: 'Are you sure you want to delete this claim? This cannot be undone.',
    showCancelButton: true,
    showConfirmButton: true,
  }).then(() => {
    claimsStore.deleteClaim(claim.value.id).then(() => {
      router.push({ name: 'ProgressClaimsIndex' });

      Swal.fire({
        icon: 'success',
        title: 'Claim deleted.',
      });
    });
  });
};

const overallStatus = (status: ObjectValues<typeof ClaimStatus>) => {
  switch (true) {
    case status === ClaimStatus.certified:
      return { colour: 'bg-green-600', label: 'Certified' };

    default:
      return { colour: 'bg-red-600', label: 'Draft' };
  }
};

const contractorStatus = (status: ObjectValues<typeof ClaimStatus>) => {
  switch (true) {
    case status === ClaimStatus.draft_pqs:

    case status === ClaimStatus.draft:
      return { colour: 'bg-red-600', label: 'Not Issued' };

    case status === ClaimStatus.contractor_claim:
      return { colour: 'bg-orange-600', label: 'Issued' };

    case status === ClaimStatus.pqs_review:

    case status === ClaimStatus.certified:
      return { colour: 'bg-green-600', label: 'Submitted' };

    default:
      return undefined;
  }
};

const pqsStatus = (status: ObjectValues<typeof ClaimStatus>) => {
  switch (true) {
    case status === ClaimStatus.draft:
      return { colour: 'bg-red-600', label: 'Not Issued' };

    case status === ClaimStatus.pqs_review:
      return { colour: 'bg-orange-600', label: 'Issued' };

    case status === ClaimStatus.certified:
      return { colour: 'bg-green-600', label: 'Submitted' };

    default:
      return undefined;
  }
};
</script>

<style scoped></style>
