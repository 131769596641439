import { useProjectsStore } from '@/store/projects';
import { useUserStore } from '@/store/user';
import { RouteLocationNormalized } from 'vue-router';

export function baselineBoqLock() {
  const userStore = useUserStore();
  const projectsStore = useProjectsStore();

  if(userStore.isRole(['contract_manager'], false) && projectsStore.project?.boq_locked) {
    return false;
  }

  return true;
}

export function forecastingAvailable() {
  const projectsStore = useProjectsStore();

  return projectsStore.project?.boq_locked;
}

export function cashflowAvailable() {
  const projectsStore = useProjectsStore();

  return projectsStore.project?.boq_locked;
}

// We can try implement these in the future where we wait for projects to load before attempting to route
// but we need to consider admin and login/reset/guest routes
// export async function cashflowAvailableGuard(to: RouteLocationNormalized) {
//   const projectsStore = useProjectsStore();
//
//   await projectsStore.projectsFetched.promise;
//
//   return cashflowAvailable() ? true : { name: 'BillOfQuantities', params: { projectId: to.params.projectId } };
// }
//
// export async function forecastingAvailableGuard(to: RouteLocationNormalized) {
//   const projectsStore = useProjectsStore();
//
//   await projectsStore.projectsFetched.promise;
//
//   return forecastingAvailable() ? true : { name: 'BillOfQuantities', params: { projectId: to.params.projectId } };
// }
//  In App.vue, assuming you have a promise with resolver
// const projectsLoaded = ref(false);
//
//
// (async () => {
//   await projectsStore.projectsFetched.promise;
//
//   projectsLoaded.value = true;
// })();
