<template>
  <tr
    class="group even:bg-gray-100 border-x border-black cursor-pointer hover:bg-blue-100 odd:bg-white"
    @click="open = !open"
  >
    <td class="pl-3 py-2 rounded-bl-lg w-[200px]">
      <div class="flex items-center space-x-1">
        <div
          :class="[{'rotate-90': open}]"
          data-test="open-labour"
        >
          <Icon
            name="chevron-right"
            class="btn-icon h-5 w-5 group-hover:text-gray-700"
          />
        </div>

        <div class="flex flex-col">
          <p class="font-semibold">
            {{ $props.wbsCode.code }}
          </p>

          <p class="line-clamp-1 text-sm w-[200px]">
            {{ $props.wbsCode.description }}
          </p>
        </div>
      </div>
    </td>

    <td class="text-right pr-4">
      {{ australianCurrency($props.wbsCode.tender_cost) }}
    </td>

    <td
      v-if="typingFinalQuantity"
      class="bg-blue-200/40 pr-2"
    >
      <div class="flex items-center justify-end">
        <form
          action=""
          @submit.prevent="updateWbsCode"
        >
          <AppInput
            ref="finalQuantityInput"
            v-model="finalQuantity"
            name="final_quantity"
            type="number"
            step="any"
            min="0"
            data-test="wbs-code-final-quantity"
            @blur="updateWbsCode"
            @keyup.enter="updateWbsCode"
            @keyup.esc="typingFinalQuantity = false"
          />
        </form>
      </div>
    </td>

    <td
      v-else
      class="text-right pr-2"
      data-test="record-paid-text"
      @click.stop="toggleTypingFinalQuantity"
    >
      <div class="group flex items-center justify-end">
        <Icon
          name="edit"
          class="group-hover:visible btn-icon h-5 w-5 inline-block invisible"
        />
        {{ australianNumber($props.wbsCode.final_quantity || $props.wbsCode.tender_quantity) }}
      </div>
    </td>

    <td class="text-right pr-4">
      {{ $props.wbsCode.unit }}
    </td>

    <td class="text-right">
      {{ australianCurrency($props.wbsCode.unitCost) }}/{{ wbsCode.unit }}
    </td>

    <td class="text-right">
      {{ australianCurrency(toComplete.toDateUnitCost) }}/{{ wbsCode.unit }}
    </td>

    <td class="text-right pr-4">
      {{ australianCurrency(toComplete.toCompleteUnitCost) }}/{{ wbsCode.unit }}
    </td>

    <td class="text-right">
      {{ australianCurrency(toComplete.toDateCost) }}
    </td>

    <td class="text-right">
      {{ australianCurrency(toComplete.toDateEarnedAmount) }}
    </td>

    <td class="text-right pr-4">
      {{ australianCurrency(toComplete.toDateVariance) }}
    </td>

    <td class="text-right pr-4">
      {{ australianCurrency(toComplete.toCompleteCost) }}
    </td>

    <td class="text-right pr-4">
      {{ toComplete.toDateCost === 0 ? 0 : australianCurrency(toComplete.forecastCost) }}
    </td>

    <td class="text-right pr-4">
      {{ australianCurrency(toComplete.profit) }}
    </td>
  </tr>

  <tr
    v-if="open"
    class="border-x border-black bg-gray-300"
  >
    <td colspan="100%">
      <div class="m-2 border border-gray-200 rounded-md shadow-md">
        <table class="w-full border-separate border-spacing-0">
          <thead class="border-b border-black">
            <tr class="sticky top-0 h-7 bg-white text-center">
              <th
                colspan="3"
                class="text-center border-b border-b-gray-200"
                title="The number of days based on the quantity and production rate.
                  The formula is [Qty/Production rate]"
              >
                Days
              </th>

              <th
                colspan="3"
                class="py-2 bg-gray-200"
                title="The production rate that is used in the tender estimate"
              >
                Production Rate
              </th>

              <th
                colspan="3"
                class="border-b border-gray-200"
                title="The quantity to date as per the Quantities form"
              >
                Quantities
              </th>
            </tr>

            <tr class="sticky top-0 h-7 bg-white text-right border-collapse">
              <template
                v-for="i in 3"
                :key="i"
              >
                <th
                  class="border-b border-gray-200"
                  :title="tenderTitle(i)"
                >
                  Tender
                </th>

                <th
                  class="border-b border-gray-200 pr-2"
                  :title="toDateTitle(i)"
                >
                  To Date
                </th>

                <th
                  class="border-b border-gray-200 pr-4"
                  :title="toCompleteTitle(i)"
                >
                  To Complete
                </th>
              </template>
            </tr>
          </thead>

          <tbody>
            <WbsCodeRowDetails
              :wbs-code="wbsCode"
              :to-complete="toComplete"
            />
          </tbody>
        </table>
      </div>
    </td>
  </tr>
</template>

<script setup lang="ts">
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, australianNumber } from '@/helpers';
import WbsCode from '@/models/WbsCode';
import { useWbsCodesStore } from '@/store/wbsCodes';
import { nextTick, ref } from 'vue';
import WbsCodeRowDetails from './WbsCodeRowDetails.vue';

const props = defineProps<{
  wbsCode: WbsCode;
  toComplete: CostAtCompletion;
}>();

const open = ref(false);
const typingFinalQuantity = ref(false);
const wbsCodesStore = useWbsCodesStore();
const saveFinalQuantityAction = useStoreApiAction(wbsCodesStore.updateCode);
const finalQuantity = ref(undefined);

const updateWbsCode = () => {
  saveFinalQuantityAction.request(props.wbsCode.id, { final_quantity: finalQuantity.value }).then(() => {
    typingFinalQuantity.value = false;
  });
};

const finalQuantityInput = ref();

const toggleTypingFinalQuantity = () => {
  typingFinalQuantity.value = !typingFinalQuantity.value;

  if(typingFinalQuantity.value) {
    nextTick(() => {
      finalQuantityInput.value?.focusInput();
    });
  }
};

const toCompleteTitle = (i: number) => {
  switch (i) {
    case 1:
      return 'This is the number of days to complete Formula [Qty to Complete/Production rate To Complete]';

    case 2:
      return 'The forecasted production rate to complete the project, inserted by the user';

    case 3:
      return 'The final quantity less the Quantity to date. The formula is [Final Qty - to Date Qty]';
  }
};

const tenderTitle = (i: number) => {
  switch (i) {
    case 1:
      return 'The number of days based on the quantity and production rate. The formula is [Tender Qty/Tender Production rate]';

    case 2:
      return 'The production rate that is used in the tender estimate';

    case 3:
      return 'The Tender Quantity as adjusted by changes';
  }
};

const toDateTitle = (i: number) => {
  switch (i) {
    case 1:
      return 'The number of days with a recorded Quantity';

    case 2:
      return 'The production rate achieved to date [Quantity to date / No Days]';

    case 3:
      return 'The quantity to date as per the Quantities form';
  }
};
</script>

<style scoped></style>
