<template>
  <div class="!pt-0">
    <div class="flex flex-col space-y-2.5 max-h-screen pt-16 pb-2.5">
      <div class="flex items-center justify-between space-x-4 mt-2.5">
        <div class="flex space-x-2 element-island">
          <h1 class="font-semibold text-3xl">
            Plant Dashboard
          </h1>

          <a
            :href="exportUrl"
            target="_blank"
            class="btn btn--secondary-blue ml-2 inline-block"
          >
            Export
          </a>

          <button
            type="button"
            class="btn btn--secondary-blue ml-2"
            @click="showReport = true"
          >
            Utilisation Report
          </button>
        </div>

        <div class="element-island grid grid-cols-2">
          <button
            type="button"
            class="btn btn--secondary-blue"
            data-test="add-plant"
            @click="show = true"
          >
            + Plant
          </button>

          <button
            type="button"
            class="btn ml-2"
            :class="[plantsStore.active ? 'btn--primary-blue' : 'btn--secondary-blue']"
            data-test="show-filter"
            @click="showFilter = true"
          >
            Filter
          </button>
        </div>
      </div>

      <div
        v-if="plants.length === 0 && fetchPlantsAction.state.value === States.COMPLETE"
        class="py-5 px-3 element-island text-center font-semibold w-1/2 mx-auto"
        data-test="no-plant-message"
      >
        No Plant available, try adjusting your filter.
      </div>

      <div
        v-else-if="fetchPlantsAction.state.value === States.COMPLETE"
        class="element-island overflow-y-auto"
      >
        <PlantList
          :plants="plants"
          :project-id="$props.projectId"
        />
      </div>

      <div v-else>
        <AppSpinner class="h-[68px]" />
      </div>

      <PlantSaveModal
        v-if="show"
        :project-id="$props.projectId"
        @close="show = false"
      />

      <PlantFilterModal
        v-if="showFilter"
        @close="showFilter = false"
        @filter="setFilter"
        @clear="clearFilter"
      />

      <PlantUtilisationReport
        v-if="showReport"
        @close="showReport = false"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import PlantFilterModal from '@/components/PlantDashboard/PlantFilterModal.vue';
import PlantList from '@/components/PlantDashboard/PlantList.vue';
import PlantSaveModal from '@/components/PlantDashboard/PlantSaveModal.vue';
import PlantUtilisationReport from '@/components/PlantDashboard/PlantUtilisationReport.vue';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { usePlantsStore } from '@/store/plants';
import { useProjectsStore } from '@/store/projects';
import { route } from '@/ziggy-shim';
import { computed, ref, watch } from 'vue';

const props = defineProps<{
  projectId: string;
}>();

const plantsStore = usePlantsStore();
const fetchPlantsAction = useStoreApiAction(plantsStore.fetchPlants);

watch(() => props.projectId, () => {
  fetchPlantsAction.request(props.projectId, plantsStore.filter.form).catch((error) => {
    console.log(error);
  });
}, { immediate: true });

const show = ref(false);
const showFilter = ref(false);

const plants = computed(() =>
  plantsStore.models
    .with('supplier')
    .with('project')
    .with('records', (query) =>
      query
        .with('user').orderBy('day')
        .with('wbsCode')
        .with('comments', (query) => query.with('creator'))
        .with('plant', (query) => query.with('project')))
    .with('on_hired', (query) => {
      return query.orderBy('id');
    })
    .where('project_id', parseInt(props.projectId))
    .get()
);

const clearFilter = () => {
  showFilter.value = false;
  plantsStore.filter.resetData();
  plantsStore.models.flush();
  plantsStore.active = false;

  fetchPlantsAction.request(props.projectId).catch((error) => {
    console.log(error);
  });
};

const setFilter = () => {
  showFilter.value = false;
  plantsStore.models.flush();

  fetchPlantsAction.request(props.projectId, plantsStore.filter.form).then(() => {
    plantsStore.active = true;
  }).catch((error) => {
    console.log(error);
  });
};

const projectsStore = useProjectsStore();
const showReport = ref(false);

const exportUrl = computed(() => {
  if(projectsStore.project) {
    return route('api.v1.projects.export', projectsStore.project.id);
  }

  return undefined;
});
</script>

<style scoped></style>
