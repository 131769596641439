<template>
  <Teleport
    v-if="showTeleports"
    :to="`[data-month-row='${trade.id}']`"
  >
    <th
      class="w-10"
    >
      {{ trade.combinedStart.plus({ months: monthIndex - 1 }).toFormat('MMM-yy') }}
    </th>
  </Teleport>

  <Teleport
    v-if="showTeleports"
    :to="`[data-percent-row='${trade.id}']`"
  >
    <td>
      <div class="flex flex-col">
        <input
          class="form-input opacity-50"
          disabled
          value="-"
          type="text"
        >
      </div>
    </td>
  </Teleport>

  <Teleport
    v-if="showTeleports"
    :to="`[data-amount-row='${trade.id}']`"
  >
    <td>
      <div class="flex flex-col">
        <input
          class="form-input opacity-50"
          disabled
          value="-"
          type="text"
        >
      </div>
    </td>
  </Teleport>
</template>

<script setup lang="ts">
import { roundDecimals } from '@/helpers';
import Trade from '@/models/Trade';
import { onMounted, ref, watch } from 'vue';

const props = defineProps<{
  trade: Trade;
  monthIndex: number;
}>();

const showTeleports = ref(false);

onMounted(() => {
  showTeleports.value = true;
});
</script>

<style scoped></style>
