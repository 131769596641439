<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          {{ $props.wbsCode ? 'Update' : 'Add' }} Wbs Code
        </h1>
      </div>
    </template>

    <template #body>
      <div class="h-full w-full p-4 overflow-y-auto">
        <form
          action=""
          class="flex flex-col space-y-4"
          @submit.prevent="saveWbsCode"
        >
          <label>
            Code

            <AppInput
              v-model="wbsCodeForm.form.code"
              name="code"
              type="text"
              :error="wbsCodeForm.getErrors('code')"
              data-test="update-code"
              class="form-input"
            />
          </label>

          <label>
            Description

            <AppInput
              v-model="wbsCodeForm.form.description"
              name="description"
              type="text"
              :error="wbsCodeForm.getErrors('description')"
              data-test="update-description"
              class="form-input"
            />
          </label>

          <div class="flex space-x-4">
            <label>
              Unit

              <AppInput
                v-model="wbsCodeForm.form.unit"
                name="unit"
                type="text"
                :error="wbsCodeForm.getErrors('unit')"
                data-test="update-unit"
                class="form-input"
              />
            </label>

            <label>
              <p>
                Tender Production Rate <span class="text-sm text-gray-400">{{ wbsCodeForm.form.unit ? `(${wbsCodeForm.form.unit}/day)` : '' }}</span>
              </p>

              <AppInput
                v-model="wbsCodeForm.form.tender_production_rate"
                name="tender_production_rate"
                type="number"
                :error="wbsCodeForm.getErrors('tender_production_rate')"
                data-test="update-tender_production_rate"
                class="form-input"
              />
            </label>
          </div>


          <label>
            <p>
              Tender Quantity <span class="text-sm text-gray-400">{{ wbsCodeForm.form.unit ? `(${wbsCodeForm.form.unit})` : '' }}</span>
            </p>

            <AppInput
              v-model="wbsCodeForm.form.tender_quantity"
              name="tender_quantity"
              type="number"
              :error="wbsCodeForm.getErrors('tender_quantity')"
              data-test="update-tender_quantity"
              class="form-input"
            />
          </label>

          <label>
            <p>
              Tender Cost <span class="text-sm text-gray-400">($)</span>
            </p>

            <AppInput
              v-model="wbsCodeForm.form.tender_cost"
              name="tender_cost"
              type="number"
              :error="wbsCodeForm.getErrors('tender_cost')"
              data-test="update-tender_cost"
              class="form-input"
            />
          </label>

          <div class="flex space-x-4">
            <div
              v-if="isNumber(wbsCodeForm.form.tender_quantity / wbsCodeForm.form.tender_cost) && wbsCodeForm.form.unit"
              class="text-center w-1/2 my-auto"
            >
              <p>
                Tender Rate
              </p>

              <p>
                <span>
                  {{ `${australianCurrency(wbsCodeForm.form.tender_cost / wbsCodeForm.form.tender_quantity)}/${wbsCodeForm.form.unit}` }}
                </span>
              </p>
            </div>

            <div
              v-if="isNumber(wbsCodeForm.form.tender_quantity / wbsCodeForm.form.tender_cost) && wbsCodeForm.form.unit"
              class="text-center w-1/2 my-auto"
            >
              <p>
                Tender Days
              </p>

              <p>
                <span>
                  {{ `${australianNumber(wbsCodeForm.form.tender_quantity / wbsCodeForm.form.tender_production_rate)} days` }}
                </span>
              </p>
            </div>
          </div>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-between p-2 space-x-4">
        <button
          v-if="$props.wbsCode"
          type="button"
          class="btn btn--tertiary-blue"
          @click="deleteWbsCode"
        >
          Delete
        </button>

        <div class="space-x-2 ml-auto">
          <button
            type="button"
            class="btn btn--secondary-blue"
            @click="$emit('close'); wbsCodeForm.resetData()"
          >
            Cancel
          </button>

          <button
            type="button"
            class="btn btn--primary-blue"
            data-test="save-code"
            @click="saveWbsCode"
          >
            {{ $props.wbsCode ? 'Update' : 'Create' }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/Modal.vue';
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, australianNumber, isNumber } from '@/helpers';
import WbsCode from '@/models/WbsCode';
import { useWbsCodesStore } from '@/store/wbsCodes';
import Swal from 'sweetalert2';
import { watch } from 'vue';

const props = defineProps<{
  wbsCode?: WbsCode;
  projectId: Id;
}>();

const emits = defineEmits<{
  (e: 'close');
}>();

const wbsCodeStore = useWbsCodesStore();

const wbsCodeForm = useFormData({
  code: '',
  description: '',
  unit: '',
  tender_production_rate: 0,
  tender_quantity: 0,
  tender_cost: 0,
});

watch(() => props.wbsCode, () => {
  if(props.wbsCode) {
    wbsCodeForm.setData(props.wbsCode);
  }
}, { immediate: true });

const updateWbsCodeAction = useStoreApiAction(wbsCodeStore.updateCode);
const createWbsCodeAction = useStoreApiAction(wbsCodeStore.createCode);

const saveWbsCode = () => {
  if(props.wbsCode) {
    wbsCodeForm.resetErrors();

    updateWbsCodeAction.request(props.wbsCode.id, wbsCodeForm.form).then(() => {
      Swal.fire({
        icon: 'success',
        text: 'WBS Code updated',
      }).then(() => {
        emits('close');
        wbsCodeForm.resetData();
      });
    }).catch((error) => {
      wbsCodeForm.setErrors(error.data);
    });
  } else {
    createWbsCodeAction.request(props.projectId, wbsCodeForm.form).then(() => {
      Swal.fire({
        icon: 'success',
        text: 'WBS Code created',
      }).then(() => {
        emits('close');
        wbsCodeForm.resetData();
      });
    }).catch((error) => {
      wbsCodeForm.setErrors(error.data);
    });
  }
};

const deleteAction = useStoreApiAction(wbsCodeStore.deleteCode);

const deleteWbsCode = () => {
  Swal.fire({
    icon: 'warning',
    title: 'Delete WBS Code?',
    text: `Are you sure you want to delete ${props.wbsCode.code}?`,
    showCancelButton: true,
    showConfirmButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      deleteAction.request(props.wbsCode.id).then(() => {
        Swal.fire({
          icon: 'success',
          text: 'WBS Code deleted',
        }).then(() => {
          emits('close');
        });
      }).catch((error) => {
        if(error?.status === 422) {
          Swal.fire({
            icon: 'error',
            text: `Unable to delete ${props.wbsCode.code} as it has records associated with it.`,
          });
        }
      });
    }
  });
};
</script>

<style scoped></style>
