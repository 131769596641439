import { dateStringToYMD } from '@/helpers';
import DailyQuantity from '@/models/DailyQuantity';
import DailyQuantityLoads from '@/models/DailyQuantityLoads';
import DailyQuantityService from '@/services/DailyQuantityService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';

export const useDailyQuantitiesStore = defineStore('dailyQuantities', {
  state: () => ({}),

  getters: {
    models: () => useRepo(DailyQuantity),
  },

  actions: {
    async fetchQuantities(projectId: Id, form: ApiRequests['api.v1.quantities.index']) {
      const [data, error] = await DailyQuantityService.index(projectId, form);

      if(data) {
        this.models.save(data.quantities);

        return data;
      }

      throw error;
    },

    async createQuantity(projectId: Id, form: ApiRequests['api.v1.quantities.store']) {
      const [data, error] = await DailyQuantityService.create(projectId, form);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updateQuantity(id: Id, form: ApiRequests['api.v1.quantities.update']) {
      const [data, error] = await DailyQuantityService.update(id, form);

      if(data) {
        useRepo(DailyQuantityLoads).where('daily_quantity_id', id).delete();

        this.models.save(data);

        return data;
      }

      throw error;
    },

    async deleteQuantity(id: Id) {
      const [data, error] = await DailyQuantityService.delete(id);

      if(data) {
        this.models.destroy(id);

        return data;
      }

      throw error;
    },

    async queryQuantity(projectId: Id, form: ApiRequests['api.v1.quantities.query']) {
      const [data, error] = await DailyQuantityService.query(projectId, form);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async toComplete(
      projectId: Id,
      form: ApiRequests['api.v1.quantities.to-complete'],
    ): Promise<Map<string, { totalHours: number; totalCost: number; totalQuantity: number; totalDays: number }>> {
      const [data, error] = await DailyQuantityService
        .toComplete(projectId, { to_date: dateStringToYMD(form.to_date) });

      if(data) {
        return new Map(Object.entries(data));
      }

      throw error;
    },

    async forecast(projectId: Id, form: ApiRequests['api.v1.quantities.forecast']) {
      const [data, error] = await DailyQuantityService.forecast(projectId, form);

      if(data) {
        return data;
      }

      throw error;
    },
  },
});
