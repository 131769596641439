<template>
  <div
    class="flex flex-col items-center justify-center h-full
    space-y-2.5 my-auto md:my-0 md:max-h-content py-8 md:flex-row"
  >
    <div
      class="flex flex-col border border-gray-300 px-4 md:px-10 rounded-lg py-5
    justify-between items-center max-h-content h-full space-y-8 bg-white shadow-md m-5 lg:m-0"
    >
      <h1 class="text-4xl">
        Daily Production Rate
      </h1>

      <template v-if="project?.codes.length">
        <form
          action=""
          class="flex flex-col space-y-4 w-full"
          @submit.prevent="fetchMetrics"
        >
          <label>
            <span>
              WBS Code
            </span>

            <AppSelect
              v-model.number="wbs_code_id"
              :options="project?.codes.map((code) => ({ value: code.id, label: code.code }))"
              name="wbs_code"
              data-test="wbs_code"
              class="form-input"
            />
          </label>

          <div class="max-w-md">
            <label>
              WBS Description

              <AppSelect
                v-model.number="wbs_code_id"
                :options="project?.codes.map((code) => ({ value: code.id, label: code.description }))"
                name="wbs_code_description"
                data-test="wbs_code_description"
                class="form-input"
              />
            </label>
          </div>

          <label
            v-if="wbsCode"
            class="flex justify-between items-center"
          >
            <span>
              Unit
            </span>

            <span>{{ wbsCode.unit }}</span>
          </label>

          <label class="flex justify-between items-center">
            <button
              type="button"
              class="px-2 py-1 bg-gray-200 rounded-full"
              data-test="prev-day"
              @click="prevDate"
            >
              <Icon
                name="chevron-left-mini"
                class="h-5 w-5"
              />
            </button>

            <AppInput
              ref="selectDateInput"
              v-model="day"
              class="px-4 py-1 border border-gray-200 rounded-full"
              type="date"
              name="day"
              data-test="day"
            />

            <button
              type="button"
              class="px-2 py-1 bg-gray-200 rounded-full"
              data-test="next-day"
              @click="nextDate"
            >
              <Icon
                name="chevron-right-mini"
                class="h-5 w-5"
              />
            </button>
          </label>

          <button
            type="submit"
            :disabled="averageAction.state.value === States.LOADING"
            class="btn btn--primary-blue"
          >
            Fetch
          </button>
        </form>

        <div
          v-if="averageAction.state.value === States.COMPLETE && wbsCode"
          class="flex flex-col space-y-3 w-full"
        >
          <div class="flex text-lg justify-between space-x-2">
            <span>
              Tender Production Rate
            </span>

            <span>
              {{ australianCurrency(wbsCode.unitCost) }}/{{ wbsCode.unit }}
            </span>
          </div>

          <div class="flex text-lg justify-between space-x-2">
            <span>
              Average Production Rate
            </span>

            <span v-if="metrics.average_quantity === 0">
              N/A
            </span>

            <span
              v-else
              :class="[metrics.average_cost / metrics.average_quantity > wbsCode.unitCost ? 'text-red-600' : 'text-green-600']"
            >
              {{ australianCurrency(metrics.average_cost / metrics.average_quantity) }}/{{ wbsCode.unit }}
            </span>
          </div>

          <div class="flex text-lg justify-between space-x-2">
            <span>
              Daily Production Rate
            </span>

            <span v-if="metrics.daily_quantity === 0 || metrics.daily_cost === 0">
              N/A
            </span>

            <span
              v-else
              :class="[metrics.daily_cost / metrics.daily_quantity > wbsCode.unitCost ? 'text-red-600' : 'text-green-600']"
            >
              {{ australianCurrency(metrics.daily_cost / metrics.daily_quantity) }}/{{ wbsCode.unit }}
            </span>
          </div>
        </div>

        <div v-else>
          <AppSpinner class="h-[68px]" />
        </div>
      </template>

      <div
        v-else
        class="text-center font-semibold w-full p-2"
      >
        No WBS Codes found.
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useDayQueryParam from '@/composables/useDayQueryParam';
import useScreenSize from '@/composables/useScreenSize';
import { ScreenSize } from '@/composables/useScreenSize';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency } from '@/helpers';
import { useNavStore } from '@/store/nav';
import { useProjectsStore } from '@/store/projects';
import { useWbsCodesStore } from '@/store/wbsCodes';
import { computed, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const projectsStore = useProjectsStore();

const props = defineProps<{
  projectId: string;
}>();

const project = computed(() => {
  return projectsStore.models.with('codes').find(parseInt(props.projectId));
});

const wbs_code_id = ref();
const queryDate = useDayQueryParam();
const day = ref(queryDate.value.toISOString().slice(0, 10));

const metrics = reactive({
  average_cost: 0,
  average_quantity: 0,
  daily_cost: 0,
  daily_quantity: 0,
});

const averageAction = useStoreApiAction(projectsStore.wbsCodeAverage);

const fetchMetrics = () => {
  if(wbs_code_id.value) {
    averageAction.request(project.value?.id, { wbs_code: wbs_code_id.value, day: day.value })
      .then((averageMetrics) => {
        metrics.average_cost = averageMetrics.average_cost;
        metrics.average_quantity = averageMetrics.average_quantity;
        metrics.daily_cost = averageMetrics.daily_cost;
        metrics.daily_quantity = averageMetrics.daily_quantity;
      }).catch((error) => {
        console.error(error);
      });
  }
};

watch(project, (newValue) => {
  if(newValue) {
    wbs_code_id.value = newValue.codes[0]?.id;
    fetchMetrics();
  }
}, { immediate: true });

const wbsCodesStore = useWbsCodesStore();

const wbsCode = computed(() => {
  return wbsCodesStore.models.find(wbs_code_id.value);
});

const { screenSize } = useScreenSize();
const navStore = useNavStore();

if(screenSize.value < ScreenSize.MD) {
  navStore.backRoute = { name: 'MobileProjectPage' };
}

const nextDate = () => {
  const nextDay = new Date(day.value);

  nextDay.setDate(nextDay.getDate() + 1);
  day.value = nextDay.toISOString().slice(0, 10);
};

const prevDate = () => {
  const nextDay = new Date(day.value);

  nextDay.setDate(nextDay.getDate() - 1);
  day.value = nextDay.toISOString().slice(0, 10);
};

const router = useRouter();

watch(day, () => {
  router.replace({ query: { day: day.value } });
}, { immediate: true });

watch([day, wbs_code_id], () => {
  fetchMetrics();
});
</script>

<style scoped></style>
