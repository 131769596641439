<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          Split Record
        </h1>
      </div>
    </template>

    <template #body>
      <div class="flex h-full w-full p-4 overflow-y-auto">
        <div class="flex flex-col w-full my-2 px-3">
          <form
            action=""
            class="w-full space-y-2"
            @submit.prevent="saveRecord"
          >
            <h2 class="w-full text-center font-bold text-lg">
              Original
            </h2>

            <fieldset class="flex flex-col space-y-2">
              <legend class="flex justify-between font-bold w-full">
                <span>Time Details</span>

                <span>{{ oldTotalTime }}</span>
              </legend>

              <template v-if="!record.smu_start">
                <div class="flex space-x-4">
                  <label>
                    Start Time
                    <AppInput
                      v-model="recordForm.form.old.start_at"
                      name="start_at"
                      type="time"
                      data-test="old-start_at"
                      :error="recordForm.getErrors('old.start_at')"
                      class="form-input"
                    />
                  </label>

                  <label>
                    End Time
                    <AppInput
                      v-model="recordForm.form.old.end_at"
                      name="end_at"
                      :type="oldEndAtType"
                      data-test="old-end_at"
                      :error="recordForm.getErrors('old.end_at')"
                      class="form-input"
                    />
                  </label>
                </div>
              </template>

              <template v-else>
                <div class="flex justify-between space-x-4">
                  <label>
                    SMU Start
                    <AppInput
                      v-model.number="recordForm.form.old.smu_start"
                      name="smu_start"
                      type="number"
                      data-test="old-smu_start"
                      :error="recordForm.getErrors('old.smu_start')"
                      class="form-input"
                    />
                  </label>

                  <label>
                    SMU
                    <AppInput
                      v-model.number="recordForm.form.old.smu"
                      name="smu_end"
                      type="number"
                      data-test="old-smu_end"
                      :error="recordForm.getErrors('old.smu_end')"
                      class="form-input"
                    />
                  </label>
                </div>

                <div class="w-full flex justify-between">
                  <p class="font-bold">
                    SMU End
                  </p>

                  <p>{{ recordForm.form.old.smu_end }}</p>
                </div>
              </template>


              <div class="flex space-x-4">
                <label class="w-1/2">
                  Breakdown
                  <AppInput
                    v-model.number="recordForm.form.old.breakdown"
                    name="breakdown"
                    type="number"
                    step="0.5"
                    min="0"
                    data-test="old-breakdown"
                    :max="project?.plant_standard_shift"
                    :error="recordForm.getErrors('old.breakdown')"
                    class="form-input w-full"
                  />
                </label>

                <label class="w-1/2">
                  Standdown
                  <AppInput
                    v-model.number="recordForm.form.old.standdown"
                    name="standdown"
                    type="number"
                    step="0.5"
                    min="0"
                    data-test="old-standdown"
                    :max="project?.plant_standard_shift"
                    :error="recordForm.getErrors('old.standdown')"
                    class="form-input"
                  />
                </label>
              </div>
            </fieldset>

            <fieldset class="flex flex-col space-y-2">
              <legend class="font-bold">
                Location Details
              </legend>


              <label>
                Area

                <AppSelect
                  v-model="recordForm.form.old.area"
                  :options="project?.areas"
                  name="area"
                  data-test="old-area"
                  :error="recordForm.getErrors('old.area')"
                  class="form-input"
                />
              </label>

              <label>
                Element

                <AppSelect
                  v-model="recordForm.form.old.element"
                  :options="project?.elements"
                  name="element"
                  data-test="old-element"
                  :error="recordForm.getErrors('old.element')"
                  class="form-input"
                />
              </label>

              <label>
                Activity

                <AppSelect
                  v-model="recordForm.form.old.activity"
                  :options="project?.activities"
                  name="activity"
                  data-test="old-activity"
                  :error="recordForm.getErrors('old.activity')"
                  class="form-input"
                />
              </label>

              <label>
                WBS Code

                <AppSelect
                  v-model="recordForm.form.old.wbs_code_id"
                  :options="projectsStore.project?.codes.map((code) => ({ value: code.id, label: code.code }))"
                  name="wbs_code"
                  data-test="old-wbs_code"
                  :error="recordForm.getErrors('old.wbs_code_id')"
                  class="form-input"
                />
              </label>
            </fieldset>
          </form>
        </div>

        <div class="flex flex-col w-full my-2 px-3">
          <form
            action=""
            class="w-full space-y-2"
            @submit.prevent="saveRecord"
          >
            <h2 class="w-full text-center font-bold text-lg">
              New
            </h2>

            <fieldset class="flex flex-col space-y-2">
              <legend class="flex justify-between font-bold w-full">
                <span>Time Details</span>

                <span>{{ newTotalTime }}</span>
              </legend>

              <template v-if="!record.smu_start">
                <div class="flex space-x-4">
                  <label>
                    Start Time
                    <AppInput
                      v-model="recordForm.form.new.start_at"
                      name="start_at"
                      type="time"
                      data-test="new-start_at"
                      :error="recordForm.getErrors('new.start_at')"
                      class="form-input"
                    />
                  </label>

                  <label>
                    End Time
                    <AppInput
                      v-model="recordForm.form.new.end_at"
                      name="end_at"
                      :type="newEndAtType"
                      data-test="new-end_at"
                      :error="recordForm.getErrors('new.end_at')"
                      class="form-input"
                    />
                  </label>
                </div>
              </template>

              <template v-else>
                <div class="flex justify-between space-x-4">
                  <label>
                    SMU Start
                    <AppInput
                      v-model.number="recordForm.form.new.smu_start"
                      name="smu_start"
                      type="number"
                      data-test="new-smu_start"
                      :error="recordForm.getErrors('new.smu_start')"
                      class="form-input"
                    />
                  </label>

                  <label>
                    SMU
                    <AppInput
                      v-model.number="recordForm.form.new.smu"
                      name="smu_end"
                      type="number"
                      data-test="new-smu_end"
                      :error="recordForm.getErrors('new.smu_end')"
                      class="form-input"
                    />
                  </label>
                </div>

                <div class="w-full flex justify-between">
                  <p class="font-bold">
                    SMU End
                  </p>

                  <p>{{ recordForm.form.new.smu_end }}</p>
                </div>
              </template>

              <div class="flex space-x-4">
                <label class="w-1/2">
                  Breakdown
                  <AppInput
                    v-model.number="recordForm.form.new.breakdown"
                    name="breakdown"
                    type="number"
                    step="0.5"
                    min="0"
                    data-test="new-breakdown"
                    :max="project?.plant_standard_shift"
                    :error="recordForm.getErrors('new.breakdown')"
                    class="w-full form-input"
                  />
                </label>

                <label class="w-1/2">
                  Standdown
                  <AppInput
                    v-model.number="recordForm.form.new.standdown"
                    name="standdown"
                    type="number"
                    step="0.5"
                    min="0"
                    data-test="new-standdown"
                    :max="project?.plant_standard_shift"
                    :error="recordForm.getErrors('new.standdown')"
                    class="form-input"
                  />
                </label>
              </div>
            </fieldset>

            <fieldset class="flex flex-col space-y-2">
              <legend class="font-bold">
                Location Details
              </legend>

              <label>
                Area

                <AppSelect
                  v-model="recordForm.form.new.area"
                  :options="project?.areas"
                  name="area"
                  data-test="new-area"
                  :error="recordForm.getErrors('new.area')"
                  class="form-input"
                />
              </label>

              <label>
                Element

                <AppSelect
                  v-model="recordForm.form.new.element"
                  :options="project?.elements"
                  name="element"
                  data-test="new-element"
                  :error="recordForm.getErrors('new.element')"
                  class="form-input"
                />
              </label>

              <label>
                Activity

                <AppSelect
                  v-model="recordForm.form.new.activity"
                  :options="project?.activities"
                  name="activity"
                  data-test="new-activity"
                  :error="recordForm.getErrors('new.activity')"
                  class="form-input"
                />
              </label>

              <label>
                WBS Code

                <AppSelect
                  v-model="recordForm.form.new.wbs_code_id"
                  :options="projectsStore.project?.codes.map((code) => ({ value: code.id, label: code.code }))"
                  name="wbs_code"
                  data-test="new-wbs_code"
                  :error="recordForm.getErrors('new.wbs_code_id')"
                  class="form-input"
                />
              </label>
            </fieldset>
          </form>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end p-4">
        <div>
          <button
            type="button"
            class="btn btn--secondary-blue"
            @click.prevent="$emit('close')"
          >
            Cancel
          </button>

          <button
            type="button"
            class="btn btn--primary-blue ml-2"
            data-test="split-record"
            @click="saveRecord"
          >
            Split
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { dateForInput, timeForInput, timeZone } from '@/helpers';
import Plant from '@/models/Plant';
import { usePlantDailyRecordsStore } from '@/store/plantDailyRecord';
import { useProjectsStore } from '@/store/projects';
import Swal from 'sweetalert2';
import { computed, nextTick, ref, watch } from 'vue';
import Modal from '../Modal.vue';

const props = defineProps<{
  projectId: Id;
  recordId: Id;
  plant: Plant;
}>();

const emits = defineEmits<{
  (e: 'close');
}>();

const projectsStore = useProjectsStore();

const project = computed(() => {
  return projectsStore.models.find(props.projectId);
});

const recordForm = useFormData({
  new: {
    day: '',
    start_at: '',
    end_at: '',
    smu_start: 0,
    smu: 0,
    smu_end: 0,
    breakdown: 0,
    standdown: 0,
    quantity: 0,
    area: '',
    element: '',
    activity: '',
    wbs_code_id: undefined,
    comment: '',
    time_zone: timeZone,
  },

  old: {
    day: '',
    start_at: '',
    end_at: '',
    smu_start: 0,
    smu: 0,
    smu_end: 0,
    breakdown: 0,
    standdown: 0,
    quantity: 0,
    area: '',
    element: '',
    activity: '',
    wbs_code_id: undefined,
    comment: '',
    time_zone: timeZone,
  },
});

watch(() => recordForm.form.old.smu, (newValue) => {
  if(newValue) {
    recordForm.form.old.smu_end = newValue + recordForm.form.old.smu_start;
  } else {
    recordForm.form.old.smu_end = recordForm.form.old.smu_start;
  }
}, { immediate: true });

watch(() => recordForm.form.new.smu, (newValue) => {
  if(newValue) {
    recordForm.form.new.smu_end = newValue + recordForm.form.new.smu_start;
  } else {
    recordForm.form.new.smu_end = recordForm.form.new.smu_start;
  }
}, { immediate: true });

const plantDailyRecordsStore = usePlantDailyRecordsStore();
const oldEndAtType = ref<'time' | 'datetime-local'>('time');

watch(oldEndAtType, () => {
  recordForm.form.old.end_at = '';
});

const newEndAtType = ref<'time' | 'datetime-local'>('time');

watch(newEndAtType, () => {
  recordForm.form.new.end_at = '';
});

const record = computed(() => {
  return plantDailyRecordsStore.models.with('comments').with('plant').find(props.recordId);
});

plantDailyRecordsStore.fetchRecord(props.recordId).then(() => {
  plantDailyRecordsStore.models.with('comments').load([record.value]);
});

watch(record, (newValue) => {
  if(newValue) {
    if(!props.plant.smu_tracking) {
      recordForm.form.old.start_at = timeForInput(newValue.startAt);
      const hour = newValue.startAt.hour;

      if(hour >= 17) {
        oldEndAtType.value = 'datetime-local';

        nextTick(() => {
          recordForm.form.old.end_at = dateForInput(newValue.endAt);
        });
      } else {
        oldEndAtType.value = 'time';

        nextTick(() => {
          recordForm.form.old.end_at = timeForInput(newValue.endAt);
        });
      }

      recordForm.form.new.start_at = timeForInput(newValue.endAt);
      const end = newValue.endAt.plus({ hours: projectsStore.project?.labour_standard_shift });

      if(hour >= 17) {
        newEndAtType.value = 'datetime-local';

        nextTick(() => {
          recordForm.form.new.end_at = dateForInput(end);
        });
      } else {
        newEndAtType.value = 'time';

        nextTick(() => {
          recordForm.form.new.end_at = timeForInput(end);
        });
      }
    }

    recordForm.setData({
      old: newValue,
      new: {
        day: newValue.day,
      },
    }, [
      'old.start_at',
      'old.end_at',
      'old.time_zone',
    ]);

    if(props.plant.smu_tracking) {
      recordForm.form.old.smu = recordForm.form.old.smu_end - recordForm.form.old.smu_start;
    }
  }
}, { immediate: true });

watch(() => recordForm.form.old.start_at, (newValue, oldValue) => {
  if(newValue) {
    const hourSplit = newValue.split(':');
    const hour = parseInt(hourSplit[0]);

    if(hour >= 17) {
      oldEndAtType.value = 'datetime-local';

      nextTick(() => {
        if(!oldValue && !recordForm.form.old.end_at) {
          const endAt = new Date(record?.value.day);
          const hourSplit = newValue.split(':');

          endAt.setHours(parseInt(hourSplit[0]));
          endAt.setMinutes(parseInt(hourSplit[1]));
          recordForm.form.old.end_at = dateForInput(endAt);
        }
      });
    } else {
      oldEndAtType.value = 'time';

      nextTick(() => {
        if(!oldValue && !recordForm.form.old.end_at) {
          const endAt = new Date(record?.value.day);
          const hourSplit = newValue.split(':');

          endAt.setHours(parseInt(hourSplit[0]));
          endAt.setMinutes(parseInt(hourSplit[1]));
          recordForm.form.old.end_at = timeForInput(endAt);
        }
      });
    }
  }
});

watch(() => recordForm.form.new.start_at, (newValue, oldValue) => {
  if(newValue) {
    const hourSplit = newValue.split(':');
    const hour = parseInt(hourSplit[0]);

    if(hour >= 17) {
      newEndAtType.value = 'datetime-local';

      nextTick(() => {
        if(!oldValue && !recordForm.form.new.end_at) {
          const endAt = new Date(record?.value.day);
          const hourSplit = newValue.split(':');

          endAt.setHours(parseInt(hourSplit[0]));
          endAt.setMinutes(parseInt(hourSplit[1]));
          recordForm.form.new.end_at = dateForInput(endAt);
        }
      });
    } else {
      newEndAtType.value = 'time';

      nextTick(() => {
        if(!oldValue && !recordForm.form.new.end_at) {
          const endAt = new Date(record?.value.day);
          const hourSplit = newValue.split(':');

          endAt.setHours(parseInt(hourSplit[0]));
          endAt.setMinutes(parseInt(hourSplit[1]));
          recordForm.form.new.end_at = timeForInput(endAt);
        }
      });
    }
  }
});

const oldTotalTime = computed(() => {
  if(recordForm.form.old.start_at && recordForm.form.old.end_at) {
    let endAt: Date = undefined;

    if(oldEndAtType.value === 'time') {
      endAt = new Date(`${record?.value.day} ${recordForm.form.old.end_at}`);
    } else {
      endAt = new Date(recordForm.form.old.end_at);
    }

    const timeDiff = Math.abs(
      new Date(`${record?.value.day} ${recordForm.form.old.start_at}`).getTime() -
        endAt.getTime(),
    ) / 3600000;

    return `${(Math.round(timeDiff * 2) / 2).toFixed(1)} hrs`;
  }

  if(recordForm.form.old.smu_start && recordForm.form.old.smu_end) {
    const timeDiff = Math.abs(recordForm.form.old.smu_start - recordForm.form.old.smu_end);

    return Math.round(timeDiff * 2) / 2;
  }

  return undefined;
});

const newTotalTime = computed(() => {
  if(recordForm.form.new.start_at && recordForm.form.new.end_at) {
    let endAt: Date = undefined;

    if(newEndAtType.value === 'time') {
      endAt = new Date(`${record?.value.day} ${recordForm.form.new.end_at}`);
    } else {
      endAt = new Date(recordForm.form.new.end_at);
    }

    const timeDiff = Math.abs(
      new Date(`${record?.value.day} ${recordForm.form.new.start_at}`).getTime() -
        endAt.getTime(),
    ) / 3600000;

    return `${(Math.round(timeDiff * 2) / 2).toFixed(1)} hrs`;
  }

  if(recordForm.form.new.smu_start && recordForm.form.new.smu_end) {
    const timeDiff = Math.abs(recordForm.form.new.smu_start - recordForm.form.new.smu_end);

    return Math.round(timeDiff * 2) / 2;
  }

  return undefined;
});

const splitRecordAction = useStoreApiAction(plantDailyRecordsStore.splitRecord);

const saveRecord = () => {
  recordForm.resetErrors();

  splitRecordAction
    .request(record.value.id, recordForm.form, { newDay: recordForm.form.new.day, oldDay: recordForm.form.old.day })
    .then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Record Split',
      }).then(() => {
        emits('close');
      });
    })
    .catch((error) => {
      console.log(error);

      recordForm.setErrors(error.data);
    });
};
</script>

<style scoped></style>
