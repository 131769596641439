import Comment from '@/models/Comment';
import LabourDailyRecord from '@/models/LabourDailyRecord';
import LabourDailyRecordService from '@/services/LabourDailyRecordService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';

export const useLabourDailyRecordStore = defineStore('labourDailyRecords', {
  state: () => ({}),

  getters: {
    models: () => useRepo(LabourDailyRecord),
  },

  actions: {
    async createRecord(labourId: Id, recordForm: ApiRequests['api.v1.labour.daily.records.store']) {
      const [data, error] = await LabourDailyRecordService.create(labourId, recordForm);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updateRecord(
      id: Id,
      recordForm: ApiRequests['api.v1.labour.daily.records.update'],
      transformData: { day: string },
    ) {
      const [data, error] = await LabourDailyRecordService.update(id, recordForm, transformData);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async approve(id: Id) {
      const [data, error] = await LabourDailyRecordService.approve(id);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async unapprove(id: Id) {
      const [data, error] = await LabourDailyRecordService.unapprove(id);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async paid(id: Id, paid: ApiRequests['api.v1.labour.daily.records.paid']) {
      const [data, error] = await LabourDailyRecordService.paid(id, paid);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async fetchRecords(
      labourId: Id,
      form: ApiRequests['api.v1.labour.daily.records.index'],
    ): Promise<ResourcePaginator<LabourDailyRecord>> {
      const [data, error] = await LabourDailyRecordService.index(labourId, form);

      if(data) {
        this.models.save(data.data);

        return data;
      }

      throw error;
    },

    async fetchRecord(recordId: Id) {
      const [data, error] = await LabourDailyRecordService.show(recordId);

      if(data) {
        // Save comments separately as pinia orm cannot handle saving the morph relationship
        const comments = data.comments;

        delete data.comments;
        const commentsRepo = useRepo(Comment);

        commentsRepo.save(comments);

        this.models.save(data);

        return data;
      }

      throw error;
    },

    async splitRecord(
      id: Id,
      request: ApiRequests['api.v1.labour.daily.records.split'],
      transformData: { newDay: string; oldDay: string },
    ) {
      const [data, error] = await LabourDailyRecordService.split(id, request, transformData);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async comment(id: Id, request: ApiRequests['api.v1.labour.daily.records.comments']) {
      const [data, error] = await LabourDailyRecordService.comment(id, request);

      if(data) {
        const comments = useRepo(Comment);

        comments.save(data);

        return data;
      }

      throw error;
    },

    async vendor(id: Id, form: ApiRequests['api.v1.labour.daily.records.vendor']) {
      const [data, error] = await LabourDailyRecordService.vendor(id, form);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async search(projectId: Id, form: ApiRequests['api.v1.labour.daily.records.search']) {
      const [data, error] = await LabourDailyRecordService.search(projectId, form);

      if(data) {
        return data;
      }

      throw error;
    },

    async deleteRecord(id: Id) {
      const [data, error] = await LabourDailyRecordService.delete(id);

      if(data) {
        this.models.destroy(data.id);

        return data;
      }

      throw error;
    },
  },
});
