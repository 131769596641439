<template>
  <table class="w-full border-separate border-spacing-0 border border-black rounded-lg">
    <thead>
      <tr>
        <th
          colspan="4"
          class="bg-orange-500 rounded-tl-lg"
        >
          Labour
        </th>

        <th
          colspan="5"
          class="bg-blue-500 rounded-tr-lg"
        >
          Costs
        </th>
      </tr>

      <tr class="bg-gray-300 text-left">
        <th class="pl-4 py-2">
          Name
        </th>

        <th>Supplier</th>

        <th title="The labour classification reference for skill rating">
          CW Ref
        </th>

        <th>
          Employment
        </th>

        <th class="text-right">
          Rate
        </th>

        <th class="text-right">
          Total hrs
        </th>

        <th class="text-right">
          Accrual
        </th>

        <th class="text-right">
          Paid to Date
        </th>

        <th class="text-right pr-4">
          Total Cost
        </th>
      </tr>
    </thead>

    <tr
      class="sticky -top-2 bg-white z-10"
      data-test="totals"
    >
      <td
        colspan="5"
        class="border-y border-r border-black"
      />
      <!--
      <td
        class="border-y border-black text-right bg-white"
      >
        Total
      </td> -->

      <td
        class="border-y border-black text-right bg-white"
        data-test="totals-hours"
      >
        {{
          $props.labours.reduce((total, value) => total + value.totalHours, 0).toLocaleString(
            'en-AU', { maximumFractionDigits: 2, minimumFractionDigits: 2}
          )
        }}
      </td>

      <td
        class="border-y border-black text-right bg-white"
        data-test="totals-accrual"
      >
        {{
          australianCurrency(
            $props.labours.reduce((total, value) => total + value.totalAccrual, 0)
          )
        }}
      </td>

      <td
        class="border-y border-black text-right bg-white"
        data-test="totals-paid-to-date"
      >
        {{
          australianCurrency(
            $props.labours.reduce((total, value) => total + value.paidToDate, 0)
          )
        }}
      </td>

      <td
        class="border-y border-black text-right pr-4 bg-white"
        data-test="totals-total"
      >
        {{
          australianCurrency(
            $props.labours.reduce((total, value) => total + (value.paidToDate + value.totalAccrual), 0)
          )
        }}
      </td>
    </tr>

    <tbody
      class="h-full rounded-b-lg"
      data-test="labour-list"
    >
      <LabourListRow
        v-for="labour in $props.labours"
        :key="labour.id"
        :labour="labour"
        @show-update-modal="$event => showUpdateModal($event)"
      />
    </tbody>
  </table>

  <div>
    <LabourSaveModal
      v-if="show"
      :labour-id="selectedLabourId"
      :project-id="$props.projectId"
      @close="show = false"
    />
  </div>
</template>

<script setup lang="ts">
import { australianCurrency } from '@/helpers';
import Labour from '@/models/Labour';
import { ref } from 'vue';
import LabourListRow from './LabourListRow.vue';
import LabourSaveModal from './LabourSaveModal.vue';

defineProps<{
  labours: Labour[];
  projectId: string;
}>();

const show = ref(false);
const selectedLabourId = ref(undefined);

const showUpdateModal = (id: Id) => {
  selectedLabourId.value = id;
  show.value = true;
};
</script>

<style scoped></style>
