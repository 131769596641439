export class Queue<T> {
  _queue: T[];
  _maxCapacity: number;

  constructor(maxCapacity = 10, queue?: T[]) {
    this._queue = queue || [];
    this._maxCapacity = maxCapacity;
  }

  enqueueFront(elements: T[]) {
    const newQueue = [...new Set(this._queue.concat(elements))];
    const elementsToDequeue = newQueue.slice(0, -this._maxCapacity);

    this._queue = newQueue.slice(-this._maxCapacity);

    return elementsToDequeue;
  }

  enqueueBack(elements: T[]) {
    const newQueue = [...new Set(elements.concat(this._queue))];
    const elementsToDequeue = newQueue.slice(this._maxCapacity);

    this._queue = newQueue.slice(0, this._maxCapacity);

    return elementsToDequeue;
  }

  dequeue() {
    const element = this._queue[this._queue.length - 1];

    this._queue[this._queue.length - 1] = undefined;

    return element;
  }

  reset() {
    this._queue = [];
  }
}

export function useQueue<T>(maxCapacity: number, queue?: T[]) {
  return new Queue(maxCapacity, queue);
}
