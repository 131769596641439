<template>
  <Teleport
    v-if="showTeleports"
    :to="`[data-month-row='${trade.id}']`"
  >
    <th
      class="w-10"
      :class="{ 'text-red-600': error}"
    >
      {{ trade.combinedStart.plus({ months: monthIndex - 1 }).toFormat('MMM-yy') }}
    </th>
  </Teleport>

  <Teleport
    v-if="showTeleports"
    :to="`[data-percent-row='${trade.id}']`"
  >
    <td>
      <AppInput
        v-model="forecastPercent"
        name="forecast[]"
        type="number"
        step="1"
        class="w-32 form-input mx-auto"
        :disabled="actualFilled"
        @focus="percentFocused = true"
        @blur="saveForecastAmount(); percentFocused = false;"
        @keyup.enter="saveForecastAmount"
        @keyup.esc="revertForecastAmount"
      />
    </td>
  </Teleport>

  <Teleport
    v-if="showTeleports"
    :to="`[data-amount-row='${trade.id}']`"
  >
    <td>
      <AppInput
        v-model="forecastAmount"
        name="forecast_amounts[]"
        type="number"
        step="1"
        class="w-32 form-input mx-auto"
        :disabled="actualFilled"
        @focus="amountFocused = true"
        @blur="saveForecastAmount(); amountFocused = false;"
        @keyup.enter="saveForecastAmount"
        @keyup.esc="revertForecastAmount"
      />
    </td>
  </Teleport>
</template>

<script setup lang="ts">
import { roundDecimals } from '@/helpers';
import Trade from '@/models/Trade';
import { onMounted, ref, watch } from 'vue';

const props = defineProps<{
  trade: Trade;
  monthIndex: number;
  error: boolean;
  actualFilled: boolean;
}>();

const emits = defineEmits<{
  (e: 'savePercent', data: { newAmount: number; oldAmount: number });
}>();

const percentFocused = ref(false);
const amountFocused = ref(false);
const forecastPercent = defineModel<number | ''>('percent');
const forecastAmount = defineModel<number | ''>('amount');
const oldForecastAmount = ref(forecastAmount.value);

watch(forecastPercent, (newValue) => {
  // if(newValue !== oldValue && !percentFocused.value && !amountFocused.value) {
  //   oldForecastAmount.value = newValue || 0;
  // }

  if(!amountFocused.value && percentFocused.value) {
    forecastAmount.value = newValue ? roundDecimals(props.trade.totalBudget * (newValue / 100)) : newValue;
  }
});

const saveForecastAmount = () => {
  emits('savePercent', {
    newAmount: forecastAmount.value || 0,
    oldAmount: oldForecastAmount.value || 0,
  });

  oldForecastAmount.value = forecastAmount.value;
};

const revertForecastAmount = () => {
  forecastAmount.value = oldForecastAmount.value;
};

watch(forecastAmount, (newValue, oldValue) => {
  if(newValue !== oldValue && !percentFocused.value && !amountFocused.value) {
    oldForecastAmount.value = newValue || 0;
  }

  if(!percentFocused.value) {
    forecastPercent.value = newValue ? roundDecimals((newValue / props.trade.totalBudget) * 100) : newValue;
  }
});

const showTeleports = ref(false);

onMounted(() => {
  showTeleports.value = true;
});
</script>

<style scoped></style>
