import Comment from '@/models/Comment';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';

export const useCommentsStore = defineStore('comments', {
  state: () => ({}),

  getters: {
    models: () => useRepo(Comment),
  },

  actions: {},
});
