import { route } from '@/ziggy-shim';
import { api, CastValue } from './LaravelClient';

export default {
  async index(projectId: Id, data: ApiRequests['api.v1.quantities.index']) {
    return await api(route('api.v1.quantities.index', projectId), 'get', {
      data,
    });
  },

  async create(projectId: Id, data: ApiRequests['api.v1.quantities.store']) {
    return await api(route('api.v1.quantities.store', projectId), 'post', {
      data,
      casts: {
        asset_types: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async update(id: Id, data: ApiRequests['api.v1.quantities.update']) {
    return await api(route('api.v1.quantities.update', id), 'patch', {
      data,
      casts: {
        asset_types: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async delete(id: Id) {
    return await api(route('api.v1.quantities.destroy', id), 'delete');
  },

  async query(projectId: Id, data: ApiRequests['api.v1.quantities.query']) {
    return await api(route('api.v1.quantities.query', projectId), 'get', {
      data,
    });
  },

  async toComplete(projectId: Id, data: ApiRequests['api.v1.quantities.to-complete']) {
    return await api(route('api.v1.quantities.to-complete', projectId), 'get', {
      data,
    });
  },

  async forecast(projectId: Id, data: ApiRequests['api.v1.quantities.forecast']) {
    return await api(route('api.v1.quantities.forecast', projectId), 'get', {
      data,
    });
  },
};
