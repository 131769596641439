<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          Copy Company Supplier
        </h1>
      </div>
    </template>

    <template #body>
      <div class="max-h-[min(45rem,90vh)] h-full w-full p-4 min-w-md">
        <form
          action=""
          class="space-y-4"
          @submit.prevent="saveSupplier"
        >
          <label>
            <p>
              Company Supplier
            </p>

            <AppTypeAhead
              v-model="supplierForm.form.supplier_name"
              name="supplier_name"
              :suggestion-callback="getSuggestions"
              :initial-suggestions="getSuggestions"
              data-test="supplier-name"
              :error="supplierForm.getErrors('supplier_id') || supplierForm.getErrors('supplier_name') || supplierForm.getErrors('project_id')"
              class="w-full"
              @value-selected="selectSupplier"
            />
          </label>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-between p-2 space-x-4">
        <div class="space-x-2 ml-auto">
          <button
            type="button"
            class="btn btn--secondary-blue"
            @click="$emit('close'); supplierForm.resetData()"
          >
            Cancel
          </button>

          <button
            type="button"
            class="btn btn--primary-blue"
            data-test="save-order"
            @click="saveSupplier"
          >
            Copy to Project
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/Modal.vue';
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { useCompanyProjectSupplierStore } from '@/store/companyProjectSupplier';
import { useSuppliersStore } from '@/store/supplier';
import Swal from 'sweetalert2';
import { nextTick, ref, watch } from 'vue';

const props = defineProps<{
  projectId: Id;
  companyId: Id;
}>();

const emits = defineEmits<{
  (e: 'close');
}>();

const projectSuppliersStore = useCompanyProjectSupplierStore();

const supplierForm = useFormData({
  supplier_name: '',
  supplier_id: undefined,
  project_id: undefined,
});

watch(() => props.projectId, (newValue) => {
  supplierForm.form.project_id = newValue;
}, { immediate: true });

const cloneProjectSupplerAction = useStoreApiAction(projectSuppliersStore.cloneProjectSuppler);

const saveSupplier = () => {
  supplierForm.resetErrors();

  cloneProjectSupplerAction.request(props.companyId, supplierForm.form).then(() => {
    Swal.fire({
      icon: 'success',
      text: 'Supplier updated',
    }).then(() => {
      emits('close');
      supplierForm.resetData();
    });
  }).catch((error) => {
    supplierForm.setErrors(error.data);
  });
};

const suppliersStore = useSuppliersStore();
const suggestedSuppliers = ref<{ id: number; name: string }[]>([]);

const getSuggestions = async () => {
  suggestedSuppliers.value = await suppliersStore
    .search({ name: supplierForm.form.supplier_name, company_id: parseInt(`${props.companyId}`) });

  return suggestedSuppliers.value.map((value) => value.name);
};

const selectSupplier = (index: number) => {
  nextTick(() => {
    supplierForm.form.supplier_id = suggestedSuppliers.value[index].id;
  });
};
</script>

<style scoped></style>
