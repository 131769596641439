<template>
  <tr class="even:bg-gray-100 odd:bg-white text-right border-black border-x hover:bg-blue-100 p-2 m-2">
    <td
      class="py-2 text-left pl-3"
    >
      {{ $props.variationOrder.code }}
    </td>

    <td class="pr-3">
      {{ australianCurrency($props.variationOrder.budget) }}
    </td>

    <td
      v-if="!typingClaim"
      :class="{ 'cursor-pointer': canEditClaim }"
      class="bg-green-200/50"
      @click="typeClaim"
    >
      <div class="group flex items-center justify-end">
        <Icon
          v-if="canEditClaim"
          name="edit"
          class="group-hover:visible btn-icon h-5 w-5 inline-block invisible"
        />
        {{ canEditClaim ? australianCurrency(costForm.form.current_claim) : australianCurrency(variationOrderCost.current_claim) }}
      </div>
    </td>

    <td
      v-else-if="canEditClaim"
      class="bg-green-200/50"
    >
      <div class="w-1/2 ml-auto">
        <AppInput
          ref="claimInput"
          v-model.number="costForm.form.current_claim"
          name="current_claim"
          type="number"
          min="1"
          data-test="quantity-input"
          class="w-20 h-6"
          @blur="stopTypingClaim"
          @keyup.enter="stopTypingClaim"
          @keyup.esc="typingClaim = false"
        />
      </div>
    </td>

    <td
      v-else
      class="bg-green-200/50"
    />

    <td
      v-if="variationOrderCost.current_certified && !typingCertified"
      :class="{ 'cursor-pointer': canEditCertified }"
      class="bg-green-200/50"
      @click="typeCertified"
    >
      <div class="group flex items-center justify-end">
        <Icon
          v-if="canEditCertified"
          name="edit"
          class="group-hover:visible btn-icon h-5 w-5 inline-block invisible"
        />
        {{ canEditCertified ? australianCurrency(costForm.form.current_certified) : australianCurrency(variationOrderCost.current_certified) }}
      </div>
    </td>

    <td
      v-else-if="canEditCertified"
      class="bg-green-200/50"
    >
      <div class="w-1/2 ml-auto">
        <AppInput
          ref="claimInput"
          v-model.number="costForm.form.current_certified"
          name="current_certified"
          type="number"
          min="1"
          data-test="quantity-input"
          class="w-20 h-6"
          @blur="stopTypingCertified"
          @keyup.enter="stopTypingCertified"
          @keyup.esc="typingCertified = false"
        />
      </div>
    </td>

    <td
      v-else
      class="bg-green-200/50"
    />

    <td class="bg-green-200/50">
      {{ australianCurrency(variationOrder.previous(claim)) }}
    </td>

    <td class="bg-green-200/50 pr-3">
      {{ australianCurrency(variationOrder.previous(claim) + (variationOrderCost?.current_certified || 0)) }}
    </td>

    <td class="bg-blue-300/50">
      {{ variationOrderCost ? `${australianNumber((variationOrderCost.current_claim / totalCosts.budget) * 100)}%` : '' }}
    </td>

    <td class="bg-blue-300/50">
      {{ variationOrderCost ? `${australianNumber((variationOrderCost.current_certified / totalCosts.budget) * 100)}%` : '' }}
    </td>

    <td class="bg-blue-300/50">
      {{ australianNumber((variationOrder.previous(claim) / totalCosts.budget) * 100) }}%
    </td>

    <td class="bg-blue-300/50 pr-3">
      {{ australianNumber(((variationOrder.previous(claim) + (variationOrderCost?.current_certified || 0)) / totalCosts.budget) * 100) }}%
    </td>

    <td
      :class="{ 'text-red-600': hasVariance && variance !== 0 }"
      :title="hasVariance && variance !== 0 ? varianceComment?.body : ''"
    >
      {{ hasVariance ? australianCurrency(variance) : '' }}
    </td>

    <td class="pr-3">
      {{ australianCurrency(variationOrder.budget - variationOrder.previous(claim) - (variationOrderCost?.current_certified || 0)) }}
    </td>
  </tr>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { useSignedData } from '@/composables/useSignedData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, australianNumber, falsey, parseUrlParams } from '@/helpers';
import Claim, { ClaimStatus } from '@/models/Claim';
import VariationOrder from '@/models/VariationOrder';
import VariationOrderCost from '@/models/VariationOrderCost';
import { useCommentsStore } from '@/store/comments';
import { useVariationOrderCostsStore } from '@/store/variationOrderCosts';
import { useVariationOrdersStore } from '@/store/variationOrders';
import Swal from 'sweetalert2';
import { computed, nextTick, ref, watch } from 'vue';

const props = defineProps<{
  variationOrderCost: VariationOrderCost;
  variationOrder: VariationOrder;
  claim: Claim;
}>();

const variationOrdersStore = useVariationOrdersStore();

const variationOrders = computed(() => {
  return variationOrdersStore.models.where('type', props.variationOrder.type).with('costs', (query) => {
    query.where('claim_id', props.claim.id);
  }).get();
});

const totalCosts = computed(() => {
  const totals = {
    budget: 0,
    currentClaim: 0,
    currentCertified: 0,
    previous: 0,
    cumulative: 0,
  };

  variationOrders.value.forEach((variationOrder) => {
    totals.budget += variationOrder.budget;
    totals.previous += variationOrder.previous(props.claim);
    const variationOrderCost = variationOrder.costs[0];

    if(variationOrderCost) {
      totals.currentClaim += variationOrderCost.current_claim;
      totals.currentCertified += variationOrderCost.current_certified;
    }
  });

  totals.cumulative = totals.previous + totals.currentCertified;

  return totals;
});

const variance = computed(() => {
  return props.variationOrderCost.current_claim - props.variationOrderCost.current_certified;
});

const hasVariance = computed(() => {
  return !falsey(props.variationOrderCost.current_claim) && !falsey(props.variationOrderCost.current_certified);
});

const commentsStore = useCommentsStore();

const varianceComment = computed(() => {
  return commentsStore.models
    .where('commentable_id', props.variationOrderCost.id)
    .where('commentable_type', props.variationOrderCost.$entity())
    .where('creator_id', props.claim.id)
    .where('creator_type', props.claim.$entity())
    .first();
});

const typingClaim = ref(false);
const typingCertified = ref(false);
const signedParams = useSignedData();
const claimInput = ref();
const certifiedInput = ref();

const canEditClaim = computed(() => {
  return signedParams.meta.edit_claim &&
    (props.claim.status === ClaimStatus.contractor_claim || props.claim.status === ClaimStatus.draft_contractor);
});

const canEditCertified = computed(() => {
  return signedParams.meta.edit_certified && props.claim.status === ClaimStatus.pqs_review;
});

const typeClaim = () => {
  if(canEditClaim.value) {
    typingClaim.value = true;

    nextTick(() => {
      claimInput.value?.focusInput();
    });
  }
};

const typeCertified = () => {
  if(canEditCertified.value) {
    typingCertified.value = true;

    nextTick(() => {
      certifiedInput.value?.focusInput();
    });
  }
};

const variationOrderCostsStore = useVariationOrderCostsStore();
const saveClaimAction = useStoreApiAction(variationOrderCostsStore.saveClaim);

const claimParams = computed(() => {
  return parseUrlParams(signedParams.meta.variation_claim_url as string);
});

const costForm = useFormData({
  id: undefined,
  current_certified: 0,
  current_claim: 0,
  variation_order_id: undefined,
  comment: undefined,
});

watch(() => props.variationOrderCost, (newValue) => {
  if(newValue) {
    if(canEditCertified.value) {
      costForm.setData({
        id: newValue.id,
        current_certified: newValue.current_certified || 0,
      });
    } else if(canEditClaim.value) {
      costForm.setData({
        id: newValue.id,
        current_claim: newValue.current_claim || 0,
      });
    }
  }
}, { immediate: true });

const stopTypingClaim = () => {
  if(costForm.form.current_claim !== props.variationOrderCost.current_claim) {
    saveClaimAction.request(props.claim.id, claimParams.value, costForm.form).then((tradeItemCost) => {
      console.log(tradeItemCost);
    }).catch((error) => {
      console.log(error);

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong.',
      });
    });
  }

  typingClaim.value = false;
};

const certifiedParams = computed(() => {
  return parseUrlParams(signedParams.meta.variation_certified_url as string);
});

const saveCertifiedAction = useStoreApiAction(variationOrderCostsStore.saveCertified);

const stopTypingCertified = async () => {
  if(costForm.form.current_certified !== props.variationOrderCost.current_certified) {
    if(costForm.form.current_certified !== props.variationOrderCost.current_claim) {
      const comment = await Swal.fire({
        icon: 'warning',
        title: 'Reason for Adjustment',
        text: 'Please provide a reason for the variance between the claim and certified amounts.',
        input: 'text',
      });

      costForm.form.comment = comment.value;
    } else {
      costForm.form.comment = undefined;
    }

    saveCertifiedAction.request(props.claim.id, certifiedParams.value, costForm.form).catch((error) => {
      console.log(error);

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong.',
      });

      costForm.form.current_certified = props.variationOrderCost.current_certified;
    });
  }

  typingCertified.value = false;
};
</script>

<style scoped></style>
