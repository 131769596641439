<template>
  <div class="space-y-10 p-5">
    <div class="card">
      <div class="card__header">
        <div class="flex justify-between">
          <p>
            Users
          </p>

          <p>
            Total Users {{ users.length }}
          </p>
        </div>
      </div>

      <div class="flex w-full p-4 space-x-4">
        <ul
          v-if="fetchCompanyUsersAction.is(States.COMPLETE) && users.length > 0"
          class="overflow-auto space-y-4 flex-1"
        >
          <CompanyUserRow
            v-for="user in users"
            :key="user.id"
            :user="user"
            :company="company"
          />
        </ul>

        <div
          v-else-if="fetchCompanyUsersAction.is(States.COMPLETE) && users.length === 0"
          class="font-semibold mx-auto"
        >
          No users found
        </div>

        <div
          v-else
          class="mx-auto"
        >
          <AppSpinner />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { useCompaniesStore } from '@/store/companies';
import { useRolesStore } from '@/store/roles';
import { useUserStore } from '@/store/user';
import Swal from 'sweetalert2';
import { computed, watch } from 'vue';
import CompanyUserRow from './CompanyUserRow.vue';

const props = defineProps<{
  companyId: number;
}>();

const emits = defineEmits<{
  (e: 'dirty');
  (e: 'saved');
}>();

const companiesStore = useCompaniesStore();
const userStore = useUserStore();
const fetchCompanyUsersAction = useStoreApiAction(userStore.fetchCompanyUsersIndex);

fetchCompanyUsersAction.request(props.companyId).catch((error) => {
  Swal.fire({
    icon: 'error',
    title: 'Failed to fetch users.',
  });
});

const users = computed(() => {
  return userStore.models.query().whereHas('projects', (query) => {
    query.where('company_id', props.companyId);
  }).with('projects').whereNotIn('id', [userStore.user.id]).get();
});

const company = computed(() => {
  return companiesStore.models.query().with('projects').find(props.companyId);
});

const rolesStore = useRolesStore();

rolesStore.fetchRoles();
</script>

<style scoped></style>
