<template>
  <div
    class="group odd:bg-gray-100 w-full even:bg-white"
  >
    <p
      class="text-lg font-semibold pl-10 pt-2.5"
      :class="{ 'text-red-600': !trade.baselineDuration }"
    >
      {{ trade.name }}
    </p>

    <div class="flex">
      <div class="flex flex-col pl-10 pb-5 pr-5 justify-center">
        <div class="flex items-center h-full">
          <form
            class="flex gap-4"
            :class="[trade.baselineDuration ? 'flex-col' : 'flex-row items-end']"
            @submit.prevent="updateTrade"
          >
            <label class="mb-auto">
              Baseline Start Date

              <AppInput
                v-model="baselineDatesForm.form.baseline_start_date"
                name="baseline_start_date"
                type="date"
                :error="baselineDatesForm.getErrors('baseline_start_date')"
                class="form-input"
              />
            </label>

            <label class="mb-auto">
              Baseline End Date

              <AppInput
                v-model="baselineDatesForm.form.baseline_end_date"
                name="baseline_end_date"
                type="date"
                :error="baselineDatesForm.getErrors('baseline_end_date')"
                class="form-input"
              />
            </label>

            <button
              class="btn btn--secondary-blue mb-auto"
              :class="{ 'mt-6': !trade.baselineDuration}"
              type="submit"
              :disabled="updateTradeAction.is(States.LOADING)"
            >
              Save
            </button>
          </form>
        </div>
      </div>

      <div
        v-if="trade.baselineDuration"
        class="overflow-x-auto w-full"
      >
        <div class="sticky left-0 flex flex-col">
          <div class=" grid-cols-[1fr_auto_1fr] grid">
            <div>
              <button
                type="button"
                class="btn btn--tertiary-blue"
                @click="resetBaselineLinear"
              >
                Reset Linear
              </button>

              <button
                v-if="trade.baseline_forecast?.length > 0"
                type="button"
                class="btn btn--tertiary-blue"
                @click="revertBaseline"
              >
                Revert
              </button>
            </div>

            <div class="flex justify-center items-center space-x-4">
              <p>
                <span class="font-semibold">Baseline Duration: </span>{{ trade.baselineDuration }} months
              </p>

              <div class="flex space-x-4">
                <!-- NOTE: this tolerance should be the exact same as src/app/Http/Requests/Trade/BaselineForecastRequest.php -->
                <p>
                  <span class="font-semibold">
                    Allocated:
                  </span>

                  <span
                    :class="[totalDifference !== 0 ? 'text-red-600' : 'text-green-600']"
                  >
                    {{ australianCurrency(totalAmount) }} of {{ australianCurrency(trade.totalBudget) }} <span v-if="totalDifference !== 0">({{ (totalDifference > 0 ? "+" : "") + australianCurrency(totalDifference) }})</span>
                  </span>
                </p>
              </div>

              <button
                type="button"
                class="btn btn--tertiary-blue disabled:opacity-50 flex no-underline"
                :disabled="updateBaselineForecastAction.is(States.LOADING)"
                @click="updateBaselineForecast"
              >
                <p class="underline">
                  Save
                </p>

                <span
                  v-if="baselineForm.isDirty.value"
                  class="text-black font-normal ml-1"
                >
                  (unsaved)
                </span>
              </button>
            </div>
          </div>

          <p
            v-if="baselineForm.errors['baseline_forecast'] ?? false"
            class="text-red-600 mx-auto first-letter:capitalize"
          >
            {{ baselineForm.errors['baseline_forecast'][0] ?? '' }}
          </p>

          <p
            v-if="!forecastsSet"
            class="text-red-600 mx-auto first-letter:capitalize"
          >
            Forecasts missing {{ Math.abs(baselineForm.form.baseline_forecast.length - props.trade.baselineDuration) }}
          </p>
        </div>

        <div class="flex p-5">
          <div
            v-if="trade.baselineDuration"
            class="flex pr-5"
          >
            <table>
              <thead>
                <tr :data-month-row="trade.id">
                  <th class="sticky left-0 group-odd:bg-gray-100 pr-2 group-even:bg-white" />
                </tr>
              </thead>

              <tbody>
                <tr
                  :data-percent-row="trade.id"
                >
                  <td class="sticky left-0 group-odd:bg-gray-100 pr-2 group-even:bg-white font-bold">
                    %
                  </td>
                </tr>

                <tr
                  :data-amount-row="trade.id"
                >
                  <td class="sticky left-0 group-odd:bg-gray-100 pr-2 group-even:bg-white font-bold">
                    $
                  </td>
                </tr>
              </tbody>
            </table>

            <template
              v-for="month in trade.baselineDuration"
              :key="month"
            >
              <BaselineInput
                v-model:percent="baselineForm.form.baseline_percents[month - 1]"
                v-model:amount="baselineForm.form.baseline_forecast[month - 1]"
                :trade="trade"
                :month-index="month"
                :error="baselineForm.hasErrors(`baseline_forecast.${month - 1}`)"
                @save-percent="proRataSplitPercents(month - 1, $event.oldAmount, $event.newAmount)"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, roundDecimals } from '@/helpers';
import Trade from '@/models/Trade';
import { useTradesStore } from '@/store/trades';
import Swal from 'sweetalert2';
import { computed, nextTick, watch } from 'vue';
import BaselineInput from './BaselineInput.vue';

const props = defineProps<{
  trade: Trade;
}>();

const emits = defineEmits<{
  (e: 'baselineUpdated', data: { newBaselines: number[] });
}>();

const baselineDatesForm = useFormData({
  baseline_start_date: '',
  baseline_end_date: '',
});

watch(() => props.trade, (newValue) => {
  if(newValue) {
    baselineDatesForm.setData(newValue);
  }
}, { immediate: true });

const tradesStore = useTradesStore();

const baselineForm = useFormData({
  baseline_forecast: [] as (number[]),
  baseline_percents: [] as (number[]),
});

const totalAmount = computed(() => {
  return baselineForm.form.baseline_forecast.reduce((total, current) => {
    return total + (current || 0);
  }, 0);
});

const totalDifference = computed(() => {
  return roundDecimals(totalAmount.value - props.trade.totalBudget);
});

const forecastsSet = computed(() => {
  return baselineForm.form.baseline_forecast.length === props.trade.baselineDuration;
});

watch(() => props.trade, (newValue) => {
  if(newValue) {
    // get months between baseline start/end
    // create array based on that, rather than just copying what's saved
    baselineForm.form.baseline_forecast = [];

    if(newValue.baseline_forecast?.length > 0) {
      for(let i = 0;i < newValue.baselineDuration;i++) {
        baselineForm.form.baseline_forecast[i] = newValue.baseline_forecast[i] ?? 0;
      }
    }

    baselineForm.form.baseline_percents = baselineForm.form.baseline_forecast.map((forecastAmount) => {
      return roundDecimals(forecastAmount / newValue.totalBudget * 100);
    });

    nextTick(() => {
      baselineForm.resetDirtyStatus();
    });
  }
}, { immediate: true });

const proRataSplitPercents = (index: number, oldAmount: number, newAmount: number) => {
  // get all months after

  const monthsAfter = props.trade.baselineDuration - (index + 1);

  if(monthsAfter > 0) {
    // get the difference between old and new value
    const amountDiff = oldAmount - newAmount;
    // divide difference by months after
    const amountDiffPerMonth = roundDecimals(amountDiff / monthsAfter);

    // add to each month
    console.log(monthsAfter, amountDiffPerMonth, oldAmount, newAmount);

    let totalAmount = 0;

    for(let i = index + 1;i <= monthsAfter + index;i++) {
      // NOTE: Math.max will return NaN if it's passed in, so we convert it to 0

      baselineForm.form.baseline_forecast[i] = Math.max(
        roundDecimals(
          baselineForm.form.baseline_forecast[i] + amountDiffPerMonth,
        ) || 0,
        0,
      );

      console.log(baselineForm.form.baseline_forecast[i]);
    }

    baselineForm.form.baseline_forecast.forEach((amount) => {
      totalAmount += amount || 0;
    });

    let totalDifference = roundDecimals(props.trade.totalBudget - totalAmount);
    // const additionalPercent = roundDecimals(100 - totalPercent);
    const additionalPerMonth = roundDecimals(totalDifference / monthsAfter);

    if(additionalPerMonth > 0.01) {
      for(let i = index + 1;i <= monthsAfter + index;i++) {
        // NOTE: Math.max will return NaN if it's passed in, so we convert it to 0
        baselineForm.form.baseline_forecast[i] = Math.max(
          roundDecimals(
            baselineForm.form.baseline_forecast[i] + additionalPerMonth,
          ) || 0,
          0,
        );

        totalAmount += additionalPerMonth;
      }
    }

    totalDifference = roundDecimals(props.trade.totalBudget - totalAmount);

    if(totalDifference !== 0) {
      baselineForm.form.baseline_forecast[baselineForm.form.baseline_forecast.length - 1] = Math.max(
        (baselineForm.form.baseline_forecast[baselineForm.form.baseline_forecast.length - 1] +
          roundDecimals(totalDifference)) || 0,
        0,
      );
    }
  }

  emits('baselineUpdated', { newBaselines: baselineForm.form.baseline_forecast });
};

const updateBaselineForecastAction = useStoreApiAction(tradesStore.updateBaselineForecast);

const updateBaselineForecast = () => {
  baselineForm.resetErrors();

  updateBaselineForecastAction.request(props.trade.id, baselineForm.form).then((data) => {
    console.log(data);

    Swal.fire({
      icon: 'success',
      text: 'Baseline forecast saved.',
    });
  }).catch((error) => {
    Swal.fire({
      icon: 'error',
      text: error.data.message,
    });

    baselineForm.setErrors(error.data);
  });
};

const resetBaselineLinear = () => {
  const amountPerMonth = roundDecimals(props.trade.totalBudget / props.trade.baselineDuration);
  let totalAmount = 0;

  for(let i = 0;i < props.trade.baselineDuration;i++) {
    baselineForm.form.baseline_forecast[i] = amountPerMonth;

    totalAmount += baselineForm.form.baseline_forecast[i];
  }

  if(baselineForm.form.baseline_forecast.length !== props.trade.baselineDuration) {
    baselineForm.form.baseline_forecast = baselineForm.form.baseline_forecast.slice(
      0,
      props.trade.baselineDuration - 1,
    );
  }

  const totalDifference = roundDecimals(props.trade.totalBudget - totalAmount);

  if(totalDifference !== 0) {
    baselineForm.form.baseline_forecast[baselineForm.form.baseline_forecast.length - 1] = roundDecimals(
      baselineForm.form.baseline_forecast[baselineForm.form.baseline_forecast.length - 1] +
        totalDifference,
    );
  }

  emits('baselineUpdated', { newBaselines: baselineForm.form.baseline_forecast });
};

const revertBaseline = () => {
  baselineForm.form.baseline_forecast = [];

  if(props.trade.baseline_forecast?.length > 0) {
    for(let i = 0;i < props.trade.baselineDuration;i++) {
      baselineForm.form.baseline_forecast[i] = props.trade.baseline_forecast[i] ?? 0;
    }
  }

  baselineForm.form.baseline_percents = baselineForm.form.baseline_forecast.map((forecastAmount) => {
    return roundDecimals(forecastAmount / props.trade.totalBudget * 100);
  });

  nextTick(() => {
    baselineForm.resetDirtyStatus();
  });

  emits('baselineUpdated', { newBaselines: baselineForm.form.baseline_forecast });
};

const updateTradeAction = useStoreApiAction(tradesStore.updateTrade);

const updateTrade = () => {
  baselineDatesForm.resetErrors();

  updateTradeAction.request(props.trade.id, baselineDatesForm.form).then((data) => {
    console.log(data);

    if(!props.trade.baseline_forecast) {
      // Set initial baseline linear forecast after forecast dates established
      resetBaselineLinear();
    }
  }).catch((error) => {
    baselineDatesForm.setErrors(error.data);
  });
};
</script>

<style scoped></style>
