import { computed, Ref } from 'vue';

export function useVirtualList(list: Ref<number>, index: Ref<number>, itemHeight: number) {
  const getDistanceTop = () => {
    return index.value * itemHeight;
  };

  const offsetTop = computed(() => getDistanceTop());
  const totalHeight = computed(() => list.value * itemHeight);

  const wrapperProps = computed(() => {
    return {
      style: {
        height: `${totalHeight.value - offsetTop.value}px`,
        marginTop: `${offsetTop.value}px`,
      },
    };
  });

  return {
    wrapperProps,
  };
}
