<template>
  <div class="lg:my-auto m-auto lg:flex lg:m-0 lg:justify-center">
    <p>
      It seems you are lost.
      <RouterLink
        :to="homeRoute"
        class="underline text-blue-500 active:text-blue-700"
      >
        Take me Home.
      </RouterLink>
    </p>
  </div>
</template>

<script setup lang="ts">
import useScreenSize, { ScreenSize } from '@/composables/useScreenSize';
import { computed } from 'vue';

const { screenSize } = useScreenSize();

const homeRoute = computed(() => {
  if(screenSize.value < ScreenSize.MD) {
    return {
      name: 'MobileProjectList',
    };
  }

  return { name: 'ProjectDashboard' };
});
</script>
