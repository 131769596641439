<template>
  <div class="p-5">
    <div class="flex space-x-10">
      <div class="flex flex-col w-full justify-between items-start space-y-10">
        <button
          type="button"
          class="btn btn--primary-blue ml-auto"
          data-test="save-head-contract"
          @click="updateProject"
        >
          Save Head Contract Settings
        </button>

        <div
          v-if="userStore.isRole(['admin', 'project_admin', 'company_admin', 'contract_admin'])"
          class="card mx-auto w-full"
        >
          <div class="card__header">
            <div class="flex justify-between items-center">
              <p>
                Emails
              </p>
            </div>
          </div>

          <div class="w-1/2 p-4">
            <form
              method="post"
              class="flex flex-col space-y-4"
              @submit.prevent="updateProject"
            >
              <template v-if="!project.feature_flag_progress_claims_lite">
                <label>
                  Contractor Email

                  <AppInput
                    v-model="projectForm.form.contractor_email"
                    :error="projectForm.getErrors('contractor_email')"
                    class="form-input"
                    type="text"
                    name="contractor_email"
                  />
                </label>

                <label>
                  PQS Firm Email

                  <AppInput
                    v-model="projectForm.form.pqs_email"
                    :error="projectForm.getErrors('pqs_email')"
                    class="form-input"
                    type="text"
                    name="pqs_email"
                  />
                </label>
              </template>

              <label>
                Contract Start Date

                <AppInput
                  v-model="projectForm.form.contract_start_date"
                  :error="projectForm.getErrors('contract_start_date')"
                  class="form-input"
                  type="date"
                  name="con.contract_start_date"
                />
              </label>

              <label>
                Contract End Date

                <AppInput
                  v-model="projectForm.form.contract_end_date"
                  :error="projectForm.getErrors('contract_end_date')"
                  class="form-input"
                  type="date"
                  name="contract_end_date"
                />
              </label>

              <label>
                Claim Start Date

                <AppInput
                  v-model="projectForm.form.claim_start"
                  :error="projectForm.getErrors('claim_start')"
                  class="form-input"
                  type="date"
                  name="claim_start"
                />
              </label>

              <div class="font-semibold">
                Project Length {{ contractLength }} months
              </div>
            </form>
          </div>
        </div>

        <VariationOrderList
          :project-id="$props.projectId"
          class="w-full"
        />
      </div>

      <!-- <div
        v-if="trades.length === 0 && userStore.isRole(['admin', 'project_admin', 'company_admin']) && fetchTradesAction.is(States.COMPLETE)"
        class="flex items-start justify-end space-x-4"
      >
        <div>
          <button
            type="button"
            class="btn btn--secondary-blue"
            @click="generateTrades"
          >
            Generate Trades
          </button>
        </div>
      </div> -->

      <!-- <div
        v-if="trades.length > 0"
        class="card w-1/2 overflow-y-auto h-96"
      >
        <div class="card__header sticky top-0 flex justify-between items-center">
          <p>
            Trades
          </p>

          <button
            type="button"
            class="btn btn--secondary-blue ml-auto"
            @click="createTrade"
          >
            Add Trade
          </button>
        </div>

        <div class="flex flex-col w-full p-4 space-y-4">
          <TradeRow
            v-for="trade in trades"
            :key="trade.id"
            :trade="trade"
            @edit="selectTrade(trade)"
            @delete="deleteTrade(trade)"
          />
        </div>
      </div> -->
    </div>

    <TradeSaveModal
      v-if="showTradeModal"
      :trade="selectedTrade"
      :project-id="$props.projectId"
      @close="showTradeModal = false"
    />
  </div>
</template>

<script setup lang="ts">
import TradeRow from '@/components/HeadContractSettings/TradeRow.vue';
import { useFormData } from '@/composables/useFormData';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import Trade from '@/models/Trade';
import { useProjectsStore } from '@/store/projects';
import { useTradesStore } from '@/store/trades';
import { useUserStore } from '@/store/user';
import { DateTime } from 'luxon';
import Swal from 'sweetalert2';
import { computed, nextTick, ref, watch } from 'vue';
import TradeSaveModal from './TradeSaveModal.vue';
import VariationOrderList from './VariationOrderList.vue';

const props = defineProps<{
  projectId: string;
}>();

const emits = defineEmits<{
  (e: 'dirty');
  (e: 'saved');
}>();

const tradesStore = useTradesStore();
const fetchTradesAction = useStoreApiAction(tradesStore.fetchTrades);
const selectedTrade = ref<Trade | undefined>();
const showTradeModal = ref(false);
const projectsStore = useProjectsStore();

const projectForm = useFormData({
  contractor_email: '',
  pqs_email: '',
  contract_start_date: '',
  contract_end_date: '',
  claim_start: '',
});

const project = computed(() => {
  return projectsStore.project;
});

watch(() => props.projectId, (newValue) => {
  if(newValue) {
    fetchTradesAction.request(newValue);
  }
}, { immediate: true });

watch(project, (newValue) => {
  if(newValue) {
    projectForm.setData(newValue);

    nextTick(() => {
      projectForm.resetDirtyStatus();
    });
  }
}, { immediate: true });

watch(projectForm.isDirty, (newValue) => {
  if(newValue) {
    emits('dirty');
  } else {
    emits('saved');
  }
});

const updateProjectAction = useStoreApiAction(projectsStore.updateHeadContractSettings);

const updateProject = () => {
  projectForm.resetErrors();

  updateProjectAction.request(props.projectId, projectForm.form).then(() => {
    Swal.fire({
      icon: 'success',
      title: 'Head Contract Settings Updated.',
    });
  }).catch((error) => {
    projectForm.setErrors(error.data);
  });
};

const userStore = useUserStore();

const contractLength = computed(() => {
  if(projectForm.form.contract_end_date && projectForm.form.contract_start_date) {
    const contractEndDate = DateTime.fromFormat(projectForm.form.contract_end_date, 'yyyy-MM-dd').endOf('month');
    const contractStartDate = DateTime.fromFormat(projectForm.form.contract_start_date, 'yyyy-MM-dd').startOf('month');

    if(contractEndDate.isValid && contractStartDate.isValid) {
      return Math.round(Math.abs(contractStartDate.diff(contractEndDate, ['months']).months));
    }
  }

  return undefined;
});
</script>

<style scoped></style>
