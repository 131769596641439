<template>
  <div class="!pt-0">
    <div class="flex flex-col space-y-2.5 max-h-screen pt-16 pb-2.5">
      <div class="flex items-center justify-between space-x-4 mt-2.5">
        <div class="flex space-x-2 element-island">
          <h1 class="font-semibold text-3xl">
            Labour Dashboard
          </h1>

          <a
            :href="exportUrl"
            target="_blank"
            class="btn btn--secondary-blue ml-2 inline-block"
          >
            Export
          </a>
        </div>

        <div class="element-island">
          <button
            type="button"
            class="btn btn--secondary-blue"
            data-test="add-labour"
            @click="show = true"
          >
            + Labour
          </button>

          <button
            type="button"
            class="btn ml-2"
            :class="[laboursStore.active ? 'btn--primary-blue' : 'btn--secondary-blue']"
            data-test="show-filter"
            @click="showFilter = true"
          >
            Filter
          </button>
        </div>
      </div>

      <div
        v-if="labours.length === 0 && fetchLaboursAction.state.value === States.COMPLETE"
        class="py-5 px-3 element-island text-center font-semibold w-1/2 mx-auto"
        data-test="no-labour-message"
      >
        No Labours found, try adjusting your filter.
      </div>

      <div
        v-else-if="fetchLaboursAction.state.value === States.COMPLETE"
        class="element-island overflow-y-auto"
      >
        <LabourList
          :labours="labours"
          :project-id="$props.projectId"
        />
      </div>

      <div v-else>
        <AppSpinner class="h-[68px]" />
      </div>

      <LabourSaveModal
        v-if="show"
        :project-id="$props.projectId"
        @close="show = false"
      />

      <LabourFilterModal
        v-if="showFilter"
        @close="showFilter = false"
        @filter="setFilter"
        @clear="clearFilter"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import LabourFilterModal from '@/components/LabourDashboard/LabourFilterModal.vue';
import LabourList from '@/components/LabourDashboard/LabourList.vue';
import LabourSaveModal from '@/components/LabourDashboard/LabourSaveModal.vue';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { useLaboursStore } from '@/store/labours';
import { useProjectsStore } from '@/store/projects';
import { route } from '@/ziggy-shim';
import { computed, ref, watch } from 'vue';

const props = defineProps<{
  projectId: string;
}>();

const laboursStore = useLaboursStore();
const fetchLaboursAction = useStoreApiAction(laboursStore.fetchLabours);

watch(() => props.projectId, () => {
  fetchLaboursAction.request(props.projectId, laboursStore.filter.form).catch((error) => {
    console.log(error);
  });
}, { immediate: true });

const show = ref(false);
const showFilter = ref(false);

const labours = computed(() =>
  laboursStore.models
    .with('supplier')
    .with('records', (query) =>
      query
        .with('wbsCode')
        .with('comments', (query) => query.with('creator'))
        .with('user')
        .with('labour')
        .orderBy('day'))
    .where('project_id', parseInt(props.projectId))
    .get()
);

const clearFilter = () => {
  showFilter.value = false;
  laboursStore.filter.resetData();
  laboursStore.models.flush();
  laboursStore.active = false;

  fetchLaboursAction.request(props.projectId).catch((error) => {
    console.log(error);
  });
};

const setFilter = () => {
  showFilter.value = false;
  laboursStore.models.flush();

  fetchLaboursAction.request(props.projectId, laboursStore.filter.form).then(() => {
    laboursStore.active = true;
  }).catch((error) => {
    console.log(error);
  });
};

const projectsStore = useProjectsStore();

const exportUrl = computed(() => {
  if(projectsStore.project) {
    return route('api.v1.projects.export', projectsStore.project.id);
  }

  return undefined;
});
</script>

<style scoped></style>
