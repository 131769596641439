import { Model } from 'pinia-orm';
import { BelongsTo, Num, Str } from 'pinia-orm/dist/decorators';
import Project from './Project';
import WbsCode from './WbsCode';
/* --- user header --- */
/* --- end user header --- */

export default class SurveyedQuantity extends Model {
  static entity = 'App\\Models\\SurveyedQuantity';
  // fields
  @Num(0)
  declare id: number;
  @Num(0)
  declare project_id: number;
  @Num(0)
  declare wbs_code_id: number;
  @Str('')
  declare day: string;
  @Num(0)
  declare quantity: number;
  @Str('')
  declare created_at: string | undefined;
  @Str('')
  declare updated_at: string | undefined;
  // relations
  @BelongsTo(() => Project, 'project_id', 'id')
  declare project: Project;
  @BelongsTo(() => WbsCode, 'wbs_code_id', 'id')
  declare wbsCode: WbsCode;
  /* --- user code --- */
  /* --- end user code --- */
}
