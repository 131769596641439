<template>
  <div>
    <table
      class="w-full h-full"
    >
      <thead>
        <tr class="border border-gray-300">
          <th
            colspan="4"
            class="bg-white py-1"
          />

          <th
            colspan="3"
            class="bg-gray-200 py-1"
          >
            Cost per Unit
          </th>

          <th
            colspan="3"
            class="bg-white py-1"
          >
            To Date
          </th>

          <th class="bg-gray-200 py-1">
            To Complete
          </th>

          <th
            colspan="2"
            class="bg-white py-1"
          >
            Forecast
          </th>
        </tr>

        <tr class="bg-gray-300">
          <th class="text-left pl-4 py-2">
            WBS
          </th>

          <th
            class="text-right pr-4"
            title="The tender amount is based on the quantity and cost per unit"
          >
            Tender Cost
          </th>

          <th
            class="text-right pr-2"
            title="The final quantity that includes the quantity as updated by the user"
          >
            Final Quantity
          </th>

          <th
            class="text-right pr-4"
            title="The unit of measure for the combined items"
          >
            Unit
          </th>

          <th
            class="text-right"
            title="The cost per unit of work the formula is [Tender Amount/Qty]"
          >
            Tender
          </th>

          <th
            class="text-right"
            title="The cost per unit of work the formula is [To Date Amount/Qty]"
          >
            To Date
          </th>

          <th
            class="text-right pr-4"
            title="The cost per unit of work the formula is [Cost Per Unit to date x (To Complete Production Rate / To Date Production Rate)]"
          >
            To Complete
          </th>

          <th
            class="text-right"
            title="The cost value based on the number of quantities completed"
          >
            Cost
          </th>

          <th
            class="text-right"
            title="The earned value based on the number of quantities completed"
          >
            Earned Amount
          </th>

          <th
            class="text-right pr-4"
            title="The difference between the cost and earned amounts"
          >
            Variance
          </th>

          <th
            class="text-right pr-4"
            title="The cost to complete the works. The formula is [cost per unit x the Qty to Complete]"
          >
            Cost
          </th>

          <th
            class="text-right pr-4"
            title="The forecast final cost. The formula is [Cost To Complete + Cost Amount To Date]"
          >
            Cost
          </th>

          <th class="text-right pr-4">
            Profit/Loss
          </th>
        </tr>
      </thead>

      <tbody class="h-full border-t border-black">
        <template
          v-for="wbsCode in $props.wbsCodes"
          :key="wbsCode.id"
        >
          <WbsCodeListRow
            v-if="toCompleteTotals.get(wbsCode.id)"
            :wbs-code="wbsCode"
            :to-complete="toCompleteTotals.get(wbsCode.id)"
          />
        </template>

        <tr>
          <td
            class="border-y border-l border-black text-center bg-white"
          >
            Total
          </td>

          <td
            class="border-y border-r border-black text-right bg-white pr-4"
          >
            {{
              australianCurrency(
                $props.wbsCodes.reduce((total, value) => total + value.tender_cost, 0)
              )
            }}
          </td>

          <td
            colspan="5"
            class="border-t border-black bg-transparent"
          />

          <td
            class="border-y border-l border-black text-right bg-white"
          >
            {{
              australianCurrency(totals.totalToDateCost)
            }}
          </td>

          <td
            class="border-y border-black text-right bg-white"
          >
            {{
              australianCurrency(totals.totalEarnedAmount)
            }}
          </td>

          <td
            class="border-y border-black text-right bg-white pr-4"
          >
            {{
              australianCurrency(totals.totalVariance)
            }}
          </td>

          <td
            class="border-y border-black text-right bg-white pr-4"
          >
            {{
              australianCurrency(totals.totalToCompleteCost)
            }}
          </td>

          <td
            class="border-y border-black text-right pr-4 bg-white"
          >
            {{
              australianCurrency(totals.totalForecastCost)
            }}
          </td>

          <td
            class="border-y border-r border-black text-right pr-4 bg-white"
          >
            {{
              australianCurrency(totals.totalProfit)
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { australianCurrency } from '@/helpers';
import WbsCode from '@/models/WbsCode';
import { computed } from 'vue';
import WbsCodeListRow from './WbsCodeListRow.vue';

const props = defineProps<{
  wbsCodes: WbsCode[];
  toCompleteTotals: Map<number, CostAtCompletion>;
}>();

const totals = computed(() => {
  const totalTenderCost = 0;
  let totalToDateCost = 0;
  let totalEarnedAmount = 0;
  let totalVariance = 0;
  let totalToCompleteCost = 0;
  let totalForecastCost = 0;
  let totalProfit = 0;

  for(const [_key, value] of props.toCompleteTotals) {
    totalToDateCost += value.toDateCost;
    totalEarnedAmount += value.toDateEarnedAmount;
    totalVariance += value.toDateVariance;
    totalToCompleteCost += value.toCompleteCost;
    totalForecastCost += value.forecastCost;
    totalProfit += value.profit;
  }

  return {
    totalTenderCost,
    totalToDateCost,
    totalEarnedAmount,
    totalVariance,
    totalToCompleteCost,
    totalForecastCost,
    totalProfit,
  };
});
</script>

<style scoped></style>
