<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl mx-10">
          {{ tradeItem ? 'Update' : 'Create' }} Trade Item
        </h1>
      </div>
    </template>

    <template #body>
      <div class="h-full w-full p-4 overflow-y-auto">
        <form
          action=""
          class="flex flex-col space-y-4"
          @submit.prevent="saveTrade"
        >
          <div>
            <p class="text-lg font-semibold">
              {{ tradeItem ? 'Under:' : 'Adding under:' }} {{ trade.name }}
            </p>
          </div>

          <label>
            Name
            <AppInput
              v-model="tradeItemForm.form.name"
              name="name"
              type="text"
              class="form-input"
              :error="tradeItemForm.getErrors('name')"
              data-test="name-input"
            />
          </label>

          <div class="flex space-x-4">
            <label>
              Unit
              <AppInput
                v-model="tradeItemForm.form.unit"
                class="form-input"
                name="unit"
                type="text"
                :error="tradeItemForm.getErrors('unit')"
                data-test="unit-input"
              />
            </label>

            <label>
              Quantity
              <AppInput
                v-model="tradeItemForm.form.quantity"
                class="form-input"
                name="quantity"
                type="number"
                :error="tradeItemForm.getErrors('quantity')"
                data-test="quantity-input"
              />
            </label>
          </div>

          <label>
            Rate <span class="text-sm text-gray-400">($/unit)</span>

            <AppInput
              v-model="tradeItemForm.form.rate"
              class="form-input"
              name="rate"
              type="number"
              :error="tradeItemForm.getErrors('rate')"
              data-test="rate-input"
            />
          </label>

          <label>
            Budget <span class="text-sm text-gray-400">($)</span>

            <AppInput
              v-model="tradeItemForm.form.budget"
              class="form-input disabled:bg-gray-300"
              name="budget"
              type="number"
              :error="tradeItemForm.getErrors('budget')"
              data-test="budget-input"
              disabled
            />
          </label>

          <!-- <label>
            Awarded Value <span class="text-sm text-gray-400">($)</span>

            <AppInput
              v-model="tradeItemForm.form.awarded_value"
              class="form-input"
              name="awarded_value"
              type="number"
              :error="tradeItemForm.getErrors('awarded_value')"
              data-test="awarded_value-input"
            />
          </label> -->
        </form>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-center p-2 space-x-4">
        <button
          type="button"
          class="btn btn--secondary-blue"
          @click="$emit('close'); tradeItemForm.resetData()"
        >
          Cancel
        </button>

        <button
          type="button"
          class="btn btn--primary-blue"
          :disabled="createTradeItemAction.is(States.LOADING)"
          data-test="save-trade-item"
          @click="saveTrade"
        >
          {{ tradeItem ? 'Update' : 'Create' }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/Modal.vue';
import { useFormData } from '@/composables/useFormData';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import Trade from '@/models/Trade';
import TradeItem from '@/models/TradeItem';
import { useTradeItemsStore } from '@/store/tradeItems';
import Swal from 'sweetalert2';
import { watch } from 'vue';

const props = defineProps<{
  trade: Trade;
  tradeItem?: TradeItem;
  selectedPosition?: number;
}>();

const emits = defineEmits<{
  (e: 'close');
  (e: 'updateSort', data: { tradeItem: TradeItem; index: number });
}>();

const tradeItemForm = useFormData({
  name: '',
  budget: 0,
  trade_id: props.trade.id,
  rate: 0,
  quantity: 0,
  unit: '',
  awarded_value: undefined,
  sort_order: undefined,
});

watch(() => props.selectedPosition, (newValue) => {
  tradeItemForm.form.sort_order = newValue;
}, { immediate: true });

watch(() => [tradeItemForm.form.rate, tradeItemForm.form.quantity], (newValue) => {
  if(newValue[0] > 0 && newValue[1] > 0) {
    tradeItemForm.form.budget = newValue[0] * newValue[1];
  }
});

watch(() => props.tradeItem, (newValue) => {
  if(newValue) {
    tradeItemForm.setData(newValue);
  }
}, { immediate: true });

const tradeItemsStore = useTradeItemsStore();
const createTradeItemAction = useStoreApiAction(tradeItemsStore.createTradeItem);
const updateTradeItemAction = useStoreApiAction(tradeItemsStore.updateTradeItem);

const saveTrade = () => {
  tradeItemForm.resetErrors();

  if(props.tradeItem) {
    updateTradeItemAction.request(props.tradeItem.id, tradeItemForm.form).then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Trade item updated.',
      }).then(() => {
        emits('close');
      });
    }).catch((error) => {
      tradeItemForm.setErrors(error.data);
    });
  } else {
    createTradeItemAction.request(props.trade.project_id, tradeItemForm.form).then((data) => {
      emits('updateSort', { tradeItem: data, index: data.sort_order });

      Swal.fire({
        icon: 'success',
        title: 'Trade item created.',
      }).then(() => {
        emits('close');
      });
    }).catch((error) => {
      tradeItemForm.setErrors(error.data);
    });
  }
};
</script>

<style scoped></style>
