<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl mx-10 capitalize">
          Surveyed Quantities for {{ wbsCode.code }}
        </h1>
      </div>
    </template>

    <template #body>
      <div class="w-full p-4 overflow-y-auto min-w-[40rem] h-full">
        <div class="flex">
          <form
            class="flex flex-col space-y-2 w-full"
            @submit.prevent="saveSurveyed"
          >
            <h3 class="text-center text-xl">
              Record New Survey
            </h3>

            <label title="The date of the actual survey">
              Survey Date

              <AppInput
                v-model="surveyedQuantityForm.form.day"
                type="date"
                name="survey_day"
                data-test="survey_day"
                class="form-input"
                :error="surveyedQuantityForm.getErrors('day')"
              />
            </label>

            <label title="The combined final amount surveyed for the item">
              Surveyed Quantity ({{ wbsCode.unit }})

              <AppInput
                v-model.number="surveyedQuantityForm.form.quantity"
                type="number"
                name="survey_quantity"
                data-test="survey_quantity"
                class="form-input"
                :error="surveyedQuantityForm.getErrors('quantity')"
              />
            </label>

            <button
              type="submit"
              :disabled="createSurveyAction.state.value === States.LOADING ||
                updateSurveyAction.state.value === States.LOADING"
              class="btn btn--primary-blue"
              data-test="save_surveyed"
            >
              Save Surveyed Quantity
            </button>
          </form>

          <div class="border-gray-300 w-1 border-l translate-x-1/2 mx-2" />

          <div class="flex flex-col space-y-2 w-full">
            <h3 class="text-center text-xl">
              Past Surveys
            </h3>

            <div class="flex w-full space-x-4">
              <ul class="overflow-auto space-y-4 h-64 flex-1">
                <li
                  v-for="quantity in surveyedQuantities"
                  :key="quantity.id"
                  class="card__list-item"
                >
                  <div class="grid grid-cols-2">
                    <div class="flex flex-col w-max">
                      <p>
                        {{ new Date(quantity.day).toLocaleDateString() }}
                      </p>

                      <AppInput
                        v-if="updateSurvey"
                        v-model.number="updateSurveyedQuantityForm.form.quantity"
                        type="text"
                        :error="updateSurveyedQuantityForm.getErrors('quantity')"
                        name="quantity"
                        class="form-input w-32"
                        @blur="updateSurveyed"
                        @keyup.enter="updateSurveyed"
                        @keyup.esc="updateSurvey = false"
                      />

                      <p v-else>
                        {{ quantity.quantity }}
                      </p>
                    </div>

                    <div class="flex justify-end row-span-2 items-center">
                      <button
                        v-if="quantity.id === latestSurvey?.id"
                        type="button"
                        data-test="edit"
                        class="mr-2"
                        @click="selectSurvey(quantity)"
                      >
                        <Icon
                          name="edit"
                          class="btn-icon h-5 w-5"
                        />
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-end p-2 space-x-4">
        <button
          type="button"
          class="btn btn--secondary-blue"
          @click="$emit('close');"
        >
          Close
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import SurveyedQuantity from '@/models/SurveyedQuantity';
import WbsCode from '@/models/WbsCode';
import { useProjectsStore } from '@/store/projects';
import { useSurveyedQuantitiesStore } from '@/store/surveyedQuantities';
import Swal from 'sweetalert2';
import { computed, ref } from 'vue';
import Modal from '../Modal.vue';

const props = defineProps<{
  wbsCode: WbsCode;
}>();

defineEmits<{
  (e: 'close');
}>();

const projectsStore = useProjectsStore();
const surveyedQuantitiesStore = useSurveyedQuantitiesStore();

const surveyedQuantityForm = useFormData({
  day: '',
  quantity: 0,
  wbs_code_id: props.wbsCode.id,
});

const updateSurveyedQuantityForm = useFormData({
  quantity: 0,
});

const surveyedQuantities = computed(() => {
  return surveyedQuantitiesStore.models
    .where('project_id', projectsStore.project?.id)
    .where('wbs_code_id', props.wbsCode.id)
    .orderBy('day', 'desc')
    .get();
});

const latestSurvey = computed(() => {
  return surveyedQuantities.value[0];
});

const createSurveyAction = useStoreApiAction(surveyedQuantitiesStore.createQuantity);
const updateSurveyAction = useStoreApiAction(surveyedQuantitiesStore.updateQuantity);

const saveSurveyed = () => {
  surveyedQuantityForm.resetErrors();

  createSurveyAction.request(projectsStore.project?.id, surveyedQuantityForm.form).then(() => {
    Swal.fire({
      icon: 'success',
      text: 'Saved Surveyed Quantity.',
    });

    surveyedQuantityForm.resetData();
  }).catch((error) => {
    surveyedQuantityForm.setErrors(error.data);
  });
};

const updateSurveyed = () => {
  updateSurveyedQuantityForm.resetErrors();

  updateSurveyAction.request(selectedSurveyId.value, updateSurveyedQuantityForm.form).then(() => {
    updateSurvey.value = false;

    Swal.fire({
      icon: 'success',
      text: 'Updated Surveyed Quantity.',
    });

    updateSurveyedQuantityForm.resetData();
  }).catch((error) => {
    updateSurveyedQuantityForm.setErrors(error.data);
  });
};

const selectedSurveyId = ref();
const updateSurvey = ref(false);

const selectSurvey = (survey: SurveyedQuantity) => {
  selectedSurveyId.value = survey.id;
  updateSurvey.value = true;
  updateSurveyedQuantityForm.form.quantity = survey.quantity;
};
</script>

<style scoped></style>
