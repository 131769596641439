<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          Filter
        </h1>
      </div>
    </template>

    <template #body>
      <div class="h-full w-full p-4">
        <form
          action=""
          class="flex flex-col space-y-4"
          @submit.prevent=""
        >
          <div class="flex space-x-4">
            <label>
              First Name
              <AppInput
                v-model="laboursStore.filter.form.first_name"
                type="text"
                name="first_name"
                data-test="filter-first-name"
                class="form-input"
              />
            </label>

            <label>
              Surname
              <AppInput
                v-model="laboursStore.filter.form.last_name"
                type="text"
                name="surname"
                data-test="filter-surname"
                class="form-input"
              />
            </label>
          </div>

          <label>
            Supplier
            <AppInput
              v-model="laboursStore.filter.form.supplier_name"
              type="text"
              name="supplier"
              data-test="filter-supplier-name"
              class="form-input"
            />
          </label>

          <div class="flex flex-col w-full">
            <label>
              Record date
            </label>

            <div class="flex w-full space-x-4 items-center justify-between">
              <AppInput
                v-model="laboursStore.filter.form.day_min"
                name="day_min"
                type="date"
                class="form-input"
              />

              <p>
                to
              </p>

              <AppInput
                v-model="laboursStore.filter.form.day_max"
                name="day_max"
                type="date"
                class="form-input"
              />
            </div>
          </div>

          <label>
            WBS Code

            <AppSelect
              v-model.number="laboursStore.filter.form.wbs_code_id"
              :options="project?.codes.map((code) => ({ value: code.id, label: code.code }))"
              name="wbs_code"
              data-test="wbs_code"
              class="form-input"
            />
          </label>

          <label>
            WBS Description

            <AppSelect
              v-model.number="laboursStore.filter.form.wbs_code_id"
              :options="project?.codes.map((code) => ({ value: code.id, label: code.description }))"
              name="wbs_description"
              data-test="wbs_description"
              class="form-input max-w-md"
            />
          </label>

          <label>
            <p>
              Vendor Ref
            </p>

            <AppTypeAhead
              v-model="laboursStore.filter.form.vendor_ref"
              name="vendor_ref"
              :suggestion-callback="getSuggestions"
              data-test="filter-vendor-ref"
              class="form-input"
            />
          </label>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-end p-2 space-x-4">
        <button
          type="button"
          class="btn btn--secondary-blue"
          @click="$emit('clear')"
        >
          Clear
        </button>

        <button
          type="button"
          class="btn btn--primary-blue"
          data-test="apply-filter"
          @click="$emit('filter')"
        >
          Filter
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { useLabourDailyRecordStore } from '@/store/labourDailyRecord';
import { useLaboursStore } from '@/store/labours';
import { useProjectsStore } from '@/store/projects';
import { computed } from 'vue';
import Modal from '../Modal.vue';

defineEmits<{
  (e: 'close');
  (e: 'filter');
  (e: 'clear');
}>();

const laboursStore = useLaboursStore();
const projectsStore = useProjectsStore();

const project = computed(() => {
  return projectsStore.project;
});

const labourDailyRecordsStore = useLabourDailyRecordStore();

const getSuggestions = () => {
  return labourDailyRecordsStore.search(project.value?.id, { vendor_ref: laboursStore.filter.form.vendor_ref });
};
</script>

<style scoped></style>
