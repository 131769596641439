<template>
  <li
    class="grid grid-cols-4 space-x-4"
  >
    <p>
      {{ day.toLocaleString('en-us', { day: '2-digit', weekday: 'short'}) }}
    </p>

    <template v-if="typingQuantity">
      <div class="flex items-center justify-center">
        <form
          action=""
          @submit.prevent=""
        >
          <AppInput
            ref="quantityInput"
            v-model="quantity"
            name="quantity"
            type="number"
            min="0"
            data-test="quantity-input"
            class="w-14 h-6"
            @blur="saveQuantity"
            @keyup.enter="saveQuantity"
            @keyup.esc="typingQuantity = false"
          />
        </form>
      </div>
    </template>

    <p
      v-else
      class="text-right"
      :class="{
        'bg-red-600/50': (!$props.dailyQuantity && day.getTime() < new Date().getTime() && $props.cost),
        'cursor-pointer': ($props.showCaptured || ($props.cost && !$props.dailyQuantity))
      }"
      @click="toggleTypingQuantity"
    >
      {{ $props.dailyQuantity ?
        australianNumber(
          showCaptured ?
            $props.dailyQuantity.quantity :
            $props.dailyQuantity.surveyedQuantity
        ) : ''
      }}
    </p>

    <p class="text-right">
      {{ $props.cost ? australianCurrency($props.cost) : '' }}
    </p>

    <p
      v-if="$props.cost &&
        ((!showCaptured && $props.dailyQuantity?.surveyedQuantity) ||
          (showCaptured && $props.dailyQuantity?.quantity))"
      class="text-right"
    >
      {{
        showCaptured ?
          australianCurrency($props.cost/$props.dailyQuantity.quantity) :
          australianCurrency($props.cost/$props.dailyQuantity.surveyedQuantity)
      }}/{{ wbsCode?.unit }}
    </p>
  </li>
</template>

<script setup lang="ts">
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, australianNumber, dateAsYMD } from '@/helpers';
import DailyQuantity from '@/models/DailyQuantity';
import WbsCode from '@/models/WbsCode';
import { useDailyQuantitiesStore } from '@/store/dailyQuantities';
import { useProjectsStore } from '@/store/projects';
import Swal from 'sweetalert2';
import { nextTick, ref } from 'vue';

const props = defineProps<{
  dailyQuantity?: DailyQuantity;
  cost?: number;
  day: Date;
  showCaptured: boolean;
  wbsCode: WbsCode;
}>();

const typingQuantity = ref(false);
const quantity = ref(0);
const quantityInput = ref();

const toggleTypingQuantity = () => {
  const quantityHasBias = typeof props.dailyQuantity?.bias === 'number';

  if((props.showCaptured && !quantityHasBias) || (props.cost && !props.dailyQuantity)) {
    quantity.value = props.dailyQuantity?.quantity || 0;
    typingQuantity.value = !typingQuantity.value;

    if(typingQuantity.value) {
      nextTick(() => {
        quantityInput.value?.focusInput();
      });
    }
  }
};

const dailyQuantitiesStore = useDailyQuantitiesStore();
const projectsStore = useProjectsStore();
const storeQuantityAction = useStoreApiAction(dailyQuantitiesStore.createQuantity);
const updateQuantityAction = useStoreApiAction(dailyQuantitiesStore.updateQuantity);
const deleteQuantityAction = useStoreApiAction(dailyQuantitiesStore.deleteQuantity);

const saveQuantity = () => {
  if(props.dailyQuantity) {
    if(quantity.value) {
      updateQuantityAction.request(props.dailyQuantity.id, { quantity: quantity.value }).then(() => {
        typingQuantity.value = false;
      }).catch(() => {
        Swal.fire({
          icon: 'error',
          text: 'Failed to Update.',
        });
      });
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Remove Quantity?',
        text: 'Are you sure you want to delete this captured quantity?',
        showCancelButton: true,
        showConfirmButton: true,
      }).then((result) => {
        if(result.isConfirmed) {
          deleteQuantityAction.request(props.dailyQuantity.id).then(() => {
            typingQuantity.value = false;

            Swal.fire({
              icon: 'success',
              text: 'Quantity Deleted.',
            });
          }).catch(() => {
            Swal.fire({
              icon: 'error',
              text: 'Failed to Delete.',
            });
          });
        } else {
          typingQuantity.value = false;
        }
      });
    }
  } else {
    if(quantity.value !== 0) {
      storeQuantityAction
        .request(projectsStore.project?.id, {
          day: dateAsYMD(props.day),
          quantity: quantity.value,
          wbs_code_id: props.wbsCode.id,
        })
        .then((_quantity) => {
          typingQuantity.value = false;
        })
        .catch(() => {
          Swal.fire({
            icon: 'error',
            text: 'Failed to Save.',
          });
        });
    } else {
      typingQuantity.value = false;
    }
  }
};
</script>

<style scoped></style>
