<template>
  <table class="w-full border-separate border-spacing-0 border border-black rounded-lg">
    <thead>
      <tr>
        <th
          colspan="4"
          class="bg-orange-500 rounded-tl-lg  w-1/5"
        >
          Plant
        </th>

        <th
          colspan="5"
          class="bg-blue-500 rounded-tr-lg"
        >
          Costs
        </th>
      </tr>

      <tr class="bg-gray-300">
        <th
          class="pl-3 py-2  w-1/5 text-left"
          title="The supplier&rsquo;s designated plant ID number. A detailed description of the plant item"
        >
          Plant
        </th>

        <th
          class="pl-6 text-left"
          title="The supplier&rsquo;s name"
        >
          Supplier
        </th>

        <th
          class="text-left"
          title="Whether or not the plant is on site but not used for the day"
        >
          Pay Standby
        </th>

        <th
          class="pl-6 text-left"
          title="The date the plant is placed on-hire with the supplier"
        >
          Hire Period
        </th>

        <th
          class="text-right"
          title="The rate paid per hour or day"
        >
          Rate
        </th>

        <th
          class="text-right"
          title="The total number of hours/days recorded"
        >
          Period
        </th>

        <!-- <th
            class="text-right"
            title="The total number of days recorded"
          >
            Days
          </th> -->

        <th
          class="text-right"
          title="The cost of recorded days or hours that have not been paid yet"
        >
          Accrual
        </th>

        <th
          class="text-right"
          title="The total paid to date for the plant item"
        >
          Paid to Date
        </th>

        <th
          class="text-right pr-4"
          title="The amount paid to date including accruals"
        >
          Total Cost
        </th>
      </tr>

      <tr class="sticky -top-2 bg-white z-10">
        <td
          colspan="5"
          class="border-y border-r border-black"
        />

        <td
          class="border-y border-black text-right bg-white"
          data-test="totals-wet"
        >
          {{
            $props.plants.filter((plant) => plant.wet)
              .reduce((total, value) => total + value.totalHours, 0)
              .toLocaleString('en-AU', { maximumFractionDigits: 2, minimumFractionDigits: 2})
          }} hrs

          {{
            $props.plants.filter((plant) => !plant.wet)
              .reduce((total, value) => total + value.totalDays, 0)
              .toLocaleString('en-AU')
          }} days
        </td>

        <td
          class="border-y border-black text-right bg-white"
          data-test="totals-accrual"
        >
          {{ australianCurrency($props.plants.reduce((total, value) => total + value.totalAccrual, 0)) }}
        </td>

        <td
          class="border-y border-black text-right bg-white"
          data-test="totals-paid-to-date"
        >
          {{ australianCurrency($props.plants.reduce((total, value) => total + value.paidToDate, 0)) }}
        </td>

        <td
          class="text-right bg-white border-y border-black pr-4"
          data-test="totals-total"
        >
          {{
            australianCurrency(
              $props.plants.reduce((total, value) => total + (value.paidToDate + value.totalAccrual), 0)
            )
          }}
        </td>
      </tr>
    </thead>

    <tbody
      class="h-full rounded-b-lg"
      data-test="plant-list"
    >
      <PlantListRow
        v-for="plant in $props.plants"
        :key="plant.id"
        :plant="plant"
        @show-off-hired="($event: Id) => showHirePeriodModal($event)"
        @show-update-modal="($event: Id) => showUpdateModal($event)"
      />
    </tbody>
  </table>

  <div>
    <PlantSaveModal
      v-if="show"
      :project-id="$props.projectId"
      :plant-id="selectedPlantId"
      @close="show = false"
    />

    <NewHirePeriodModal
      v-if="showHirePeriod"
      :plant-id="selectedPlantId"
      @close="showHirePeriod = false"
    />
  </div>
</template>

<script setup lang="ts">
import { australianCurrency } from '@/helpers';
import Plant from '@/models/Plant';
import { ref } from 'vue';
import NewHirePeriodModal from './NewHirePeriodModal.vue';
import PlantListRow from './PlantListRow.vue';
import PlantSaveModal from './PlantSaveModal.vue';

defineProps<{
  projectId: string;
  plants: Plant[];
}>();

const show = ref(false);
const selectedPlantId = ref(undefined);
const showHirePeriod = ref(false);

const showHirePeriodModal = (id: Id) => {
  selectedPlantId.value = id;
  showHirePeriod.value = true;
};

const showUpdateModal = (id: Id) => {
  selectedPlantId.value = id;
  show.value = true;
};
</script>

<style scoped></style>
