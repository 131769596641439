<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2 px-10">
        <h1 class="text-2xl">
          {{ $props.variationOrder ? 'Update' : 'Add' }} Event
        </h1>
      </div>
    </template>

    <template #body>
      <div class="h-full w-full p-4 overflow-y-auto">
        <form
          action=""
          class="flex flex-col space-y-4"
          @submit.prevent="saveOrder"
        >
          <label>
            Code

            <AppInput
              v-model="orderForm.form.code"
              class="form-input"
              name="code"
              type="text"
              :error="orderForm.getErrors('code')"
              data-test="update-code"
            />
          </label>

          <label>
            Description

            <AppInput
              v-model="orderForm.form.description"
              class="form-input"
              name="description"
              type="text"
              :error="orderForm.getErrors('description')"
              data-test="update-description"
            />
          </label>

          <label>
            Budget <span class="text-sm text-gray-400">($)</span>

            <AppInput
              v-model="orderForm.form.budget"
              class="form-input"
              name="budget"
              type="text"
              :error="orderForm.getErrors('budget')"
              data-test="update-budget"
            />
          </label>

          <label>
            Type

            <AppSelect
              v-model="orderForm.form.type"
              name="type"
              class="form-input capitalize"
              :error="orderForm.getErrors('type')"
              data-test="update-type"
              :options="variationTypes"
              :disabled="$props.variationOrder"
            />
          </label>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-between p-2 space-x-4">
        <button
          v-if="$props.variationOrder"
          type="button"
          class="btn btn--tertiary-blue"
          @click="deleteOrder"
        >
          Delete
        </button>

        <div class="space-x-2 ml-auto">
          <button
            type="button"
            class="btn btn--secondary-blue"
            @click="$emit('close'); orderForm.resetData()"
          >
            Cancel
          </button>

          <button
            type="button"
            class="btn btn--primary-blue"
            data-test="save-order"
            @click="saveOrder"
          >
            {{ $props.variationOrder ? 'Update' : 'Create' }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/Modal.vue';
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import VariationOrder, { VariationOrderType } from '@/models/VariationOrder';
import { useVariationOrdersStore } from '@/store/variationOrders';
import Swal from 'sweetalert2';
import { computed, watch } from 'vue';

const props = defineProps<{
  variationOrder?: VariationOrder;
  projectId: Id;
}>();

const emits = defineEmits<{
  (e: 'close');
}>();

const variationOrdersStore = useVariationOrdersStore();

const orderForm = useFormData({
  code: '',
  description: '',
  budget: 0,
  type: 0,
});

watch(() => props.variationOrder, () => {
  if(props.variationOrder) {
    orderForm.setData(props.variationOrder);
  }
}, { immediate: true });

const updateOrderAction = useStoreApiAction(variationOrdersStore.updateVariationOrder);
const createOrderAction = useStoreApiAction(variationOrdersStore.createVariationOrder);

const saveOrder = () => {
  orderForm.resetErrors();

  if(props.variationOrder) {
    updateOrderAction.request(props.variationOrder.id, orderForm.form).then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Event Updated',
      }).then(() => {
        emits('close');
      });

      orderForm.resetData();
    }).catch((error) => {
      orderForm.setErrors(error.data);
    });
  } else {
    createOrderAction.request(props.projectId, orderForm.form).then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Event Created',
      }).then(() => {
        emits('close');
      });

      orderForm.resetData();
    }).catch((error) => {
      orderForm.setErrors(error.data);
    });
  }
};

const deleteAction = useStoreApiAction(variationOrdersStore.deleteVariationOrder);

const deleteOrder = () => {
  deleteAction.request(props.variationOrder.id).then(() => {
    Swal.fire({
      icon: 'success',
      title: 'Event deleted',
    }).then(() => {
      emits('close');
    });
  }).catch(console.error);
};

const variationTypes = computed(() => {
  const types = [];

  for(const type in VariationOrderType) {
    const value = VariationOrderType[type];

    types.push({ value, label: type.replace('_', ' ') });
  }

  return types;
});
</script>

<style scoped></style>
