<template>
  <div class="card">
    <div class="card__header flex justify-between items-center space-x-4">
      <p>Events</p>

      <button
        type="button"
        class="btn btn--secondary-blue"
        data-test="add-order"
        @click.prevent="addOrder"
      >
        Add Event
      </button>
    </div>

    <div class="flex w-full p-4 space-x-4">
      <ul class="overflow-auto space-y-4 h-64 flex-1">
        <li
          v-for="(variationOrder, i) in variationOrders"
          :key="variationOrder.id"
          class="card__list-item"
          :data-test="`order-item-${i}`"
        >
          <div class="flex justify-between">
            <div class="flex flex-col">
              <p :title="variationOrder.code">
                {{ variationOrder.code }}
              </p>

              <p
                :title="variationOrder.description"
              >
                {{ variationOrder.description }}
              </p>
            </div>

            <div class="flex justify-end row-span-2 items-center">
              <button
                type="button"
                data-test="edit"
                class="mr-2"
                @click="editOrder(variationOrder)"
              >
                <Icon
                  name="edit"
                  class="btn-icon h-5 w-5"
                />
              </button>

              <Icon
                name="trash"
                class="w-6 h-6 hover:stroke-red-600 cursor-pointer"
                data-test="trash"
                @click="removeOrder(variationOrder.id)"
              />
            </div>
          </div>
        </li>
      </ul>
    </div>

    <VariationOrderModal
      v-if="showEditOrder"
      :variation-order="selectedOrder"
      :project-id="props.projectId"
      @close="showEditOrder = false"
    />
  </div>
</template>

<script setup lang="ts">
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import VariationOrder from '@/models/VariationOrder';
import { useVariationOrdersStore } from '@/store/variationOrders';
import Swal from 'sweetalert2';
import { computed, ref } from 'vue';
import VariationOrderModal from './VariationOrderModal.vue';

const props = defineProps<{
  projectId: string;
}>();

const variationOrdersStore = useVariationOrdersStore();
const destroyWbsCodeAction = useStoreApiAction(variationOrdersStore.deleteVariationOrder);

const removeOrder = (id: Id) => {
  Swal.fire({
    icon: 'warning',
    title: 'Delete Event?',
    text: 'Are you sure you want to delete this Event?',
    showConfirmButton: true,
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      destroyWbsCodeAction.request(id).catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Failed to delete',
        });
      });
    }
  });
};

const showEditOrder = ref(false);
const selectedOrder = ref();

const editOrder = (variationOrder: VariationOrder) => {
  selectedOrder.value = variationOrder;
  showEditOrder.value = true;
};

const variationOrders = computed(() => {
  return variationOrdersStore.models.where('project_id', parseInt(props.projectId)).get();
});

const addOrder = () => {
  selectedOrder.value = undefined;
  showEditOrder.value = true;
};
</script>

<style scoped></style>
