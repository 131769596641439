<template>
  <div
    class="relative"
    v-on="{
      mouseenter: $props.enableClick ? undefined : open,
      mouseleave: close
    }"
  >
    <div
      class="min-w-max"
      :class="[$props.enableClick ? 'cursor-pointer' : 'cursor-default']"
      v-bind="$attrs"
      v-on="{
        click: $props.enableClick ? open : undefined
      }"
    >
      <slot
        name="button"
        :show="show"
        :close="close"
      >
        {{ $props.title }}
      </slot>
    </div>

    <div
      v-if="show"
    >
      <div class="absolute p-2 w-full" />

      <div
        class="min-w-max absolute bg-gray-200 shadow-lg p-4 left-0 rounded-lg w-full flex flex-col space-y-4 max-h-72 overflow-y-auto mt-2"
      >
        <slot
          :close="close"
          :show="show"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

withDefaults(
  defineProps<{
    title: string;
    enableClick?: boolean;
  }>(),
  {
    enableClick: false,
  },
);

const show = ref(false);

const open = () => {
  show.value = true;
};

const close = () => {
  show.value = false;
};
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<style scoped></style>
