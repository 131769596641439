<template>
  <div class="flex items-center justify-center h-full space-y-2.5 max-h-content my-8">
    <div
      class="flex flex-col border border-gray-300 p-5 rounded-lg justify-between
    items-center max-h-content h-full space-y-10 bg-white shadow-md"
    >
      <div class="w-full relative grid grid-cols-[1fr_auto_1fr] gap-x-4">
        <div />

        <h1 class="text-4xl">
          {{ showCaptured ? 'Captured' : 'Surveyed' }} Quantities for {{ selectedMonth.toLocaleString('default', { month: 'long' }) }}
        </h1>

        <div
          v-if="projectsStore.project?.codes.length"
          class="ml-auto flex space-x-4"
        >
          <button
            type="button"
            class="btn btn--secondary-blue"
            data-test="show_surveys"
            @click="show = true"
          >
            Surveys
          </button>

          <a
            :href="exportUrl"
            target="_blank"
            class="btn btn--secondary-blue inline-block"
          >
            Export
          </a>
        </div>
      </div>

      <div
        v-if="quantitiesAction.state.value === States.COMPLETE || quantitiesAction.firstLoad.value"
        class="flex w-full"
      >
        <form
          class="flex flex-col space-y-6 w-1/4 mx-auto h-full"
          @submit.prevent="requestRecords"
        >
          <label>
            WBS Code <span class="text-sm text-gray-400">{{ wbsCode ? `(${wbsCode.unit})` : '' }}</span>

            <AppSelect
              v-model.number="quantitiesForm.form.wbs_code_id"
              :options="projectsStore.project?.codes.map((code) => ({ value: code.id, label: code.code }))"
              name="wbs_code"
              data-test="wbs_code"
              class="form-input"
            />
          </label>

          <label>
            WBS Description

            <AppSelect
              v-model.number="quantitiesForm.form.wbs_code_id"
              :options="projectsStore.project?.codes.map((code) => ({ value: code.id, label: code.description }))"
              name="wbs_code_description"
              data-test="wbs_code_description"
              class="form-input"
            />
          </label>

          <label>
            Month

            <AppInput
              v-model="quantitiesForm.form.date"
              type="month"
              name="date"
              data-test="date"
              class="form-input"
            />
          </label>

          <button
            type="submit"
            :disabled="quantitiesAction.state.value === States.LOADING"
            class="btn btn--primary-blue w-full"
            data-test="load-quantities"
          >
            Load Quantities
          </button>
        </form>

        <div class="w-1/4 space-y-6 mx-auto flex flex-col justify-between">
          <div class="col-span-full grid grid-cols-3 gap-y-4">
            <h4 class="text-center font-semibold text-lg col-span-full">
              Monthly Total
            </h4>

            <div>
              <p
                class="text-center"
                title="Total Cost in the month"
              >
                Cost
              </p>

              <p class="text-center">
                {{ australianCurrency(totalCost) }}
              </p>
            </div>

            <div>
              <p
                class="text-center"
                title="Total Quantity in the month"
              >
                Quantity
              </p>

              <p class="text-center">
                {{ totalQuantity.toLocaleString('en-AU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }) }}
              </p>
            </div>

            <div>
              <p
                class="text-center"
                title="Rate per unit for the month"
              >
                Rate
              </p>

              <p class="text-center">
                {{
                  australianCurrency(
                    !isNaN(totalCost/totalQuantity) ?
                      totalCost/totalQuantity : 0
                  )
                }}/{{ wbsCode?.unit }}
              </p>
            </div>
          </div>

          <div class="grid grid-cols-3 gap-y-4">
            <h4 class="text-center font-semibold text-lg col-span-full">
              Total to {{ previousMonth.toLocaleString('default', { month: 'long' }) }}
            </h4>

            <div>
              <p
                class="text-center"
                title="Cost up to but excluding the current months totals"
              >
                Cost
              </p>

              <p class="text-center">
                {{ australianCurrency(toDateTotals.cost) }}
              </p>
            </div>

            <div>
              <p
                class="text-center"
                title="Quantity up to but excluding the current months totals"
              >
                Quantity
              </p>

              <p class="text-center">
                {{ toDateTotals.quantity.toLocaleString('en-AU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }) }}
              </p>
            </div>

            <div>
              <p
                class="text-center"
                title="Average rate up to but excluding the current months totals"
              >
                Rate
              </p>

              <p class="text-center">
                {{
                  australianCurrency(toDateTotals.cost / safeDenominator(toDateTotals.quantity))
                }}/{{ wbsCode?.unit }}
              </p>
            </div>
          </div>

          <div class="bg-gray-200 p-1 rounded-lg shadow-inner">
            <div class="flex relative gap-x-4">
              <div
                :class="[showCaptured ? 'left-0' : 'left-1/2']"
                class="absolute btn bg-white w-1/2 transition-[left] border-transparent shadow-lg h-full"
              />

              <button
                type="button"
                :class="[showCaptured ? 'text-ccm-blue-100' : 'text-black']"
                class="btn w-full justify-self-end col-span-full bg-transparent z-10 relative hover:bg-white hover:bg-opacity-50"
                data-test="toggle-quantities"
                @click="showCaptured = true"
              >
                Captured
              </button>

              <button
                type="button"
                :class="[showCaptured ? 'text-black' : 'text-ccm-blue-100']"
                class="btn w-full justify-self-end col-span-full bg-transparent z-10 relative hover:bg-white hover:bg-opacity-50"
                data-test="toggle-quantities"
                @click="showCaptured = false"
              >
                Surveyed
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        v-else-if="projectsStore.project?.codes.length === 0"
        class="text-center font-semibold w-full p-2"
      >
        No WBS Codes found.
      </div>

      <div v-else>
        <AppSpinner />
      </div>

      <div
        v-if="allDays"
        class="grid grid-flow-col border border-black"
        data-test="quantities-list"
      >
        <ul
          v-for="(week, i) in allDays"
          :key="i"
          class="last:border-0 border-r border-black p-2 space-y-2 px-4"
        >
          <li class="grid grid-cols-4 space-x-4">
            <div>Day</div>

            <p
              class="text-right"
              title="The quantity used in the day. This may be adjusted when the surveyed quantity is inserted"
            >
              Quantity
            </p>

            <p class="text-right">
              Cost
            </p>

            <p class="text-right">
              Rate
            </p>
          </li>

          <template
            v-for="day in week"
            :key="day"
          >
            <DailyQuantity
              :day="new Date(day)"
              :wbs-code="wbsCode"
              :show-captured="showCaptured"
              :daily-quantity="quantities.get(day)"
              :cost="quantitiesBreakdown[day]"
            />
          </template>
        </ul>
      </div>
    </div>

    <SurveyedQuantityModal
      v-if="show"
      :wbs-code="wbsCode"
      @close="show = false"
    />
  </div>
</template>

<script setup lang="ts">
import DailyQuantity from '@/components/Quantities/DailyQuantity.vue';
import SurveyedQuantityModal from '@/components/Quantities/SurveyedQuantityModal.vue';
import useDayQueryParam from '@/composables/useDayQueryParam';
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { States } from '@/composables/useStoreApiAction';
import { australianCurrency, dateAsYM, safeDenominator, timezoneOffset } from '@/helpers';
import { useDailyQuantitiesStore } from '@/store/dailyQuantities';
import { useProjectsStore } from '@/store/projects';
import { useSurveyedQuantitiesStore } from '@/store/surveyedQuantities';
import { useWbsCodesStore } from '@/store/wbsCodes';
import { route } from '@/ziggy-shim';
import Swal from 'sweetalert2';
import { computed } from 'vue';
import { ref, watch } from 'vue';

const projectsStore = useProjectsStore();
const dailyQuantitiesStore = useDailyQuantitiesStore();
const surveyedQuantitiesStore = useSurveyedQuantitiesStore();
const selectedDate = useDayQueryParam();

const project = computed(() => {
  return projectsStore.project;
});

const quantitiesForm = useFormData({
  wbs_code_id: project.value?.codes[0]?.id || undefined,
  date: selectedDate.value.toISOString().slice(0, 7),
});

const selectedMonth = computed({
  get: () => selectedDate.value,
  set: (value) => {
    if(!isNaN(value.getTime())) {
      selectedDate.value = value;
    }
  },
});

const previousMonth = computed(() => {
  const prevMonth = new Date(selectedMonth.value);

  prevMonth.setMonth(prevMonth.getMonth() - 1);

  return prevMonth;
});

watch(quantitiesForm.form, (newValue) => {
  selectedMonth.value = new Date(newValue.date);
});

const quantitiesBreakdown = ref<[K: number]>();

const quantities = computed(() => {
  return new Map(
    dailyQuantitiesStore.models
      .where('wbs_code_id', quantitiesForm.form.wbs_code_id)
      .get().map((quantity) => [quantity.day, quantity]),
  );
});

const totalCost = computed(() => {
  let totalCost = 0;

  if(quantitiesBreakdown.value) {
    for(const [_key, value] of Object.entries(quantitiesBreakdown.value)) {
      totalCost += value;
    }
  }

  return totalCost;
});

const quantitiesAction = useStoreApiAction(dailyQuantitiesStore.fetchQuantities);
const surveyedQuantitiesAction = useStoreApiAction(surveyedQuantitiesStore.fetchQuantities);
const allDays = ref<string[][]>();

const toDateTotals = ref({
  quantity: 0,
  cost: 0,
});

const getAllDaysInMonth = (month: number, year: number) =>
  Array.from(
    { length: new Date(year, month, 0, 0).getDate() },
    (_, i) =>
      new Date(
        new Date(year, month - 1, i + 1).getTime() - (timezoneOffset * 60 * 1000),
      )
        .toISOString()
        .split('T')[0],
  ).reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 7);

    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

const requestRecords = () => {
  surveyedQuantitiesAction.request(projectsStore.project?.id, quantitiesForm.form);

  quantitiesAction.request(projectsStore.project?.id, quantitiesForm.form).then((quantities) => {
    quantitiesBreakdown.value = quantities.days;
    allDays.value = getAllDaysInMonth(quantities.month, quantities.year);
    toDateTotals.value = quantities.to_date_totals;
  }).catch((error) => {
    console.error(error);

    Swal.fire({
      icon: 'error',
      text: 'Something went wrong.',
    });
  });
};

const surveyedQuantityForm = useFormData({
  day: '',
  quantity: 0,
  wbs_code_id: undefined,
});

const surveyedQuantities = computed(() => {
  return surveyedQuantitiesStore.models
    .where('project_id', projectsStore.project?.id)
    .where('wbs_code_id', quantitiesForm.form.wbs_code_id)
    .orderBy('day', 'desc')
    .get();
});

const latestSurvey = computed(() => {
  return surveyedQuantities.value[0];
});

watch(latestSurvey, (newValue) => {
  if(newValue) {
    surveyedQuantityForm.form.day = newValue.day;
    surveyedQuantityForm.form.quantity = newValue.quantity;
  } else {
    surveyedQuantityForm.form.quantity = 0;
  }
});

watch(project, (newValue, oldValue) => {
  if(newValue && newValue.id !== oldValue?.id) {
    quantitiesForm.form.wbs_code_id = newValue.codes[0]?.id;
  }
}, { immediate: true });

watch(quantitiesForm.form, (newValue) => {
  if(newValue.date && newValue.wbs_code_id) {
    requestRecords();
  }
}, { immediate: true });

const totalQuantity = computed(() => {
  let totalQuantity = 0;

  dailyQuantitiesStore.models
    .where('wbs_code_id', quantitiesForm.form.wbs_code_id)
    .where('day', (day) => {
      return day.slice(0, -3) === dateAsYM(selectedMonth.value);
    }).get().forEach((quantity) => {
      totalQuantity += quantity.surveyedQuantity;
    });

  return totalQuantity;
});

const wbsCodesStore = useWbsCodesStore();

const wbsCode = computed(() => {
  return wbsCodesStore.models.find(quantitiesForm.form.wbs_code_id);
});

const showCaptured = ref(false);

const exportUrl = computed(() => {
  if(projectsStore.project) {
    return `${
      route('api.v1.quantities.export', projectsStore.project?.id)
    }?wbs_code_id=${quantitiesForm.form.wbs_code_id}&month=${quantitiesForm.form.date}`;
  }

  return undefined;
});

const show = ref(false);
</script>

<style scoped></style>
