import TradeItem from '@/models/TradeItem';
import TradeItemsService from '@/services/TradeItemsService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';

export const useTradeItemsStore = defineStore('trade_items', {
  state: () => ({
    selectedTradeItems: new Set(),
  }),

  getters: {
    models: () => useRepo(TradeItem),
  },

  actions: {
    async fetchTradeItems(projectId: Id) {
      const [data, error] = await TradeItemsService.index(projectId);

      if(data) {
        this.models.save(data.data);

        return data;
      }

      throw error;
    },

    async createTradeItem(projectId: Id, request: ApiRequests['api.v1.trade-items.store']) {
      const [data, error] = await TradeItemsService.create(projectId, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updateTradeItem(id: Id, request: ApiRequests['api.v1.trade-items.update']) {
      const [data, error] = await TradeItemsService.update(id, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async deleteTradeItem(id: Id) {
      const [data, error] = await TradeItemsService.delete(id);

      if(data) {
        this.models.destroy(data.id);

        return data;
      }

      throw error;
    },

    async updateSort(id: Id, form: ApiRequests['api.v1.trade-items.sort']) {
      const [data, error] = await TradeItemsService.updateSort(id, form);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },
  },
});
