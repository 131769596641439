import Comment from '@/models/Comment';
import VariationOrderCost from '@/models/VariationOrderCost';
import VariationOrderCostService from '@/services/VariationOrderCostService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';

export const useVariationOrderCostsStore = defineStore('variation_order_costs', {
  state: () => ({}),

  getters: {
    models: () => useRepo(VariationOrderCost),
  },

  actions: {
    async createVariationOrderCost(
      claimId: Id,
      tradeItemId: Id,
      request: ApiRequests['api.v1.variation-order-costs.store'],
    ) {
      const [data, error] = await VariationOrderCostService.create(claimId, tradeItemId, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updateVariationOrderCost(id: Id, request: ApiRequests['api.v1.variation-order-costs.update']) {
      const [data, error] = await VariationOrderCostService.update(id, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async deleteVariationOrderCost(id: Id) {
      const [data, error] = await VariationOrderCostService.delete(id);

      if(data) {
        this.models.destroy(id);

        return data;
      }

      throw error;
    },

    async saveClaim(claimId: Id, signedParams: unknown, request: ApiRequests['api.v1.claims.variation.claim.store']) {
      const [data, error] = await VariationOrderCostService.claim(claimId, signedParams, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async saveCertified(
      claimId: Id,
      signedParams: unknown,
      request: ApiRequests['api.v1.claims.variation.certified.store'],
    ) {
      const [data, error] = await VariationOrderCostService.certified(claimId, signedParams, request);

      if(data) {
        this.models.save(data.cost);

        if(data.comment) {
          const comment = data.comment;
          const commentsRepo = useRepo(Comment);

          commentsRepo.save(comment);
        }

        return data;
      }

      throw error;
    },
  },
});
