<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          {{ $props.labourId ? 'Update' : 'Add' }} Labour
        </h1>
      </div>
    </template>

    <template #body>
      <div class="h-full w-full p-4 overflow-y-auto">
        <form
          action=""
          class="flex flex-col space-y-4"
          @submit.prevent="saveLabour"
        >
          <div class="flex space-x-4">
            <label class="w-1/2">
              First Name
              <AppInput
                v-model="labourForm.form.first_name"
                class="form-input"
                name="first_name"
                type="text"
                :error="labourForm.getErrors('first_name')"
                data-test="first-name"
              />
            </label>

            <label class="w-1/2">
              Surname
              <AppInput
                v-model="labourForm.form.last_name"
                name="last_name"
                type="text"
                :error="labourForm.getErrors('last_name')"
                data-test="surname"
                class="form-input"
              />
            </label>
          </div>

          <label>
            Employee Type
            <div class="flex items-center mx-auto space-x-4">
              <button
                type="button"
                class="btn w-1/2"
                :class="[labourForm.form.type === 'internal' ? 'btn--primary-blue' : 'btn--secondary-blue']"
                data-test="type-internal"
                @click="labourForm.form.type = 'internal'"
              >Internal</button>

              <button
                type="button"
                class="btn w-1/2"
                :class="[labourForm.form.type === 'external' ? 'btn--primary-blue' : 'btn--secondary-blue']"
                data-test="type-external"
                @click="labourForm.form.type = 'external'"
              >External</button>
            </div>
          </label>

          <label>
            Supplier

            <AppTypeAhead
              v-model="labourForm.form.supplier_name"
              name="supplier_name"
              :suggestion-callback="getSuggestions"
              :initial-suggestions="getSuggestions"
              data-test="supplier-name"
              :error="labourForm.getErrors('supplier_id') || labourForm.getErrors('supplier_name')"
              class="form-input"
              @value-selected="selectSupplier"
            />
          </label>

          <div class="flex space-x-4">
            <label class="w-1/2">
              Rate <span class="text-sm text-gray-400">($/hr)</span>

              <AppInput
                v-model="labourForm.form.rate"
                name="rate"
                type="text"
                :error="labourForm.getErrors('rate')"
                data-test="rate"
                class="form-input"
              />
            </label>

            <label class="w-1/2">
              CW Ref
              <AppInput
                v-model="labourForm.form.cw_ref"
                name="cw_ref"
                type="text"
                :error="labourForm.getErrors('cw_ref')"
                data-test="cw-ref"
                class="form-input"
              />
            </label>
          </div>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-between p-2 space-x-4">
        <button
          v-if="$props.labourId"
          type="button"
          class="btn btn--tertiary-blue"
          @click="deleteLabour"
        >
          Delete
        </button>

        <div class="space-x-2 ml-auto">
          <button
            type="button"
            class="btn btn--secondary-blue"
            @click="$emit('close'); labourForm.resetData()"
          >
            Cancel
          </button>

          <button
            type="button"
            class="btn btn--primary-blue"
            :disabled="saveLabourAction.is(States.LOADING)"
            data-test="save-labour"
            @click="saveLabour"
          >
            {{ $props.labourId ? 'Update' : 'Create' }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { SupplierType } from '@/models/ProjectSupplier';
import { useLaboursStore } from '@/store/labours';
import { useSuppliersStore } from '@/store/supplier';
import Swal from 'sweetalert2';
import { computed, nextTick, ref, watch } from 'vue';
import Modal from '../Modal.vue';

const props = defineProps<{
  projectId: Id;
  labourId?: Id;
}>();

const emits = defineEmits<{
  (e: 'close');
}>();

const laboursStore = useLaboursStore();

const labourForm = useFormData({
  first_name: '',
  last_name: '',
  type: 'internal',
  supplier_name: '',
  rate: 0,
  cw_ref: '',
  supplier_id: undefined,
});

const selectedLabour = computed(() => {
  return laboursStore.models.with('supplier').find(props.labourId);
});

watch(selectedLabour, () => {
  if(selectedLabour.value) {
    labourForm.form.supplier_name = selectedLabour.value.supplier.name;
    labourForm.setData(selectedLabour.value);
  }
}, { immediate: true });

const saveLabourAction = useStoreApiAction(laboursStore.saveLabour);
const updateLabourAction = useStoreApiAction(laboursStore.updateLabour);

const saveLabour = () => {
  labourForm.resetErrors();

  if(props.labourId) {
    updateLabourAction.request(props.labourId, labourForm.form).then((_data) => {
      labourForm.resetData();
      emits('close');
    }).catch((error) => {
      labourForm.setErrors(error.data);
    });
  } else {
    saveLabourAction.request(props.projectId, labourForm.form).then((_data) => {
      labourForm.resetData();
      emits('close');
    }).catch((error) => {
      labourForm.setErrors(error.data);
    });
  }
};

const suppliersStore = useSuppliersStore();
const suggestedSuppliers = ref<{ id: number; name: string }[]>([]);

const getSuggestions = async () => {
  suggestedSuppliers.value = await suppliersStore
    .search({
      name: labourForm.form.supplier_name,
      project_id: parseInt(props.projectId.toString()),
      type: SupplierType.labour,
    });

  return suggestedSuppliers.value.map((value) => value.name);
};

const selectSupplier = (index: number) => {
  nextTick(() => {
    labourForm.form.supplier_id = suggestedSuppliers.value[index].id;
  });
};

watch(() => labourForm.form.supplier_name, (newValue, oldValue) => {
  if(
    newValue !== oldValue &&
    labourForm.form.supplier_id !== undefined
  ) {
    labourForm.form.supplier_id = undefined;
  }
});

const deleteLabourAction = useStoreApiAction(laboursStore.deleteLabour);

const deleteLabour = () => {
  Swal.fire({
    icon: 'warning',
    title: 'Delete Labour?',
    text: `Are you sure you want to delete ${selectedLabour.value.first_name} ${selectedLabour.value.last_name}?`,
    showCancelButton: true,
    showConfirmButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      deleteLabourAction.request(props.labourId).then(() => {
        Swal.fire({
          icon: 'success',
          text: 'Labour deleted',
        }).then(() => {
          emits('close');
        });
      }).catch((error) => {
        if(error?.status === 422) {
          Swal.fire({
            icon: 'error',
            text:
              `Unable to delete labour ${selectedLabour.value.first_name} ${selectedLabour.value.last_name} as it has records associated with it.`,
          });
        }
      });
    }
  });
};
</script>

<style scoped></style>
