<template>
  <tr class="even:bg-gray-100 odd:bg-white text-right border-black border-x hover:bg-blue-100 p-2 m-2">
    <td
      class="py-2 text-left pl-3"
    >
      {{ $props.variationOrder.code }}
    </td>

    <td class="pr-3">
      {{ australianCurrency($props.variationOrder.budget) }}
    </td>

    <template v-if="claim.capture_claim_amount">
      <td
        v-if="variationOrderCost?.current_claim && !typingClaim && canEditClaim"
        :class="{ 'cursor-pointer': canEditClaim }"
        class="bg-green-200/50"
        @click="typeClaim"
      >
        <div class="group flex items-center justify-end">
          <Icon
            name="trash"
            class="group-hover:visible btn-icon-red h-5 w-5 inline-block invisible"
            @click.stop.capture="removeClaim"
          />

          <Icon
            v-if="canEditClaim"
            name="edit"
            class="group-hover:visible btn-icon-blue h-5 w-5 inline-block invisible"
          />
          {{ variationOrderCost.current_claim ? australianCurrency(variationOrderCost.current_claim) : '' }}
        </div>
      </td>

      <td
        v-else-if="canEditClaim"
        class="bg-green-200/50"
      >
        <div class="w-1/2 ml-auto">
          <AppInput
            ref="certifiedInput"
            v-model.number="costForm.form.current_claim"
            name="current_claim"
            type="number"
            min="1"
            class="w-20 h-6"
            @blur="stopTypingClaim"
            @keyup.enter="stopTypingClaim"
            @keyup.esc="typingClaim = false"
          />
        </div>
      </td>

      <td
        v-else
        class="bg-green-200/50"
      >
        {{ variationOrderCost?.current_claim ? australianCurrency(variationOrderCost.current_claim) : '' }}
      </td>
    </template>

    <td
      v-if="variationOrderCost?.current_certified && !typingCertified && canEditCertified"
      :class="{ 'cursor-pointer': canEditCertified }"
      class="bg-green-200/50"
      @click="typeCertified"
    >
      <div class="group flex items-center justify-end">
        <Icon
          name="trash"
          class="group-hover:visible btn-icon-red h-5 w-5 inline-block invisible"
          @click.stop.capture="removeCertified"
        />

        <Icon
          v-if="canEditCertified"
          name="edit"
          class="group-hover:visible btn-icon-blue h-5 w-5 inline-block invisible"
        />
        {{ variationOrderCost.current_certified ? australianCurrency(variationOrderCost.current_certified) : '' }}
      </div>
    </td>

    <td
      v-else-if="canEditCertified"
      class="bg-green-200/50"
    >
      <div class="w-1/2 ml-auto">
        <AppInput
          ref="certifiedInput"
          v-model.number="costForm.form.current_certified"
          name="current_certified"
          type="number"
          min="1"
          class="w-20 h-6"
          @blur="stopTypingCertified"
          @keyup.enter="stopTypingCertified"
          @keyup.esc="typingCertified = false"
        />
      </div>
    </td>

    <td
      v-else
      class="bg-green-200/50"
    >
      {{ variationOrderCost?.current_certified ? australianCurrency(variationOrderCost.current_certified) : '' }}
    </td>

    <td class="bg-green-200/50">
      {{ australianCurrency(variationOrder.previous(claim)) }}
    </td>

    <td class="bg-green-200/50 pr-3">
      {{ australianCurrency(variationOrder.previous(claim) + (variationOrderCost?.current_certified || 0)) }}
    </td>

    <template v-if="claim.capture_claim_amount">
      <td
        v-if="variationOrderCost?.current_claim && !typingClaim && canEditClaim"
        :class="{ 'cursor-pointer': canEditClaim }"
        class="bg-blue-300/50"
        @click="typeClaimPercent"
      >
        <div class="group flex items-center justify-end">
          <Icon
            name="trash"
            class="group-hover:visible btn-icon-red h-5 w-5 inline-block invisible"
            @click.stop.capture="removeClaim"
          />

          <Icon
            v-if="canEditClaim"
            name="edit"
            class="group-hover:visible btn-icon-blue h-5 w-5 inline-block invisible"
          />
          {{ variationOrderCost?.current_claim ? `${australianNumber((variationOrderCost.current_claim / variationOrder.budget) * 100)}%` : '' }}
        </div>
      </td>

      <td
        v-else-if="canEditClaim"
        class="bg-blue-300/50"
      >
        <div class="w-1/2 ml-auto">
          <AppInput
            ref="claimPercentInput"
            v-model.number="currentClaimPercent"
            name="current_claim_percent"
            type="number"
            class="w-20 h-6"
            @blur="stopTypingClaim"
            @keyup.enter="stopTypingClaim"
            @keyup.esc="typingClaim = false"
          />
        </div>
      </td>

      <td
        v-else
        class="bg-blue-300/50"
      >
        {{ variationOrderCost?.current_claim ? `${australianNumber((variationOrderCost.current_claim / variationOrder.budget) * 100)}%` : '' }}
      </td>
    </template>

    <td
      v-if="variationOrderCost?.current_certified && !typingCertified && canEditCertified"
      :class="{ 'cursor-pointer': canEditCertified }"
      class="bg-blue-300/50"
      @click="typeCertifiedPercent"
    >
      <div class="group flex items-center justify-end">
        <Icon
          name="trash"
          class="group-hover:visible btn-icon-red h-5 w-5 inline-block invisible"
          @click.stop.capture="removeCertified"
        />

        <Icon
          v-if="canEditCertified"
          name="edit"
          class="group-hover:visible btn-icon-blue h-5 w-5 inline-block invisible"
        />
        {{ variationOrderCost?.current_certified ? `${australianNumber((variationOrderCost.current_certified / variationOrder.budget) * 100)}%` : '' }}
      </div>
    </td>

    <td
      v-else-if="canEditCertified"
      class="bg-blue-300/50"
    >
      <div class="w-1/2 ml-auto">
        <AppInput
          ref="certifiedPercentInput"
          v-model.number="currentCertifiedPercent"
          name="current_certified_percent"
          type="number"
          class="w-20 h-6"
          @blur="stopTypingCertified"
          @keyup.enter="stopTypingCertified"
          @keyup.esc="typingCertified = false"
        />
      </div>
    </td>

    <td
      v-else
      class="bg-blue-300/50"
    >
      {{ variationOrderCost?.current_certified ? `${australianNumber((variationOrderCost.current_certified / variationOrder.budget) * 100)}%` : '' }}
    </td>

    <td class="bg-blue-300/50">
      {{ australianNumber((variationOrder.previous(claim) / variationOrder.budget) * 100) }}%
    </td>

    <td class="bg-blue-300/50 pr-3">
      {{ australianNumber(((variationOrder.previous(claim) + (variationOrderCost?.current_certified || 0)) / variationOrder.budget) * 100) }}%
    </td>

    <td
      v-if="claim.capture_claim_amount"
      class="pr-3"
    >
      {{ (isNumber(variationOrderCost?.current_claim) && isNumber(variationOrderCost?.current_certified)) ? australianCurrency(Math.abs(variationOrderCost.current_claim - variationOrderCost.current_certified)) : '' }}
    </td>

    <td class="pr-3">
      {{ australianCurrency(variationOrder.budget - variationOrder.previous(claim) - (variationOrderCost?.current_certified || 0)) }}
    </td>
  </tr>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, australianNumber, falsey, isNumber, roundDecimals } from '@/helpers';
import Claim, { ClaimStatus } from '@/models/Claim';
import VariationOrder from '@/models/VariationOrder';
import { useVariationOrderCostsStore } from '@/store/variationOrderCosts';
import Swal from 'sweetalert2';
import { computed, nextTick, ref, watch } from 'vue';

const props = defineProps<{
  variationOrder: VariationOrder;
  claim: Claim;
}>();

const variationOrderCostsStore = useVariationOrderCostsStore();

const variationOrderCost = computed(() => {
  return variationOrderCostsStore.models.where('claim_id', props.claim.id).where(
    'variation_order_id',
    props.variationOrder.id,
  ).first();
});

const canEditCertified = computed(() => {
  return props.claim.status === ClaimStatus.draft;
});

const typingCertified = ref(falsey(variationOrderCost.value?.current_certified));
const certifiedInput = ref();
const certifiedPercentInput = ref();

const typeCertified = () => {
  if(canEditCertified.value) {
    typingCertified.value = true;

    nextTick(() => {
      certifiedInput.value?.focusInput();
    });
  }
};

const typeCertifiedPercent = () => {
  if(canEditCertified.value) {
    typingCertified.value = true;

    nextTick(() => {
      certifiedPercentInput.value?.focusInput();
    });
  }
};

const currentCertifiedPercent = computed({
  get() {
    return roundDecimals((costForm.form.current_certified / props.variationOrder.budget) * 100) || undefined;
  },

  set(value) {
    costForm.form.current_certified = roundDecimals((value / 100) * props.variationOrder.budget);
  },
});

const canEditClaim = computed(() => {
  return props.claim.status === ClaimStatus.draft;
});

const typingClaim = ref(falsey(variationOrderCost.value?.current_claim));
const claimInput = ref();
const claimPercentInput = ref();

const typeClaim = () => {
  if(canEditClaim.value) {
    typingClaim.value = true;

    nextTick(() => {
      claimInput.value?.focusInput();
    });
  }
};

const typeClaimPercent = () => {
  if(canEditClaim.value) {
    typingClaim.value = true;

    nextTick(() => {
      claimPercentInput.value?.focusInput();
    });
  }
};

const currentClaimPercent = computed({
  get() {
    return roundDecimals((costForm.form.current_claim / props.variationOrder.budget) * 100) || undefined;
  },

  set(value) {
    costForm.form.current_claim = roundDecimals((value / 100) * props.variationOrder.budget);
  },
});

const costForm = useFormData({
  current_certified: undefined,
  current_claim: undefined,
});

watch(variationOrderCost, (newValue) => {
  if(newValue) {
    costForm.setData(newValue);
  }
}, { immediate: true });

const storeVariationOrderCostAction = useStoreApiAction(variationOrderCostsStore.createVariationOrderCost);
const updateVariationOrderCostAction = useStoreApiAction(variationOrderCostsStore.updateVariationOrderCost);
const deleteVariationOrderCostAction = useStoreApiAction(variationOrderCostsStore.deleteVariationOrderCost);

const stopTypingClaim = async () => {
  if(
    costForm.form.current_claim === 0 || (falsey(costForm.form.current_claim) && !variationOrderCost.value) ||
    !typingClaim.value
  ) {
    return;
  }

  if(
    falsey(costForm.form.current_claim) && variationOrderCost.value &&
    falsey(variationOrderCost.value.current_certified)
  ) {
    deleteVariationOrderCostAction.request(variationOrderCost.value.id).then(() => {
      typingClaim.value = true;
    }).catch((_error) => {
      Swal.fire({
        icon: 'error',
        title: 'Something went wrong.',
      });

      typingClaim.value = false;
    });
  } else if(variationOrderCost.value) {
    updateVariationOrderCostAction.request(variationOrderCost.value.id, { current_claim: costForm.form.current_claim })
      .then((_variationOrderCost) => {
        if(falsey(variationOrderCost.value?.current_claim)) {
          typingClaim.value = true;
        } else {
          typingClaim.value = false;
        }
      }).catch((_error) => {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong.',
        });

        costForm.form.current_claim = variationOrderCost.value.current_claim;
        typingClaim.value = false;
      });
  } else {
    storeVariationOrderCostAction.request(props.claim.id, props.variationOrder.id, costForm.form).then(
      (variationOrderCost) => {
        typingClaim.value = false;
      },
    ).catch((_error) => {
      Swal.fire({
        icon: 'error',
        title: 'Something went wrong.',
      });

      typingClaim.value = true;
    });
  }
};

const stopTypingCertified = async () => {
  if(
    costForm.form.current_certified === 0 || (falsey(costForm.form.current_certified) && !variationOrderCost.value) ||
    !typingCertified.value
  ) {
    return;
  }

  if(
    falsey(costForm.form.current_certified) && variationOrderCost.value &&
    falsey(variationOrderCost.value.current_claim)
  ) {
    deleteVariationOrderCostAction.request(variationOrderCost.value.id).then(() => {
      typingCertified.value = true;
    }).catch((error) => {
      Swal.fire({
        icon: 'error',
        title: 'Something went wrong.',
      });

      typingCertified.value = false;
    });
  } else if(variationOrderCost.value) {
    updateVariationOrderCostAction.request(variationOrderCost.value.id, {
      current_certified: costForm.form.current_certified,
    }).then((_variationOrderCost) => {
      if(falsey(variationOrderCost.value?.current_certified)) {
        typingCertified.value = true;
      } else {
        typingCertified.value = false;
      }
    }).catch((error) => {
      if(error.status === 422) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid amount.',
          text: error.data.message,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong.',
        });
      }

      costForm.form.current_certified = variationOrderCost.value.current_certified;
      typingCertified.value = false;
    });
  } else {
    storeVariationOrderCostAction.request(props.claim.id, props.variationOrder.id, costForm.form).then(
      (variationOrderCost) => {
        typingCertified.value = false;
      },
    ).catch((error) => {
      if(error.status === 422) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid amount.',
          text: error.data.message,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong.',
        });
      }

      typingCertified.value = true;
    });
  }
};

const deleteCost = () => {
  deleteVariationOrderCostAction.request(variationOrderCost.value.id).then(() => {
    costForm.form.current_certified = undefined;
    costForm.form.current_claim = undefined;
    typingCertified.value = true;
    typingClaim.value = true;
  }).catch((error) => {
    Swal.fire({
      icon: 'error',
      title: 'Something went wrong.',
    });
  });
};

const removeClaim = () => {
  if(falsey(variationOrderCost.value.current_certified)) {
    deleteCost();
  } else {
    updateVariationOrderCostAction.request(variationOrderCost.value.id, { current_claim: undefined })
      .then((tradeItemCost) => {
        console.log(tradeItemCost);
        typingClaim.value = true;
      }).catch((error) => {
        if(error.status === 422) {
          Swal.fire({
            icon: 'error',
            title: 'Invalid amount.',
            text: error.data.message,
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Something went wrong.',
          });
        }

        costForm.form.current_claim = variationOrderCost.value.current_claim;
      });
  }
};

const removeCertified = () => {
  if(falsey(variationOrderCost.value.current_claim)) {
    deleteCost();
  } else {
    updateVariationOrderCostAction.request(variationOrderCost.value.id, { current_certified: undefined })
      .then((tradeItemCost) => {
        console.log(tradeItemCost);
        typingCertified.value = true;
      }).catch((error) => {
        if(error.status === 422) {
          Swal.fire({
            icon: 'error',
            title: 'Invalid amount.',
            text: error.data.message,
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Something went wrong.',
          });
        }

        costForm.form.current_certified = variationOrderCost.value.current_certified;
      });
  }
};
</script>

<style scoped></style>
