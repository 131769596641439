<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          Comments
        </h1>
      </div>
    </template>

    <template #body>
      <div class="flex flex-col my-4 h-full overflow-y-auto">
        <form
          action=""
          class="w-full space-y-2 px-4"
          @submit.prevent="saveComment"
        >
          <label class="flex items-center justify-between space-x-4">
            <span>
              Comment
            </span>

            <AppInput
              v-model="recordForm.form.comment"
              name="comment"
              type="text"
              data-test="comment"
              :error="recordForm.getErrors('comment')"
            />
          </label>
        </form>

        <hr class="mt-4">

        <div
          v-if="record.comments.length"
          class="overflow-y-auto w-full max-h-60 px-4"
        >
          <div
            v-for="comment in record.comments"
            :key="comment.id"
            class="w-72"
          >
            <p class="font-semibold">
              {{ comment.creator?.name }}
              <span class="text-sm font-normal">
                {{ comment.createdAt.toLocaleString('en-AU', {
                  hour12: true,
                  hour: "numeric",
                  minute: "numeric",
                  day: "numeric",
                  month: 'numeric',
                  year: 'numeric'

                }) }}
              </span>
            </p>
            {{ comment.body }}
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-between p-4">
        <button
          type="button"
          class="btn btn--secondary-blue"
          @click.prevent="$emit('close')"
        >
          Close
        </button>

        <button
          type="button"
          class="btn btn--primary-blue ml-2"
          data-test="update-record"
          @click="saveComment"
        >
          Save Comment
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { usePlantDailyRecordsStore } from '@/store/plantDailyRecord';
import Swal from 'sweetalert2';
import { computed } from 'vue';
import Modal from '../Modal.vue';

const props = defineProps<{
  projectId: Id;
  recordId: Id;
}>();

const emits = defineEmits<{
  (e: 'close');
}>();

const recordForm = useFormData({
  comment: '',
});

const plantDailyRecordsStore = usePlantDailyRecordsStore();

const record = computed(() => {
  return plantDailyRecordsStore.models.with('comments', (query) => {
    query.with('creator');
  }).with('plant').find(props.recordId);
});

plantDailyRecordsStore.fetchRecord(props.recordId).then(() => {
  plantDailyRecordsStore.models.with('comments', (query) => {
    query.with('creator');
  }).load([record.value]);
});

const commentRecordAction = useStoreApiAction(plantDailyRecordsStore.comment);

const saveComment = () => {
  recordForm.resetErrors();

  commentRecordAction.request(record.value.id, recordForm.form).then(() => {
    Swal.fire({
      icon: 'success',
      text: 'Comment Saved.',
    });

    emits('close');
  }).catch((error) => {
    recordForm.setErrors(error.data);
  });
};
</script>

<style scoped></style>
