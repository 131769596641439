<template>
  <div class="card">
    <div class="card__header flex justify-between items-center space-x-4">
      <p>Plant Category</p>

      <button
        type="button"
        class="btn btn--secondary-blue"
        data-test="add-plant-category"
        @click.prevent="addPlantCategory"
      >
        Add Plant Category
      </button>
    </div>

    <div class="flex w-full p-4 space-x-4">
      <ul
        v-if="categories.length"
        class="overflow-auto space-y-4 h-64 flex-1"
      >
        <li
          v-for="(category) in categories"
          :key="category.id"
          class="card__list-item"
          data-test="order-item"
        >
          <div class="flex justify-between">
            <div class="flex flex-col">
              <p>
                {{ category.name }}
              </p>
            </div>

            <div class="flex justify-end row-span-2 items-center">
              <Icon
                name="trash"
                class="w-6 h-6 hover:stroke-red-600 cursor-pointer"
                data-test="trash"
                @click="removeAssetType(category.id)"
              />

              <button
                type="button"
                data-test="edit"
                class="mr-2"
                @click="editPlantCategory(category)"
              >
                <Icon
                  name="edit"
                  class="btn-icon h-5 w-5"
                />
              </button>
            </div>
          </div>
        </li>
      </ul>

      <div
        v-else
        class="text-center font-semibold w-full p-2"
      >
        No Plant Categories Found.
      </div>
    </div>

    <PlantCategoryModal
      v-if="show"
      :asset-type="selectedCategory"
      :project-id="props.projectId"
      @close="show = false"
    />
  </div>
</template>

<script setup lang="ts">
import PlantCategory from '@/models/PlantCategory';
import { usePlantCategoriesStore } from '@/store/plantCategories';
import Swal from 'sweetalert2';
import { computed, ref } from 'vue';
import PlantCategoryModal from './PlantCategoryModal.vue';

const props = defineProps<{
  projectId: string;
}>();

const plantCategoriesStore = usePlantCategoriesStore();

const removeAssetType = (id: Id) => {
  Swal.fire({
    icon: 'warning',
    title: 'Remove Loader Type?',
    showConfirmButton: true,
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      plantCategoriesStore.deletePlantCategory(id).catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Failed to delete',
        });
      });
    }
  });
};

const show = ref(false);
const selectedCategory = ref();

const editPlantCategory = (category: PlantCategory) => {
  selectedCategory.value = category;
  show.value = true;
};

const categories = computed(() => {
  return plantCategoriesStore.models.where('project_id', parseInt(props.projectId)).get();
});

const addPlantCategory = () => {
  selectedCategory.value = undefined;
  show.value = true;
};
</script>

<style scoped></style>
