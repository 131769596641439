<template>
  <component
    :is="claimsShowComponent"
    :project-id="projectId"
  />
</template>

<script setup lang="ts">
import { useProjectsStore } from '@/store/projects';
import { computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import ProgressClaimsShow from './ProgressClaimsShow.vue';
import ProgressClaimsShowLite from './ProgressClaimsShowLite.vue';

const props = defineProps<{
  projectId: string;
}>();

const projectsStore = useProjectsStore();

const project = computed(() => {
  return projectsStore.project;
});

const router = useRouter();

watch(project, (newValue) => {
  if(newValue && !newValue.boq_locked) {
    router.push({ name: 'BillOfQuantities', params: { projectId: newValue.id } });
  }
}, { immediate: true });

watch(() => props.projectId, (newValue, oldValue) => {
  if(newValue !== oldValue) {
    router.push({ name: 'ProgressClaimsIndex' });
  }
});

const claimsShowComponent = computed(() => {
  return projectsStore.project?.feature_flag_progress_claims_lite ? ProgressClaimsShowLite : ProgressClaimsShow;
});
</script>
