<template>
  <tr class="even:bg-gray-100 odd:bg-white text-right border-black border-x hover:bg-blue-100 p-2 m-2">
    <td
      class="py-2 text-left pl-3"
    >
      {{ tradeItem.name }}
    </td>

    <td class="pr-3">
      {{ australianCurrency(tradeItem.budget) }}
    </td>

    <td class="bg-green-200/50">
      {{ tradeItemCost ? australianCurrency(tradeItemCost.current_claim) : '' }}
    </td>

    <td class="bg-green-200/50">
      {{ tradeItemCost ? australianCurrency(tradeItemCost.current_certified) : '' }}
    </td>

    <td class="bg-green-200/50">
      {{ australianCurrency(tradeItem.previous(claim)) }}
    </td>

    <td class="bg-green-200/50 pr-3">
      {{ australianCurrency(tradeItem.previous(claim) + (tradeItemCost?.current_certified || 0)) }}
    </td>

    <td class="bg-blue-300/50">
      {{ tradeItemCost ? `${australianNumber((tradeItemCost.current_claim / totalCosts.budget) * 100)}%` : '' }}
    </td>

    <td class="bg-blue-300/50">
      {{ tradeItemCost ? `${australianNumber((tradeItemCost.current_certified / totalCosts.budget) * 100)}%` : '' }}
    </td>

    <td class="bg-blue-300/50">
      {{ australianNumber((tradeItem.previous(claim) / totalCosts.budget) * 100) }}%
    </td>

    <td class="bg-blue-300/50 pr-3">
      {{ australianNumber(((tradeItem.previous(claim) + (tradeItemCost?.current_certified || 0)) / totalCosts.budget) * 100) }}%
    </td>

    <td
      :class="{ 'text-red-600': hasVariance && variance !== 0 }"
      :title="hasVariance && variance !== 0 ? varianceComment?.body : ''"
    >
      {{ hasVariance ? australianCurrency(variance) : '' }}
    </td>

    <td class="pr-3">
      {{ australianCurrency(Math.abs(tradeItem.budget - tradeItem.previous(claim) - (tradeItemCost?.current_certified || 0))) }}
    </td>
  </tr>
</template>

<script setup lang="ts">
import { australianCurrency, australianNumber, falsey } from '@/helpers';
import Claim from '@/models/Claim';
import Trade from '@/models/Trade';
import TradeItem from '@/models/TradeItem';
import { useTradeItemCostsStore } from '@/store/tradeItemCosts';
import { useTradeItemsStore } from '@/store/tradeItems';
import { computed } from 'vue';

const props = defineProps<{
  trade: Trade;
  claim: Claim;
  tradeItem: TradeItem;
}>();

const tradeItemsStore = useTradeItemsStore();
const tradeItemCostsStore = useTradeItemCostsStore();

const tradeItems = computed(() => {
  return tradeItemsStore.models.where('trade_id', props.trade.id).with('trade_item_costs', (query) => {
    query.where('claim_id', props.claim.id);
  }).get();
});

const tradeItemCost = computed(() => {
  return tradeItemCostsStore.models.with('comments')
    .where('trade_item_id', props.tradeItem.id)
    .where('claim_id', props.claim.id)
    .first();
});

const totalCosts = computed(() => {
  const totals = {
    budget: 0,
    currentClaim: 0,
    currentCertified: 0,
    previous: 0,
    cumulative: 0,
  };

  tradeItems.value.forEach((tradeItem) => {
    totals.budget += tradeItem.budget;
    totals.previous += tradeItem.previous(props.claim);
    const tradeItemCost = tradeItem.trade_item_costs[0];

    if(tradeItemCost) {
      totals.currentClaim += tradeItemCost.current_claim;
      totals.currentCertified += tradeItemCost.current_certified;
    }
  });

  totals.cumulative = totals.previous + totals.currentCertified;

  return totals;
});

const varianceComment = computed(() => {
  return tradeItemCost.value?.comments[0];
});

const hasVariance = computed(() => {
  return !falsey(tradeItemCost.value?.current_claim) && !falsey(tradeItemCost.value?.current_certified);
});

const variance = computed(() => {
  return tradeItemCost.value?.current_claim - tradeItemCost.value?.current_certified;
});
</script>

<style scoped></style>
