import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

export default function() {
  const queryParams = useRoute().query.day as string;
  const day = ref<Date>(new Date());

  const selectedDate = computed({
    get() {
      return day.value;
    },

    set(newValue) {
      if(!isNaN(new Date(newValue).getTime())) {
        day.value = new Date(newValue);
      }
    },
  });

  selectedDate.value = new Date(queryParams);

  return selectedDate;
}
