<template>
  <tr class="group first-child:pl-5 last-child:pr-5 [&>*]:px-5 [&>*]:py-2.5 even:bg-gray-100 odd:bg-white">
    <td
      :title="tradeItem.name"
      class="group-last:rounded-bl-lg"
    >
      <div class="w-80 flex items-center space-x-2">
        <button
          type="button"
          @click.stop="$emit('edit')"
        >
          <Icon
            name="edit"
            class="btn-icon-blue h-5 w-5 inline-block"
          />
        </button>

        <button
          type="button"
          @click.stop="$emit('delete')"
        >
          <Icon
            name="trash"
            class="btn-icon-red h-5 w-5 inline-block"
          />
        </button>

        <span class="font-bold">{{ tradeItem.billNumber }}</span>

        <p>
          {{ tradeItem.name }}
        </p>
      </div>
    </td>

    <td class="text-left">
      {{ tradeItem.unit }}
    </td>

    <td class="text-right">
      {{ australianNumber(tradeItem.quantity) }}
    </td>

    <td class="text-right">
      {{ australianCurrency(tradeItem.rate) }}
    </td>

    <td class="text-right group-last:rounded-br-lg">
      {{ australianCurrency(tradeItem.budget) }}
    </td>
  </tr>
</template>

<script setup lang="ts">
import { australianCurrency, australianNumber } from '@/helpers';
import TradeItem from '@/models/TradeItem';
import { ref } from 'vue';

defineProps<{
  tradeItem: TradeItem;
}>();

defineEmits<{
  (e: 'edit');
  (e: 'delete');
  (e: 'createInPosition');
}>();

const isHovering = ref(false);
</script>

<style scoped></style>
