<template>
  <div class="flex flex-col items-center justify-center">
    Available projects

    <RouterLink
      v-for="project in projects"
      :key="project.id"
      class="flex flex-col space-y-2"
      :to="{ name: 'ProjectDailyRates', params: { projectId: project.id } }"
    >
      {{ project.name }}
    </RouterLink>
  </div>
</template>

<script setup lang="ts">
import { useProjectsStore } from '@/store/projects';
import { computed, watch } from 'vue';
import { useRouter } from 'vue-router';

const projectsStore = useProjectsStore();
const router = useRouter();

const projects = computed(() => {
  return projectsStore.models.all();
});

watch(projects, (newValue) => {
  if(newValue.length === 1) {
    router.push({ name: 'LabourDashboard', params: { projectId: projects.value[0].id } });
  }
}, { immediate: true });
</script>

<style scoped></style>
