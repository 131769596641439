<template>
  <tr
    class="even:bg-gray-100 hover:bg-blue-100 odd:bg-white"
  >
    <td class="w-28">
      <div class="flex items-center justify-center space-x-2">
        <input
          type="checkbox"
          name="approve"
          :checked="!!record.user"
          data-test="approve-labour"
          @input="toggleApprove($event as InputEvent, record)"
        >

        <button
          v-if="!record.user"
          type="button"
          data-test="edit"
          @click="$emit('selectRecord', $props.record.id)"
        >
          <Icon
            name="edit"
            class="btn-icon h-5 w-5"
          />
        </button>

        <button
          type="button"
          data-test="show-comments"
          @click="$emit('showComments', $props.record.id)"
        >
          <Icon
            name="comment"
            class="btn-icon h-5 w-5"
          />
        </button>
      </div>
    </td>

    <td>
      <p class="font-semibold">
        {{ formatRecordApproved(record)?.name }}
      </p>

      <p class="text-sm">
        {{ formatRecordApproved(record)?.date }}
      </p>
    </td>

    <td class="py-2">
      {{ new Date(record.day).toLocaleDateString() }}
    </td>

    <td
      :title="record.wbsCode?.code"
      class="w-[200px]"
    >
      <div class="flex flex-col pr-2">
        <p class="font-semibold">
          {{ record.wbsCode?.code }}
        </p>

        <p class="line-clamp-1 text-sm w-[200px]">
          {{ record.wbsCode?.description }}
        </p>
      </div>
    </td>

    <td>
      {{ record.area }}
    </td>

    <td>
      {{ record.element }}
    </td>

    <td>
      {{ record.activity }}
    </td>

    <td class="w-[5%]">
      <p class="">
        {{ formatStart(record.startAt) }}
      </p>

      <p class="break-words whitespace-pre">
        {{ formatEnd(record.endAt, record) }}
      </p>
    </td>

    <td class="text-left w-[5%] pl-6">
      {{ record.hours.toFixed(2) }}
    </td>

    <td class="text-right px-2">
      {{ australianCurrency(record.accrual) }}
    </td>

    <td v-if="typingPaid || !record.paid">
      <div class="flex items-center justify-center">
        <form
          action=""
          @submit.prevent="submitForm(record);"
        >
          <AppInput
            ref="paidInput"
            v-model="paid"
            name="paid"
            type="number"
            step="any"
            min="0"
            data-test="labour-paid"
            @blur="submitForm(record);"
          />
        </form>
      </div>
    </td>

    <td
      v-else
      class="text-center cursor-pointer"
      data-test="record-paid-text"
      @click="toggleTypingPaid"
    >
      {{ australianCurrency(record.paid) }}
    </td>

    <td v-if="typingVendor || !record.vendor_ref">
      <div class="flex items-center justify-center">
        <form
          action=""
          @submit.prevent="submitVendor(record);"
        >
          <AppInput
            ref="vendorInput"
            v-model="vendor"
            name="vendor"
            type="text"
            data-test="record-vendor"
            @blur="submitVendor(record);"
          />
        </form>
      </div>
    </td>

    <td
      v-else
      class="text-center cursor-pointer"
      data-test="record-vendor-text"
      @click="toggleTypingVendor"
    >
      {{ record.vendor_ref }}
    </td>
  </tr>
</template>

<script setup lang="ts">
import { australianCurrency } from '@/helpers';
import Labour from '@/models/Labour';
import LabourDailyRecord from '@/models/LabourDailyRecord';
import { useLabourDailyRecordStore } from '@/store/labourDailyRecord';
import { DateTime } from 'luxon';
import Swal from 'sweetalert2';
import { nextTick, ref } from 'vue';

const props = defineProps<{
  record: LabourDailyRecord;
  labour: Labour;
}>();

defineEmits<{
  (e: 'selectRecord', id: Id);
  (e: 'showComments', id: Id);
}>();

const paid = ref(props.record.paid);
const typingPaid = ref(false);
const paidInput = ref(undefined);

const toggleTypingPaid = () => {
  typingPaid.value = !typingPaid.value;

  if(typingPaid.value) {
    nextTick(() => {
      paidInput.value?.focusInput();
    });
  }
};

const labourDailyRecordStore = useLabourDailyRecordStore();

const toggleApprove = (event: InputEvent, record: LabourDailyRecord) => {
  if((event.target as HTMLInputElement).checked) {
    labourDailyRecordStore.approve(record.id);
  } else {
    if(record.paid) {
      Swal.fire({
        icon: 'warning',
        text: 'This record has been paid. Are you sure you want to change the approval?',
        showCancelButton: true,
      }).then((result) => {
        if(result.isConfirmed) {
          labourDailyRecordStore.unapprove(record.id);
        } else {
          (event.target as HTMLInputElement).checked = true;
        }
      });
    } else {
      labourDailyRecordStore.unapprove(record.id);
    }
  }
};

const formatRecordApproved = (record: LabourDailyRecord) => {
  if(record.user) {
    return {
      name: record.user.name,
      date: record.approvedAt?.toLocaleDateString('en-AU'),
    };
  }

  return undefined;
};

const submitForm = (record: LabourDailyRecord) => {
  labourDailyRecordStore.paid(record.id, { paid: paid.value }).then(() => {
    typingPaid.value = false;
  });
};

const formatStart = (date: DateTime | undefined) => {
  if(date) {
    return date.toLocaleString({
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    });
  }
};

const formatEnd = (date: DateTime | undefined, record: LabourDailyRecord) => {
  if(date) {
    if(record.endAt?.day === (record.startAt?.day + 1) % 7) {
      return date.toLocaleString({
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
        month: '2-digit',
      });
    }

    return date.toLocaleString({
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    });
  }
};

const vendor = ref(props.record.vendor_ref);
const typingVendor = ref(false);
const vendorInput = ref(undefined);

const toggleTypingVendor = () => {
  typingVendor.value = !typingVendor.value;

  if(typingVendor.value) {
    nextTick(() => {
      vendorInput.value?.focusInput();
    });
  }
};

const submitVendor = (record: LabourDailyRecord) => {
  labourDailyRecordStore.vendor(record.id, { vendor_ref: vendor.value }).then(() => {
    typingVendor.value = false;
  });
};
</script>

<style scoped></style>
