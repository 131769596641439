<template>
  <div class="space-y-10 p-5">
    <div class="card">
      <div class="card__header">
        Details
      </div>

      <div>
        <form
          class="p-6 space-x-10 flex"
          @submit.prevent="updateCompany"
        >
          <div>
            <label>
              Name
              <AppInput
                v-model="companyForm.form.name"
                name="name"
                type="text"
                data-test="name"
                class="form-input"
              />
            </label>


            <div class="flex justify-between mt-6">
              <button
                type="submit"
                class="btn btn--primary-blue"
                data-test="save-company"
              >
                Save Company Settings
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!-- <div class="flex w-full gap-4">
      <div class="card w-full">
        <div class="card__header">
          <p>
            Company Projects
          </p>
        </div>

        <div class="flex w-full p-4 space-x-4">
          <ul
            v-if="company?.projects.length"
            class="overflow-auto space-y-4 h-64 flex-1"
          >
            <li
              v-for="project in company.projects"
              :key="project.id"
              class="card__list-item"
            >
              <div class="flex">
                {{ project.name }}
                <div>
                  Created
                </div>

                <div>
                  Lite Claims
                </div>

                <div>
                  Contract Start/End
                </div>

                <div>edit</div>

                <div>Delete</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div> -->

    <div class="flex w-full gap-4">
      <CompanySupplierList
        :company-id="$props.companyId"
        class="w-full"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { useCompaniesStore } from '@/store/companies';
import Swal from 'sweetalert2';
import { computed, watch } from 'vue';
import CompanySupplierList from './CompanySupplierList.vue';

const props = defineProps<{
  companyId: number;
}>();

const emits = defineEmits<{
  (e: 'dirty');
  (e: 'saved');
}>();

const companiesStore = useCompaniesStore();

const company = computed(() => {
  return companiesStore.models.query().with('projects').find(props.companyId);
});

const companyForm = useFormData({
  name: '',
}, true);

watch(company, (newValue) => {
  if(newValue) {
    companyForm.form.name = newValue.name;
    companyForm.resetDirtyStatus();
  }
}, { immediate: true });

watch(companyForm.isDirty, (newValue) => {
  if(newValue) {
    emits('dirty');
  } else {
    emits('saved');
  }
});

const updateCompanyAction = useStoreApiAction(companiesStore.updateCompany);

const updateCompany = () => {
  companyForm.resetErrors();

  updateCompanyAction.request(props.companyId, companyForm.form).then(() => {
    Swal.fire({
      title: 'Project Updated',
      icon: 'success',
    });

    companyForm.form.name = company.value.name;
  }).catch((error) => {
    companyForm.setErrors(error.data);
  });
};
</script>

<style scoped></style>
