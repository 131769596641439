<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          {{ $props.plantId ? 'Update' : 'Create' }} Plant
        </h1>
      </div>
    </template>

    <template #body>
      <div class="max-h-[40rem] overflow-y-auto w-full py-4 px-6 max-w-3xl">
        <form
          action=""
          class="flex flex-col space-y-4"
          @submit.prevent="saveLabour"
        >
          <div class="flex space-x-4">
            <label class="w-1/2">
              Asset ID
              <AppInput
                v-model="plantForm.form.fleet"
                class="block border-gray-400 py-2 px-4 rounded w-full focus:outline-none focus:border-blue-400"
                name="fleet"
                type="text"
                :error="plantForm.getErrors('fleet')"
                data-test="fleet"
              />
            </label>

            <label class="w-1/2">
              Supplier

              <AppTypeAhead
                v-model="plantForm.form.supplier_name"
                class="block border-gray-400 py-2 px-4 rounded w-full focus:outline-none focus:border-blue-400"
                name="supplier_name"
                :suggestion-callback="getSuggestions"
                :initial-suggestions="getSuggestions"
                data-test="supplier-name"
                :error="plantForm.getErrors('supplier_id') || plantForm.getErrors('supplier_name')"
                @value-selected="selectSupplier"
              />
            </label>
          </div>

          <label>
            Category

            <AppTypeAhead
              v-model="plantForm.form.category_name"
              class="block border-gray-400 py-2 px-4 rounded w-full focus:outline-none focus:border-blue-400"
              name="category_name"
              :suggestion-callback="getCategorySuggestions"
              :initial-suggestions="getCategorySuggestions"
              data-test="category-name"
              :error="plantForm.getErrors('plant_category_id')"
              @value-selected="selectCategory"
            />
          </label>

          <label class="flex items-center justify-between">
            <div class="mx-auto pl-4">
              <button
                :disabled="plantHasRecords"
                type="button"
                class="rounded-l-lg py-2 px-4 border-r border-black disabled:cursor-not-allowed"
                :class="[plantForm.form.wet === true ? 'bg-gray-300' : 'bg-gray-100']"
                data-test="type-wet"
                @click="plantForm.form.wet = true"
              >Hour</button>

              <button
                :disabled="plantHasRecords"
                type="button"
                class="rounded-r-lg py-2 px-4 disabled:cursor-not-allowed"
                :class="[plantForm.form.wet === false ? 'bg-gray-300' : 'bg-gray-100']"
                data-test="type-dry"
                @click="plantForm.form.wet = false"
              >Day</button>
            </div>
          </label>

          <label>
            <p>
              Rate <span class="text-sm text-gray-400">($/{{ plantForm.form.wet ? 'Hr' : 'Day' }})</span>
            </p>

            <AppInput
              v-model="plantForm.form.rate"
              class="block border-gray-400 py-2 px-4 rounded w-full focus:outline-none focus:border-blue-400"
              name="rate"
              type="text"
              :error="plantForm.getErrors('rate')"
              data-test="rate"
            />
          </label>

          <div class="flex space-x-4">
            <div class="w-1/2">
              <label class="flex items-center space-x-2">
                <input
                  v-model="plantForm.form.pay_standby"
                  name="pay_standby"
                  type="checkbox"
                  data-test="pay-standby"
                  class="focus:ring-0 border border-gray-400"
                >

                <p>
                  Pay Standby
                </p>
              </label>
            </div>

            <div
              v-if="!props.plantId || selectedPlant?.smu_tracking"
              class="w-1/2"
            >
              <label class="flex items-center space-x-2">
                <input
                  v-model="plantForm.form.smu_tracking"
                  name="smu_tracking"
                  type="checkbox"
                  data-test="smu-tracking"
                  class="disabled:opacity-50 focus:ring-0 border border-gray-400"
                  :disabled="!!props.plantId"
                >

                <p>
                  SMU Tracking
                </p>
              </label>
            </div>
          </div>

          <div class="flex space-x-4">
            <label class="w-1/2">
              On Hire
              <AppInput
                v-model="plantForm.form.on_hired_at"
                class="block border-gray-400 py-2 px-4 rounded w-full focus:outline-none focus:border-blue-400"
                name="cw_ref"
                type="date"
                :error="plantForm.getErrors('on_hired_at')"
                data-test="on-hire"
              />
            </label>

            <label class="w-1/2">
              Off Hire
              <AppInput
                v-model="plantForm.form.off_hired_at"
                class="block border-gray-400 py-2 px-4 rounded w-full
                  focus:outline-none focus:border-blue-400 font-normal"
                name="cw_ref"
                type="date"
                :error="plantForm.getErrors('off_hired_at')"
                data-test="off-hire"
              />
            </label>
          </div>

          <div>
            <label>
              <p>
                Description
              </p>

              <textarea
                v-model="plantForm.form.description"
                data-test="description"
                placeholder="(E.g. 20T Excavator)"
                rows="2"
                class="block w-full resize-none border-gray-400 rounded"
              />

              <span
                v-if="plantForm.getErrors('description')"
                class="text-red-600 text-sm font-semibold"
              >{{ plantForm.getErrors('description') }}</span>
            </label>
          </div>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-between p-2 space-x-4">
        <button
          v-if="$props.plantId"
          type="button"
          class="btn btn--tertiary-blue"
          @click="deletePlant"
        >
          Delete
        </button>

        <div class="space-x-2 ml-auto">
          <button
            type="button"
            class="btn btn--secondary-blue"
            @click="$emit('close'); plantForm.resetData()"
          >
            Cancel
          </button>

          <button
            type="button"
            class="btn btn--primary-blue"
            :disabled="updatePlantAction.is(States.LOADING) || savePlantAction.is(States.LOADING)"
            data-test="save-plant"
            @click="saveLabour"
          >
            {{ $props.plantId ? 'Update' : 'Create' }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { States } from '@/composables/useStoreApiAction';
import { dateForDateInput } from '@/helpers';
import { SupplierType } from '@/models/ProjectSupplier';
import { usePlantCategoriesStore } from '@/store/plantCategories';
import { usePlantsStore } from '@/store/plants';
import { useSuppliersStore } from '@/store/supplier';
import Swal from 'sweetalert2';
import { computed, nextTick, ref, watch } from 'vue';
import Modal from '../Modal.vue';

const props = defineProps<{
  projectId: string;
  plantId?: Id;
}>();

const emits = defineEmits<{
  (e: 'close');
}>();

const plantsStore = usePlantsStore();

const plantForm = useFormData({
  fleet: '',
  rate: 0,
  wet: true,
  pay_standby: false,
  supplier_name: '',
  description: '',
  on_hired_at: '',
  off_hired_at: '',
  supplier_id: undefined,
  smu_tracking: false,
  plant_category_id: undefined,
  category_name: '',
});

const savePlantAction = useStoreApiAction(plantsStore.savePlant);
const updatePlantAction = useStoreApiAction(plantsStore.updatePlant);

const saveLabour = () => {
  plantForm.resetErrors();

  if(props.plantId) {
    updatePlantAction.request(props.plantId, plantForm.form).then((_data) => {
      Swal.fire({
        icon: 'success',
        title: 'Plant Updated.',
      }).then(() => {
        emits('close');
        plantForm.resetData();
      });
    }).catch((error) => {
      plantForm.setErrors(error.data);
    });
  } else {
    savePlantAction.request(props.projectId, plantForm.form).then((_data) => {
      Swal.fire({
        icon: 'success',
        title: 'Plant Created.',
      }).then(() => {
        emits('close');
        plantForm.resetData();
      });
    }).catch((error) => {
      plantForm.setErrors(error.data);
    });
  }
};

const selectedPlant = computed(() => {
  return plantsStore.models.with('on_hired').with('supplier').with('plant_category').with('records').find(
    props.plantId,
  );
});

const plantHasRecords = computed(() => {
  return selectedPlant.value?.records.length > 0;
});

watch(selectedPlant, (newValue) => {
  if(newValue) {
    plantForm.form.on_hired_at = dateForDateInput(newValue.on_hired?.on_hired_at);
    plantForm.form.off_hired_at = dateForDateInput(newValue.on_hired?.off_hired_at);
    plantForm.form.supplier_name = newValue.supplier.name;
    plantForm.form.category_name = newValue.plant_category?.name;
    plantForm.setData(newValue, ['on_hired_at', 'off_hired_at']);
  }
}, { immediate: true });

const suppliersStore = useSuppliersStore();
const suggestedSuppliers = ref<{ id: number; name: string }[]>([]);

const getSuggestions = async () => {
  suggestedSuppliers.value = await suppliersStore
    .search({ name: plantForm.form.supplier_name, project_id: parseInt(props.projectId), type: SupplierType.plant });

  return suggestedSuppliers.value.map((value) => value.name);
};

const selectSupplier = (index: number) => {
  nextTick(() => {
    if(suggestedSuppliers.value[index]) {
      plantForm.form.supplier_id = suggestedSuppliers.value[index].id;
    }
  });
};

watch(() => plantForm.form.supplier_name, (newValue, oldValue) => {
  if(
    newValue !== oldValue &&
    plantForm.form.supplier_id !== undefined
  ) {
    plantForm.form.supplier_id = undefined;
  }
});

const plantCategoriesStore = usePlantCategoriesStore();
const suggestedCategories = ref<{ id: number; name: string }[]>([]);

const getCategorySuggestions = async () => {
  suggestedCategories.value = plantCategoriesStore.models.where('project_id', parseInt(props.projectId)).get();

  return suggestedCategories.value.map((category) => category.name);
};

const selectCategory = (index: number) => {
  nextTick(() => {
    if(suggestedCategories.value[index]) {
      plantForm.form.plant_category_id = suggestedCategories.value[index].id;
    }
  });
};

watch(() => plantForm.form.category_name, (newValue, oldValue) => {
  if(
    newValue !== oldValue &&
    plantForm.form.plant_category_id !== undefined
  ) {
    plantForm.form.plant_category_id = undefined;
  }
});

const deletePlantAction = useStoreApiAction(plantsStore.deletePlant);

const deletePlant = () => {
  Swal.fire({
    icon: 'warning',
    title: 'Delete Plant?',
    text: `Are you sure you want to delete ${selectedPlant.value.fleet}?`,
    showCancelButton: true,
    showConfirmButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      deletePlantAction.request(props.plantId).then(() => {
        Swal.fire({
          icon: 'success',
          text: 'Plant deleted',
        }).then(() => {
          emits('close');
        });
      }).catch((error) => {
        if(error?.status === 422) {
          Swal.fire({
            icon: 'error',
            text: `Unable to delete plant ${selectedPlant.value.fleet} as it has records associated with it.`,
          });
        }
      });
    }
  });
};
</script>

<style scoped></style>
