<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2 px-20">
        <h1 class="text-2xl">
          {{ $props.plantCategory ? 'Update' : 'Add' }} Plant Category
        </h1>
      </div>
    </template>

    <template #body>
      <div class="h-full w-full p-4 overflow-y-auto container">
        <form
          action=""
          class="flex flex-col space-y-4"
          @submit.prevent="savePlantCategory"
        >
          <label>
            Name

            <AppInput
              v-model="plantCategoryForm.form.name"
              name="name"
              type="text"
              :error="plantCategoryForm.getErrors('name')"
              data-test="update-name"
              class="form-input"
            />
          </label>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-between p-2 space-x-4">
        <button
          v-if="$props.plantCategory"
          type="button"
          class="btn btn--tertiary-blue"
          @click="deletePlantCategory"
        >
          Delete
        </button>

        <div class="space-x-2 ml-auto">
          <button
            type="button"
            class="btn btn--secondary-blue"
            @click="$emit('close'); plantCategoryForm.resetData()"
          >
            Cancel
          </button>

          <button
            type="button"
            class="btn btn--primary-blue"
            data-test="save-order"
            @click="savePlantCategory"
          >
            {{ $props.plantCategory ? 'Update' : 'Create' }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/Modal.vue';
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import PlantCategory from '@/models/PlantCategory';
import { usePlantCategoriesStore } from '@/store/plantCategories';
import Swal from 'sweetalert2';
import { watch } from 'vue';

const props = defineProps<{
  plantCategory?: PlantCategory;
  projectId: Id;
}>();

const emits = defineEmits<{
  (e: 'close');
}>();

const plantCategoriesStore = usePlantCategoriesStore();

const plantCategoryForm = useFormData({
  name: '',
});

watch(() => props.plantCategory, (newValue) => {
  if(newValue) {
    plantCategoryForm.setData(newValue);
  }
}, { immediate: true });

const updatePlantCategoryAction = useStoreApiAction(plantCategoriesStore.updatePlantCategory);
const createPlantCategoryAction = useStoreApiAction(plantCategoriesStore.createPlantCategory);

const savePlantCategory = () => {
  plantCategoryForm.resetErrors();

  if(props.plantCategory) {
    updatePlantCategoryAction.request(props.plantCategory.id, plantCategoryForm.form).then(() => {
      Swal.fire({
        icon: 'success',
        text: 'Plant Category updated',
      }).then(() => {
        emits('close');
        plantCategoryForm.resetData();
      });
    }).catch((error) => {
      plantCategoryForm.setErrors(error.data);
    });
  } else {
    createPlantCategoryAction.request(props.projectId, plantCategoryForm.form).then(() => {
      Swal.fire({
        icon: 'success',
        text: 'Plant Category created',
      }).then(() => {
        emits('close');
        plantCategoryForm.resetData();
      });
    }).catch((error) => {
      plantCategoryForm.setErrors(error.data);
    });
  }
};

const deletePlantCategory = () => {
  Swal.fire({
    icon: 'warning',
    title: 'Remove Plant Category?',
    showConfirmButton: true,
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      plantCategoriesStore.deletePlantCategory(props.plantCategory.id).then(() => {
        emits('close');
      }).catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Failed to delete',
        });
      });
    }
  });
};
</script>

<style scoped></style>
