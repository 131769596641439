import { route } from '@/ziggy-shim';
import { api } from './LaravelClient';

export default {
  async index(projectId: Id, data: ApiRequests['api.v1.surveyed.index']) {
    return await api(route('api.v1.surveyed.index', projectId), 'get', {
      data,
    });
  },

  async create(projectId: Id, data: ApiRequests['api.v1.surveyed.store']) {
    return await api(route('api.v1.surveyed.store', projectId), 'post', {
      data,
    });
  },

  async update(id: Id, data: ApiRequests['api.v1.surveyed.update']) {
    return await api(route('api.v1.surveyed.update', id), 'patch', {
      data,
    });
  },
};
