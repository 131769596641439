<template>
  <div class="card">
    <div class="card__header flex justify-between items-center space-x-4">
      <p>Loader Type</p>

      <button
        type="button"
        class="btn btn--secondary-blue"
        data-test="add-asset-type"
        @click.prevent="addAssetType"
      >
        Add Loader Type
      </button>
    </div>

    <div class="flex w-full p-4 space-x-4">
      <ul
        v-if="assetTypes.length"
        class="overflow-auto space-y-4 h-64 flex-1"
      >
        <li
          v-for="(assetType) in assetTypes"
          :key="assetType.id"
          class="card__list-item"
          data-test="order-item"
        >
          <div class="flex justify-between">
            <div class="flex flex-col">
              <p>
                {{ assetType.name }}
              </p>
            </div>

            <div class="flex justify-end row-span-2 items-center">
              <Icon
                name="trash"
                class="w-6 h-6 hover:stroke-red-600 cursor-pointer"
                data-test="trash"
                @click="removeAssetType(assetType.id)"
              />

              <button
                type="button"
                data-test="edit"
                class="mr-2"
                @click="editAssetType(assetType)"
              >
                <Icon
                  name="edit"
                  class="btn-icon h-5 w-5"
                />
              </button>
            </div>
          </div>
        </li>
      </ul>

      <div
        v-else
        class="text-center font-semibold w-full p-2"
      >
        No Load Types Found.
      </div>
    </div>

    <AssetTypeModal
      v-if="show"
      :asset-type="selectedAssetType"
      :project-id="props.projectId"
      @close="show = false"
    />
  </div>
</template>

<script setup lang="ts">
import AssetType from '@/models/AssetType';
import { useAssetTypesStore } from '@/store/assetTypes';
import Swal from 'sweetalert2';
import { computed, ref } from 'vue';
import AssetTypeModal from './AssetTypeModal.vue';

const props = defineProps<{
  projectId: string;
}>();

const assetTypesStore = useAssetTypesStore();

const removeAssetType = (id: Id) => {
  Swal.fire({
    icon: 'warning',
    title: 'Remove Loader Type?',
    showConfirmButton: true,
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      assetTypesStore.deleteAssetType(id).catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Failed to delete',
        });
      });
    }
  });
};

const show = ref(false);
const selectedAssetType = ref();

const editAssetType = (assetType: AssetType) => {
  selectedAssetType.value = assetType;
  show.value = true;
};

const assetTypes = computed(() => {
  return assetTypesStore.models.where('project_id', parseInt(props.projectId)).get();
});

const addAssetType = () => {
  selectedAssetType.value = undefined;
  show.value = true;
};
</script>

<style scoped></style>
