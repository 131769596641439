<template>
  <div>
    <div>
      <div>
        <form
          class="flex flex-col p-5"
          @submit.prevent="updateProject"
        >
          <!-- TODO: 14/03/24 group these into plant config and record config sections -->
          <div class="grid grid-cols-2 2xl:grid-cols-3 gap-10">
            <!-- TODO: 14/03/24 move these into separate components -->
            <div class="card">
              <div
                class="card__header"
                title="The activities on the project that will be managed in the Self Preform Production Manager"
              >
                Activities
              </div>

              <div class="p-4">
                <div class="flex space-x-2 justify-between">
                  <AppInput
                    v-model="selfPerformForm.form.activity"
                    name="activity"
                    type="text"
                    @keydown.enter.prevent="addToActivityList"
                  />

                  <button
                    type="button"
                    class="btn btn--secondary-blue w-max"
                    @click.prevent="addToActivityList"
                  >
                    Add
                  </button>
                </div>

                <ul
                  v-if="projectForm.form.activities.length > 0"
                  class="flex flex-col p-2 overflow-auto max-h-40"
                >
                  <li
                    v-for="(activity, i) in projectForm.form.activities"
                    :key="i + activity"
                    class="flex space-x-2"
                  >
                    <p>{{ activity }}</p>

                    <Icon
                      name="trash"
                      class="w-6 h-6 hover:stroke-red-600 cursor-pointer"
                      @click="removeActivity(i)"
                    />
                  </li>
                </ul>

                <div
                  v-else
                  class="text-center font-semibold w-full p-2"
                >
                  No Activities Found.
                </div>
              </div>
            </div>

            <div class="card">
              <div
                class="card__header"
                title="The elements on the project that will be managed in the Self Preform Production Manager"
              >
                Elements
              </div>

              <div class="p-4">
                <div class="flex space-x-2 justify-between">
                  <AppInput
                    v-model="selfPerformForm.form.element"
                    name="element"
                    type="text"
                    @keydown.enter.prevent="addToElementList"
                  />

                  <button
                    type="button"
                    class="btn btn--secondary-blue w-max"
                    @click.prevent="addToElementList"
                  >
                    Add
                  </button>
                </div>

                <ul
                  v-if="projectForm.form.elements.length > 0"
                  class="flex flex-col p-2 overflow-auto max-h-40"
                >
                  <li
                    v-for="(element, i) in projectForm.form.elements"
                    :key="i + element"
                    class="flex space-x-2"
                  >
                    <p>{{ element }}</p>

                    <Icon
                      name="trash"
                      class="w-6 h-6 hover:stroke-red-600 cursor-pointer"
                      @click="removeElement(i)"
                    />
                  </li>
                </ul>

                <div
                  v-else
                  class="text-center font-semibold w-full p-2"
                >
                  No Elements Found.
                </div>
              </div>
            </div>

            <div class="card">
              <div
                class="card__header"
                title="The areas on the project that will be managed in the Self Preform Production Manager"
              >
                Areas
              </div>

              <div class="p-4">
                <div class="flex space-x-2 justify-between">
                  <AppInput
                    v-model="selfPerformForm.form.area"
                    name="area"
                    type="text"
                    @keydown.enter.prevent="addToAreaList"
                  />

                  <button
                    type="button"
                    class="btn btn--secondary-blue w-max"
                    @click.prevent="addToAreaList"
                  >
                    Add
                  </button>
                </div>

                <ul
                  v-if="projectForm.form.areas.length > 0"
                  class="flex flex-col p-2 overflow-auto max-h-40"
                >
                  <li
                    v-for="(area, i) in projectForm.form.areas"
                    :key="i + area"
                    class="flex space-x-2"
                  >
                    <p>{{ area }}</p>

                    <Icon
                      name="trash"
                      class="w-6 h-6 hover:stroke-red-600 cursor-pointer"
                      @click="removeArea(i)"
                    />
                  </li>
                </ul>

                <div
                  v-else
                  class="text-center font-semibold w-full p-2"
                >
                  No Areas Found.
                </div>
              </div>
            </div>

            <WbsCodeList :project-id="$props.projectId" />

            <AssetTypeList :project-id="$props.projectId" />

            <PlantCategoryList :project-id="$props.projectId" />
          </div>

          <div class="flex justify-between mt-6">
            <button
              type="submit"
              class="btn btn--primary-blue"
              data-test="save-self-perform"
            >
              Save Self Perform Settings
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import AssetTypeList from '@/components/ProjectSettings/ProjectTab/AssetTypeList.vue';
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { useProjectsStore } from '@/store/projects';
import Swal from 'sweetalert2';
import { computed, watch } from 'vue';
import WbsCodeList from '../ProjectTab/WbsCodeList.vue';
import PlantCategoryList from './PlantCategoryList.vue';

const props = defineProps<{
  projectId: string;
}>();

const emits = defineEmits<{
  (e: 'dirty');
  (e: 'saved');
}>();

const projectsStore = useProjectsStore();

const project = computed(() => {
  return projectsStore.models.find(parseInt(props.projectId));
});

const projectForm = useFormData({
  activities: [],
  elements: [],
  areas: [],
}, true);

watch(project, (newValue) => {
  if(newValue) {
    projectForm.form.activities = newValue.activities.slice();
    projectForm.form.elements = newValue.elements.slice();
    projectForm.form.areas = newValue.areas.slice();
    projectForm.resetDirtyStatus();
  }
}, { immediate: true });

watch(projectForm.isDirty, (newValue) => {
  if(newValue) {
    emits('dirty');
  } else {
    emits('saved');
  }
});

const updateProjectAction = useStoreApiAction(projectsStore.updateProject);

const updateProject = () => {
  projectForm.resetErrors();

  updateProjectAction.request(props.projectId, projectForm.form).then(() => {
    Swal.fire({
      title: 'Self Perform Settings Updated',
      icon: 'success',
    });

    projectForm.form.activities = project.value.activities.slice();
    projectForm.form.elements = project.value.elements.slice();
    projectForm.form.areas = project.value.areas.slice();
    projectForm.resetDirtyStatus();
  }).catch((error) => {
    projectForm.setErrors(error.data);
  });
};

const selfPerformForm = useFormData({
  activity: '',
  element: '',
  area: '',
});

const addToActivityList = () => {
  if(selfPerformForm.form.activity) {
    projectForm.form.activities.push(selfPerformForm.form.activity);
    selfPerformForm.form.activity = undefined;
  }
};

const removeActivity = (index: number) => {
  projectForm.form.activities.splice(index, 1);
};

const addToElementList = () => {
  if(selfPerformForm.form.element) {
    projectForm.form.elements.push(selfPerformForm.form.element);
    selfPerformForm.form.element = undefined;
  }
};

const removeElement = (index: number) => {
  projectForm.form.elements.splice(index, 1);
};

const addToAreaList = () => {
  if(selfPerformForm.form.area) {
    projectForm.form.areas.push(selfPerformForm.form.area);
    selfPerformForm.form.area = undefined;
  }
};

const removeArea = (index: number) => {
  projectForm.form.areas.splice(index, 1);
};
</script>

<style scoped></style>
