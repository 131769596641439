<template>
  <div class="w-full">
    <div class="flex w-full justify-center bg-blue-900 p-2">
      <h1 class="text-3xl text-white font-semibold">
        Plant Record
      </h1>

      <p class="text-white absolute right-2">
        {{ day.toLocaleDateString() }}
      </p>
    </div>

    <div class="w-full justify-start bg-blue-900 p-2">
      <h2 class="text-base text-white font-semibold">
        {{ plant?.fleet }}
      </h2>

      <h3 class="text-base text-white">
        {{ plant?.description }}
      </h3>
    </div>

    <div class="w-[90%] mx-auto mt-8">
      <div class="flex flex-col w-full my-2 p-5 bg-white rounded-lg">
        <form
          action=""
          class="w-full flex flex-col space-y-4"
          @submit.prevent="saveRecord"
        >
          <fieldset class="flex flex-col space-y-4">
            <legend class="flex justify-between font-bold w-full">
              <span>Time Details</span>
            </legend>

            <template v-if="!plant?.smu_tracking">
              <label>
                Start Time
                <AppInput
                  v-model="recordForm.form.start_at"
                  class="form-input"
                  name="start_at"
                  type="time"
                  data-test="start_at"
                  :error="recordForm.getErrors('start_at')"
                />
              </label>

              <label>
                End Time
                <AppInput
                  v-model="recordForm.form.end_at"
                  class="form-input"
                  name="end_at"
                  :type="endAtType"
                  data-test="end_at"
                  :error="recordForm.getErrors('end_at')"
                />
              </label>
            </template>

            <template v-else>
              <label>
                SMU Start
                <AppInput
                  v-model.number="recordForm.form.smu_start"
                  name="smu_start"
                  class="form-input"
                  type="number"
                  data-test="smu_start"
                  :error="recordForm.getErrors('smu_start')"
                />
              </label>

              <label>
                SMU for record
                <AppInput
                  v-model.number="recordForm.form.smu"
                  name="smu"
                  class="form-input"
                  type="number"
                  data-test="smu"
                  :error="recordForm.getErrors('smu_end')"
                />
              </label>
            </template>

            <div
              v-if="plant?.smu_tracking"
              class="w-full flex justify-between"
            >
              <p class="font-bold">
                SMU End
              </p>

              <p>{{ recordForm.form.smu_end }}</p>
            </div>

            <div
              v-else
              class="w-full flex justify-between"
            >
              <p class="font-bold">
                Total Time
              </p>

              <p>{{ totalTime > 0 ? `${totalTime.toFixed(1)} hrs` : '' }}</p>
            </div>

            <label>
              Breakdown (hr)

              <AppSelect
                v-model.number="recordForm.form.breakdown"
                :options="breakdownOptions"
                class="form-input"
                name="breakdown"
                data-test="breakdown"
                :error="recordForm.getErrors('breakdown')"
              />
            </label>

            <label>
              Standdown (hr)

              <AppSelect
                v-model.number="recordForm.form.standdown"
                :options="standdownOptions"
                class="form-input"
                name="standdown"
                data-test="standdown"
                :error="recordForm.getErrors('standdown')"
              />
            </label>
          </fieldset>

          <div class="w-full flex justify-between">
            <p class="font-bold">
              Operating
            </p>

            <p>{{ (Math.max(totalTime - recordForm.form.breakdown - recordForm.form.standdown, 0)).toFixed(1) }} hrs</p>
          </div>

          <fieldset class="flex flex-col space-y-4">
            <legend class="font-bold">
              Location Details
            </legend>


            <label>
              Area

              <AppSelect
                v-model="recordForm.form.area"
                :options="project?.areas"
                class="form-input"
                name="area"
                data-test="area"
                :error="recordForm.getErrors('area')"
              />
            </label>

            <label>
              Element

              <AppSelect
                v-model="recordForm.form.element"
                :options="project?.elements"
                class="form-input"
                name="element"
                data-test="element"
                :error="recordForm.getErrors('element')"
              />
            </label>

            <label>
              Activity

              <AppSelect
                v-model="recordForm.form.activity"
                :options="project?.activities"
                class="form-input"
                name="activity"
                data-test="activity"
                :error="recordForm.getErrors('activity')"
              />
            </label>

            <label>
              WBS Code

              <AppSelect
                v-model.number="recordForm.form.wbs_code_id"
                :options="project?.codes.map((code) => ({ value: code.id, label: code.code }))"
                class="form-input"
                name="wbs_code"
                data-test="wbs_code"
                :error="recordForm.getErrors('wbs_code_id')"
              />
            </label>

            <label>
              WBS Description

              <AppSelect
                v-model.number="recordForm.form.wbs_code_id"
                class="form-input"
                :options="project?.codes.map((code) => ({ value: code.id, label: code.description }))"
                name="wbs_description"
                data-test="wbs_description"
              />
            </label>

            <div class="inline-block">
              <label>
                Off-hire
                <input
                  v-model="recordForm.form.off_hire"
                  type="checkbox"
                  name="off_hire"
                  data-test="off_hire"
                  @change="checkOffhire"
                >
              </label>
            </div>
          </fieldset>

          <fieldset
            v-if="project?.orders.length"
            class="flex flex-col space-y-4"
          >
            <legend class="font-bold">
              Variation/Delay Details
            </legend>

            <label>
              <span>
                Event Number
              </span>

              <div class="flex space-x-2">
                <div class="flex-1">
                  <AppSelect
                    v-model.number="recordForm.form.variation_order_id"
                    :options="project?.orders.map((order) => ({ value: order.id, label: order.code }))"
                    name="vo_number"
                    data-test="vo_number"
                    :error="recordForm.getErrors('variation_order_id')"
                    class="form-input"
                    optional
                  />
                </div>

                <button
                  v-if="recordForm.form.variation_order_id"
                  class="group"
                  type="button"
                  @click.prevent="recordForm.resetData(['variation_order_id'])"
                >
                  <Icon
                    name="close"
                    class="h-6 w-6 text-red-600 m-1"
                  />
                </button>
              </div>
            </label>

            <label>
              <span>
                Event Description
              </span>

              <div class="w-full">
                <AppSelect
                  v-model.number="recordForm.form.variation_order_id"
                  class="form-input"
                  :options="project?.orders.map((order) => ({ value: order.id, label: order.description }))"
                  name="vo_description"
                  data-test="vo_description"
                  :error="recordForm.getErrors('variation_order_id')"
                  optional
                />
              </div>
            </label>
          </fieldset>

          <div class="flex justify-between pt-10">
            <button
              v-if="!record?.approved_at && recordForm.form.off_hire"
              type="button"
              class="btn btn--tertiary-blue"
              @click.prevent="clearData"
            >
              Clear
            </button>


            <div class="ml-auto">
              <button
                type="button"
                class="btn btn--secondary-blue"
                @click.prevent="router.push({ name: 'MobilePlantDashboard', query: { day: dateAsYMD(day) } })"
              >
                Cancel
              </button>

              <p
                v-if="record?.approved_at"
                class="text-center"
              >
                Record has been approved and cannot be modified
              </p>

              <button
                v-else
                type="submit"
                class="btn btn--primary-blue ml-2"
                data-test="submit_form"
              >
                {{ record ? 'Update' : 'Save' }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { dateAsYMD, dateForDateInput, dateForInput, hoursToDate, timeForInput, timeZone } from '@/helpers';
import Plant from '@/models/Plant';
import Project from '@/models/Project';
import { useNavStore } from '@/store/nav';
import { usePlantDailyRecordsStore } from '@/store/plantDailyRecord';
import { usePlantsStore } from '@/store/plants';
import { useProjectsStore } from '@/store/projects';
import Swal from 'sweetalert2';
import { computed, nextTick, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const props = defineProps<{
  projectId: string;
  plantId: string;
  recordId?: string;
}>();

const projectsStore = useProjectsStore();

const project = computed(() => {
  return projectsStore.project;
});

const plantsStore = usePlantsStore();

plantsStore.fetchPlant(props.plantId);

const plant = computed(() => {
  return plantsStore.models.query().with('records', (query) => {
    query.whereId(parseInt(props.recordId));
  }).find(parseInt(props.plantId));
});

const queryParams = useRoute().query.day as string;
const day = ref(queryParams ? new Date(queryParams) : new Date());
const navStore = useNavStore();

navStore.backRoute = { name: 'MobilePlantDashboard', query: { day: dateAsYMD(day.value) } };

const record = computed(() => {
  return plant.value?.records.length ? plant.value?.records[0] : undefined;
});

const recordForm = useFormData({
  day: dateAsYMD(day.value),
  start_at: '',
  end_at: '',
  smu_start: 0,
  smu_end: 0,
  smu: 0,
  breakdown: 0,
  standdown: 0,
  quantity: 0,
  area: '',
  element: '',
  activity: '',
  wbs_code_id: undefined,
  off_hire: false,
  comment: '',
  variation_order_id: undefined,
  time_zone: timeZone,
});

const endAtType = ref<'time' | 'datetime-local'>('time');

watch(endAtType, () => {
  recordForm.form.end_at = '';
});

watch(() => recordForm.form.start_at, (newValue, oldValue) => {
  if(newValue) {
    const hourSplit = newValue.split(':');
    const hour = parseInt(hourSplit[0]);

    if(hour >= 17) {
      endAtType.value = 'datetime-local';

      nextTick(() => {
        if(!oldValue && !recordForm.form.end_at) {
          const endAt = new Date(day.value);
          const hourSplit = newValue.split(':');

          endAt.setHours(!props.recordId ? parseInt(hourSplit[0]) : parseInt(hourSplit[0]) + 2);
          endAt.setMinutes(parseInt(hourSplit[1]));
          recordForm.form.end_at = dateForInput(endAt);
        }
      });
    } else {
      endAtType.value = 'time';

      nextTick(() => {
        if(!oldValue && !recordForm.form.end_at) {
          const endAt = new Date(day.value);
          const hourSplit = newValue.split(':');

          endAt.setHours(!props.recordId ? parseInt(hourSplit[0]) : parseInt(hourSplit[0]) + 2);
          endAt.setMinutes(parseInt(hourSplit[1]));
          recordForm.form.end_at = timeForInput(endAt);
        }
      });
    }
  }
});

const futureRecord = ref(false);

watch(() => recordForm.form.day, (newValue) => {
  if(newValue) {
    const dayAsDate = new Date(newValue);

    if(!isNaN(dayAsDate.getTime()) && dayAsDate.getTime() > new Date().getTime()) {
      futureRecord.value = true;
    } else {
      futureRecord.value = false;
    }
  }
}, { immediate: true });

watch(() => recordForm.form.smu, (newValue) => {
  if(newValue) {
    recordForm.form.smu_end = newValue + recordForm.form.smu_start;
  } else {
    recordForm.form.smu_end = recordForm.form.smu_start;
  }
}, { immediate: true });

const totalTime = computed(() => {
  if(recordForm.form.start_at && recordForm.form.end_at) {
    let endAt: Date = undefined;

    if(endAtType.value === 'time') {
      endAt = new Date(`${recordForm.form.day} ${recordForm.form.end_at}`);
    } else {
      endAt = new Date(recordForm.form.end_at);
    }

    const timeDiff = Math.abs(
      new Date(`${recordForm.form.day} ${recordForm.form.start_at}`).getTime() -
        endAt.getTime(),
    ) / 3600000;

    return Math.round(timeDiff * 2) / 2;
  }

  if(recordForm.form.smu_start && recordForm.form.smu_end) {
    const timeDiff = Math.abs(recordForm.form.smu_start - recordForm.form.smu_end);

    return Math.round(timeDiff * 2) / 2;
  }

  return 0;
});

watch(record, (newValue) => {
  if(newValue) {
    if(!plant.value?.smu_tracking) {
      recordForm.form.start_at = timeForInput(newValue.startAt);

      if(newValue.startAt.hour >= 17) {
        endAtType.value = 'datetime-local';

        nextTick(() => {
          recordForm.form.end_at = dateForInput(newValue.endAt);
        });
      } else {
        endAtType.value = 'time';

        nextTick(() => {
          recordForm.form.end_at = timeForInput(newValue.endAt);
        });
      }
    }

    recordForm.setData(newValue, [
      'start_at',
      'end_at',
      'time_zone',
    ]);

    if(plant.value.smu_tracking) {
      recordForm.form.smu = recordForm.form.smu_end - recordForm.form.smu_start;
    }

    day.value = new Date(newValue.day);
    navStore.backRoute = { name: 'MobilePlantDashboard', query: { day: dateAsYMD(day.value) } };
  }
});

const plantDailyRecordsStore = usePlantDailyRecordsStore();
const createRecordAction = useStoreApiAction(plantDailyRecordsStore.createRecord);
const updateRecordAction = useStoreApiAction(plantDailyRecordsStore.updateRecord);
const router = useRouter();

const saveRecord = () => {
  recordForm.resetErrors();

  if(record.value) {
    Swal.fire({
      text: 'Why are you making this change?',
      icon: 'info',
      input: 'text',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if(result.isConfirmed) {
        recordForm.form.comment = result.value;

        updateRecordAction.request(record.value.id, recordForm.form, { day: recordForm.form.day }).then(() => {
          router.push({ name: 'MobilePlantDashboard', query: { day: dateAsYMD(day.value) } });
        }).catch((error) => {
          recordForm.setErrors(error.data);
        });
      }
    });
  } else {
    if(futureRecord.value) {
      Swal.fire({
        icon: 'warning',
        title: 'This is a future date record',
        text: 'Are you sure you want to save this record?',
        showCancelButton: true,
      }).then((result) => {
        if(result.isConfirmed) {
          createRecordAction.request(plant.value?.id, recordForm.form).then(() => {
            router.push({ name: 'MobilePlantDashboard', query: { day: dateAsYMD(day.value) } });
          }).catch((error) => {
            recordForm.setErrors(error.data);
          });
        }
      });
    } else {
      createRecordAction.request(plant.value?.id, recordForm.form).then(() => {
        router.push({ name: 'MobilePlantDashboard', query: { day: dateAsYMD(day.value) } });
      }).catch((error) => {
        recordForm.setErrors(error.data);
      });
    }
  }
};

const previousRecord = computed(() => {
  return plantDailyRecordsStore.models
    .where('day', dateAsYMD(new Date(recordForm.form.day)))
    .where('plant_id', parseInt(props.plantId))
    .orderBy('id', 'desc')
    .first() ||
    plantDailyRecordsStore.models
      .where('plant_id', parseInt(props.plantId))
      .orderBy('id', 'desc')
      .first();
});

watch(previousRecord, (newValue) => {
  if(newValue && !props.recordId) {
    recordForm.form.area = newValue.area;
    recordForm.form.element = newValue.element;
    recordForm.form.wbs_code_id = newValue.wbs_code_id;
    recordForm.form.activity = newValue.activity;

    if(plant.value?.smu_tracking) {
      recordForm.form.smu_start = newValue.smu_end;
      recordForm.form.smu_end = newValue.smu_end + projectsStore.project?.plant_standard_shift;
      recordForm.form.smu = projectsStore.project?.plant_standard_shift;
    } else if(newValue.day === dateAsYMD(day.value) && !plant.value?.smu_tracking) {
      recordForm.form.start_at = timeForInput(newValue.end_at);
    }
  }
});

watch(() => [projectsStore.project, plant], ([newProject, newPlant]) => {
  const previousRecord = newPlant instanceof Plant ?
    plantDailyRecordsStore.models
      .where('day', dateAsYMD(day.value))
      .where('plant_id', plant.value?.id)
      .first() :
    undefined;

  if(
    !previousRecord &&
    !plant.value?.smu_tracking &&
    newProject instanceof Project
  ) {
    recordForm.form.start_at = newProject.shift_start_at;

    const end = new Date(newProject.shiftStartAt);

    end.setHours(end.getHours() + newProject.plant_standard_shift);

    if(hoursToDate(newProject.shift_start_at).getHours() >= 17) {
      recordForm.form.end_at = dateForDateInput(end);
    } else {
      recordForm.form.end_at = timeForInput(end);
    }
  }
}, { immediate: true });

const checkOffhire = (event: Event) => {
  if((event.target as HTMLInputElement).checked) {
    Swal.fire({
      icon: 'question',
      text: 'Remove data for the record?',
      showConfirmButton: true,
      confirmButtonColor: 'green',
      confirmButtonText: 'Yes',
      showDenyButton: true,
    }).then((result) => {
      if(result.value) {
        recordForm.resetData([
          'start_at',
          'end_at',
          'smu_start',
          'smu_end',
          'breakdown',
          'standdown',
          'quantity',
          'area',
          'element',
          'activity',
          'wbs_code_id',
        ]);
      }
    });
  }
};

const clearData = () => {
  recordForm.resetData([
    'start_at',
    'end_at',
    'smu_start',
    'smu_end',
    'breakdown',
    'standdown',
    'quantity',
    'area',
    'element',
    'activity',
    'wbs_code_id',
  ]);
};

const standdownOptions = computed(() => {
  const maxTime = project.value?.maximum_shift - totalTime.value;
  const options = [];

  for(let i = 0;i <= maxTime;i += 0.5) {
    options.push(i);
  }

  return options;
});

const breakdownOptions = computed(() => {
  const maxTime = project.value?.maximum_shift - totalTime.value;
  const options = [];

  for(let i = 0;i <= maxTime;i += 0.5) {
    options.push(i);
  }

  return options;
});
</script>

<style scoped></style>
