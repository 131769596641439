import { route } from '@/ziggy-shim';
import { api, apiConfig, CastValue } from './LaravelClient';

export default {
  async create(claimId: Id, variationOrderId: Id, data: ApiRequests['api.v1.variation-order-costs.store']) {
    return await api(route('api.v1.variation-order-costs.store', [claimId, variationOrderId]), 'post', {
      data,
      casts: {
        current_certified: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        current_claim: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async update(id: Id, data: ApiRequests['api.v1.variation-order-costs.update']) {
    return await api(route('api.v1.variation-order-costs.update', id), 'patch', {
      data,
      casts: {
        current_certified: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        current_claim: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async delete(id: Id) {
    return await api(route('api.v1.variation-order-costs.destroy', id), 'delete');
  },

  async claim(claimId: Id, signedParams: unknown, data: ApiRequests['api.v1.claims.variation.claim.store']) {
    return await apiConfig(route('api.v1.claims.variation.claim.store', claimId), 'post', {
      params: signedParams,
      paramsSerializer: {
        serialize: (params) => {
          return new URLSearchParams(params).toString();
        },
      },
    }, {
      data,
      casts: {
        id: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async certified(claimId: Id, signedParams: unknown, data: ApiRequests['api.v1.claims.variation.certified.store']) {
    return await apiConfig(route('api.v1.claims.variation.certified.store', claimId), 'post', {
      params: signedParams,
      paramsSerializer: {
        serialize: (params) => {
          return new URLSearchParams(params).toString();
        },
      },
    }, {
      data,
      casts: {
        comment: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },
};
