import { onMounted, onUnmounted, ref } from 'vue';

export const ScreenSize = {
  XS: 1,
  SM: 2,
  MD: 3,
  LG: 4,
  XL: 5,
  XXL: 6,
} as const;

type ObjectValues<T> = T[keyof T];

type ScreenSizeType = ObjectValues<typeof ScreenSize>;

const screenSize = ref<ScreenSizeType>();

export function getScreenSize() {
  const size = window.innerWidth;

  if(size > 1536) screenSize.value = ScreenSize.XXL;
  else if(size > 1280) screenSize.value = ScreenSize.XL;
  else if(size > 1024) screenSize.value = ScreenSize.LG;
  else if(size > 768) screenSize.value = ScreenSize.MD;
  else if(size > 640) screenSize.value = ScreenSize.SM;
  else screenSize.value = ScreenSize.XS;

  return screenSize.value;
}

getScreenSize();

export default function useScreenSize() {
  onMounted(() => {
    window.addEventListener('resize', getScreenSize);
  });

  onUnmounted(() => {
    // TODO: 27/02/24 why does this unmount get called
    // window.removeEventListener('resize', getScreenSize);
  });

  return { screenSize };
}
