<template>
  <div class="w-full">
    <!-- <div class="flex w-full justify-center bg-blue-900 py-2">
      <h1 class="text-3xl text-white font-semibold">
        {{ projectsStore.project?.name }}
      </h1>
    </div> -->

    <div class="w-[90%] mx-auto mt-8">
      <div class="flex flex-col w-full my-2 p-5 bg-white rounded-lg shadow-md">
        <div class="text-center rounded-lg">
          <h1 class="text-3xl font-semibold">
            Quantities
          </h1>
        </div>

        <form
          action=""
          class="w-full space-y-2"
          @submit.prevent="saveForm"
        >
          <div class="flex flex-col space-y-4">
            <label>
              Date

              <AppInput
                v-model="quantityForm.form.day"
                class="form-input"
                type="date"
                name="date"
                data-test="select-day-input"
                :error="quantityForm.getErrors('day')"
              />
            </label>

            <label
              class=""
            >
              WBS Code

              <AppSelect
                v-model.number="quantityForm.form.wbs_code_id"
                class="form-input"
                data-test="wbs_code"
                name="wbs_code_id"
                :options="project?.codes.map((code) => ({ value: code.id, label: code.code }))"
                :error="quantityForm.getErrors('wbs_code_id')"
              />
            </label>

            <label class="">
              WBS Description

              <AppSelect
                v-model.number="quantityForm.form.wbs_code_id"
                class="form-input"
                :options="project?.codes.map((code) => ({ value: code.id, label: code.description }))"
                name="wbs_description"
                data-test="wbs_description"
                :error="quantityForm.getErrors('wbs_code_id')"
              />
            </label>

            <div>
              <p>
                Capture By
              </p>

              <div class="flex space-x-4">
                <button
                  class="w-1/2 btn"
                  :class="[quantityForm.form.capture_loads ? 'btn--secondary-blue' : 'btn--primary-blue']"
                  type="button"
                  @click="toggleCaptureQuantity"
                >
                  Quantity
                </button>

                <button
                  class="w-1/2 btn"
                  :class="[quantityForm.form.capture_loads ? 'btn--primary-blue' : 'btn--secondary-blue']"
                  type="button"
                  @click="toggleCaptureLoads"
                >
                  Loads
                </button>
              </div>
            </div>

            <template v-if="quantityForm.form.capture_loads === true">
              <div>
                <p>
                  Conversion
                </p>

                <div class="flex space-x-4">
                  <button
                    class="w-1/2 btn"
                    :class="
                      [quantityForm.form.conversion_type === 'tonnes' ? 'btn--primary-blue' : 'btn--secondary-blue']
                    "
                    type="button"
                    @click="quantityForm.form.conversion_type = 'tonnes'"
                  >
                    Tonnes
                  </button>

                  <button
                    class="w-1/2 btn"
                    :class="[quantityForm.form.conversion_type === 'cubes' ? 'btn--primary-blue' : 'btn--secondary-blue']"
                    type="button"
                    @click="quantityForm.form.conversion_type = 'cubes'"
                  >
                    Cubes
                  </button>
                </div>
              </div>

              <template
                v-for="(assetType, i) in quantityForm.form.asset_types"
                :key="i"
              >
                <div class="flex space-x-4">
                  <div class="w-1/2">
                    <label>
                      Asset Type

                      <AppSelect
                        v-model.number="assetType.asset_type_id"
                        class="form-input"
                        data-test="asset_type_id"
                        name="asset_type_id[]"
                        required
                        :options="assetTypes.map((assetType) => ({ value: assetType.id, label: assetType.name}))"
                        :error="quantityForm.getErrors(`asset_types.${i}.asset_type_id`)"
                      />
                    </label>
                  </div>

                  <div class="w-1/2">
                    <label>
                      Loads

                      <AppInput
                        v-model.number="assetType.loads"
                        class="form-input"
                        name="loads[]"
                        type="number"
                        min="0"
                        required
                        data-test="loads"
                      />
                    </label>
                  </div>
                </div>

                <div class="w-full flex space-x-4 items-center">
                  <p class="w-1/2">
                    Conversion per load

                    {{
                      assetType.asset_type_id ? conversionForAsset(assetType.asset_type_id) : ''
                    }}
                  </p>

                  <div class="w-1/2 flex justify-between">
                    <p>
                      Total conversion

                      {{
                        assetType.asset_type_id ? assetType.loads * conversionForAsset(assetType.asset_type_id) : ''
                      }}
                    </p>

                    <button
                      type="button"
                      @click="removeAssetType(i)"
                    >
                      <Icon
                        name="trash"
                        class="w-6 h-6 hover:stroke-red-600 cursor-pointer"
                      />
                    </button>
                  </div>
                </div>
              </template>

              <button
                class="w-full btn btn--secondary-blue"
                type="button"
                @click="addAssetType"
              >
                Add Asset Load
              </button>
            </template>

            <label>
              Quantity {{ wbsCodeUnit ? `(${wbsCodeUnit})` : '' }}

              <AppInput
                v-model.number="quantityForm.form.quantity"
                name="quantity"
                type="number"
                min="0"
                data-test="quantity"
                :error="quantityForm.getErrors('quantity')"
                :disabled="quantityForm.form.capture_loads"
                class="disabled:bg-gray-400 form-input"
              />
            </label>

            <label
              v-if="selectedQuantity && selectedQuantity.bias"
              class="flex items-center justify-between"
            >
              Surveyed Quantity {{ wbsCodeUnit ? `(${wbsCodeUnit})` : '' }}

              <input
                type="number"
                class="disabled:bg-gray-300"
                disabled
                :value="selectedQuantity.quantity + selectedQuantity.bias"
              >
            </label>
          </div>

          <div class="flex pt-10 space-x-4">
            <button
              type="button"
              class="btn btn--secondary-blue w-1/2"
              @click.prevent="router.push({ name: 'MobileProjectPage' })"
            >
              Back
            </button>


            <button
              type="submit"
              class="btn btn--primary-blue w-1/2"
              :disabled="queryQuantityAction.state.value === States.LOADING"
              data-test="submit_form"
            >
              {{ quantityId ? 'Update' : 'Save' }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useDayQueryParam from '@/composables/useDayQueryParam';
import { useFormData } from '@/composables/useFormData';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { dateAsYMD } from '@/helpers';
import { useAssetTypesStore } from '@/store/assetTypes';
import { useDailyQuantitiesStore } from '@/store/dailyQuantities';
import { useNavStore } from '@/store/nav';
import { useProjectsStore } from '@/store/projects';
import { useWbsCodesStore } from '@/store/wbsCodes';
import Swal from 'sweetalert2';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const projectsStore = useProjectsStore();

const project = computed(() => {
  return projectsStore.project;
});

const dailyQuantitiesStore = useDailyQuantitiesStore();
const router = useRouter();
const queryDay = useDayQueryParam();
const queryQuantityAction = useStoreApiAction(dailyQuantitiesStore.queryQuantity);

const quantityForm = useFormData({
  day: dateAsYMD(queryDay.value),
  wbs_code_id: undefined,
  quantity: 0,
  asset_types: [] as { asset_type_id: number; loads: number }[],
  capture_loads: false,
  conversion_type: 'tonnes',
});

const addAssetType = () => {
  quantityForm.form.asset_types.push({
    asset_type_id: undefined,
    loads: 0,
  });
};

const removeAssetType = (index: number) => {
  quantityForm.form.asset_types.splice(index, 1);
};

watch(() => quantityForm.form.day, (newValue) => {
  if(newValue) {
    router.replace({ query: { day: newValue } });
  }
}, { immediate: true });

const quantityId = ref();

const selectedQuantity = computed(() => {
  return dailyQuantitiesStore.models.with('quantity_loads').find(quantityId.value);
});

watch(selectedQuantity, (newValue) => {
  if(newValue) {
    if(newValue.quantity_loads.length > 0) {
      quantityForm.form.capture_loads = true;

      quantityForm.form.asset_types = newValue.quantity_loads.map((load) => ({
        asset_type_id: load.asset_type_id,
        loads: load.loads,
      }));

      quantityForm.form.conversion_type = newValue.quantity_loads[0].load_unit;
    }

    quantityForm.form.quantity = newValue.quantity;
  }
});

watch(project, (newValue, oldValue) => {
  if(newValue?.id !== oldValue?.id) {
    quantityForm.form.wbs_code_id = newValue.codes[0]?.id;
  }
}, { immediate: true });

watch(() => [quantityForm.form.wbs_code_id, quantityForm.form.day], (newValue) => {
  if(newValue) {
    queryQuantityAction.request(projectsStore.project?.id, quantityForm.form).then(({ id }) => {
      quantityId.value = id;
    }).catch((error) => {
      if(error.status === 404) {
        quantityForm.resetData([
          'quantity',
          'conversion_type',
          'capture_loads',
        ]);

        quantityForm.form.asset_types = [];

        quantityId.value = undefined;
      }
    });
  }
}, { immediate: true });

const storeQuantityAction = useStoreApiAction(dailyQuantitiesStore.createQuantity);
const updateQuantityAction = useStoreApiAction(dailyQuantitiesStore.updateQuantity);

const saveForm = () => {
  quantityForm.resetErrors();

  if(quantityId.value) {
    if(quantityForm.form.capture_loads) {
      quantityForm.form.asset_types = quantityForm.form.asset_types.map((assetType) => ({
        asset_type_id: assetType.asset_type_id,
        load_unit: quantityForm.form.conversion_type,
        loads: assetType.loads,
      }));
    } else {
      quantityForm.form.asset_types = [];
    }

    updateQuantityAction.request(quantityId.value, quantityForm.form).then(() => {
      Swal.fire({
        icon: 'success',
        text: 'Quantity Updated.',
      });
    }).catch((error) => {
      quantityForm.setErrors(error.data);
    });
  } else {
    if(quantityForm.form.asset_types.length > 0) {
      quantityForm.form.asset_types = quantityForm.form.asset_types.map((assetType) => ({
        asset_type_id: assetType.asset_type_id,
        load_unit: quantityForm.form.conversion_type,
        loads: assetType.loads,
      }));
    } else {
      quantityForm.form.asset_types = undefined;
    }

    storeQuantityAction.request(projectsStore.project?.id, quantityForm.form).then((quantity) => {
      Swal.fire({
        icon: 'success',
        text: 'Quantity Saved.',
      });

      quantityId.value = quantity.id;
    }).catch((error) => {
      quantityForm.setErrors(error.data);
    });
  }
};

const wbsCodesStore = useWbsCodesStore();

const wbsCodeUnit = computed(() => {
  return wbsCodesStore.models.where('id', quantityForm.form.wbs_code_id).first()?.unit;
});

const navStore = useNavStore();

navStore.backRoute = { name: 'MobileProjectPage' };

const assetTypesStore = useAssetTypesStore();

const assetTypes = computed(() => {
  return assetTypesStore.models.where('project_id', project.value?.id).get();
});

watch(() => [quantityForm.form.asset_types, quantityForm.form.conversion_type], (newValue) => {
  if(newValue && Array.isArray(newValue[0])) {
    let totalQuantity = 0;

    newValue[0].forEach((element) => {
      const assetType = assetTypesStore.models.find(element.asset_type_id);

      if(assetType) {
        if(quantityForm.form.conversion_type === 'tonnes') {
          totalQuantity += element.loads * assetType.tonnes_per_load;
        } else {
          totalQuantity += element.loads * assetType.cubes_per_load;
        }
      }
    });

    quantityForm.form.quantity = totalQuantity;
  }
}, { deep: true });

watch(() => quantityForm.form.capture_loads, (newValue, oldValue) => {
  if(!newValue && oldValue && !quantityId.value) {
    quantityForm.form.asset_types = [];
  }
});

const conversionForAsset = (id: number) => {
  const assetType = assetTypes.value.find((value) => value.id === id);

  return quantityForm.form.conversion_type === 'cubes' ? assetType.cubes_per_load : assetType.tonnes_per_load;
};

const toggleCaptureQuantity = () => {
  if(quantityForm.form.asset_types.length > 0) {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'Switching to capture by quantity will remove all loads',
      showCancelButton: true,
    }).then((result) => {
      if(result.isConfirmed) {
        quantityForm.form.capture_loads = false;
        quantityForm.form.asset_types = [];
      }
    });
  } else {
    quantityForm.form.capture_loads = false;
  }
};

const toggleCaptureLoads = () => {
  if(quantityForm.form.quantity > 0) {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'Switching to capture by loads will remove the current quantity',
      showCancelButton: true,
    }).then((result) => {
      if(result.isConfirmed) {
        quantityForm.form.capture_loads = true;
        quantityForm.form.quantity = 0;
      }
    });
  } else {
    quantityForm.form.capture_loads = true;
  }
};
</script>

<style scoped></style>
