<template>
  <div
    class="bg-white border border-gray-400 rounded-lg shadow-md cursor-pointer"
    @click="open = !open"
  >
    <div class="flex px-3 py-5 justify-between">
      <div class="flex items-center space-x-1">
        <button
          :class="[{'rotate-90': open}]"
          data-test="open-trade"
          type="button"
        >
          <Icon
            name="chevron-right"
            class="btn-icon h-5 w-5 group-hover:text-gray-700"
          />
        </button>

        <button
          type="button"
          @click.stop="$emit('edit')"
        >
          <Icon
            name="edit"
            class="btn-icon h-5 w-5 inline-block"
          />
        </button>

        <button
          type="button"
          @click.stop="$emit('delete')"
        >
          <Icon
            name="trash"
            class="btn-icon h-5 w-5 inline-block"
          />
        </button>

        <span>
          {{ trade.name }}
        </span>
      </div>

      <div class="flex space-x-2">
        <div>
          ${{ totalTradeCost }}
        </div>

        <button
          type="button"
          @click.stop="createTradeItem"
        >
          <Icon
            name="plus"
            class="btn-icon h-5 w-5"
          />
        </button>
      </div>
    </div>

    <template v-if="open && tradeItems.length">
      <TradeRowItem
        v-for="tradeItem in tradeItems"
        :key="tradeItem.id"
        :trade-item="tradeItem"
        @edit="editTradeItem(tradeItem)"
        @delete="deleteTradeItem(tradeItem)"
      />
    </template>

    <template v-else-if="open">
      <div class="text-center w-full p-2">
        No trade items found
      </div>
    </template>
  </div>

  <TradeItemSaveModal
    v-if="show"
    :trade="trade"
    :trade-item="selectedTradeItem"
    @close="show = false"
  />
</template>

<script setup lang="ts">
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import Trade from '@/models/Trade';
import TradeItem from '@/models/TradeItem';
import { useTradeItemsStore } from '@/store/tradeItems';
import Swal from 'sweetalert2';
import { computed, ref } from 'vue';
import TradeItemSaveModal from './TradeItemSaveModal.vue';
import TradeRowItem from './TradeRowItem.vue';

const props = defineProps<{
  trade: Trade;
}>();

defineEmits<{
  (e: 'edit');
  (e: 'delete');
}>();

const open = ref(false);
const tradeItemsStore = useTradeItemsStore();

const tradeItems = computed(() => {
  return tradeItemsStore.models.where('trade_id', props.trade.id).get();
});

const totalTradeCost = computed(() => {
  return tradeItems.value.reduce((total, current) => {
    return total + current.budget;
  }, 0);
});

const show = ref(false);
const selectedTradeItem = ref<TradeItem | undefined>();

const editTradeItem = (tradeItem: TradeItem) => {
  selectedTradeItem.value = tradeItem;
  show.value = true;
};

const createTradeItem = () => {
  selectedTradeItem.value = undefined;
  open.value = true;
  show.value = true;
};

const deleteTradeItemAction = useStoreApiAction(tradeItemsStore.deleteTradeItem);

const deleteTradeItem = (tradeItem: TradeItem) => {
  Swal.fire({
    icon: 'warning',
    title: 'Delete Trade Item?',
    text: `You are about to delete ${tradeItem.name}. This action cannot be undone.`,
    showCancelButton: true,
    showConfirmButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      deleteTradeItemAction.request(tradeItem.id).then(() => {
        Swal.fire({
          icon: 'warning',
          title: 'Trade Item Deleted.',
        });
      });
    }
  });
};
</script>

<style scoped></style>
