<template>
  <div class="flex flex-col items-center justify-center h-full space-y-2.5 my-auto">
    <div class="flex space-x-10">
      <h1 class="text-3xl font-semibold hidden sm:block break-words my-auto">
        Request Password Reset
      </h1>
    </div>

    <div
      class="p-4 w-full sm:w-1/2
        sm:mx-auto sm:border sm:shadow-lg sm:rounded-md sm:mt-2 lg:w-1/4 bg-white rounded-lg"
    >
      <form
        v-if="!success"
        class="flex flex-col space-y-2"
        action="/forgot-password"
        @submit.prevent="resetPassword"
      >
        <label>
          Email
          <AppInput
            v-model="passwordForm.form.email"
            type="email"
            name="email"
            :error="passwordForm.getErrors('email')"
            required
          />
        </label>

        <div class="flex w-full space-x-2">
          <button
            type="submit"
            class="w-1/2 flex items-center justify-center bg-blue-300 py-2 rounded-lg active:bg-blue-500"
          >
            Send Password Reset Link
          </button>

          <RouterLink
            :to="{ name: 'Login' }"
            class="w-1/2 flex items-center justify-center bg-blue-300 py-2 rounded-lg active:bg-blue-500"
          >
            Login
          </RouterLink>
        </div>
      </form>

      <div
        v-else
        class="text-green-400 text-center"
      >
        <p class="font-bold text-lg">
          Password Reset Link Sent
        </p>

        <p>If the email exists, then an email containing the reset link has been sent</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import AxiosClient from '@/services/AxiosClient';
import Swal from 'sweetalert2';
import { ref } from 'vue';

const passwordForm = useFormData({
  email: '',
});

const success = ref(false);

const resetPassword = () => {
  AxiosClient.bootstrap();

  AxiosClient.postRequest('/forgot-password', passwordForm.form).then(([_data, error]) => {
    success.value = true;

    if(error && error.status !== 422) {
      Swal.fire({
        icon: 'error',
        title: 'Error resetting password.',
        text: 'Please try clearing your cookies before trying again.',
      });
    }
  });
};
</script>

<style scoped></style>
