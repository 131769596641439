import Comment from '@/models/Comment';
import PlantDailyRecord from '@/models/PlantDailyRecord';
import PlantDailyRecordService from '@/services/PlantDailyRecordService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';

export const usePlantDailyRecordsStore = defineStore('plantDailyRecords', {
  state: () => ({}),

  getters: {
    models: () => useRepo(PlantDailyRecord),
  },

  actions: {
    async createRecord(plantId: Id, recordForm: ApiRequests['api.v1.plant.daily.records.store']) {
      const [data, error] = await PlantDailyRecordService.create(plantId, recordForm);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updateRecord(
      id: Id,
      recordForm: ApiRequests['api.v1.plant.daily.records.update'],
      transformData: { day: string },
    ) {
      const [data, error] = await PlantDailyRecordService.update(id, recordForm, transformData);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async approve(id: Id) {
      const [data, error] = await PlantDailyRecordService.approve(id);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async unapprove(id: Id) {
      const [data, error] = await PlantDailyRecordService.unapprove(id);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async paid(id: Id, paid: ApiRequests['api.v1.plant.daily.records.paid']) {
      const [data, error] = await PlantDailyRecordService.paid(id, paid);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async fetchRecords(
      plantId: Id,
      form: ApiRequests['api.v1.plant.daily.records.index'] = { page: 1 },
    ): Promise<ResourcePaginator<PlantDailyRecord>> {
      const [data, error] = await PlantDailyRecordService.index(plantId, form);

      if(data) {
        this.models.save(data.data);

        return data;
      }

      throw error;
    },

    async fetchRecord(recordId: Id) {
      const [data, error] = await PlantDailyRecordService.show(recordId);

      if(data) {
        // Save comments separately as pinia orm cannot handle saving the morph relationship
        const comments = data.comments;

        delete data.comments;
        const commentsRepo = useRepo(Comment);

        commentsRepo.save(comments);

        this.models.save(data);

        return data;
      }

      throw error;
    },

    async splitRecord(
      id: Id,
      request: ApiRequests['api.v1.plant.daily.records.split'],
      transformData: { newDay: string; oldDay: string },
    ) {
      const [data, error] = await PlantDailyRecordService.split(id, request, transformData);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async comment(id: Id, request: ApiRequests['api.v1.plant.daily.records.comments']) {
      const [data, error] = await PlantDailyRecordService.comment(id, request);

      if(data) {
        const comments = useRepo(Comment);

        comments.save(data);

        return data;
      }

      throw error;
    },

    async vendor(id: Id, request: ApiRequests['api.v1.plant.daily.records.vendor']) {
      const [data, error] = await PlantDailyRecordService.vendor(id, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async search(projectId: Id, form: ApiRequests['api.v1.plant.daily.records.search']) {
      const [data, error] = await PlantDailyRecordService.search(projectId, form);

      if(data) {
        return data;
      }

      throw error;
    },

    async deleteRecord(id: Id) {
      const [data, error] = await PlantDailyRecordService.delete(id);

      if(data) {
        this.models.destroy(data.id);

        return data;
      }

      throw error;
    },
  },
});
