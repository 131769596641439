<template>
  <tr
    class="group even:bg-gray-100 border-x border-black hover:bg-blue-100 odd:bg-white"
  >
    <td class="pl-3 py-2 w-[200px]">
      <div class="flex items-center space-x-1">
        <div class="flex flex-col">
          <p class="font-semibold">
            {{ $props.wbsCode.code }}
          </p>

          <p class="line-clamp-1 text-sm w-[200px]">
            {{ $props.wbsCode.description }}
          </p>
        </div>
      </div>
    </td>

    <td class="text-left pl-2">
      {{ $props.wbsCode.unit }}
    </td>

    <td class="text-right">
      {{ australianNumber($props.wbsCode.tender_quantity) }}{{ $props.wbsCode.unit }}
    </td>

    <td class="text-right pr-2">
      {{ australianNumber($props.toComplete.toDateQuantity) }}{{ $props.wbsCode.unit }}
    </td>

    <td
      class="text-right pr-2"
    >
      {{ australianNumber($props.toComplete.toCompleteQuantity) }}{{ $props.wbsCode.unit }}
    </td>

    <td class="text-right pr-2">
      {{ australianCurrency($props.wbsCode.unitCost) }}/{{ wbsCode.unit }}
    </td>

    <td class="text-right pr-2">
      {{ australianCurrency(toComplete.toDateUnitCost) }}/{{ wbsCode.unit }}
    </td>

    <td class="text-right pr-2">
      {{ australianCurrency(toComplete.toCompleteUnitCost) }}/{{ wbsCode.unit }}
    </td>

    <td class="text-right pr-2">
      {{ australianCurrency(toComplete.toDateCost) }}
    </td>

    <td class="text-right pr-2">
      {{ australianCurrency(toComplete.toDateEarnedAmount) }}
    </td>

    <td
      class="text-right pr-2"
      :class="[$props.toComplete.toDateVariance < 0 ? 'bg-red-400/40' : 'bg-green-400/40']"
    >
      {{ australianCurrency(toComplete.toDateVariance) }}
    </td>

    <td class="text-right pr-2">
      {{ australianCurrency(toComplete.toCompleteCost) }}
    </td>

    <td class="text-right pr-2">
      {{ toComplete.toDateCost === 0 ? 0 : australianCurrency(toComplete.forecastCost) }}
    </td>

    <td class="text-right pr-2">
      {{ australianCurrency(toComplete.profit) }}
    </td>
  </tr>
</template>

<script setup lang="ts">
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, australianNumber } from '@/helpers';
import WbsCode from '@/models/WbsCode';
import { useWbsCodesStore } from '@/store/wbsCodes';
import { nextTick, ref } from 'vue';
import WbsCodeRowDetails from './WbsCodeRowDetails.vue';

const props = defineProps<{
  wbsCode: WbsCode;
  toComplete: CostAtCompletion;
}>();

const open = ref(false);
const typingFinalQuantity = ref(false);
const wbsCodesStore = useWbsCodesStore();
const saveFinalQuantityAction = useStoreApiAction(wbsCodesStore.updateCode);
const finalQuantity = ref(props.wbsCode.final_quantity);

const updateWbsCode = () => {
  saveFinalQuantityAction.request(props.wbsCode.id, { final_quantity: finalQuantity.value }).then(() => {
    typingFinalQuantity.value = false;
  });
};

const finalQuantityInput = ref();

const toggleTypingFinalQuantity = () => {
  typingFinalQuantity.value = !typingFinalQuantity.value;

  if(typingFinalQuantity.value) {
    nextTick(() => {
      finalQuantityInput.value?.focusInput();
    });
  }
};

const toCompleteTitle = (i: number) => {
  switch (i) {
    case 1:
      return 'This is the number of days to complete Formula [Qty to Complete/Production rate To Complete]';

    case 2:
      return 'The forecasted production rate to complete the project, inserted by the user';

    case 3:
      return 'The final quantity less the Quantity to date. The formula is [Final Qty - to Date Qty]';
  }
};

const tenderTitle = (i: number) => {
  switch (i) {
    case 1:
      return 'The number of days based on the quantity and production rate. The formula is [Tender Qty/Tender Production rate]';

    case 2:
      return 'The production rate that is used in the tender estimate';

    case 3:
      return 'The Tender Quantity as adjusted by changes';
  }
};

const toDateTitle = (i: number) => {
  switch (i) {
    case 1:
      return 'The number of days with a recorded Quantity';

    case 2:
      return 'The production rate achieved to date [Quantity to date / No Days]';

    case 3:
      return 'The quantity to date as per the Quantities form';
  }
};
</script>

<style scoped></style>
