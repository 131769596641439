<template>
  <div>
    <RouterLink
      v-slot="{ navigate }"
      :to="{
        name: 'MobilePlantRecord',
        params: {
          plantId: plant.id
        },
        query: {
          day: `${dateAsYMD($props.selectedDate)}`
        }
      }"
      custom
    >
      <div
        class="flex w-full text-base text-black text-left justify-start p-2"
        :class="[plantClass(plant)]"
        data-test="plant-row"
        @click="plant.dailyRecords($props.selectedDate).length === 0 ? navigate() : open = !open"
      >
        <h2
          class="w-1/5 truncate"
          data-test="plant-fleet"
        >
          {{ plant.fleet }}
        </h2>

        <h2 class="w-2/5 truncate">
          {{ plant.description }}
        </h2>

        <h2 class="w-1/5 text-center">
          {{ plant.wet ? 'Hr' : 'Day' }}
        </h2>

        <h2 class="w-1/5 text-right">
          {{
            plant.records.length ? plant.dailyHours($props.selectedDate).toLocaleString('en-AU', {
              maximumFractionDigits: 2, minimumFractionDigits: 2
            }) : 0
          }}
        </h2>

        <RouterLink
          class="fa-btn fa-btn--blue ml-2"
          :to="{
            name: 'MobilePlantRecord',
            params: {
              plantId: plant.id
            },
            query: {
              day: `${dateAsYMD($props.selectedDate)}`
            }
          }"
          data-test="add-record"
        >
          <Icon
            name="plus"
            class="w-4 h-4"
          />
        </RouterLink>
      </div>
    </RouterLink>

    <div
      v-if="open"
      class="grid bg-blue-900 text-white"
      data-test="plant-record-table"
    >
      <div class="grid grid-cols-5 gap-2 font-bold text-sm px-1.5 pt-1">
        <p>
          WBS Code
        </p>

        <p>
          Area
        </p>

        <p>
          Element
        </p>

        <p>
          Activity
        </p>
      </div>

      <RouterLink
        v-for="record in plant.dailyRecords($props.selectedDate)"
        :key="record.id"
        class="grid grid-cols-5 gap-2 text-sm p-1.5 odd:bg-blue-700"
        :to="{
          name: 'MobilePlantRecord',
          params: {
            plantId: plant.id,
            recordId: record.id
          }
        }"
      >
        <p class="truncate">
          {{ record.wbsCode.code }}
        </p>

        <p class="truncate">
          {{ record.area }}
        </p>

        <p class="truncate">
          {{ record.element }}
        </p>

        <p class="truncate">
          {{ record.activity }}
        </p>

        <p class="text-right">
          {{ record.hours.toLocaleString('en-AU', {
            maximumFractionDigits: 2, minimumFractionDigits: 2
          }) }}
        </p>
      </RouterLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { dateAsYMD } from '@/helpers';
import Plant from '@/models/Plant';
import Project from '@/models/Project';
import { ref } from 'vue';

const props = defineProps<{
  plant: Plant;
  selectedDate: Date;
  project: Project;
}>();

const plantClass = (plant: Plant) => {
  if(plant.dailyHours(props.selectedDate) >= props.project?.plant_standard_shift) {
    return 'bg-green-300';
  } else if(plant.dailyHours(props.selectedDate) > 0) {
    return 'bg-orange-300';
  } else {
    return 'bg-red-300';
  }
};

const open = ref(false);
</script>

<style scoped></style>
