<template>
  <div class="border border-black rounded-lg p-5 text-center w-full h-full flex flex-col justify-between">
    <p class="text-xs 2xl:text-base">
      {{ $props.label }}
    </p>

    <p class="text-xs xl:text-sm">
      {{ $props.subtitle }}
    </p>

    <p class="font-bold">
      {{ $props.value }}
    </p>

    <div
      class="h-5 w-5 rounded-full mx-auto"
      :class="[colour]"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    label: string;
    subtitle?: string;
    value?: string;
    threshold: 'low' | 'medium' | 'high';
  }>(),
  {
    value: undefined,
    subtitle: undefined,
  },
);

const colour = computed(() => {
  switch (props.threshold) {
    case 'low':
      return 'bg-red-300';

    case 'medium':
      return 'bg-orange-300';

    default:
      return 'bg-green-500';
  }
});
</script>

<style scoped></style>
