<template>
  <div
    class="group odd:bg-gray-100 w-full even:bg-white"
  >
    <p class="text-lg font-semibold pl-10 pt-2.5">
      {{ trade.name }}
    </p>

    <div class="flex">
      <div class="flex flex-col pl-10 pb-5 pr-5 justify-center">
        <div class="flex items-center h-full">
          <form
            class="flex flex-col gap-2"
            @submit.prevent="updateTrade"
          >
            <label class="mt-2">
              Baseline <pre /> {{ trade.baselineStart?.toLocaleString() }} - {{ trade.baselineEnd?.toLocaleString() }}
            </label>

            <div
              class="flex gap-4"
              :class="[trade.forecastDuration ? 'flex-col' : 'flex-row items-end']"
            >
              <label>
                Forecast Start Date

                <AppInput
                  v-model="forecastDatesForm.form.forecast_start_date"
                  name="forecast_start_date"
                  type="date"
                  :error="forecastDatesForm.getErrors('forecast_start_date')"
                  class="form-input"
                />
              </label>

              <label>
                Forecast End Date

                <AppInput
                  v-model="forecastDatesForm.form.forecast_end_date"
                  name="forecast_end_date"
                  type="date"
                  :error="forecastDatesForm.getErrors('forecast_end_date')"
                  class="form-input"
                />
              </label>

              <button
                class="btn btn--secondary-blue"
                type="submit"
                :disabled="updateTradeAction.is(States.LOADING)"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>

      <div
        v-if="trade.forecastDuration"
        class="overflow-x-auto w-full"
      >
        <div class="sticky left-0 flex flex-col">
          <div class=" grid-cols-[1fr_auto_1fr] grid">
            <div>
              <button
                type="button"
                class="btn btn--tertiary-blue"
                @click="resetForecastLinear"
              >
                Reset Linear
              </button>

              <button
                v-if="trade.forecast?.length > 0"
                type="button"
                class="btn btn--tertiary-blue"
                @click="revertForecast"
              >
                Revert
              </button>
            </div>

            <div class="flex justify-center items-center">
              <p class="mr-4">
                <span class="font-semibold">Forecast Duration: </span>{{ trade.forecastDuration }} months
              </p>

              <div class="flex space-x-2">
                <!-- NOTE: this tolerance should be the exact same as src/app/Http/Requests/Trade/ForecastRequest.php -->
                <p>
                  <span class="font-semibold">
                    Allocated:
                  </span>

                  <span
                    :class="[totalDifference !== 0 ? 'text-red-600' : 'text-green-600']"
                  >
                    {{ australianCurrency(totalAmount) }} of {{ australianCurrency(trade.totalBudget) }} <span v-if="totalDifference !== 0">({{ (totalDifference > 0 ? "+" : "") + australianCurrency(totalDifference) }})</span>
                  </span>
                </p>
              </div>

              <button
                type="button"
                class="btn btn--tertiary-blue disabled:opacity-50 flex no-underline"
                :disabled="updateForecastAction.is(States.LOADING)"
                @click="updateForecast"
              >
                <p class="underline">
                  Save
                </p>

                <span
                  v-if="forecastForm.isDirty.value"
                  class="text-black font-normal ml-1"
                >
                  (unsaved)
                </span>
              </button>
            </div>
          </div>

          <p
            v-if="forecastForm.errors['forecast'] ?? false"
            class="text-red-600 mx-auto first-letter:capitalize"
          >
            {{ forecastForm.errors['forecast'][0] ?? '' }}
          </p>

          <p
            v-if="!forecastsSet"
            class="text-red-600 mx-auto first-letter:capitalize"
          >
            Forecasts missing: {{ Math.abs(forecastForm.form.forecast.length - props.trade.forecastDuration) }}
          </p>
        </div>

        <div class="flex p-5">
          <div
            v-if="trade.forecastDuration && updateTradeAction.isNot(States.LOADING)"
            class="flex pr-5"
          >
            <table>
              <thead>
                <tr :data-month-row="trade.id">
                  <th class="sticky left-0 group-odd:bg-gray-100 pr-2 group-even:bg-white" />
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td class="sticky left-0 group-odd:bg-gray-100 pr-2 group-even:bg-white font-bold text-center z-10">
                    Baseline
                  </td>

                  <template v-if="trade.combinedStart < trade.baselineStart">
                    <td
                      v-for="month in combinedStartDiff"
                      :key="month"
                      class="text-center"
                    >
                      -
                    </td>
                  </template>

                  <td
                    v-for="(baselineAmount, i) in trade.baseline_forecast"
                    :key="i"
                    class="text-center p-1"
                  >
                    {{ australianCurrency(baselineAmount) }}/{{ ((baselineAmount / trade.totalBudget) * 100).toFixed(2) }}%
                  </td>

                  <template v-if="trade.combinedEnd > trade.baselineEnd">
                    <td
                      v-for="month in combinedEndDiff"
                      :key="month"
                      class="text-center"
                    >
                      -
                    </td>
                  </template>
                </tr>

                <tr
                  :data-percent-row="trade.id"
                >
                  <td class="sticky left-0 group-odd:bg-gray-100 pr-2 group-even:bg-white font-bold text-center z-10">
                    %
                  </td>
                </tr>

                <tr :data-amount-row="trade.id">
                  <td class="sticky left-0 group-odd:bg-gray-100 pr-2 group-even:bg-white font-bold text-center z-10">
                    $
                  </td>
                </tr>
              </tbody>
            </table>

            <template
              v-for="month in trade.combinedDuration"
              :key="month"
            >
              <ForecastInput
                v-if="(month > combinedStartDiff || forecastStartsEarlier) && (month <= (trade.combinedDuration - combinedEndDiff) || forecastEndsLater)"
                v-model:percent="forecastForm.form.forecast_percents[month - (forecastStartsEarlier ? 0 : combinedStartDiff) - 1]"
                v-model:amount="forecastForm.form.forecast[month - (forecastStartsEarlier ? 0 : combinedStartDiff) - 1]"
                :trade="trade"
                :month-index="month"
                :actual-filled="trade.actuals_filled[month - (forecastStartsEarlier ? 0 : combinedStartDiff) - 1]"
                :error="forecastForm.hasErrors(`forecast.${month - (forecastStartsEarlier ? 0 : combinedStartDiff) - 1}`)"
                @save-percent="proRataSplitPercents(month - (forecastStartsEarlier ? 0 : combinedStartDiff) - 1, $event.oldAmount, $event.newAmount)"
              />

              <DisabledInputs
                v-else
                :trade="trade"
                :month-index="month"
              />
            </template>
          </div>

          <div
            v-else-if="updateTradeAction.is(States.LOADING)"
            class="flex justify-center items-center w-full my-20"
          >
            <AppSpinner />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, roundDecimals } from '@/helpers';
import Trade from '@/models/Trade';
import { useTradesStore } from '@/store/trades';
import Swal from 'sweetalert2';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import DisabledInputs from './DisabledInputs.vue';
import ForecastInput from './ForecastInput.vue';

const props = defineProps<{
  trade: Trade;
}>();

const emits = defineEmits<{
  (e: 'forecastUpdated', data: { newForecasts: number[] });
}>();

const tradesStore = useTradesStore();

const forecastForm = useFormData({
  forecast: [] as (number[]),
  forecast_percents: [] as (number[]),
});

const totalAmount = computed(() => {
  return forecastForm.form.forecast.reduce((total, current) => {
    return total + (current || 0);
  }, 0);
});

const totalDifference = computed(() => {
  return roundDecimals(totalAmount.value - props.trade.totalBudget);
});

const forecastsSet = computed(() => {
  return forecastForm.form.forecast.length === props.trade.forecastDuration;
});

watch(() => props.trade, (newValue) => {
  if(newValue) {
    // get months between forecast start/end
    // create array based on that, rather than just copying what's saved
    forecastForm.form.forecast = [];

    if(newValue.forecast?.length > 0) {
      for(let i = 0;i < newValue.forecastDuration;i++) {
        forecastForm.form.forecast[i] = newValue.forecast[i] ?? 0;
        // console.log(forecastForm.form.forecast[i]);
      }
    }

    forecastForm.form.forecast_percents = forecastForm.form.forecast.map((forecastAmount) => {
      return roundDecimals(forecastAmount / newValue.totalBudget * 100);
    });

    nextTick(() => {
      forecastForm.resetDirtyStatus();
    });
  }
}, { immediate: true });

const proRataSplitPercents = (index: number, oldAmount: number, newAmount: number) => {
  // console.log('pro rata split');

  // get all months after

  const monthsAfter = props.trade.forecastDuration - (index + 1);

  if(monthsAfter > 0) {
    // get the difference between old and new value
    const amountDiff = oldAmount - newAmount;

    // divide difference by months after

    const amountDiffPerMonth = roundDecimals(amountDiff / monthsAfter);

    // add to each month
    // console.log(monthsAfter, percentDiffPerMonth, oldPercent, newPercent);

    let totalAmount = 0;

    for(let i = index + 1;i <= monthsAfter + index;i++) {
      // NOTE: Math.max will return NaN if it's passed in, so we convert it to 0
      forecastForm.form.forecast[i] = Math.max(
        roundDecimals(
          forecastForm.form.forecast[i] + amountDiffPerMonth,
        ) || 0,
        0,
      );
    }

    forecastForm.form.forecast.forEach((amount) => {
      totalAmount += amount || 0;
    });

    let totalDifference = roundDecimals(props.trade.totalBudget - totalAmount);
    const additionalPerMonth = roundDecimals(totalDifference / monthsAfter);

    if(additionalPerMonth > 0.01) {
      for(let i = index + 1;i <= monthsAfter + index;i++) {
        // NOTE: Math.max will return NaN if it's passed in, so we convert it to 0
        forecastForm.form.forecast[i] = Math.max(
          roundDecimals(
            forecastForm.form.forecast[i] + additionalPerMonth,
          ) || 0,
          0,
        );

        totalAmount += additionalPerMonth;
      }
    }

    totalDifference = roundDecimals(props.trade.totalBudget - totalAmount);

    if(totalDifference !== 0) {
      forecastForm.form.forecast[forecastForm.form.forecast.length - 1] = Math.max(
        forecastForm.form.forecast[forecastForm.form.forecast.length - 1] +
            roundDecimals(totalDifference) || 0,
        0,
      );
    }
  }

  emits('forecastUpdated', { newForecasts: forecastForm.form.forecast });
};

const updateForecastAction = useStoreApiAction(tradesStore.updateForecast);

const updateForecast = () => {
  forecastForm.resetErrors();

  updateForecastAction.request(props.trade.id, forecastForm.form).then((data) => {
    // console.log(data);

    Swal.fire({
      icon: 'success',
      text: 'Forecast forecast saved.',
    });
  }).catch((error) => {
    Swal.fire({
      icon: 'error',
      text: error.data.message,
    });

    forecastForm.setErrors(error.data);
  });
};

const resetForecastLinear = () => {
  // total budget - actuals filled

  const remainingBudget = props.trade.totalBudget - props.trade.totalForecastActuals;
  let monthsWithoutActuals = 0;

  for(let i = 0;i < props.trade.forecastDuration;i++) {
    if(!props.trade.actuals_filled[i]) {
      monthsWithoutActuals += 1;
    }
  }

  if(monthsWithoutActuals > 0 && remainingBudget > 0) {
    const amountPerMonth = remainingBudget / monthsWithoutActuals;
    let totalAmount = 0;

    for(let i = 0;i < props.trade.forecastDuration;i++) {
      if(!props.trade.actuals_filled[i]) {
        forecastForm.form.forecast[i] = roundDecimals(amountPerMonth);

        totalAmount += forecastForm.form.forecast[i];
      }
    }

    if(forecastForm.form.forecast.length !== props.trade.forecastDuration) {
      forecastForm.form.forecast = forecastForm.form.forecast.slice(
        0,
        props.trade.forecastDuration - 1,
      );
    }

    const totalDifference = roundDecimals(remainingBudget - totalAmount);

    if(totalDifference !== 0) {
      forecastForm.form.forecast[forecastForm.form.forecast.length - 1] = roundDecimals(
        forecastForm.form.forecast[forecastForm.form.forecast.length - 1] + totalDifference,
      );
    }

    emits('forecastUpdated', { newForecasts: forecastForm.form.forecast });
  }
};

const revertForecast = () => {
  forecastForm.form.forecast = [];

  if(props.trade.forecast?.length > 0) {
    for(let i = 0;i < props.trade.forecastDuration;i++) {
      forecastForm.form.forecast[i] = props.trade.forecast[i] ?? 0;
      // console.log(forecastForm.form.forecast[i]);
    }
  }

  forecastForm.form.forecast_percents = forecastForm.form.forecast.map((forecastAmount) => {
    return roundDecimals(forecastAmount / props.trade.totalBudget * 100);
  });

  nextTick(() => {
    forecastForm.resetDirtyStatus();
  });

  emits('forecastUpdated', { newForecasts: forecastForm.form.forecast });
};

const forecastDatesForm = useFormData({
  forecast_start_date: '',
  forecast_end_date: '',
});

watch(() => props.trade, (newValue) => {
  if(newValue) {
    forecastDatesForm.setData(newValue);
  }
}, { immediate: true });

const updateTradeAction = useStoreApiAction(tradesStore.updateTrade);

const updateTrade = () => {
  forecastDatesForm.resetErrors();

  updateTradeAction.request(props.trade.id, forecastDatesForm.form).then((data) => {
    // console.log(data);

    if(!props.trade.forecast) {
      // Set initial linear forecast after forecast dates established
      resetForecastLinear();
    }
  }).catch((error) => {
    forecastDatesForm.setErrors(error.data);
  });
};

const combinedStartDiff = computed(() => {
  return Math.ceil(Math.abs(props.trade.combinedStart.diff(props.trade.forecastStart, ['months']).months)) ||
    Math.ceil(Math.abs(props.trade.combinedStart.diff(props.trade.baselineStart, ['months']).months));
});

const forecastStartsEarlier = computed(() => {
  return props.trade.forecastStart < props.trade.baselineStart;
});

const forecastEndsLater = computed(() => {
  return props.trade.forecastEnd > props.trade.baselineEnd;
});

const combinedEndDiff = computed(() => {
  let diff = 0;

  if(props.trade.combinedEnd.equals(props.trade.forecastEnd)) {
    diff = Math.ceil(Math.abs(props.trade.combinedEnd.diff(props.trade.baselineEnd, ['months']).months)) -
      (props.trade.baselineEnd.day < props.trade.combinedEnd.day ? 1 : 0);
  } else {
    diff = Math.ceil(Math.abs(props.trade.combinedEnd.diff(props.trade.forecastEnd, ['months']).months)) -
      (props.trade.forecastEnd.day < props.trade.combinedEnd.day ? 1 : 0);
  }

  return diff;
});

const showTeleports = ref(false);

onMounted(() => {
  showTeleports.value = true;
});
</script>

<style scoped></style>
