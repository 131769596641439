<template>
  <div class="w-[90%] mx-auto">
    <div class="bg-white p-2 mt-8 rounded-lg shadow-lg border border-gray-300">
      <div class="justify-center rounded-lg my-5 flex">
        <h1 class="text-3xl font-semibold justify-center">
          Project List
        </h1>
      </div>

      <div class="items-center flex flex-col">
        <div
          class="w-full flex flex-col space-y-4 my-2 bg-white p-4 rounded-lg"
          data-test="project-list"
        >
          <RouterLink
            v-for="project in projects"
            :key="project.id"
            :to="{ name: 'MobileProjectPage', params: { projectId: project.id } }"
            class="btn btn--primary-blue shadow-md"
          >
            {{ project.name }}
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useProjectsStore } from '@/store/projects';
import { computed, watch } from 'vue';
import { useRouter } from 'vue-router';

const projectsStore = useProjectsStore();
const router = useRouter();

const projects = computed(() => {
  return projectsStore.models.all();
});

watch(projects, (newValue) => {
  if(newValue.length === 1) {
    router.isReady().then(() => {
      setTimeout(() => {
        router.push({ name: 'MobileProjectPage', params: { projectId: newValue[0].id } });
      }, 1);
    });
  }
}, { immediate: true });
</script>

<style scoped></style>
