import { route } from '@/ziggy-shim';
import { api, CastValue } from './LaravelClient';

export default {
  async create(labourId: Id, data: ApiRequests['api.v1.labour.daily.records.store']) {
    return await api(route('api.v1.labour.daily.records.store', labourId), 'post', {
      data,
      casts: {
        start_at: (value) => new CastValue(value).cast('Y-m-d H:i:s', data.day).get(),
        end_at: (value) => new CastValue(value).cast('Y-m-d H:i:s', data.day).get(),
        variation_order_id: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        vendor_ref: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async update(id: Id, data: ApiRequests['api.v1.labour.daily.records.update'], transformData: { day: string }) {
    return await api(route('api.v1.labour.daily.records.update', id), 'patch', {
      data,
      casts: {
        start_at: (value) => new CastValue(value).cast('Y-m-d H:i:s', transformData.day).get(),
        end_at: (value) => new CastValue(value).cast('Y-m-d H:i:s', transformData.day).get(),
        variation_order_id: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        vendor_ref: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async show(id: Id) {
    return await api(route('api.v1.labour.daily.records.show', id), 'get');
  },

  async index(labourId: Id, data: ApiRequests['api.v1.labour.daily.records.index']) {
    return await api(route('api.v1.labour.daily.records.index', labourId), 'get', {
      data,
      casts: {
        day_min: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        day_max: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        vendor_ref: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async approve(id: Id) {
    return await api(route('api.v1.labour.daily.records.approve', id), 'post');
  },

  async unapprove(id: Id) {
    return await api(route('api.v1.labour.daily.records.unapprove', id), 'post');
  },

  async paid(id: Id, data: ApiRequests['api.v1.labour.daily.records.paid']) {
    return await api(route('api.v1.labour.daily.records.paid', id), 'post', {
      data,
      casts: {
        paid: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async split(
    id: Id,
    data: ApiRequests['api.v1.labour.daily.records.split'],
    transformData: { newDay: string; oldDay: string },
  ) {
    return await api(route('api.v1.labour.daily.records.split', id), 'post', {
      data,
      casts: {
        'new.start_at': (value) => new CastValue(value).cast('Y-m-d H:i:s', transformData.newDay).get(),
        'new.end_at': (value) => new CastValue(value).cast('Y-m-d H:i:s', transformData.newDay).get(),
        'old.end_at': (value) => new CastValue(value).cast('Y-m-d H:i:s', transformData.oldDay).get(),
        'old.start_at': (value) => new CastValue(value).cast('Y-m-d H:i:s', transformData.oldDay).get(),
      },
    });
  },

  async comment(id: Id, data: ApiRequests['api.v1.labour.daily.records.comments']) {
    return await api(route('api.v1.labour.daily.records.comments', id), 'post', {
      data,
    });
  },

  async vendor(id: Id, data: ApiRequests['api.v1.labour.daily.records.vendor']) {
    return await api(route('api.v1.labour.daily.records.vendor', id), 'post', {
      data,
      casts: {
        vendor_ref: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async search(projectId: Id, data: ApiRequests['api.v1.labour.daily.records.search']) {
    return await api(route('api.v1.labour.daily.records.search', projectId), 'get', {
      data,
      casts: {
        vendor_ref: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async delete(id: Id) {
    return await api(route('api.v1.labour.daily.records.destroy', id), 'delete');
  },
};
