// import './bootstrap';
import '../css/app.css';
import 'animate.css';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createApp, Directive, markRaw } from 'vue';
import { Router } from 'vue-router';
import App from './App.vue';
import { getScreenSize, ScreenSize } from './composables/useScreenSize';
import router, { authenticatedRoutes, guestRoutes, mobileCompatibleRoutes } from './router';
import { useUserStore } from './store/user';
import Icon from './svgs/Icon.vue';

declare module 'pinia' {
  export interface PiniaCustomProperties {
    router: Router;
  }
}

Bugsnag.start({
  apiKey: '6b8c3bf5f59917e13a02861fb3f50bbc',
  enabledReleaseStages: ['production', 'staging'],
  appVersion: import.meta.env.VITE_APP_VERSION || 'version-unknown',
  releaseStage: import.meta.env.VITE_APP_ENV || 'local',
  plugins: [new BugsnagPluginVue()],
});

const bugsnagVue = Bugsnag.getPlugin('vue');
const pinia = createPinia();

pinia.use(({ store }) => {
  store.router = markRaw(router);
});

pinia.use(piniaPluginPersistedstate);

const app = createApp(App);

app.component('Icon', Icon);
app.use(bugsnagVue);
// Register global app components
const appComponents = import.meta.glob('./components/app/*.vue', { eager: true });

for(const path in appComponents) {
  const componentName = path.replace(/^.*\/(.*)\.\w+$/, '$1');

  app.component(`App${componentName}`, (appComponents[path] as any).default || appComponents[path]);
}

app.use(pinia).use(router).mount('#app');

router.beforeEach(async (to) => {
  const screenSize = getScreenSize();
  const userStore = useUserStore();

  if(
    screenSize < ScreenSize.MD &&
    !mobileCompatibleRoutes.includes(to.name) &&
    to.name.toString() !== 'NotFound'
  ) {
    return router.push({ name: 'MobileProjectList' });
  }

  await userStore.userFetched.promise;

  if(!userStore.authenticated && authenticatedRoutes.includes(to.name)) {
    return { name: 'Login' };
  }

  if(userStore.authenticated && guestRoutes.includes(to.name)) {
    return { name: 'ProjectDashboard' };
  }

  if(to.meta?.roles && !userStore.isRole(to.meta?.roles as RoleTypes[], true, to.params.projectId as string)) {
    return { name: 'ProjectDashboard' };
  }
});

app.directive('collapsible', {
  mounted(el, binding) {
    if(binding.value.show) {
      el.style.height = '0';
      el.style.transition = `height ${binding.value.duration}ms cubic-bezier(.25, .8, .50, 1)`;
      el.style.overflowY = 'hidden';

      const complete = () => {
        el.style.height = '';
        el.style.overflowY = '';
      };

      el.addEventListener('transitioncancel', complete);
      setTimeout(complete, binding.value.duration * 1.1);

      setTimeout(() => {
        el.style.height = `${el.scrollHeight}px`;
      }, 100);
    } else {
      el.style.height = `${el.getBoundingClientRect().height}px`;

      const complete = () => {
        el.style.height = '';
        el.style.transition = '';
      };

      setTimeout(complete, binding.value.duration * 1.1);
      el.style.height = '0';
    }
  },

  updated(el, binding) {
    if(binding.value.show !== binding.oldValue.show) {
      if(binding.value.show) {
        el.style.height = '0';
        el.style.transition = `height ${binding.value.duration}ms cubic-bezier(.25, .8, .50, 1)`;
        el.style.overflowY = 'hidden';

        const complete = () => {
          el.style.height = '';
          el.style.overflowY = '';
        };

        el.addEventListener('transitioncancel', complete);
        setTimeout(complete, binding.value.duration * 1.1);

        setTimeout(() => {
          el.style.height = `${el.scrollHeight}px`;
        }, 100);
      } else {
        el.style.height = `${el.getBoundingClientRect().height}px`;

        // Restart animation
        // el.offsetHeight

        const complete = () => {
          el.style.height = '';
          el.style.transition = '';
        };

        setTimeout(complete, binding.value.duration * 1.1);
        el.style.height = '0';
      }
    }
  },
} as Directive<HTMLElement, { duration: number; show: boolean }>);

if(import.meta.hot) {
  import.meta.hot.on('vite:beforeFullReload', (payload) => {
    if(!payload.path) {
      throw '(skipping full reload)';
    }
  });
}
