<template>
  <div class="my-4 space-y-4">
    <div class="flex items-center justify-between space-x-4">
      <div class="flex space-x-2 element-island">
        <h1 class="font-semibold text-3xl">
          Cost At Completion
        </h1>

        <a
          v-if="fetchDailyQuantitiesAction.is(States.COMPLETE)"
          :href="exportUrl"
          target="_blank"
          class="btn btn--secondary-blue ml-2 inline-block"
        >
          Export
        </a>
      </div>

      <div
        v-if="fetchDailyQuantitiesAction.is(States.COMPLETE)"
        class="flex space-x-2 items-center element-island"
      >
        <p class="pl-1">
          To Date
        </p>

        <AppInput
          v-model="dailyQuantitiesForm.form.to_date"
          type="date"
          name="to_date"
        />

        <button
          type="button"
          class="btn btn--secondary-blue"
          @click="fetchDailyQuantities"
        >
          Apply
        </button>
      </div>
    </div>

    <div v-if="fetchDailyQuantitiesAction.is(States.LOADING)">
      <AppSpinner class="flex justify-center w-full py-20" />
    </div>

    <div
      v-else-if="wbsTotals.size === 0 && fetchDailyQuantitiesAction.is(States.COMPLETE)"
      class="py-5 px-3 element-island text-center font-semibold w-1/2 mx-auto"
    >
      No information available.
    </div>

    <WbsCodeList
      v-else-if="fetchDailyQuantitiesAction.is(States.COMPLETE)"
      class="element-island"
      :wbs-codes="wbsCodes"
      :to-complete-totals="toCompleteTotals"
    />
  </div>
</template>

<script setup lang="ts">
import WbsCodeList from '@/components/CostAtComplete/WbsCodeList.vue';
import { useFormData } from '@/composables/useFormData';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { dateForDateInput } from '@/helpers';
import { safeDenominator } from '@/helpers';
import { useDailyQuantitiesStore } from '@/store/dailyQuantities';
import { useWbsCodesStore } from '@/store/wbsCodes';
import { route } from '@/ziggy-shim';
import Swal from 'sweetalert2';
import { computed, ref, watch } from 'vue';

const props = defineProps<{
  projectId: string;
}>();

const wbsCodesStore = useWbsCodesStore();
const dailyQuantitiesStore = useDailyQuantitiesStore();
const fetchDailyQuantitiesAction = useStoreApiAction(dailyQuantitiesStore.toComplete);

const dailyQuantitiesForm = useFormData({
  to_date: dateForDateInput(new Date()),
});

const wbsTotals = ref<Map<string, { totalHours: number; totalCost: number; totalQuantity: number; totalDays: number }>>(
  new Map(),
);

const wbsCodes = computed(() => wbsCodesStore.models.where('project_id', parseInt(props.projectId)).get());

const toCompleteTotals = computed(() => {
  const toCompleteMap = new Map<number, CostAtCompletion>();

  for(const [key, value] of wbsTotals.value) {
    const wbsCode = wbsCodesStore.models.find(parseInt(key));

    if(wbsCode) {
      const toDateUnitCost = value.totalCost / safeDenominator(value.totalQuantity);
      const toDateProductionRate = value.totalQuantity / safeDenominator(value.totalDays);
      const toDateEarnedAmount = value.totalQuantity * wbsCode.unitCost;
      const toCompleteQuantity = (wbsCode.final_quantity || wbsCode.tender_quantity) - value.totalQuantity;

      const toCompleteProductionRate = wbsCode.to_complete_production_rate || toDateProductionRate ||
        wbsCode.tender_production_rate;

      const toCompleteUnitCost = toDateUnitCost !== 0 ?
        (toDateUnitCost /
          safeDenominator(toCompleteProductionRate / safeDenominator(toDateProductionRate))) :
        (wbsCode.unitCost);

      const toCompleteCost = toCompleteUnitCost * toCompleteQuantity;
      const forecastCost = value.totalCost + toCompleteCost;

      const profit = (value.totalCost + toCompleteCost) === 0 ?
        0 :
        (wbsCode.tender_quantity * wbsCode.unitCost) - (value.totalCost + toCompleteCost);

      toCompleteMap.set(wbsCode.id, {
        toDateQuantity: value.totalQuantity,
        toDateDays: (value.totalQuantity / toDateProductionRate) || 0,
        toDateProductionRate,
        toDateUnitCost,
        toDateCost: value.totalCost,
        toDateEarnedAmount,
        toDateVariance: toDateEarnedAmount - value.totalCost,
        toCompleteQuantity,
        toCompleteProductionRate,
        toCompleteUnitCost,
        toCompleteDays: toCompleteQuantity / safeDenominator(toCompleteProductionRate),
        toCompleteCost,
        forecastCost,
        profit,
      });
    }
  }

  return toCompleteMap;
});

const fetchDailyQuantities = () => {
  fetchDailyQuantitiesAction.request(props.projectId, dailyQuantitiesForm.form).then((data) => {
    wbsTotals.value = data;
  }).catch((error) => {
    Swal.fire({
      icon: 'error',
      title: 'Server Error',
      text: 'An error has occurred and we cannot fulfill this request.',
    });

    console.log(error);
  });
};

watch(() => props.projectId, () => {
  fetchDailyQuantities();
}, { immediate: true });

const exportUrl = computed(() => {
  return `${
    route('api.v1.quantities.cost-at-complete-export', props.projectId)
  }?to_date=${dailyQuantitiesForm.form.to_date}`;
});
</script>

<style scoped></style>
