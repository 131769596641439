<template>
  <div class="bg-white border border-gray-400 rounded-lg shadow-md cursor-pointer">
    <div
      class="flex px-3 py-5 justify-between"
      @click="open = !open"
    >
      <div class="flex items-center space-x-1">
        <button
          :class="[{'rotate-90': open}]"
          data-test="open-trade"
          type="button"
        >
          <Icon
            name="chevron-right"
            class="btn-icon h-5 w-5 group-hover:text-gray-700"
          />
        </button>

        <button
          type="button"
          @click.stop="$emit('edit')"
        >
          <Icon
            name="edit"
            class="btn-icon-blue h-5 w-5 inline-block"
          />
        </button>

        <button
          type="button"
          @click.stop="$emit('delete')"
        >
          <Icon
            name="trash"
            class="btn-icon-red h-5 w-5 inline-block"
          />
        </button>

        <p>
          <span class="font-bold">{{ trade.billNumber }}</span>  {{ trade.name }}
        </p>
      </div>

      <div class="flex space-x-2">
        <div>
          {{ australianCurrency(totalTradeCost) }}
        </div>

        <button
          type="button"
          @click.stop="createTradeItem"
        >
          <Icon
            name="plus"
            class="btn-icon-blue h-5 w-5"
          />
        </button>
      </div>
    </div>

    <div
      v-if="open && tradeItems.length"
      class="overflow-x-auto"
    >
      <table class="w-full">
        <thead class="first-child:pl-5 last-child:pr-5 [&>*]:px-5 text-left">
          <th class="text-center">
            Item
          </th>

          <th>
            Unit
          </th>

          <th class="text-right">
            Quantity
          </th>

          <th class="text-right">
            Rate
          </th>

          <th class="text-right">
            Amount
          </th>
        </thead>

        <draggable
          :list="tradeItems"
          item-key="id"
          tag="tbody"
          :class="{ 'opacity-50 pointer-events-none': updateSortAction.is(States.LOADING) }"
          @end="changeSortOrder"
        >
          <template
            v-if="updateSortAction.is(States.LOADING)"
            #header
          >
            <div class="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 bottom-1/2">
              <AppSpinner />
            </div>
          </template>

          <template #item="{element}">
            <TradeRowItem
              :trade-item="element"
              @edit="editTradeItem(element)"
              @delete="deleteTradeItem(element)"
              @create-in-position="showTradeAndSelectPosition(element.sort_order + 1)"
            />
          </template>
        </draggable>
      </table>
    </div>

    <template v-else-if="open">
      <div class="text-center w-full p-2">
        No trade items found
      </div>
    </template>
  </div>

  <TradeItemSaveModal
    v-if="show"
    :trade="trade"
    :selected-position="selectedPosition"
    :trade-item="selectedTradeItem"
    @update-sort="changeSortOrderForTradeItem($event.tradeItem, $event.index)"
    @close="show = false; selectedPosition = undefined"
  />
</template>

<script setup lang="ts">
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency } from '@/helpers';
import Trade from '@/models/Trade';
import TradeItem from '@/models/TradeItem';
import { useTradeItemsStore } from '@/store/tradeItems';
import Swal from 'sweetalert2';
import { computed, ref, watch } from 'vue';
import draggable from 'vuedraggable';
import TradeItemSaveModal from './TradeItemSaveModal.vue';
import TradeRowItem from './TradeRowItem.vue';

const props = defineProps<{
  trade: Trade;
}>();

const emits = defineEmits<{
  (e: 'edit');
  (e: 'delete');
  (e: 'modalOpen');
  (e: 'modalClose');
}>();

const open = ref(false);
const tradeItemsStore = useTradeItemsStore();

const tradeItems = computed(() => {
  return tradeItemsStore.models.where('trade_id', props.trade.id).orderBy('sort_order').get();
});

const totalTradeCost = computed(() => {
  return tradeItems.value.reduce((total, current) => {
    return total + current.budget;
  }, 0);
});

const show = ref(false);
const selectedTradeItem = ref<TradeItem | undefined>();
const selectedPosition = ref<number | undefined>();

watch(show, (newValue) => {
  if(newValue) {
    emits('modalOpen');
  } else {
    emits('modalClose');
  }
});

const showTradeAndSelectPosition = (position: number) => {
  selectedPosition.value = position;
  show.value = true;
};

const editTradeItem = (tradeItem: TradeItem) => {
  selectedTradeItem.value = tradeItem;
  show.value = true;
};

const createTradeItem = () => {
  selectedTradeItem.value = undefined;
  open.value = true;
  show.value = true;
};

const deleteTradeItemAction = useStoreApiAction(tradeItemsStore.deleteTradeItem);

const deleteTradeItem = (tradeItem: TradeItem) => {
  Swal.fire({
    icon: 'warning',
    title: 'Delete Trade Item?',
    text: `You are about to delete ${tradeItem.name}. This action cannot be undone.`,
    showCancelButton: true,
    showConfirmButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      deleteTradeItemAction.request(tradeItem.id).then(() => {
        Swal.fire({
          icon: 'warning',
          title: 'Trade Item Deleted.',
        });
      });
    }
  });
};

const updateSortAction = useStoreApiAction(tradeItemsStore.updateSort);

const changeSortOrderForTradeItem = (tradeItem: TradeItem, index: number) => {
  updateSortAction.request(tradeItem.id, {
    sort_order: index,
  }).catch((error) => {
    Swal.fire({
      title: 'Something went wrong.',
      icon: 'error',
    });
  });
};

const changeSortOrder = (e) => {
  changeSortOrderForTradeItem(e.item.__draggable_context.element, e.newIndex + 1);
};
</script>

<style scoped></style>
