import ProjectSupplier from '@/models/ProjectSupplier';
import ProjectSupplierService from '@/services/ProjectSupplierService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';

export const useProjectSupplierStore = defineStore('projectSupplier', {
  state: () => ({}),

  getters: {
    models: () => useRepo(ProjectSupplier),
  },

  actions: {
    async createProjectSupplier(projectId: Id, request: ApiRequests['api.v1.project-supplier.store']) {
      const [data, error] = await ProjectSupplierService.create(projectId, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updateProjectSupplier(id: Id, request: ApiRequests['api.v1.project-supplier.update']) {
      const [data, error] = await ProjectSupplierService.update(id, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async deleteProjectSupplier(id: Id) {
      const [data, error] = await ProjectSupplierService.delete(id);

      if(data) {
        this.models.destroy(data.id);

        return data;
      }

      throw error;
    },
  },
});
