<template>
  <div class="my-2.5 space-y-2.5">
    <div class="flex items-center justify-between space-x-4">
      <div class="flex items-center space-x-4 element-island">
        <h1 class="font-semibold text-3xl">
          Settings
        </h1>
      </div>

      <div
        v-if="trades.length === 0 && userStore.isRole(['admin', 'project_admin'])"
        class="element-island"
      >
        <button
          type="button"
          class="btn btn--secondary-blue"
          @click="generateTrades"
        >
          Generate Trades
        </button>
      </div>
    </div>

    <div class="flex">
      <div
        v-if="userStore.isRole(['admin', 'project_admin'])"
        class="card w-1/3 h-1/2 mx-auto"
      >
        <div class="card__header">
          <div class="flex justify-between items-center">
            <p>
              Emails
            </p>

            <button
              type="button"
              class="btn btn--secondary-blue"
              @click="updateProject"
            >
              Update Emails
            </button>
          </div>
        </div>

        <div class="w-full p-4">
          <form
            method="post"
            class="flex flex-col space-y-4"
            @submit.prevent="updateProject"
          >
            <label>
              Contractor Email

              <AppInput
                v-model="projectForm.form.contractor_email"
                :error="projectForm.getErrors('contractor_email')"
                class="form-input"
                type="text"
                name="contractor_email"
              />
            </label>

            <label>
              PQS Firm Email

              <AppInput
                v-model="projectForm.form.pqs_email"
                :error="projectForm.getErrors('pqs_email')"
                class="form-input"
                type="text"
                name="pqs_email"
              />
            </label>
          </form>
        </div>
      </div>

      <div class="card w-1/2 mx-auto">
        <div class="card__header">
          <p>
            Trades
          </p>
        </div>

        <div class="flex flex-col w-full p-4 space-y-4">
          <TradeRow
            v-for="trade in trades"
            :key="trade.id"
            :trade="trade"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TradeRow from '@/components/HeadContractSettings/TradeRow.vue';
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { useProjectsStore } from '@/store/projects';
import { useTradesStore } from '@/store/trades';
import { useUserStore } from '@/store/user';
import Swal from 'sweetalert2';
import { computed, watch } from 'vue';

const props = defineProps<{
  projectId: string;
}>();

const tradesStore = useTradesStore();

watch(() => props.projectId, (newValue) => {
  tradesStore.fetchTrades(newValue);
}, { immediate: true });

const trades = computed(() => {
  return tradesStore.models.where('project_id', parseInt(props.projectId)).with('trade_items').get();
});

const projectsStore = useProjectsStore();
const updateProjectAction = useStoreApiAction(projectsStore.updateProject);

const projectForm = useFormData({
  contractor_email: '',
  pqs_email: '',
});

watch(() => projectsStore.project, (newValue) => {
  if(newValue) {
    projectForm.setData(newValue);
  }
}, { immediate: true });

const updateProject = () => {
  projectForm.resetErrors();

  updateProjectAction.request(props.projectId, projectForm.form).then(() => {
    Swal.fire({
      icon: 'success',
      title: 'Project Updated.',
    });
  }).catch((error) => {
    projectForm.setErrors(error.data);
  });
};

const generateTrades = () => {
  tradesStore.generateTrades(props.projectId).then(() => {
    Swal.fire({
      icon: 'info',
      title: 'Trades Generated',
    }).then(() => {
      window.location.reload();
    });
  });
};

const userStore = useUserStore();
</script>

<style scoped></style>
