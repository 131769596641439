<template>
  <tr
    class="group even:bg-gray-100 cursor-pointer hover:bg-blue-100 odd:bg-white"
    :data-test="`plant-row-${plant.id}`"
    @click="toggleTable"
  >
    <td class="pl-3 py-2 group-last:rounded-bl-lg">
      <div class="flex items-center space-x-1">
        <div
          :class="[{'rotate-90': open}]"
          data-test="open-plant"
        >
          <Icon
            name="chevron-right"
            class="btn-icon h-5 w-5 group-hover:text-gray-700"
          />
        </div>

        <button
          type="button"
          data-test="add-record"
          @click.stop="openRecordCreate"
        >
          <Icon
            name="plus"
            class="btn-icon h-5 w-5"
          />
        </button>

        <button
          type="button"
          data-test="edit"
          @click.stop="$emit('showUpdateModal', plant.id); openTable()"
        >
          <Icon
            name="edit"
            class="btn-icon h-5 w-5"
          />
        </button>

        <div class="flex flex-col w-80 truncate">
          <p
            class="truncate font-semibold"
            data-test="plant-fleet"
          >
            {{ plant.fleet }}
          </p>

          <p class="text-sm truncate">
            {{ plant.description }}
          </p>
        </div>
      </div>
    </td>

    <td
      class="w-max pl-6"
      data-test="plant-supplier-name"
    >
      {{ plant.supplier?.name }}
    </td>

    <td data-test="plant-pay-standby">
      {{ plant.pay_standby ? 'Yes' : 'No' }}
    </td>

    <td
      class="pl-6"
      data-test="plant-on-hired"
      @click.stop="$emit('showOffHired', plant.id)"
    >
      <p>
        {{ plant.on_hired?.onHiredAt?.toLocaleDateString() }}
      </p>

      <p>
        {{ plant.on_hired?.offHiredAt?.toLocaleDateString() }}
      </p>
    </td>

    <td
      class="text-right"
      data-test="plant-rate"
    >
      {{ `${australianCurrency(plant.rate)}${plant.wet ? '/hr' : '/day'}` }}
    </td>

    <td
      class="text-right"
      data-test="plant-total-dry"
    >
      {{ plant.wet ? `${plant.totalHours.toFixed(2)} hrs` : `${plant.totalDays.toFixed(2)} days` }}
    </td>

    <td
      class="text-right"
      data-test="plant-total-accrual"
    >
      {{ australianCurrency(plant.totalAccrual) }}
    </td>

    <td
      class="text-right"
      data-test="plant-paid-to-date"
    >
      {{ australianCurrency(plant.paidToDate) }}
    </td>

    <td
      class="text-right pr-4  group-last:rounded-br-lg"
      data-test="plant-total"
    >
      {{ australianCurrency(plant.paidToDate + plant.totalAccrual) }}
    </td>
  </tr>

  <tr
    v-if="open"
    class="group bg-gray-200 border border-black"
  >
    <td
      colspan="100%"
      class="group-last:rounded-b-lg"
    >
      <Transition
        name="plant-row"
        enter-active-class="animate__animated animate__fadeIn animate__slow"
      >
        <div
          v-if="plantRecords.length"
          ref="recordListRef"
          v-collapsible="{ duration: 1000, show: open && fetchRecordsAction.firstLoad.value}"
          class="max-h-[390px] overflow-y-auto m-2 border border-gray-200 rounded-md shadow-md"
          @scroll="scrolled"
        >
          <table class="w-full border-separate border-spacing-0">
            <thead>
              <tr class="sticky top-0 h-7 bg-white">
                <th class="border-b border-gray-200" />

                <th class="text-left border-b border-gray-200">
                  Totals
                </th>

                <th
                  colspan="6"
                  class="border-b border-gray-200"
                />

                <th
                  class="text-left pl-6 border-b border-gray-200"
                  title="The paid hours after deducting all breaks"
                >
                  {{
                    plant.wet ?
                      `${australianNumber(plant.totalHours)} hrs` :
                      `${australianNumber(plant.totalDays)} days`
                  }}
                </th>

                <th class="text-left border-b border-gray-200">
                  {{ australianNumber(plant.totalStandby) }} hrs
                </th>

                <th class="text-left border-b border-gray-200">
                  {{ australianNumber(plant.totalBreakdown) }} hrs
                </th>

                <th class="border-b border-gray-200" />

                <th class="border-b border-gray-200" />

                <th class="border-b border-gray-200" />
              </tr>

              <tr class="sticky top-7 bg-white border border-gray-200">
                <th class="border-b border-gray-200" />

                <th class="border-b border-gray-200 text-left">
                  Approved By
                </th>

                <th class="border-b border-gray-200 py-2 text-left">
                  Date
                </th>

                <th class="border-b border-gray-200 text-left">
                  WBS
                </th>

                <th
                  class="border-b border-gray-200 text-left"
                  title="The area captured on the Mobile app for the day for that specific WBS item"
                >
                  Area
                </th>

                <th
                  class="border-b border-gray-200 text-left"
                  title="The element captured on the Mobile app for the day for that specific WBS item"
                >
                  Element
                </th>

                <th
                  class="border-b border-gray-200 text-left"
                  title="The activity captured on the Mobile app for the day for that specific WBS item"
                >
                  Activity
                </th>

                <th class="border-b border-gray-200 text-left w-[5%]">
                  {{ props.plant.smu_tracking ? 'SMU Record' : 'Time' }}
                </th>

                <th class="border-b border-gray-200 text-left pl-6">
                  {{ props.plant.smu_tracking ? 'SMU' : 'Hours' }}
                </th>

                <th
                  class="border-b border-gray-200 text-left"
                  title="The plant is on-site but is not currently in use as per the agreement with the supplier"
                >
                  Stand By
                </th>

                <th
                  class="border-b border-gray-200 text-left"
                  title="Time recorded that the plant is not operational and the supplier is to be notified"
                >
                  Breakdowns
                </th>

                <th
                  class="border-b border-gray-200 text-right px-2"
                  title="The cost of recorded days or hours that have not been paid yet"
                >
                  Accrual
                </th>

                <th class="border-b border-gray-200 text-center">
                  Cost Paid
                </th>

                <th
                  class="border-b border-gray-200 text-center"
                  title="The supplier's reference number to either invoice or docket"
                >
                  Vendor Ref
                </th>
              </tr>
            </thead>

            <tbody v-bind="wrapperProps">
              <PlantRowRecord
                v-for="record in plantRecords"
                :key="record.id"
                :record="record"
                :plant="plant"
                :data-test="`plant-record-${record.id}`"
                class="h-[65px]"
                @select-record="selectRecord"
                @show-comments="showRecordComments"
              />
            </tbody>
          </table>
        </div>

        <div
          v-else-if="plantRecords.length === 0 && fetchRecordsAction.is(States.COMPLETE)"
          class="w-full items-center justify-center text-center h-[65px]"
        >
          <div class="font-semibold">
            No records found.
          </div>
        </div>

        <div v-else-if="fetchRecordsAction.requestTime.value > 3 && fetchRecordsAction.is(States.LOADING)">
          <AppSpinner class="w-full flex justify-center items-center h-[60px]" />
        </div>
      </Transition>
    </td>
  </tr>

  <RecordSplitModal
    v-if="showSplit"
    :project-id="plant.project_id"
    :record-id="selectedRecord"
    :plant="plant"
    @close="showSplit = false; fetchIfOpen()"
  />

  <RecordCommentModal
    v-if="showComments"
    :project-id="plant.project_id"
    :record-id="selectedRecord"
    @close="showComments = false"
  />

  <RecordModal
    v-if="show"
    :project-id="plant.project_id"
    :plant="plant"
    :record-id="selectedRecord"
    @split="switchSplit"
    @close="show = false; fetchIfOpen()"
  />
</template>

<script setup lang="ts">
import { useQueue } from '@/composables/useQueue';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { useVirtualList } from '@/composables/useVirtualList';
import { australianCurrency, australianNumber } from '@/helpers';
import Plant from '@/models/Plant';
import PlantDailyRecord from '@/models/PlantDailyRecord';
import { usePlantDailyRecordsStore } from '@/store/plantDailyRecord';
import { usePlantsStore } from '@/store/plants';
import { useCollect } from 'pinia-orm/dist/helpers';
import { computed, nextTick, ref } from 'vue';
import PlantRowRecord from './PlantRowRecord.vue';
import RecordCommentModal from './RecordCommentModal.vue';
import RecordModal from './RecordModal.vue';
import RecordSplitModal from './RecordSplitModal.vue';

const props = defineProps<{
  plant: Plant;
}>();

defineEmits<{
  (e: 'showOffHired', plantId: Id);
  (e: 'showUpdateModal', plantId: Id);
}>();

const show = ref(false);
const selectedRecord = ref();

const selectRecord = (id: Id) => {
  selectedRecord.value = id;
  show.value = true;
};

const showSplit = ref(false);

const switchSplit = () => {
  show.value = false;
  showSplit.value = true;
};

const showComments = ref(false);

const showRecordComments = (id: Id) => {
  selectedRecord.value = id;
  showComments.value = true;
};

const plantRecords = computed(() => {
  return useCollect(props.plant.records).sortBy([['day', 'desc']]);
});

const plantsStore = usePlantsStore();
const totalRecords = ref(0);
const recordIndex = ref(0);
const { wrapperProps } = useVirtualList(totalRecords, recordIndex, 65);
const plantDailyRecordsStore = usePlantDailyRecordsStore();
const recordListRef = ref();
const page = ref(1);
const paginator = ref<ResourcePaginator<PlantDailyRecord>>();
const open = ref(false);
const fetchRecordsAction = useStoreApiAction(plantDailyRecordsStore.fetchRecords);
const recordQueue = useQueue<number>(10);

const initialFetch = () => {
  plantDailyRecordsStore.models.query().where('plant_id', props.plant.id).delete();

  fetchRecordsAction.request(props.plant.id, plantsStore.filter.form).then((newPaginator) => {
    recordQueue.reset();
    totalRecords.value = 0;
    totalRecords.value += newPaginator.data.length;

    recordIndex.value = newPaginator.meta.current_page * newPaginator.meta.per_page;

    recordQueue.enqueueFront(newPaginator.data.map((record: PlantDailyRecord) => {
      return record.id;
    }));

    paginator.value = newPaginator;
  });
};

const openRecordCreate = () => {
  show.value = true;
  selectedRecord.value = undefined;

  if(!open.value) {
    open.value = true;
    initialFetch();
  }
};

const fetchIfOpen = () => {
  if(open.value) {
    nextTick(() => {
      initialFetch();
    });
  }
};

const openTable = () => {
  if(!open.value) {
    nextTick(() => {
      initialFetch();
    });
  }

  open.value = true;
};

const toggleTable = () => {
  open.value = !open.value;

  if(open.value) {
    fetchIfOpen();
  }
};

const scrolled = () => {
  const recordList = recordListRef.value;

  if(recordList) {
    const scrollable = recordList.scrollHeight > recordList.clientHeight;

    if(
      page.value > 1 &&
      scrollable &&
      recordList.scrollTop === 0
    ) {
      fetchRecordsAction.request(props.plant.id, { page: page.value - 1, ...plantsStore.filter.form })
        .then((newPaginator) => {
          paginator.value = newPaginator;

          totalRecords.value -= newPaginator.data.length;
          recordIndex.value = newPaginator.meta.current_page * newPaginator.meta.per_page;

          const recordsToDelete = recordQueue.enqueueBack(newPaginator.data.map((record: PlantDailyRecord) => {
            return record.id;
          }));

          plantDailyRecordsStore.models.query().whereId(recordsToDelete).delete();

          recordList.scrollTop = Math.round(recordList.scrollHeight * 0.1);
          page.value = newPaginator.meta.current_page;
        });
    } else if(
      paginator.value.meta.last_page !== page.value &&
      scrollable &&
      recordList.scrollHeight - recordList.scrollTop === recordList.clientHeight
    ) {
      fetchRecordsAction.request(props.plant.id, { page: page.value + 1, ...plantsStore.filter.form })
        .then((newPaginator) => {
          paginator.value = newPaginator;

          totalRecords.value += newPaginator.data.length;
          recordIndex.value = newPaginator.meta.current_page * newPaginator.meta.per_page;

          const recordsToDelete = recordQueue.enqueueFront(newPaginator.data.map((record: PlantDailyRecord) => {
            return record.id;
          }));

          plantDailyRecordsStore.models.query().whereId(recordsToDelete).delete();

          recordList.scrollTop = Math.round(recordList.scrollHeight * 0.2);
          page.value = newPaginator.meta.current_page;
        });
    }
  }
};
</script>

<style scoped></style>
