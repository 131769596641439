<template>
  <li
    class="card__list-item"
    @click="open = !open"
  >
    <div class="grid grid-cols-3 cursor-pointer">
      <div class="flex space-x-1 items-center">
        <div>
          <Icon
            :class="[{'rotate-90': open}]"
            name="chevron-right"
            class="btn-icon h-5 w-5 group-hover:text-gray-700"
          />
        </div>

        <div>
          {{ user.email }}
        </div>
      </div>

      <div>
        {{ user.name }}
      </div>
    </div>

    <div
      v-if="open"
      :class="{ 'opacity-70 cursor-not-allowed pointer-events-none': detachRoleAction.is(States.LOADING) || attachRoleAction.is(States.LOADING) }"
      @click.stop=""
    >
      <div
        v-if="companyProjectsWithNoRole.length > 0"
        class="flex flex-col items-start"
      >
        <p class="text-lg mt-4 font-semibold">
          Assign Project
        </p>

        <form

          class="flex space-x-4 items-end mt-2"
          @submit.prevent="attachRole"
        >
          <label class="mb-auto">
            Project

            <AppSelect
              v-model="userForm.form.project_id"
              class="form-input pr-8"
              name="project_id"
              :options="companyProjectsWithNoRole"
              :error="userForm.getErrors('project_id')"
            />
          </label>

          <label class="mb-auto">
            Role

            <AppSelect
              v-model="userForm.form.role_id"
              class="form-input"
              name="role_id"
              :options="roles"
              :error="userForm.getErrors('role_id')"
            />
          </label>

          <button
            type="submit"
            class="btn btn--secondary-blue"
            :class="{ 'my-auto': userForm.hasErrors('project_id') || userForm.hasErrors('role_id') }"
            @click.prevent="attachRole"
          >
            Add Project
          </button>
        </form>
      </div>


      <p class="text-lg mt-4 font-semibold">
        Projects
      </p>

      <div class="flex flex-col space-y-2 mt-2">
        <div
          v-for="project in user.projects"
          :key="project.id"
          class="flex space-x-1 items-center"
        >
          <div>
            {{ project.name }} as {{ roleNames.get(project.pivot.role_id).descriptor }}
          </div>

          <button
            type="button"
            @click="detachRole(project)"
          >
            <Icon
              name="trash"
              class="w-5 h-5 btn-icon-red"
            />
          </button>
        </div>
      </div>
    </div>
  </li>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import Company from '@/models/Company';
import Project from '@/models/Project';
import User from '@/models/User';
import { useProjectsStore } from '@/store/projects';
import { useRolesStore } from '@/store/roles';
import { useUserStore } from '@/store/user';
import Swal from 'sweetalert2';
import { computed, ref } from 'vue';

const props = defineProps<{
  user: User;
  company: Company;
}>();

const open = ref(false);
const projectsStore = useProjectsStore();

const companyProjectsWithNoRole = computed(() => {
  return projectsStore.models.query().where('company_id', props.company.id).whereDoesntHave('users', (query) => {
    query.where('id', props.user.id);
  }).get().map((project) => {
    return {
      label: project.name,
      value: project.id,
    };
  });
});

const rolesStore = useRolesStore();

const roles = computed(() => {
  return rolesStore.models.where('name', (name: string) => !['admin', 'company_admin'].includes(name)).get().map(
    (role) => {
      return {
        label: role.descriptor,
        value: role.id,
      };
    },
  );
});

const userForm = useFormData({
  user_id: props.user.id,
  role_id: undefined,
  project_id: undefined,
});

const userStore = useUserStore();
const attachRoleAction = useStoreApiAction(userStore.attachCompanyUserRole);

const attachRole = () => {
  userForm.resetErrors();

  attachRoleAction.request(props.company.id, userForm.form.project_id, userForm.form).then((data) => {
    userForm.resetData(['project_id', 'role_id']);
  }).catch((error) => {
    userForm.setErrors(error.data);
  });
};

const detachRoleAction = useStoreApiAction(userStore.detachCompanyUserRole);

const detachRole = (project: Project) => {
  Swal.fire({
    icon: 'warning',
    title: 'Remove user from project?',
    text: `Are you sure you want to remove ${props.user.name} from ${project.name}?`,
    showConfirmButton: true,
    confirmButtonText: 'Yes',
    confirmButtonColor: 'green',
    showDenyButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      detachRoleAction.request(props.company.id, project.id, userForm.form).catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Failed to detach user.',
        });
      });
    }
  });
};

const roleNames = computed(() => {
  return new Map(rolesStore.models.all().map((role) => [role.id, role]));
});
</script>

<style scoped></style>
