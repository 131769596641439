<template>
  <div class="flex justify-center h-full space-y-2.5 my-2.5 py-8">
    <div
      v-if="project"
      class="flex flex-col border border-gray-300 p-5 rounded-lg items-center h-full bg-white shadow-md container"
    >
      <h1 class="text-4xl">
        {{ project?.name }} Settings
      </h1>


      <div class="w-full flex space-x-4 mt-10">
        <div
          v-if="userStore.isRole(['admin', 'company_admin']) && project?.company_id"
          class="border-x border-t border-gray-300 rounded-t-md p-4 cursor-pointer w-fit"
          :class="{ 'btn btn--primary-blue rounded-b-none': selectedTab === 'company' }"
          data-test="company-tab"
          @click="selectTab('company')"
        >
          Company
        </div>

        <div
          v-if="userStore.isRole(['admin', 'company_admin']) && project?.company_id"
          class="border-x border-t border-gray-300 rounded-t-md p-4 cursor-pointer w-fit"
          :class="{ 'btn btn--primary-blue rounded-b-none': selectedTab === 'company_users' }"
          data-test="company_users-tab"
          @click="selectTab('company_users')"
        >
          Company Users
        </div>

        <div
          class="border-x border-t border-gray-300 rounded-t-md p-4 cursor-pointer w-fit"
          :class="{ 'btn btn--primary-blue rounded-b-none': selectedTab === 'project' }"
          data-test="project-tab"
          @click="selectTab('project')"
        >
          Project
        </div>

        <div
          class="border-x border-t border-gray-300 rounded-t-md p-4 cursor-pointer w-fit"
          :class="{ 'btn btn--primary-blue rounded-b-none': selectedTab === 'head_contract' }"
          data-test="head-contract-tab"
          @click="selectTab('head_contract')"
        >
          Head Contract
        </div>

        <div
          class="border-x border-t border-gray-300 rounded-t-md p-4 cursor-pointer w-fit"
          :class="{ 'btn btn--primary-blue rounded-b-none': selectedTab === 'self_perform' }"
          data-test="self-perform-tab"
          @click="selectTab('self_perform')"
        >
          Self Perform
        </div>
      </div>

      <div class="border border-gray-300 border-t-ccm-blue-100 p-4 rounded-r-md rounded-bl-md border-t-2 container">
        <CompanyTab
          v-if="selectedTab === 'company'"
          :company-id="project?.company_id"
          @dirty="tabIsDirty = true"
          @saved="tabIsDirty = false"
        />

        <CompanyUsersTab
          v-if="selectedTab === 'company_users'"
          :company-id="project?.company_id"
          @dirty="tabIsDirty = true"
          @saved="tabIsDirty = false"
        />

        <ProjectTab
          v-else-if="selectedTab === 'project'"
          :project-id="$props.projectId"
          @dirty="tabIsDirty = true"
          @saved="tabIsDirty = false"
        />

        <SelfPerformTab
          v-else-if="selectedTab === 'self_perform'"
          :project-id="$props.projectId"
          @dirty="tabIsDirty = true"
          @saved="tabIsDirty = false"
        />

        <HeadContractTab
          v-else-if="selectedTab === 'head_contract'"
          :project-id="$props.projectId"
          @dirty="tabIsDirty = true"
          @saved="tabIsDirty = false"
        />
      </div>
    </div>

    <div v-else>
      <AppSpinner />
    </div>
  </div>
</template>

<script setup lang="ts">
import CompanyTab from '@/components/ProjectSettings/CompanyTab/CompanyTab.vue';
import CompanyUsersTab from '@/components/ProjectSettings/CompanyUsersTab/CompanyUsersTab.vue';
import HeadContractTab from '@/components/ProjectSettings/HeadContractTab/HeadContractTab.vue';
import ProjectTab from '@/components/ProjectSettings/ProjectTab/ProjectTab.vue';
import SelfPerformTab from '@/components/ProjectSettings/SelfPerformTab/SelfPerformTab.vue';
import { useFormData } from '@/composables/useFormData';
import { timeForInput } from '@/helpers';
import { useProjectsStore } from '@/store/projects';
import { useUserStore } from '@/store/user';
import Swal from 'sweetalert2';
import { computed, ref, watch } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';

const props = defineProps<{
  projectId: string;
}>();

const projectsStore = useProjectsStore();

const project = computed(() => {
  return projectsStore.models.find(parseInt(props.projectId));
});

const projectForm = useFormData({
  name: '',
  labour_standard_shift: 0,
  plant_standard_shift: 0,
  maximum_shift: 0,
  shift_start_at: '',
  wbs_codes: [],
  activities: [],
  elements: [],
  areas: [],
});

watch(project, (newValue) => {
  if(newValue) {
    projectForm.form.name = newValue.name;
    projectForm.form.wbs_codes = newValue.wbs_codes.slice();
    projectForm.form.activities = newValue.activities.slice();
    projectForm.form.elements = newValue.elements.slice();
    projectForm.form.areas = newValue.areas.slice();
    projectForm.form.labour_standard_shift = newValue.labour_standard_shift;
    projectForm.form.plant_standard_shift = newValue.plant_standard_shift;
    projectForm.form.maximum_shift = newValue.maximum_shift;
    projectForm.form.shift_start_at = timeForInput(newValue.shiftStartAt);
  }
}, { immediate: true });

const route = useRoute();
const router = useRouter();
const tabs = ref(['company', 'project', 'self_perform', 'head_contract', 'company_users']);
const selectedTab = ref<typeof tabs.value[number]>(route.hash ? route.hash.slice(1) : 'project');
const tabIsDirty = ref(false);

const selectTab = (tab: typeof tabs.value[number]) => {
  if(tabIsDirty.value) {
    Swal.fire({
      icon: 'warning',
      title: 'Save Changes',
      text: 'Any unsaved changes will be discarded, ensure you have saved your changes. Press \'ok\' to continue.',
      showCancelButton: true,
      showConfirmButton: true,
    }).then((result) => {
      if(!result.isDismissed) {
        selectedTab.value = tab;
      }
    });
  } else {
    selectedTab.value = tab;
  }
};

watch(selectedTab, (newValue) => {
  router.push({ hash: `#${newValue}` });
  tabIsDirty.value = false;
}, { immediate: true });

onBeforeRouteLeave(async (_to, _from) => {
  if(tabIsDirty.value) {
    const result = await Swal.fire({
      icon: 'warning',
      title: 'Save Changes',
      text: 'Any unsaved changes will be discarded, ensure you have saved your changes. Press \'ok\' to continue.',
      showCancelButton: true,
      showConfirmButton: true,
    });

    if(result.isDismissed) {
      return false;
    }
  }
});

const userStore = useUserStore();
</script>

<style scoped></style>
