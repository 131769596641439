<template>
  <tr
    class="group even:bg-gray-100 odd:bg-white text-right"
  >
    <td class="py-4">
      {{ australianNumber($props.wbsCode.days) }}
    </td>

    <td class="pr-2">
      {{ australianNumber($props.toComplete.toDateDays) }}
    </td>

    <td class="pr-4">
      {{ australianNumber($props.toComplete.toCompleteDays) }}
    </td>

    <td>
      {{ australianNumber($props.wbsCode.tender_production_rate) }}{{ $props.wbsCode.unit }}/day
    </td>

    <td class="pr-2">
      {{ australianNumber($props.toComplete.toDateProductionRate) }}{{ $props.wbsCode.unit }}/day
    </td>

    <td
      v-if="typingProductionRate"
      class="pr-4"
    >
      <div class="flex items-center justify-end">
        <form
          action=""
          @submit.prevent="updateWbsCode"
        >
          <AppInput
            ref="productionRateInput"
            v-model="toCompleteProductionRate"
            name="to_complete_production_rate"
            type="number"
            step="any"
            min="0"
            data-test="wbs-code-final-quantity"
            title="The forecasted production rate to complete the project, inserted by the user"
            @blur="updateWbsCode"
            @keyup.enter="updateWbsCode"
            @keyup.esc="typingProductionRate = false"
          />
        </form>
      </div>
    </td>

    <td
      v-else
      class="text-right pr-4 cursor-pointer"
      data-test="record-paid-text"
      @click.stop="toggletypingProductionRate"
    >
      <div class=" flex justify-end items-center">
        <Icon
          name="edit"
          class="group-hover:visible btn-icon h-5 w-5 inline-block invisible"
        />
        {{ australianNumber($props.toComplete.toCompleteProductionRate) }}{{ $props.wbsCode.unit }}/day
      </div>
    </td>

    <td>
      {{ australianNumber($props.wbsCode.tender_quantity) }}{{ $props.wbsCode.unit }}
    </td>

    <td class="pr-2">
      {{ australianNumber($props.toComplete.toDateQuantity) }}{{ $props.wbsCode.unit }}
    </td>

    <td
      class="pr-4"
      :class="{ 'bg-red-400/40': $props.toComplete.toCompleteQuantity < 0 }"
    >
      {{ australianNumber($props.toComplete.toCompleteQuantity) }}{{ $props.wbsCode.unit }}
    </td>
  </tr>
</template>

<script setup lang="ts">
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianNumber } from '@/helpers';
import WbsCode from '@/models/WbsCode';
import { useWbsCodesStore } from '@/store/wbsCodes';
import { nextTick, ref } from 'vue';

const props = defineProps<{
  wbsCode: WbsCode;
  toComplete: CostAtCompletion;
}>();

const typingProductionRate = ref(false);
const wbsCodesStore = useWbsCodesStore();
const saveProductionRate = useStoreApiAction(wbsCodesStore.updateCode);

const toCompleteProductionRate = ref(
  props.wbsCode.to_complete_production_rate || Math.round(props.toComplete.toCompleteProductionRate),
);

const updateWbsCode = () => {
  if(toCompleteProductionRate.value > 0) {
    saveProductionRate
      .request(props.wbsCode.id, { to_complete_production_rate: toCompleteProductionRate.value })
      .then(() => {
        typingProductionRate.value = false;
      });
  } else {
    toCompleteProductionRate.value = props.wbsCode.to_complete_production_rate ||
      Math.round(props.toComplete.toCompleteProductionRate);

    typingProductionRate.value = false;
  }
};

const productionRateInput = ref();

const toggletypingProductionRate = () => {
  typingProductionRate.value = !typingProductionRate.value;

  if(typingProductionRate.value) {
    nextTick(() => {
      productionRateInput.value?.focusInput();
    });
  }
};
</script>

<style scoped></style>
