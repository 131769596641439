<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2 px-10">
        <h1 class="text-2xl">
          Plant Utilisation Report
        </h1>
      </div>
    </template>

    <template #body>
      <div class="flex justify-center w-full flex-col space-y-4 p-4">
        <label>
          Start

          <AppInput
            v-model="reportStart"
            name="start"
            type="date"
            data-test="start"
            class="form-input"
          />
        </label>

        <label>
          End

          <AppInput
            v-model="reportEnd"
            name="end"
            type="date"
            data-test="end"
            class="form-input"
          />
        </label>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-between p-4">
        <button
          type="button"
          class="btn btn--secondary-blue"
          @click.prevent="$emit('close')"
        >
          Cancel
        </button>

        <a
          v-if="reportStart && reportEnd && reportStart < reportEnd"
          :href="reportUrl"
          target="_blank"
          class="btn btn--primary-blue ml-2"
          @click="$emit('close')"
        >
          Get Report
        </a>

        <button
          v-else
          type="button"
          class="btn btn--primary-blue ml-2"
          disabled
        >
          Get Report
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { useProjectsStore } from '@/store/projects';
import { computed, ref } from 'vue';
import Modal from '../Modal.vue';

defineEmits<{
  (e: 'close');
}>();

const reportStart = ref();
const reportEnd = ref();
const projectsStore = useProjectsStore();

const reportUrl = computed(() => {
  return `/api/v1/projects/${projectsStore.project?.id}/plant-utilisation?start=${reportStart.value}&end=${reportEnd.value}`;
});
</script>

<style scoped></style>
