<template>
  <div class="flex bg-white shadow-md h-16 p-3 fixed w-full z-40">
    <div class="space-x-1 items-center hidden lg:flex">
      <RouterLink
        :to="{ name: 'ProjectSelector' }"
        class="flex items-center space-x-1 mr-4 min-w-max"
      >
        <img
          src="/images/logo-clear.png"
          class="h-6"
        >

        <h1 class="font-bold text-3xl">
          CCM
        </h1>
      </RouterLink>

      <a
        v-if="userStore.authenticated && userStore.isRole(['admin'])"
        class="min-w-max btn btn--ghost-default"
        href="/admin"
      >
        Admin
      </a>

      <LinkGroup
        v-if="userStore.authenticated && projectStore.selectedProject && hasHeadContractAccess"
        title="Head Contract"
      >
        <template #button="{ show }">
          <RouterLink
            v-slot="{ isActive }"
            :to="{ name: 'HeadContractDefault', params: { projectId: projectStore.selectedProject } }"
          >
            <button
              type="button"
              class="btn flex items-center"
              :class="[isActive ? 'btn--primary-blue' : 'btn--ghost-default', !isActive && show ? 'bg-gray-300 hover:bg-gray-300 hover:bg-opacity-100' : '']"
            >
              Head Contract
              <Icon
                name="chevron-down"
                class="w-4 ml-2"
              />
            </button>
          </RouterLink>
        </template>

        <template #default>
          <RouterLink
            v-if="userStore.hasAccessToRoute('BillOfQuantities')"
            class="font-bold"
            :to="{ name: 'BillOfQuantities', params: { projectId: projectStore.selectedProject } }"
          >
            Bill of Quantities
          </RouterLink>

          <RouterLink
            v-if="userStore.hasAccessToRoute('CashFlowBaseline') && canSeeBaseline"
            class="font-bold"
            :to="{ name: 'CashFlowBaseline', params: { projectId: projectStore.selectedProject } }"
          >
            Baseline Forecasting
          </RouterLink>

          <!-- <RouterLink
            v-if="userStore.hasAccessToRoute('ProjectSummary')"
            class="font-bold"
            :to="{ name: 'ProjectSummary', params: { projectId: projectStore.selectedProject } }"
          >
            Project Summary
          </RouterLink> -->

          <RouterLink
            v-if="userStore.hasAccessToRoute('CashflowForecasting')"
            class="font-bold"
            :class="{ 'opacity-50 cursor-not-allowed': !canSeeForecasting }"
            :title="projectStore.project?.boq_locked ? '' : 'You must lock the BoQ before forecasting'"
            :to="projectStore.project?.boq_locked ? { name: 'CashflowForecasting', params: { projectId: projectStore.selectedProject } } : {}"
          >
            Cashflow Forecasting
          </RouterLink>

          <RouterLink
            v-if="userStore.hasAccessToRoute('ProgressClaimsIndex')"
            class="font-bold"
            :class="{ 'opacity-50 cursor-not-allowed': !canSeeCashflow }"
            :title="projectStore.project?.boq_locked ? '' : 'You must lock the BoQ to create progress claims'"
            :to="projectStore.project?.boq_locked ? { name: 'ProgressClaimsIndex', params: { projectId: projectStore.selectedProject } } : {}"
          >
            Progress Claims
          </RouterLink>

          <RouterLink
            v-if="userStore.hasAccessToRoute('CashFlow')"
            class="font-bold"
            :class="{ 'opacity-50 cursor-not-allowed': !canSeeCashflow }"
            :title="projectStore.project?.boq_locked ? '' : 'You must lock the BoQ to view the cashflow'"
            :to="projectStore.project?.boq_locked ? { name: 'CashFlow', params: { projectId: projectStore.selectedProject } } : {}"
          >
            Cashflow
          </RouterLink>
        </template>
      </LinkGroup>

      <button
        v-if="userStore.authenticated && projectStore.selectedProject"
        type="button"
        class="btn flex items-center cursor-not-allowed btn--ghost-default"
        disabled
      >
        Sub Contract
        <Icon
          name="chevron-down"
          class="w-4 ml-2"
        />
      </button>

      <LinkGroup
        v-if="userStore.authenticated && projectStore.selectedProject && hasSelfPerformAccess"
        title="Self Perform"
      >
        <template #button="{ show }">
          <RouterLink
            v-slot="{ isActive }"
            :to="{ name: 'SelfPerformDefault', params: { projectId: projectStore.selectedProject } }"
          >
            <button
              type="button"
              class="btn flex items-center"
              :class="[isActive ? 'btn--primary-blue' : 'btn--ghost-default', !isActive && show ? 'bg-gray-300 hover:bg-gray-300 hover:bg-opacity-100' : '']"
            >
              Self Perform

              <Icon
                name="chevron-down"
                class="w-4 ml-2"
              />
            </button>
          </RouterLink>
        </template>

        <template #default>
          <RouterLink
            v-if="userStore.hasAccessToRoute('LabourDashboard')"
            :to="{ name: 'LabourDashboard', params: { projectId: projectStore.selectedProject }}"
            class="font-bold"
          >
            Labour Dashboard
          </RouterLink>

          <RouterLink
            v-if="userStore.hasAccessToRoute('PlantDashboard')"
            :to="{ name: 'PlantDashboard', params: { projectId: projectStore.selectedProject }}"
            class="font-bold"
          >
            Plant Dashboard
          </RouterLink>

          <RouterLink
            v-if="userStore.hasAccessToRoute('ProjectDailyRates')"
            class="font-bold"
            :to="{ name: 'ProjectDailyRates', params: { projectId: projectStore.selectedProject } }"
          >
            Daily Production Rates
          </RouterLink>

          <RouterLink
            v-if="userStore.hasAccessToRoute('Quantities')"
            class="font-bold"
            :to="{ name: 'Quantities', params: { projectId: projectStore.selectedProject } }"
          >
            Quantities
          </RouterLink>

          <RouterLink
            v-if="userStore.hasAccessToRoute('LineChart')"
            class="font-bold"
            :to="{ name: 'LineChart', params: { projectId: projectStore.selectedProject } }"
          >
            Production Rate Dashboard
          </RouterLink>

          <RouterLink
            v-if="userStore.hasAccessToRoute('CostAtComplete')"
            class="font-bold"
            :to="{ name: 'CostAtComplete', params: { projectId: projectStore.selectedProject } }"
          >
            Cost At Completion
          </RouterLink>
        </template>
      </LinkGroup>
    </div>

    <div class="lg:hidden flex space-x-4 w-3/4">
      <button
        v-if="!routeLocation.meta.hideBackButton && routeLocation.name !== 'Login' && canNavigateBack"
        type="button"
        class="lg:hidden"
        @click="navigateBack"
      >
        <Icon
          name="chevron-left"
          class="h-6 w-6"
        />
      </button>

      <div class="flex flex-col justify-center leading-none -mt-1 w-full">
        <p class="font-bold text-xl overflow-ellipsis whitespace-nowrap overflow-hidden">
          {{ routeProject?.name || route.meta.title || 'Self Perform' }}
        </p>

        <p
          v-if="!!routeProject"
          class="text-xs"
        >
          Self Perform
        </p>
      </div>
    </div>

    <div class="flex ml-auto items-center space-x-4">
      <LinkGroup
        v-if="userStore.authenticated && projectStore.selectedProject && projects.length > 1 && routeLocation.name !== 'ProjectSelector'"
        :title="projectsStore.project?.name || ''"
        enable-click
        class="hidden lg:block"
      >
        <template #button="{ show }">
          <div
            class="btn btn--ghost-default cursor-pointer flex items-center hover:bg-gray-300 hover:bg-opacity-100 text-lg"
            :class="[show ? 'bg-gray-300 bg-opacity-100' : '']"
          >
            Project:
            {{ projectsStore.project?.name }}
            <Icon
              name="chevron-down"
              class="w-4 ml-2"
            />
          </div>
        </template>

        <template #default="{ close }">
          <button
            v-for="project in projects"
            :key="project.id"
            class="font-semibold text-left w-max"
            type="button"
            @click="projectsStore.setProject(project.id, true); close();"
          >
            {{ project.name }}
          </button>
        </template>
      </LinkGroup>

      <p
        v-else-if="userStore.authenticated && projectStore.selectedProject"
        class="font-semibold text-lg px-4 min-w-max hidden lg:block"
      >
        Project:
        {{ projectsStore.project?.name }}
      </p>

      <RouterLink
        v-if="userStore.authenticated && projectStore.selectedProject && hasSettingsAccess"
        v-slot="{ isActive }"
        :to="{
          name: 'ProjectSettings', params: { projectId: projectStore.selectedProject }, hash: settingsTab
        }"
        class="hidden lg:inline-block"
      >
        <div
          class="p-0.5 rounded-lg hover:bg-gray-300"
          :class="{ 'bg-gray-300/50 hover:bg-gray-300/50': isActive }"
        >
          <Icon
            name="cog-6-tooth"
            class="h-6 w-6 "
          />
        </div>
      </RouterLink>

      <RouterLink
        v-if="userStore.authenticated"
        v-slot="{ isActive }"
        :to="{ name: 'ProjectSelector' }"
        class="hidden lg:inline-block"
      >
        <div
          class="p-0.5 rounded-lg hover:bg-gray-300"
          :class="{ 'bg-gray-300/50 hover:bg-gray-300/50': isActive }"
        >
          <Icon
            name="home"
            class="h-6 w-6"
            :class="{ 'bg-gray-300/500': isActive }"
          />
        </div>
      </RouterLink>

      <RouterLink
        v-if="userStore.authenticated"
        v-slot="{ isActive }"
        :to="{ name: 'MobileProjectList' }"
        class="lg:hidden"
      >
        <div
          class="p-0.5 rounded-lg hover:bg-gray-300"
          :class="{ 'bg-gray-300/50 hover:bg-gray-300/50': isActive }"
        >
          <Icon
            name="home"
            class="h-6 w-6"
            :class="{ 'bg-gray-300/500': isActive }"
          />
        </div>
      </RouterLink>

      <template v-if="!routeLocation.meta.hideLogin">
        <RouterLink
          v-if="!userStore.authenticated && routeLocation.name !== 'Login'"
          :to="{ name: 'Login'}"
        >
          Login
        </RouterLink>

        <form
          v-else-if="routeLocation.name !== 'Login'"
          method="post"
          action="/logout"
          @submit.prevent="logout"
        >
          <button
            :disabled="loggingOut"
            type="submit"
            class=" disabled:bg-gray-400 flex items-center"
            data-test="nav-logout"
            @click.stop
          >
            <Icon
              name="logout"
              class="h-6 w-6 rotate-180"
            />
          </button>
        </form>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { baselineBoqLock, cashflowAvailable, forecastingAvailable } from '@/router/nav-guards';
import { useNavStore } from '@/store/nav';
import { useProjectsStore } from '@/store/projects';
import { useUserStore } from '@/store/user';
import Swal from 'sweetalert2';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import LinkGroup from './LinkGroup.vue';

const loggingOut = ref(false);
const userStore = useUserStore();
const projectStore = useProjectsStore();

const logout = async () => {
  Swal.fire({
    icon: 'question',
    title: 'Sign out?',
    showConfirmButton: true,
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      loggingOut.value = true;

      userStore.signOut().then(() => {
        window.location.href = '/';
      }).catch(() => {
        loggingOut.value = false;
      });
    }
  });
};

const routeLocation = useRoute();
const showProject = ref(false);
const projectsStore = useProjectsStore();
const router = useRouter();
const navStore = useNavStore();

const navigateBack = () => {
  const backRoute = navStore.consumeBackRoute();

  if(backRoute) {
    router.replace(backRoute);
  } else {
    router.back();
  }
};

const projects = computed(() => {
  return projectsStore.models.all();
});

const route = useRoute();

const routeProject = computed(() => {
  if(route.params.projectId) {
    return projectStore.models.find(parseInt(route.params.projectId as string));
  }

  return undefined;
});

const settingsTab = computed(() => {
  switch (route.matched[0]?.name) {
    case 'SelfPerformParent':
      return '#self_perform';

    case 'HeadContractParent':
      return '#head_contract';
  }

  return undefined;
});

const canNavigateBack = computed(() => {
  return !(route.name === 'MobileProjectPage' && projects.value.length === 1);
});

const hasHeadContractAccess = computed(() => {
  return [
    'HeadContractDefault',
    'ProgressClaims',
  ].some((route) => userStore.hasAccessToRoute(route));
});

const hasSelfPerformAccess = computed(() => {
  return [
    'SelfPerformDefault',
    'LabourDashboard',
    'PlantDashboard',
    'ProjectDailyRates',
    'Quantities',
    'LineChart',
    'CostAtComplete',
  ].some((route) => userStore.hasAccessToRoute(route));
});

const hasSettingsAccess = computed(() => {
  return userStore.hasAccessToRoute('ProjectSettings');
});

const canSeeBaseline = computed(() => {
  return baselineBoqLock();
});

const canSeeForecasting = computed(() => {
  return forecastingAvailable();
});

const canSeeCashflow = computed(() => {
  return cashflowAvailable();
});
</script>

<style scoped></style>
