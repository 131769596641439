<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          Split Record
        </h1>
      </div>
    </template>

    <template #body>
      <div class="flex h-full w-full p-4 overflow-y-auto">
        <div class="flex flex-col w-full my-2 px-3">
          <form
            action=""
            class="w-full space-y-2"
            @submit.prevent="saveRecord"
          >
            <h2 class="w-full text-center font-bold text-lg">
              Original
            </h2>

            <fieldset class="space-y-2">
              <legend class="flex justify-between font-bold w-full">
                <span>Time Details</span>

                <span>{{ oldTotalTime ? `${oldTotalTime.toFixed(1)} hrs` : '' }}</span>
              </legend>


              <label class="flex items-center justify-between">
                Start Time
                <AppInput
                  v-model="recordForm.form.old.start_at"
                  name="start_at"
                  type="time"
                  data-test="old-start_at"
                  :error="recordForm.getErrors('old.start_at')"
                />
              </label>

              <label class="flex items-center justify-between">
                End Time
                <AppInput
                  v-model="recordForm.form.old.end_at"
                  name="end_at"
                  :type="oldEndAtType"
                  data-test="old-end_at"
                  :error="recordForm.getErrors('old.end_at')"
                />
              </label>

              <label
                v-if="showMinorBreak"
                class="flex items-center justify-between"
              >
                Smoke Break (15min)

                <input
                  v-model="recordForm.form.old.minor_break"
                  type="checkbox"
                  name="minor_break"
                  data-test="minor_break"
                >
              </label>

              <label
                v-if="showMajorBreak"
                class="flex items-center justify-between"
              >
                Lunch Break (30min)

                <input
                  v-model="recordForm.form.old.major_break"
                  type="checkbox"
                  name="major_break"
                  data-test="major_break"
                >
              </label>

              <label class="flex items-center justify-between">
                Apply Public Holiday Rate

                <input
                  v-model="recordForm.form.old.is_public_holiday"
                  type="checkbox"
                  name="is_public_holiday"
                  data-test="is_public_holiday"
                >
              </label>
            </fieldset>

            <fieldset class="space-y-2">
              <legend class="font-bold">
                Location Details
              </legend>


              <label class="flex items-center justify-between">
                Area

                <AppSelect
                  v-model="recordForm.form.old.area"
                  :options="project?.areas"
                  name="area"
                  data-test="old-area"
                  :error="recordForm.getErrors('old.area')"
                  class="ml-2"
                />
              </label>

              <label class="flex items-center justify-between">
                Element

                <AppSelect
                  v-model="recordForm.form.old.element"
                  :options="project?.elements"
                  name="element"
                  data-test="old-element"
                  :error="recordForm.getErrors('old.element')"
                  class="ml-2"
                />
              </label>

              <label class="flex items-center justify-between">
                Activity

                <AppSelect
                  v-model="recordForm.form.old.activity"
                  :options="project?.activities"
                  name="activity"
                  data-test="old-activity"
                  :error="recordForm.getErrors('old.activity')"
                />
              </label>

              <label class="flex items-center justify-between">
                WBS Code

                <AppSelect
                  v-model.number="recordForm.form.old.wbs_code_id"
                  name="wbs_code"
                  data-test="old-wbs_code"
                  :options="projectsStore.project?.codes.map((code) => ({ value: code.id, label: code.code }))"
                  :error="recordForm.getErrors('old.wbs_code_id')"
                />
              </label>

              <!-- <label class="flex items-center justify-between">
                Off-hire
                <input
                  v-model="recordForm.form.old.off_hire"
                  type="checkbox"
                  name="off_hire"
                  data-test="old-off_hire"
                  @change="checkOffhire"
                >
              </label> -->
            </fieldset>
          </form>
        </div>

        <div class="flex flex-col w-full my-2 px-3">
          <form
            action=""
            class="w-full space-y-2"
            @submit.prevent="saveRecord"
          >
            <h2 class="w-full text-center font-bold text-lg">
              New
            </h2>

            <fieldset class="space-y-2">
              <legend class="flex justify-between font-bold w-full">
                <span>Time Details</span>

                <span>{{ newTotalTime ? `${newTotalTime.toFixed(1)} hrs` : '' }}</span>
              </legend>


              <label class="flex items-center justify-between">
                Start Time
                <AppInput
                  v-model="recordForm.form.new.start_at"
                  name="start_at"
                  type="time"
                  data-test="new-start_at"
                  :error="recordForm.getErrors('new.start_at')"
                />
              </label>

              <label class="flex items-center justify-between">
                End Time
                <AppInput
                  v-model="recordForm.form.new.end_at"
                  name="end_at"
                  :type="newEndAtType"
                  data-test="new-end_at"
                  :error="recordForm.getErrors('new.end_at')"
                />
              </label>

              <label
                v-if="showMinorBreak"
                class="flex items-center justify-between"
              >
                Smoke Break (15min)

                <input
                  v-model="recordForm.form.new.minor_break"
                  type="checkbox"
                  name="minor_break"
                  data-test="minor_break"
                >
              </label>

              <label
                v-if="showMajorBreak"
                class="flex items-center justify-between"
              >
                Lunch Break (30min)

                <input
                  v-model="recordForm.form.new.major_break"
                  type="checkbox"
                  name="major_break"
                  data-test="major_break"
                >
              </label>

              <label class="flex items-center justify-between">
                Apply Public Holiday Rate

                <input
                  v-model="recordForm.form.new.is_public_holiday"
                  type="checkbox"
                  name="is_public_holiday"
                  data-test="is_public_holiday"
                >
              </label>
            </fieldset>

            <fieldset class="space-y-2">
              <legend class="font-bold">
                Location Details
              </legend>


              <label class="flex items-center justify-between">
                Area

                <AppSelect
                  v-model="recordForm.form.new.area"
                  :options="project?.areas"
                  name="area"
                  data-test="new-area"
                  :error="recordForm.getErrors('new.area')"
                />
              </label>

              <label class="flex items-center justify-between">
                Element

                <AppSelect
                  v-model="recordForm.form.new.element"
                  :options="project?.elements"
                  name="element"
                  data-test="new-element"
                  :error="recordForm.getErrors('new.element')"
                  class="ml-2"
                />
              </label>

              <label class="flex items-center justify-between">
                Activity

                <AppSelect
                  v-model="recordForm.form.new.activity"
                  :options="project?.activities"
                  name="activity"
                  data-test="new-activity"
                  :error="recordForm.getErrors('new.activity')"
                />
              </label>

              <label class="flex items-center justify-between">
                WBS Code

                <AppSelect
                  v-model.number="recordForm.form.new.wbs_code_id"
                  name="wbs_code"
                  data-test="new-wbs_code"
                  :options="projectsStore.project?.codes.map((code) => ({ value: code.id, label: code.code }))"
                  :error="recordForm.getErrors('new.wbs_code_id')"
                  :disabled-value="0"
                />
              </label>
            </fieldset>
          </form>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end p-4">
        <div>
          <button
            type="button"
            class="btn btn--secondary-blue"
            @click.prevent="$emit('close')"
          >
            Cancel
          </button>

          <button
            type="button"
            class="btn btn--primary-blue ml-2"
            data-test="split-record"
            @click="saveRecord"
          >
            Split
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { dateForInput, timeForInput, timeZone } from '@/helpers';
import { useLabourDailyRecordStore } from '@/store/labourDailyRecord';
import { useProjectsStore } from '@/store/projects';
import { computed, nextTick, ref, watch } from 'vue';
import Modal from '../Modal.vue';

const props = defineProps<{
  projectId: Id;
  recordId: Id;
}>();

const emits = defineEmits<{
  (e: 'close');
}>();

const projectsStore = useProjectsStore();

const project = computed(() => {
  return projectsStore.models.find(props.projectId);
});

const recordForm = useFormData({
  new: {
    day: '',
    start_at: '',
    end_at: '',
    area: '',
    element: '',
    wbs_code_id: undefined,
    activity: '',
    minor_break: false,
    major_break: false,
    is_public_holiday: false,
    time_zone: timeZone,
  },

  old: {
    day: '',
    start_at: '',
    end_at: '',
    area: '',
    element: '',
    wbs_code_id: undefined,
    activity: '',
    minor_break: false,
    major_break: false,
    is_public_holiday: false,
    time_zone: timeZone,
  },
});

const labourDailyRecordsStore = useLabourDailyRecordStore();
const oldEndAtType = ref<'time' | 'datetime-local'>('time');

watch(oldEndAtType, () => {
  recordForm.form.old.end_at = '';
});

const newEndAtType = ref<'time' | 'datetime-local'>('time');

watch(newEndAtType, () => {
  recordForm.form.new.end_at = '';
});

const record = computed(() => {
  return labourDailyRecordsStore.models.with('comments').with('labour').find(props.recordId);
});

labourDailyRecordsStore.fetchRecord(props.recordId).then(() => {
  labourDailyRecordsStore.models.with('comments').load([record.value]);
});

watch(record, (newValue) => {
  if(newValue) {
    recordForm.form.old.start_at = timeForInput(newValue.startAt);
    const hour = newValue.startAt.hour;

    if(hour >= 17) {
      oldEndAtType.value = 'datetime-local';

      nextTick(() => {
        recordForm.form.old.end_at = dateForInput(newValue.endAt);
      });
    } else {
      oldEndAtType.value = 'time';

      nextTick(() => {
        recordForm.form.old.end_at = timeForInput(newValue.endAt);
      });
    }

    recordForm.form.new.start_at = timeForInput(newValue.endAt);
    const end = newValue.endAt.plus({ hours: projectsStore.project?.labour_standard_shift });

    if(hour >= 17) {
      newEndAtType.value = 'datetime-local';

      nextTick(() => {
        recordForm.form.new.end_at = dateForInput(end);
      });
    } else {
      newEndAtType.value = 'time';

      nextTick(() => {
        recordForm.form.new.end_at = timeForInput(end);
      });
    }

    recordForm.setData({
      old: newValue,
      new: {
        day: newValue.day,
      },
    }, [
      'old.start_at',
      'old.end_at',
      'old.time_zone',
      'new.time_zone',
    ]);
  }
}, { immediate: true });

watch(() => recordForm.form.old.start_at, (newValue, oldValue) => {
  if(newValue) {
    const hourSplit = newValue.split(':');
    const hour = parseInt(hourSplit[0]);

    if(hour >= 17) {
      oldEndAtType.value = 'datetime-local';

      nextTick(() => {
        if(!oldValue && !recordForm.form.old.end_at) {
          const endAt = new Date(record?.value.day);
          const hourSplit = newValue.split(':');

          endAt.setHours(parseInt(hourSplit[0]));
          endAt.setMinutes(parseInt(hourSplit[1]));
          recordForm.form.old.end_at = dateForInput(endAt);
        }
      });
    } else {
      oldEndAtType.value = 'time';

      nextTick(() => {
        if(!oldValue && !recordForm.form.old.end_at) {
          const endAt = new Date(record?.value.day);
          const hourSplit = newValue.split(':');

          endAt.setHours(parseInt(hourSplit[0]));
          endAt.setMinutes(parseInt(hourSplit[1]));
          recordForm.form.old.end_at = timeForInput(endAt);
        }
      });
    }
  }
});

watch(() => recordForm.form.new.start_at, (newValue, oldValue) => {
  if(newValue) {
    const hourSplit = newValue.split(':');
    const hour = parseInt(hourSplit[0]);

    if(hour >= 17) {
      newEndAtType.value = 'datetime-local';

      nextTick(() => {
        if(!oldValue && !recordForm.form.new.end_at) {
          const endAt = new Date(record?.value.day);
          const hourSplit = newValue.split(':');

          endAt.setHours(parseInt(hourSplit[0]));
          endAt.setMinutes(parseInt(hourSplit[1]));
          recordForm.form.new.end_at = dateForInput(endAt);
        }
      });
    } else {
      newEndAtType.value = 'time';

      nextTick(() => {
        if(!oldValue && !recordForm.form.new.end_at) {
          const endAt = new Date(record?.value.day);
          const hourSplit = newValue.split(':');

          endAt.setHours(parseInt(hourSplit[0]));
          endAt.setMinutes(parseInt(hourSplit[1]));
          recordForm.form.new.end_at = timeForInput(endAt);
        }
      });
    }
  }
});

const oldTotalTime = computed(() => {
  if(recordForm.form.old.start_at && recordForm.form.old.end_at) {
    let endAt: Date = undefined;

    if(oldEndAtType.value === 'time') {
      endAt = new Date(`${record?.value.day} ${recordForm.form.old.end_at}`);
    } else {
      endAt = new Date(recordForm.form.old.end_at);
    }

    const timeDiff = Math.abs(
      new Date(`${record?.value.day} ${recordForm.form.old.start_at}`).getTime() -
        endAt.getTime(),
    ) / 3600000;

    return (Math.round(timeDiff * 2) / 2);
  }

  return undefined;
});

const newTotalTime = computed(() => {
  if(recordForm.form.new.start_at && recordForm.form.new.end_at) {
    let endAt: Date = undefined;

    if(newEndAtType.value === 'time') {
      endAt = new Date(`${record?.value.day} ${recordForm.form.new.end_at}`);
    } else {
      endAt = new Date(recordForm.form.new.end_at);
    }

    const timeDiff = Math.abs(
      new Date(`${record?.value.day} ${recordForm.form.new.start_at}`).getTime() -
        endAt.getTime(),
    ) / 3600000;

    return (Math.round(timeDiff * 2) / 2);
  }

  return undefined;
});

const splitRecordAction = useStoreApiAction(labourDailyRecordsStore.splitRecord);

const saveRecord = () => {
  recordForm.resetErrors();

  splitRecordAction
    .request(record.value.id, recordForm.form, { newDay: recordForm.form.new.day, oldDay: recordForm.form.old.day })
    .then(() => {
      emits('close');
    })
    .catch((error) => {
      console.log(error);

      recordForm.setErrors(error.data);
    });
};

const showMinorBreak = computed(() => {
  let dailyTime = (oldTotalTime.value + newTotalTime.value) || 0;
  let minorBreak = false;

  if(record.value?.minor_break) {
    return true;
  }

  const records = labourDailyRecordsStore.models
    .where('labour_id', record.value?.labour_id)
    .where('day', recordForm.form.old.day)
    .get();

  records.forEach((record) => {
    dailyTime += record.hours;

    if(record.minor_break) {
      minorBreak = record.minor_break;
    }
  });

  return dailyTime > 3 && !minorBreak;
});

const showMajorBreak = computed(() => {
  let dailyTime = (oldTotalTime.value + newTotalTime.value) || 0;
  let majorBreak = false;

  if(record.value?.major_break) {
    return true;
  }

  const records = labourDailyRecordsStore.models
    .where('labour_id', record.value?.labour_id)
    .where('day', recordForm.form.old.day)
    .get();

  records.forEach((record) => {
    dailyTime += record.hours;

    if(record.major_break) {
      majorBreak = record.major_break;
    }
  });

  return dailyTime > 3 && !majorBreak;
});
</script>

<style scoped></style>
