<template>
  <tr class="group even:bg-gray-100 odd:bg-white text-right border-black border-x hover:bg-blue-100 p-2 m-2">
    <td
      class="py-2 text-left pl-3"
    >
      <input
        v-if="$props.claim.status === ClaimStatus.draft_pqs || $props.claim.status === ClaimStatus.draft_contractor"
        v-model="selectTradeItem"
        type="checkbox"
        name="select_trade_item[]"
      >
      {{ tradeItem.name }}
    </td>

    <td class="pr-3">
      {{ australianCurrency(tradeItem.budget) }}
    </td>

    <td
      v-if="tradeItemCost && !typingClaim"
      :class="{ 'cursor-pointer': canEditClaim }"
      class="bg-green-200/50"
      @click="typeClaim"
    >
      <div class="group flex items-center justify-end">
        <Icon
          v-if="canEditClaim"
          name="edit"
          class="group-hover:visible btn-icon h-5 w-5 inline-block invisible"
        />
        {{ canEditClaim ? australianCurrency(costForm.form.current_claim) : australianCurrency(tradeItemCost.current_claim) }}
      </div>
    </td>

    <td
      v-else-if="canEditClaim"
      class="bg-green-200/50"
    >
      <div class="w-1/2 ml-auto">
        <AppInput
          ref="claimInput"
          v-model.number="costForm.form.current_claim"
          name="current_claim"
          type="number"
          min="1"
          data-test="quantity-input"
          class="w-20 h-6"
          @blur="stopTypingClaim"
          @keyup.enter="stopTypingClaim"
          @keyup.esc="typingClaim = false"
        />
      </div>
    </td>

    <td
      v-else
      class="bg-green-200/50"
    />

    <td
      v-if="tradeItemCost?.current_certified && !typingCertified"
      :class="{ 'cursor-pointer': canEditCertified }"
      class="bg-green-200/50"
      @click="typeCertified"
    >
      <div class="group flex items-center justify-end">
        <Icon
          v-if="canEditCertified"
          name="edit"
          class="group-hover:visible btn-icon h-5 w-5 inline-block invisible"
        />
        {{ tradeItemCost.current_certified ? australianCurrency(tradeItemCost.current_certified) : '' }}
      </div>
    </td>

    <td
      v-else-if="canEditCertified"
      class="bg-green-200/50"
    >
      <div class="w-1/2 ml-auto">
        <AppInput
          ref="certifiedInput"
          v-model.number="costForm.form.current_certified"
          name="current_certified"
          type="number"
          min="1"
          class="w-20 h-6"
          @blur="stopTypingCertified"
          @keyup.enter="stopTypingCertified"
          @keyup.esc="typingCertified = false"
        />
      </div>
    </td>

    <td
      v-else
      class="bg-green-200/50"
    />

    <td class="bg-green-200/50">
      {{ australianCurrency(tradeItem.previous(claim)) }}
    </td>

    <td class="bg-green-200/50 pr-3">
      {{ australianCurrency(tradeItem.previous(claim) + (tradeItemCost?.current_certified || 0)) }}
    </td>

    <td class="bg-blue-300/50">
      {{ tradeItemCost ? `${australianNumber((tradeItemCost.current_claim / totalCosts.budget) * 100)}%` : '' }}
    </td>

    <td class="bg-blue-300/50">
      {{ tradeItemCost?.current_certified ? `${australianNumber((tradeItemCost.current_certified / totalCosts.budget) * 100)}%` : '' }}
    </td>

    <td class="bg-blue-300/50">
      {{ australianNumber((tradeItem.previous(claim) / totalCosts.budget) * 100) }}%
    </td>

    <td class="bg-blue-300/50 pr-3">
      {{ australianNumber(((tradeItem.previous(claim) + (tradeItemCost?.current_certified || 0)) / totalCosts.budget) * 100) }}%
    </td>

    <td
      :class="{ 'text-red-600': hasVariance && variance !== 0 }"
      :title="hasVariance && variance !== 0 ? varianceComment?.body : ''"
    >
      {{ hasVariance ? australianCurrency(variance) : '' }}
    </td>

    <td class="pr-3">
      {{ australianCurrency(Math.abs(tradeItem.budget - tradeItem.previous(claim) - (tradeItemCost?.current_certified || 0))) }}
    </td>
  </tr>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { useSignedData } from '@/composables/useSignedData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, australianNumber, falsey, parseUrlParams } from '@/helpers';
import Claim, { ClaimStatus } from '@/models/Claim';
import Trade from '@/models/Trade';
import TradeItem from '@/models/TradeItem';
import { useCommentsStore } from '@/store/comments';
import { useTradeItemCostsStore } from '@/store/tradeItemCosts';
import { useTradeItemsStore } from '@/store/tradeItems';
import Swal from 'sweetalert2';
import { computed, nextTick, ref, watch } from 'vue';

const props = defineProps<{
  trade: Trade;
  claim: Claim;
  tradeItem: TradeItem;
}>();

const tradeItemsStore = useTradeItemsStore();
const tradeItemCostsStore = useTradeItemCostsStore();

const tradeItems = computed(() => {
  return tradeItemsStore.models.where('trade_id', props.trade.id).with('trade_item_costs', (query) => {
    query.where('claim_id', props.claim.id);
  }).get();
});

const tradeItemCost = computed(() => {
  return tradeItemCostsStore.models.with('comments', (query) => query.orderBy('created_at', 'desc')).where(
    'trade_item_id',
    props.tradeItem.id,
  ).where(
    'claim_id',
    props.claim.id,
  )
    .first();
});

const varianceComment = computed(() => {
  return tradeItemCost.value?.comments[0];
});

const variance = computed(() => {
  return tradeItemCost.value?.current_claim - tradeItemCost.value?.current_certified;
});

const hasVariance = computed(() => {
  return !falsey(tradeItemCost.value?.current_claim) && !falsey(tradeItemCost.value?.current_certified);
});

const totalCosts = computed(() => {
  const totals = {
    budget: 0,
    currentClaim: 0,
    currentCertified: 0,
    previous: 0,
    cumulative: 0,
  };

  tradeItems.value.forEach((tradeItem) => {
    totals.budget += tradeItem.budget;
    totals.previous += tradeItem.previous(props.claim);
    const tradeItemCost = tradeItem.trade_item_costs[0];

    if(tradeItemCost) {
      totals.currentClaim += tradeItemCost.current_claim;
      totals.currentCertified += tradeItemCost.current_certified;
    }
  });

  totals.cumulative = totals.previous + totals.currentCertified;

  return totals;
});

const typingClaim = ref(false);
const typingCertified = ref(false);
const signedParams = useSignedData();

const canEditClaim = computed(() => {
  return signedParams.meta.edit_claim &&
    (props.claim.status === ClaimStatus.contractor_claim || props.claim.status === ClaimStatus.draft_contractor);
});

const canEditCertified = computed(() => {
  return signedParams.meta.edit_certified && props.claim.status === ClaimStatus.pqs_review;
});

const claimInput = ref();
const certifiedInput = ref();

const typeClaim = () => {
  if(canEditClaim.value) {
    typingClaim.value = true;

    nextTick(() => {
      claimInput.value?.focusInput();
    });
  }
};

const typeCertified = () => {
  if(canEditCertified.value) {
    typingCertified.value = true;

    nextTick(() => {
      certifiedInput.value?.focusInput();
    });
  }
};

const costForm = useFormData({
  id: undefined,
  current_certified: 0,
  current_claim: 0,
  comment_trade_item: false,
  comment: undefined,
  trade_item_id: undefined,
});

costForm.setData({
  trade_item_id: props.tradeItem.id,
});

watch(tradeItemCost, (newValue) => {
  if(newValue) {
    if(canEditCertified.value) {
      costForm.setData({
        id: newValue.id,
        current_certified: newValue.current_certified || 0,
      });
    } else if(canEditClaim.value) {
      costForm.setData({
        id: newValue.id,
        current_claim: newValue.current_claim || 0,
      });
    }
  }
}, { immediate: true });

const selectTradeItem = computed({
  get() {
    return tradeItemsStore.selectedTradeItems.has(props.tradeItem.id);
  },

  set(value) {
    if(value) {
      tradeItemsStore.selectedTradeItems.add(props.tradeItem.id);
    } else {
      tradeItemsStore.selectedTradeItems.delete(props.tradeItem.id);
    }
  },
});

const storeSingleCertifiedAction = useStoreApiAction(tradeItemCostsStore.saveCertifiedCost);
const storeSingleClaimAction = useStoreApiAction(tradeItemCostsStore.saveClaimCost);

const formParams = computed(() => {
  return parseUrlParams(signedParams.meta.url as string);
});

const commentsStore = useCommentsStore();

const stopTypingCertified = async () => {
  if(costForm.form.current_certified !== tradeItemCost.value.current_certified) {
    if(costForm.form.current_certified !== tradeItemCost.value.current_claim) {
      const tradeComment = commentsStore.models
        .where('commentable_id', props.trade.id)
        .where('commentable_type', props.trade.$entity())
        .where('creator_id', props.claim.id)
        .where('creator_type', props.claim.$entity())
        .first();

      const comment = !tradeComment ?
        await Swal.fire({
          icon: 'warning',
          title: 'Reason for Adjustment',
          text: 'Please provide a reason for the variance between the claim and certified amounts.',
          input: 'text',
        }) :
        undefined;

      costForm.form.comment = comment?.value || tradeComment?.body;

      // TODO: 10/27/23 maybe stop prompting to apply to trade

      if(!tradeComment) {
        await Swal.fire({
          icon: 'question',
          title: 'Apply Comment to Trade?',
          showConfirmButton: true,
          confirmButtonText: 'Yes',
          showDenyButton: true,
        }).then((result) => {
          if(result.isConfirmed) {
            costForm.form.comment_trade_item = true;
          }
        });
      }

      storeSingleCertifiedAction.request(props.claim.id, formParams.value, costForm.form).then((tradeItemCost) => {
        console.log(tradeItemCost);
      }).catch((error) => {
        console.log(error);

        Swal.fire({
          icon: 'error',
          title: 'Something went wrong.',
        });

        costForm.form.current_certified = tradeItemCost.value.current_certified;
      });
    } else {
      costForm.form.comment = undefined;

      storeSingleCertifiedAction.request(props.claim.id, formParams.value, costForm.form).then((tradeItemCost) => {
        console.log(tradeItemCost);
      }).catch((error) => {
        console.log(error);

        Swal.fire({
          icon: 'error',
          title: 'Something went wrong.',
        });

        costForm.form.current_certified = tradeItemCost.value.current_certified;
      });
    }
  }

  typingCertified.value = false;
};

const stopTypingClaim = () => {
  if(costForm.form.current_claim !== tradeItemCost.value?.current_claim) {
    storeSingleClaimAction.request(props.claim.id, formParams.value, costForm.form).then((tradeItemCost) => {
      console.log(tradeItemCost);
    }).catch((error) => {
      console.log(error);

      Swal.fire({
        icon: 'error',
        title: 'Something went wrong.',
      });
    });
  }

  typingClaim.value = false;
};
</script>

<style scoped></style>
