import { route } from '@/ziggy-shim';
import { api, CastValue } from './LaravelClient';

export default {
  async create(projectId: Id, data: ApiRequests['api.v1.project-supplier.store']) {
    return await api(route('api.v1.project-supplier.store', projectId), 'post', {
      data,
      casts: {
        address: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        contact_email: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        contact_name: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        contact_number: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async update(id: Id, data: ApiRequests['api.v1.project-supplier.update']) {
    return await api(route('api.v1.project-supplier.update', id), 'patch', {
      data,
      casts: {
        address: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        contact_email: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        contact_name: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        contact_number: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async delete(id: Id) {
    return await api(route('api.v1.project-supplier.destroy', id), 'delete');
  },

  company: {
    async create(companyId: Id, data: ApiRequests['api.v1.company.project-supplier.store']) {
      return await api(route('api.v1.company.project-supplier.store', companyId), 'post', {
        data,
        casts: {
          address: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
          contact_email: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
          contact_name: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
          contact_number: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        },
      });
    },

    async update(id: Id, data: ApiRequests['api.v1.company.project-supplier.update']) {
      return await api(route('api.v1.company.project-supplier.update', id), 'patch', {
        data,
        casts: {
          address: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
          contact_email: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
          contact_name: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
          contact_number: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        },
      });
    },

    async delete(id: Id) {
      return await api(route('api.v1.company.project-supplier.destroy', id), 'delete');
    },

    async clone(id: Id, data: ApiRequests['api.v1.company.project-supplier.clone']) {
      return await api(route('api.v1.company.project-supplier.clone', id), 'post', {
        data,
      });
    },
  },
};
