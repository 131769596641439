<template>
  <WbsCodeList
    :wbs-codes="wbsCodes"
    :to-complete-totals="toCompleteTotals"
  />
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { dateForDateInput } from '@/helpers';
import { safeDenominator } from '@/helpers';
import { useDailyQuantitiesStore } from '@/store/dailyQuantities';
import { useWbsCodesStore } from '@/store/wbsCodes';
import { route } from '@/ziggy-shim';
import { computed, ref, watch } from 'vue';
import WbsCodeList from './WbsCodeList.vue';

const props = defineProps<{
  projectId: number;
}>();

const wbsCodesStore = useWbsCodesStore();
const dailyQuantitiesStore = useDailyQuantitiesStore();
const fetchDailyQuantitiesAction = useStoreApiAction(dailyQuantitiesStore.toComplete);

const dailyQuantitiesForm = useFormData({
  to_date: dateForDateInput(new Date()),
});

const wbsTotals = ref<Map<string, { totalHours: number; totalCost: number; totalQuantity: number; totalDays: number }>>(
  new Map(),
);

const toCompleteTotals = computed(() => {
  const toCompleteMap = new Map<number, CostAtCompletion>();

  for(const [key, value] of wbsTotals.value) {
    const wbsCode = wbsCodesStore.models.find(parseInt(key));

    if(wbsCode) {
      const toDateUnitCost = value.totalCost / safeDenominator(value.totalQuantity);
      const toDateProductionRate = value.totalQuantity / safeDenominator(value.totalDays);
      const toDateEarnedAmount = value.totalQuantity * wbsCode.unitCost;
      const toCompleteQuantity = (wbsCode.final_quantity || wbsCode.tender_quantity) - value.totalQuantity;
      const toCompleteProductionRate = wbsCode.to_complete_production_rate || toDateProductionRate;

      const toCompleteUnitCost = toDateUnitCost /
        safeDenominator(toCompleteProductionRate / safeDenominator(toDateProductionRate));

      const toCompleteCost = toCompleteUnitCost * toCompleteQuantity;
      const forecastCost = value.totalCost + toCompleteCost;

      const profit = (value.totalCost + toCompleteCost) === 0 ?
        0 :
        (wbsCode.tender_quantity * wbsCode.unitCost) - (value.totalCost + toCompleteCost);

      toCompleteMap.set(wbsCode.id, {
        toDateQuantity: value.totalQuantity,
        toDateDays: value.totalQuantity / toDateProductionRate,
        toDateProductionRate,
        toDateUnitCost,
        toDateCost: value.totalCost,
        toDateEarnedAmount,
        toDateVariance: toDateEarnedAmount - value.totalCost,
        toCompleteQuantity,
        toCompleteProductionRate,
        toCompleteUnitCost,
        toCompleteDays: toCompleteQuantity / safeDenominator(toCompleteProductionRate),
        toCompleteCost,
        forecastCost,
        profit,
      });
    }
  }

  return toCompleteMap;
});

const wbsCodes = computed(() => {
  const sortedCodes = wbsCodesStore.models.where('project_id', props.projectId).get().sort((a, b) => {
    return toCompleteTotals.value.get(b.id)?.toDateVariance - toCompleteTotals.value.get(a.id)?.toDateVariance;
  });

  if(sortedCodes.length < 10) {
    return sortedCodes;
  }

  return sortedCodes.slice(0, 5).concat(sortedCodes.slice(-5));
});

const fetchDailyQuantities = () => {
  fetchDailyQuantitiesAction.request(props.projectId, dailyQuantitiesForm.form).then((data) => {
    wbsTotals.value = data;
  }).catch((error) => {
    console.log(error);
  });
};

watch(() => props.projectId, () => {
  fetchDailyQuantities();
}, { immediate: true });
</script>

<style scoped></style>
