<template>
  <div class="!pt-0">
    <div class="flex flex-col space-y-2.5 max-h-screen pt-16 pb-2.5">
      <div class="flex items-center justify-between space-x-4 mt-2.5">
        <div class="flex items-center space-x-4 element-island">
          <h1 class="font-semibold text-3xl">
            Progress Claims
          </h1>
        </div>

        <div
          v-if="!latestClaim || latestClaim?.status === ClaimStatus.certified"
          class="element-island flex space-x-4"
        >
          <button
            type="button"
            class="btn btn--secondary-blue"
            @click.prevent="show = true"
          >
            New Claim
          </button>
        </div>
      </div>

      <div class="card w-1/2 mx-auto">
        <div class="card__header">
          <p>
            Available Claims
          </p>
        </div>

        <div class="flex flex-col w-full space-y-4">
          <ul
            v-if="claims.length"
            class="overflow-auto space-y-4 h-64 flex-1 p-4"
          >
            <li
              v-for="projectClaim in claims"
              :key="projectClaim.id"
              class="card__list-item hover:bg-gray-100 cursor-pointer shadow-md"
              @click="router.push({ name: 'ProgressClaims', params: { claimId: projectClaim.id }})"
            >
              <div class="grid grid-cols-3">
                <div>
                  <p>
                    Claim No. {{ projectClaim.claim_number }}
                    <span
                      v-if="overallStatus(projectClaim.status)"
                      :class="[overallStatus(projectClaim.status).colour]"
                      class="p-1 rounded-lg text-white m-1 font-semibold text-sm"
                    >
                      {{ overallStatus(projectClaim.status).label }}
                    </span>
                  </p>

                  <p>
                    Created {{ projectClaim.createdAt.toLocaleString() }}
                  </p>

                  <p v-if="projectClaim.status === ClaimStatus.certified">
                    Variance {{ australianCurrency(projectClaim.totalVariance) }}
                  </p>
                </div>

                <div class="border-x border-gray-300 px-2">
                  <p>
                    Claim
                    <span
                      v-if="contractorStatus(projectClaim.status)"
                      :class="[contractorStatus(projectClaim.status).colour]"
                      class="p-1 rounded-lg text-white m-1 font-semibold text-sm"
                    >
                      {{ contractorStatus(projectClaim.status).label }}
                    </span>
                  </p>

                  <p v-if="projectClaim.draftIssuedAt">
                    Received {{ projectClaim.draftIssuedAt.toLocaleString() }}
                  </p>

                  <p v-if="projectClaim.claimIssuedAt">
                    Submitted {{ projectClaim.claimIssuedAt.toLocaleString() }}
                  </p>

                  <p v-if="projectClaim.claimIssuedAt">
                    Amount {{ australianCurrency(projectClaim.totalClaim) }}
                  </p>
                </div>

                <div class="px-2">
                  <p>
                    Certficate
                    <span
                      v-if="pqsStatus(projectClaim.status)"
                      :class="[pqsStatus(projectClaim.status).colour]"
                      class="p-1 rounded-lg text-white m-1 font-semibold text-sm"
                    >
                      {{ pqsStatus(projectClaim.status).label }}
                    </span>
                  </p>

                  <p v-if="projectClaim.claimIssuedAt">
                    Received {{ projectClaim.claimIssuedAt.toLocaleString() }}
                  </p>

                  <p v-if="projectClaim.submittedAt">
                    Submitted {{ projectClaim.submittedAt.toLocaleString() }}
                  </p>

                  <p v-if="projectClaim.status === ClaimStatus.certified">
                    Amount {{ australianCurrency(projectClaim.totalCertified) }}
                  </p>
                </div>
              </div>
            </li>
          </ul>

          <div
            v-if="!claims.length"
            class="m-auto p-5"
          >
            No Claims for Project.
          </div>
        </div>
      </div>

      <CreateClaimModal
        v-if="show"
        :project-id="$props.projectId"
        @close="show = false"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import ClaimTable from '@/components/ProgressClaims/ClaimTable.vue';
import CreateClaimModal from '@/components/ProgressClaims/CreateClaimModal.vue';
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, enumKeyFromValue } from '@/helpers';
import { ClaimStatus, ClaimStatusTitles } from '@/models/Claim';
import { useClaimsStore } from '@/store/claims';
import Swal from 'sweetalert2';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps<{
  projectId: string;
}>();

const claimsStore = useClaimsStore();
const router = useRouter();

watch(() => [props.projectId], () => {
  claimsStore.fetchClaims(props.projectId);
}, { immediate: true });

const show = ref(false);

const claims = computed(() => {
  return claimsStore.models.where('project_id', parseInt(props.projectId)).orderBy('claim_number', 'desc').orderBy(
    'claim_number',
    'desc',
  ).get();
});

const overallStatus = (status: ObjectValues<typeof ClaimStatus>) => {
  switch (true) {
    case status === ClaimStatus.certified:
      return { colour: 'bg-green-600', label: 'Certified' };

    default:
      return { colour: 'bg-red-600', label: 'Draft' };
  }
};

const contractorStatus = (status: ObjectValues<typeof ClaimStatus>) => {
  switch (true) {
    case status === ClaimStatus.draft_pqs:

    case status === ClaimStatus.draft:
      return { colour: 'bg-red-600', label: 'Not Issued' };

    case status === ClaimStatus.contractor_claim:
      return { colour: 'bg-orange-600', label: 'Issued' };

    case status === ClaimStatus.pqs_review:

    case status === ClaimStatus.certified:
      return { colour: 'bg-green-600', label: 'Submitted' };

    default:
      return undefined;
  }
};

const pqsStatus = (status: ObjectValues<typeof ClaimStatus>) => {
  switch (true) {
    case status === ClaimStatus.draft:
      return { colour: 'bg-red-600', label: 'Not Issued' };

    case status === ClaimStatus.pqs_review:
      return { colour: 'bg-orange-600', label: 'Issued' };

    case status === ClaimStatus.certified:
      return { colour: 'bg-green-600', label: 'Submitted' };

    default:
      return undefined;
  }
};

const latestClaim = computed(() => {
  if(claims.value.length) {
    return claims.value[0];
  }

  return undefined;
});
</script>

<style scoped></style>
