import AssetType from '@/models/AssetType';
import AssetTypeService from '@/services/AssetTypeService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';

export const useAssetTypesStore = defineStore('asset_types', {
  state: () => ({}),

  getters: {
    models: () => useRepo(AssetType),
  },

  actions: {
    async createAssetType(projectId: Id, request: ApiRequests['api.v1.asset-type.store']) {
      const [data, error] = await AssetTypeService.create(projectId, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updateAssetType(id: Id, request: ApiRequests['api.v1.asset-type.update']) {
      const [data, error] = await AssetTypeService.update(id, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async deleteAssetType(id: Id) {
      const [data, error] = await AssetTypeService.delete(id);

      if(data) {
        this.models.destroy(data.id);

        return data;
      }

      throw error;
    },
  },
});
