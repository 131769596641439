import { SupplierType } from '@/models/ProjectSupplier';
import Supplier from '@/models/Supplier';
import SupplierService from '@/services/SupplierService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';

export const useSuppliersStore = defineStore('suppliers', {
  state: () => ({}),

  getters: {
    models: () => useRepo(Supplier),
  },

  actions: {
    async search(form: ApiRequests['api.v1.suppliers.search']): Promise<
      { id: number; name: string; type?: ObjectValues<typeof SupplierType> }[]
    > {
      const [data, error] = await SupplierService.search(form);

      if(data) {
        return data;
      }

      throw error;
    },
  },
});
