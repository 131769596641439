<template>
  <div class="h-full flex pb-6 justify-center">
    <canvas
      v-show="weeklyPerformanceGraphAction.isNot(States.LOADING)"
      ref="weeklyPerformanceGraph"
    />

    <AppSpinner
      v-if="weeklyPerformanceGraphAction.is(States.LOADING)"
      class="my-auto"
    />
  </div>
</template>

<script setup lang="ts">
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { useProjectsStore } from '@/store/projects';
import { Chart } from 'chart.js';
import { computed, onMounted, ref, watch } from 'vue';

const props = defineProps<{
  projectId: number;
  selectedDate: string;
}>();

const weeklyPerformanceGraph = ref();
const projectsStore = useProjectsStore();
const weeklyPerformanceGraphAction = useStoreApiAction(projectsStore.weeklyPerformanceGraph);

const data = {
  labels: [],

  datasets: [{
    label: 'Weekly Cost',
    data: [],
    backgroundColor: '#19428d',

    borderColor: '#19428d',

    borderWidth: 1,
    stack: 'Stack 0',
  }, {
    label: 'Weekly Earned',
    data: [],
    backgroundColor: '#ffa010',

    borderColor: '#ffa010',

    borderWidth: 1,
    stack: 'Stack 1',
  }, {
    label: 'Variance',
    data: [],
    backgroundColor: '#707070',

    borderColor: '#707070',

    borderWidth: 1,
    stack: 'Stack 2',
  }],
};

let chart: Chart | undefined = undefined;

const updateChart = (metrics) => {
  const labels = [];
  const costs = [];
  const earned = [];
  const variance = [];

  metrics.forEach((metric) => {
    labels.push(new Date(metric.label).toLocaleDateString());

    costs.push(metric.cost);
    earned.push(metric.earned);
    variance.push(metric.variance);
  });

  chart.data.labels = labels;

  chart.data.datasets[0].data = costs;
  chart.data.datasets[1].data = earned;
  chart.data.datasets[2].data = variance;

  chart.update();
};

onMounted(() => {
  const weeklyPerformanceCtx = weeklyPerformanceGraph.value.getContext('2d');

  chart = new Chart(weeklyPerformanceCtx, {
    type: 'bar',
    data,
    options: {
      plugins: {
        title: {
          display: false,
        },

        legend: {
          display: true,
        },
      },

      interaction: {
        intersect: false,
      },

      scales: {
        x: {
          stacked: true,
        },
      },
    },
  });

  weeklyPerformanceGraphAction.request(props.projectId).then((metrics) => {
    updateChart(metrics);
  });
});

watch(() => props.projectId, (newValue) => {
  if(newValue !== undefined) {
    weeklyPerformanceGraphAction.request(props.projectId).then((metrics) => {
      updateChart(metrics);
    });
  }
});
</script>

<style scoped></style>
