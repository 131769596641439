<template>
  <tr
    class="group even:bg-gray-100 cursor-pointer hover:bg-blue-100 odd:bg-white"
    :data-test="`labour-row-${labour.id}`"
    @click="openTable"
  >
    <td
      class="  pl-3 py-2 group-last:rounded-bl-lg"
      data-test="labour-first-name"
    >
      <div class="flex items-center space-x-1">
        <div
          :class="[{'rotate-90': open}]"
          data-test="open-labour"
        >
          <Icon
            name="chevron-right"
            class="btn-icon h-5 w-5 group-hover:text-gray-700"
          />
        </div>

        <button
          type="button"
          data-test="add-record"
          @click.stop="openRecordCreate()"
        >
          <Icon
            name="plus"
            class="btn-icon h-5 w-5"
          />
        </button>

        <button
          type="button"
          data-test="edit"
          @click.stop="$emit('showUpdateModal', labour.id)"
        >
          <Icon
            name="edit"
            class="btn-icon h-5 w-5"
          />
        </button>

        <div class="flex flex-col truncate">
          <p
            class="truncate font-semibold"
          >
            {{ labour.first_name }}
          </p>

          <p
            class="text-sm truncate"
            data-test="labour-last-name"
          >
            {{ labour.last_name }}
          </p>
        </div>
      </div>
    </td>

    <td data-test="labour-supplier-name">
      {{ labour.supplier?.name }}
    </td>

    <td data-test="labour-cw-ref">
      {{ labour.cw_ref }}
    </td>

    <td data-test="labour-cw-ref">
      {{ labour.type }}
    </td>

    <td
      class="text-right"
      data-test="labour-rate"
    >
      {{ australianCurrency(labour.rate) }}/hr
    </td>

    <td
      class="text-right"
      data-test="labour-total-hours"
    >
      {{ australianNumber(labour.totalHours) }}
    </td>

    <td
      class="text-right"
      data-test="labour-total-accrual"
    >
      {{ australianCurrency(labour.totalAccrual) }}
    </td>

    <td
      class="text-right"
      data-test="labour-paid-to-date"
    >
      {{ australianCurrency(labour.paidToDate) }}
    </td>

    <td
      class="text-right pr-4 group-last:rounded-br-lg"
      data-test="labour-total"
    >
      {{ australianCurrency(labour.paidToDate + labour.totalAccrual) }}
    </td>
  </tr>

  <tr
    v-if="open"
    class="group bg-gray-200 border border-black"
  >
    <td
      colspan="100%"
      class="group-last:rounded-b-lg"
    >
      <Transition
        name="labour-row"
        enter-active-class="animate__animated animate__fadeIn animate__slow"
      >
        <div
          v-if="labourRecords.length"
          ref="recordListRef"
          v-collapsible="{ duration: 1000, show: open && fetchRecordsAction.firstLoad.value}"
          class="max-h-[390px] overflow-y-auto m-2 border border-gray-200 rounded-md shadow-md"
          @scroll="scrolled"
        >
          <table class="w-full border-separate border-spacing-0">
            <thead class="border-b border-black">
              <tr class="sticky top-0 h-7 bg-white">
                <th class="border-b border-gray-200 w-28" />

                <th class="text-left border-b border-gray-200">
                  Approved By
                </th>

                <th class="py-2 text-left border-b border-gray-200">
                  Date
                </th>

                <th class="text-left border-b border-gray-200">
                  WBS code
                </th>

                <th
                  class="text-left border-b border-gray-200"
                  title="The area captured on the Mobile app for the day for that specific WBS item"
                >
                  Area
                </th>

                <th
                  class="text-left border-b border-gray-200"
                  title="The element captured on the Mobile app for the day for that specific WBS item"
                >
                  Element
                </th>

                <th
                  class="text-left border-b border-gray-200"
                  title="The activity captured on the Mobile app for the day for that specific WBS item"
                >
                  Activity
                </th>

                <th class="text-left w-[5%] border-b border-gray-200">
                  Time
                </th>

                <th class="text-left w-[5%] pl-6 border-b border-gray-200">
                  Hours
                </th>

                <th
                  class="text-right px-2 border-b border-gray-200"
                  title="The cost based on recorded hours that are still to be paid"
                >
                  Accrual
                </th>

                <th class="text-center border-b border-gray-200">
                  Cost Paid
                </th>

                <th
                  class="text-center border-b border-gray-200"
                  title="The supplier's reference number to either invoice or docket"
                >
                  Vendor Ref
                </th>
              </tr>
            </thead>

            <tbody v-bind="wrapperProps">
              <LabourRowRecord
                v-for="record in labourRecords"
                :key="record.id"
                :record="record"
                :data-test="`labour-record-${record.id}`"
                :labour="labour"
                class="h-[65px]"
                @select-record="selectRecord"
                @show-comments="showRecordComments"
              />
            </tbody>
          </table>
        </div>

        <div
          v-else-if="labourRecords.length === 0 && fetchRecordsAction.is(States.COMPLETE)"
          class="w-full items-center justify-center text-center h-[65px]"
        >
          <div class="font-semibold">
            No records found.
          </div>
        </div>

        <div v-else-if="fetchRecordsAction.requestTime.value > 3 && fetchRecordsAction.is(States.LOADING)">
          <AppSpinner class="w-full flex justify-center items-center h-[60px]" />
        </div>
      </Transition>
    </td>
  </tr>

  <RecordModal
    v-if="show"
    :record-id="selectedRecord"
    :labour="labour"
    @close="show = false; fetchIfOpen()"
    @split="switchSplit"
  />

  <RecordSplitModal
    v-if="showSplit"
    :project-id="labour.project_id"
    :record-id="selectedRecord"
    @close="showSplit = false; fetchIfOpen()"
  />

  <RecordCommentModal
    v-if="showComments"
    :project-id="labour.project_id"
    :record-id="selectedRecord"
    @close="showComments = false"
  />

  <!-- <RecordCreateModal
    v-if="showRecordCreate"
    :project-id="labour.project_id"
    :labour="labour"
    @close="showRecordCreate = false; fetchIfOpen()"
  /> -->
</template>

<script setup lang="ts">
import { useQueue } from '@/composables/useQueue';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { useVirtualList } from '@/composables/useVirtualList';
import { australianCurrency, australianNumber } from '@/helpers';
import Labour from '@/models/Labour';
import LabourDailyRecord from '@/models/LabourDailyRecord';
import { useLabourDailyRecordStore } from '@/store/labourDailyRecord';
import { useLaboursStore } from '@/store/labours';
import { useCollect } from 'pinia-orm/dist/helpers';
import { computed, nextTick, ref } from 'vue';
import LabourRowRecord from './LabourRowRecord.vue';
import RecordCommentModal from './RecordCommentModal.vue';
import RecordModal from './RecordModal.vue';
import RecordSplitModal from './RecordSplitModal.vue';

const props = defineProps<{
  labour: Labour;
}>();

defineEmits<{
  (e: 'showUpdateModal', labourId: Id);
}>();

const show = ref(false);
const selectedRecord = ref();

const selectRecord = (id: Id) => {
  selectedRecord.value = id;
  show.value = true;
};

const showSplit = ref(false);

const switchSplit = () => {
  show.value = false;
  showSplit.value = true;
};

const showComments = ref(false);

const showRecordComments = (id: Id) => {
  selectedRecord.value = id;
  showComments.value = true;
};

const labourRecords = computed(() => {
  return useCollect(props.labour.records).sortBy([['day', 'desc']]);
});

const laboursStore = useLaboursStore();
const totalRecords = ref(0);
const recordIndex = ref(0);
const { wrapperProps } = useVirtualList(totalRecords, recordIndex, 65);
const labourDailyRecordsStore = useLabourDailyRecordStore();
const recordListRef = ref();
const page = ref(1);
const paginator = ref<ResourcePaginator<LabourDailyRecord>>();
const open = ref(false);
const fetchRecordsAction = useStoreApiAction(labourDailyRecordsStore.fetchRecords);
const recordQueue = useQueue<number>(10);

const initialFetch = () => {
  labourDailyRecordsStore.models.query().where('labour_id', props.labour.id).delete();

  fetchRecordsAction.request(props.labour.id, laboursStore.filter.form).then((newPaginator) => {
    recordQueue.reset();
    totalRecords.value = 0;

    totalRecords.value += newPaginator.data.length;

    recordIndex.value = newPaginator.meta.current_page * newPaginator.meta.per_page;

    recordQueue.enqueueFront(newPaginator.data.map((record: LabourDailyRecord) => {
      return record.id;
    }));

    paginator.value = newPaginator;
  });
};

const openRecordCreate = () => {
  selectedRecord.value = undefined;
  show.value = true;

  if(!open.value) {
    open.value = true;
    initialFetch();
  }
};

const fetchIfOpen = () => {
  if(open.value) {
    nextTick(() => {
      initialFetch();
    });
  }
};

const openTable = () => {
  open.value = !open.value;

  if(open.value) {
    initialFetch();
  }
};

const scrolled = () => {
  const recordList = recordListRef.value;

  if(recordList) {
    const scrollable = recordList.scrollHeight > recordList.clientHeight;

    if(
      page.value > 1 &&
      scrollable &&
      recordList.scrollTop === 0
    ) {
      fetchRecordsAction.request(props.labour.id, { page: page.value - 1, ...laboursStore.filter.form })
        .then((newPaginator) => {
          paginator.value = newPaginator;

          totalRecords.value -= newPaginator.data.length;
          recordIndex.value = newPaginator.meta.current_page * newPaginator.meta.per_page;

          const recordsToDelete = recordQueue.enqueueBack(newPaginator.data.map((record: LabourDailyRecord) => {
            return record.id;
          }));

          labourDailyRecordsStore.models.query().whereId(recordsToDelete).delete();

          recordList.scrollTop = Math.round(recordList.scrollHeight * 0.1);
          page.value = newPaginator.meta.current_page;
        });
    } else if(
      paginator.value.meta.last_page !== page.value &&
      scrollable &&
      recordList.scrollHeight - recordList.scrollTop === recordList.clientHeight
    ) {
      fetchRecordsAction.request(props.labour.id, { page: page.value + 1, ...laboursStore.filter.form })
        .then((newPaginator) => {
          paginator.value = newPaginator;

          totalRecords.value += newPaginator.data.length;
          recordIndex.value = newPaginator.meta.current_page * newPaginator.meta.per_page;

          const recordsToDelete = recordQueue.enqueueFront(newPaginator.data.map((record: LabourDailyRecord) => {
            return record.id;
          }));

          labourDailyRecordsStore.models.query().whereId(recordsToDelete).delete();

          recordList.scrollTop = Math.round(recordList.scrollHeight * 0.2);
          page.value = newPaginator.meta.current_page;
        });
    }
  }
};
</script>

<style scoped></style>
