<template>
  <tr
    class="even:bg-gray-100 odd:bg-white text-right border-black border-x hover:bg-blue-100"
    @click="open = !open"
  >
    <td class="flex pl-3 py-5 items-center space-x-1">
      <button
        :class="[{'rotate-90': open}]"
        data-test="open-claim"
        type="button"
      >
        <Icon
          name="chevron-right"
          class="btn-icon h-5 w-5 group-hover:text-gray-700"
        />
      </button>

      <button
        v-if="canEditClaim"
        type="button"
        @click.stop="createCost"
      >
        <Icon
          name="plus"
          class="btn-icon h-5 w-5"
        />
      </button>

      <span class="capitalize">
        {{ title.replace('_', ' ') }}
      </span>
    </td>

    <td class="pr-3">
      {{ australianCurrency(totalCosts.budget) }}
    </td>

    <td class="bg-green-200/50">
      {{ australianCurrency(totalCosts.currentClaim) }}
    </td>



    <td class="bg-green-200/50">
      {{ australianCurrency(totalCosts.currentCertified) }}
    </td>

    <td class="bg-green-200/50">
      {{ australianCurrency(totalCosts.previous) }}
    </td>

    <td class="bg-green-200/50 pr-3">
      {{ australianCurrency(totalCosts.cumulative) }}
    </td>

    <td class="bg-blue-300/50">
      {{ totalCosts.budget > 0 ? australianNumber((totalCosts.currentClaim / totalCosts.budget) * 100) : 0 }}%
    </td>

    <td class="bg-blue-300/50">
      {{ totalCosts.budget > 0 ? australianNumber((totalCosts.currentCertified / totalCosts.budget) * 100) : 0 }}%
    </td>

    <td class="bg-blue-300/50">
      {{ totalCosts.budget > 0 ? australianNumber((totalCosts.previous / totalCosts.budget) * 100) : 0 }}%
    </td>

    <td class="bg-blue-300/50 pr-3">
      {{ totalCosts.budget > 0 ? australianNumber((totalCosts.cumulative / totalCosts.budget) * 100) : 0 }}%
    </td>

    <td>
      {{ australianCurrency(totalCosts.currentClaim - totalCosts.currentCertified) }}
    </td>

    <td class="pr-3">
      {{ australianCurrency(totalCosts.budget - totalCosts.cumulative) }}
    </td>
  </tr>

  <template v-if="open">
    <VariationOrderCostRow
      v-for="cost in costs"
      :key="cost.id"
      :claim="$props.claim"
      :variation-order-cost="cost"
      :variation-order="cost.variation_order"
    />

    <tr
      v-if="costs.length === 0"
      class="border-x border-black"
    >
      <td
        colspan="100%"
        class="text-center p-4"
      >
        <p>
          No {{ title.replace('_', ' ') }}s found for this claim
        </p>

        <button
          v-if="canEditClaim"
          type="button"
          class="btn btn--secondary-blue mt-2"
          @click="createCost"
        >
          Attach {{ title.replace('_', ' ') }}
        </button>
      </td>
    </tr>
  </template>


  <VariationOrderCostModal
    v-if="show"
    :claim="claim"
    :variation-order-cost="selectedCost"
    :type="variationOrderType"
    @close="show = false"
  />
</template>

<script setup lang="ts">
import { useSignedData } from '@/composables/useSignedData';
import { australianCurrency, australianNumber } from '@/helpers';
import Claim, { ClaimStatus } from '@/models/Claim';
import { VariationOrderType } from '@/models/VariationOrder';
import VariationOrderCost from '@/models/VariationOrderCost';
import { useVariationOrdersStore } from '@/store/variationOrders';
import { computed, ref } from 'vue';
import VariationOrderCostModal from './VariationOrderCostModal.vue';
import VariationOrderCostRow from './VariationOrderCostRow.vue';

const props = defineProps<{
  costs: VariationOrderCost[];
  claim: Claim;
  title: string;
  variationOrderType: ObjectValues<typeof VariationOrderType>;
}>();

const variationOrderStore = useVariationOrdersStore();

const variationOrders = computed(() => {
  return variationOrderStore.models.where('type', props.variationOrderType).whereHas(
    'costs',
    (query) => {
      query.where('claim_id', props.claim.id);
    },
  )
    .with(
      'costs',
      (query) => {
        query.where('claim_id', props.claim.id);
      },
    )
    .get();
});

const totalCosts = computed(() => {
  const totals = {
    budget: 0,
    currentClaim: 0,
    currentCertified: 0,
    previous: 0,
    cumulative: 0,
  };

  variationOrders.value.forEach((variationOrder) => {
    totals.budget += variationOrder.budget;
    totals.previous += variationOrder.previous(props.claim);
    const variationOrderCost = variationOrder.costs[0];

    if(variationOrderCost) {
      totals.currentClaim += variationOrderCost.current_claim;
      totals.currentCertified += variationOrderCost.current_certified;
    }
  });

  totals.cumulative = totals.previous + totals.currentCertified;

  return totals;
});

const open = ref(false);
const show = ref(false);
const selectedCost = ref<VariationOrderCost | undefined>();

const createCost = () => {
  selectedCost.value = undefined;
  show.value = true;
  open.value = true;
};

const signedParams = useSignedData();

const canEditClaim = computed(() => {
  return signedParams.meta.edit_claim &&
    (props.claim.status === ClaimStatus.contractor_claim ||
      props.claim.status === ClaimStatus.draft_contractor);
});
</script>

<style scoped></style>
