<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl mx-10">
          {{ $props.trade ? 'Update' : 'Create' }} Trade
        </h1>
      </div>
    </template>

    <template #body>
      <div class="h-full w-full p-4 overflow-y-auto">
        <form
          action=""
          class="flex flex-col space-y-4"
          @submit.prevent="saveTrade"
        >
          <label>
            Name
            <AppInput
              v-model="tradeForm.form.name"
              name="name"
              type="text"
              class="form-input"
              :error="tradeForm.getErrors('name')"
              data-test="name-input"
            />
          </label>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-center p-2 space-x-4">
        <button
          type="button"
          class="btn btn--secondary-blue"
          @click="$emit('close'); tradeForm.resetData()"
        >
          Cancel
        </button>

        <button
          type="button"
          class="btn btn--primary-blue"
          :disabled="createTradeAction.is(States.LOADING)"
          data-test="save-trade-item"
          @click="saveTrade"
        >
          {{ $props.trade ? 'Update' : 'Create' }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/Modal.vue';
import { useFormData } from '@/composables/useFormData';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import Trade from '@/models/Trade';
import { useTradesStore } from '@/store/trades';
import Swal from 'sweetalert2';
import { watch } from 'vue';

const props = defineProps<{
  projectId: Id;
  trade?: Trade;
}>();

const emits = defineEmits<{
  (e: 'close');
}>();

const tradeForm = useFormData({
  name: '',
});

watch(() => props.trade, (newValue) => {
  if(newValue) {
    tradeForm.setData(newValue);
  }
}, { immediate: true });

const tradesStore = useTradesStore();
const createTradeAction = useStoreApiAction(tradesStore.createTrade);
const updateTradeAction = useStoreApiAction(tradesStore.updateTrade);

const saveTrade = () => {
  if(props.trade) {
    updateTradeAction.request(props.trade.id, tradeForm.form).then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Trade updated.',
      }).then(() => {
        emits('close');
      });
    }).catch((error) => {
      tradeForm.setErrors(error.data);
    });
  } else {
    createTradeAction.request(props.projectId, tradeForm.form).then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Trade created.',
      }).then(() => {
        emits('close');
      });
    }).catch((error) => {
      tradeForm.setErrors(error.data);
    });
  }
};
</script>

<style scoped></style>
