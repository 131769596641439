<template>
  <div class="flex flex-col min-h-screen bg-gray-50 bg-app">
    <HomeNavbar />

    <!-- <Navbar v-else /> -->

    <div
      v-if="isMobileScreen"
      class="lg:my-auto m-auto lg:flex lg:m-0 lg:justify-center text-center"
    >
      <p>
        This screen is intended for viewing on a desktop device only.
      </p>

      <p>Please resize the screen or switch to a desktop device.</p>
    </div>

    <router-view
      v-else
      class="lg:mx-3 pt-16 overflow-y-auto min-h-content"
    />

    <div
      v-if="route.meta.footer"
      class="sticky w-full z-50 bg-white lg:z-40 bottom-0"
    >
      <div id="footer" />
    </div>
  </div>

  <div id="modals" />
</template>

<script setup lang="ts">
import Swal from 'sweetalert2';
import { computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import HomeNavbar from './components/layout/HomeNavbar.vue';
import Navbar from './components/layout/Navbar.vue';
import useScreenSize, { ScreenSize } from './composables/useScreenSize';
import { inCapacitor } from './helpers';
import { authenticatedRoutes, guestRoutes, mobileCompatibleRoutes } from './router';
import AuthService from './services/AuthService';
import AxiosClient from './services/AxiosClient';
import { useProjectsStore } from './store/projects';
import { useUserStore } from './store/user';

const userStore = useUserStore();
const router = useRouter();
const route = useRoute();
const projectsStore = useProjectsStore();
const { screenSize } = useScreenSize();

const initialiseApp = async () => {
  // const intendedRoute = `${window.location.pathname}${window.location.search}${window.location.hash}`;

  if(inCapacitor) {
    await AuthService.attachBearerTokenFromStorage();
  }

  const user = await userStore.fetchLoggedInUser();

  // Bootstrap after checking user is signed in to prevent infinite redirect loop
  AxiosClient.bootstrap();

  await router.isReady();

  if(user) {
    // Example connecting to private channel
    // window.Echo.private('channel').notification((notif) => {
    //   console.log('notification', notif);
    // });

    const [project] = await projectsStore.fetchProjects();

    if(!project) {
      Swal.fire({
        icon: 'error',
        text: 'You don\'t have any projects, please contact CCM support',
      });

      router.push({ name: 'ProjectDashboard' });

      return;
    }

    if(route.params && 'projectId' in route.params && route.params.projectId) {
      projectsStore.setProject(parseInt(route.params.projectId as string), false);
    } else {
      projectsStore.setProject(project.id, false);
    }

    // STOPSHIP: 27/03/24 revise to check if have permission for route, if not then go to home route
    // troy to define home route per role
    if(guestRoutes.includes(route.name)) {
      if(screenSize.value < ScreenSize.MD) {
        router.push({ name: 'MobileProjectList' });
      } else {
        router.push({ name: 'ProjectSelector', params: { projectId: project.id } });
      }
    }
    // This may not be needed now
    //  else {
    //   router.push(intendedRoute);
    // }
  } else {
    userStore.user = undefined;

    if(authenticatedRoutes.includes(route.name)) {
      router.push({ name: 'Login' });
    }
  }
};

const isMobileScreen = computed(() => {
  return screenSize.value < ScreenSize.MD &&
    !mobileCompatibleRoutes.includes(route.name);
});

watch(route, () => {
  if(route.params) {
    if(route.params && 'projectId' in route.params) {
      projectsStore.setProject(parseInt(route.params.projectId as string));
    }
  }
});

initialiseApp();
</script>

<style scoped></style>
