<template>
  <tr
    class="even:bg-gray-100 odd:bg-white text-right border-black border-x hover:bg-blue-100"
    @click="open = !open"
  >
    <td class="flex pl-3 py-5 items-center space-x-1">
      <button
        :class="[{'rotate-90': open}]"
        data-test="open-claim"
        type="button"
      >
        <Icon
          name="chevron-right"
          class="btn-icon h-5 w-5 group-hover:text-gray-700"
        />
      </button>

      <input
        v-if="$props.claim.status === ClaimStatus.draft_pqs || $props.claim.status === ClaimStatus.draft_contractor"
        v-model="selectTrade"
        type="checkbox"
        name="select_trade[]"
        @click.stop=""
      >

      <span>
        {{ trade.name }}
      </span>
    </td>

    <td class="pr-3">
      {{ australianCurrency(totalCosts.budget) }}
    </td>

    <td class="bg-green-200/50">
      {{ australianCurrency(totalCosts.currentClaim) }}
    </td>

    <td class="bg-green-200/50">
      {{ australianCurrency(totalCosts.currentCertified) }}
    </td>

    <td class="bg-green-200/50">
      {{ australianCurrency(totalCosts.previous) }}
    </td>

    <td class="bg-green-200/50 pr-3">
      {{ australianCurrency(totalCosts.cumulative) }}
    </td>

    <td class="bg-blue-300/50">
      {{ australianNumber((totalCosts.currentClaim / totalCosts.budget) * 100) }}%
    </td>

    <td class="bg-blue-300/50">
      {{ australianNumber((totalCosts.currentCertified / totalCosts.budget) * 100) }}%
    </td>

    <td class="bg-blue-300/50">
      {{ australianNumber((totalCosts.previous / totalCosts.budget) * 100) }}%
    </td>

    <td class="bg-blue-300/50 pr-3">
      {{ australianNumber((totalCosts.cumulative / totalCosts.budget) * 100) }}%
    </td>

    <td
      :class="{ 'text-red-600': hasVariance && variance !== 0 }"
      :title="hasVariance && variance !== 0 ? varianceComment?.body : ''"
    >
      {{ australianCurrency(totalCosts.currentClaim - totalCosts.currentCertified) }}
    </td>

    <td class="pr-3">
      {{ australianCurrency(Math.abs(totalCosts.budget - totalCosts.cumulative)) }}
    </td>
  </tr>

  <template v-if="open">
    <TradeRowItem
      v-for="tradeItem in tradeItems"
      :key="tradeItem.id"
      :trade="$props.trade"
      :claim="$props.claim"
      :trade-item="tradeItem"
    />
  </template>
</template>

<script setup lang="ts">
import { useSignedData } from '@/composables/useSignedData';
import { australianCurrency, australianNumber, falsey } from '@/helpers';
import Claim, { ClaimStatus } from '@/models/Claim';
import Trade from '@/models/Trade';
import TradeItem from '@/models/TradeItem';
import { useCommentsStore } from '@/store/comments';
import { useTradeItemsStore } from '@/store/tradeItems';
import { computed, ref, watch } from 'vue';
import TradeRowItem from './TradeRowItem.vue';

const props = defineProps<{
  trade: Trade;
  claim: Claim;
}>();

const signedParams = useSignedData();
const tradeItemsStore = useTradeItemsStore();

const tradeItemIds = computed(() => {
  return (signedParams.models.tradeItems as TradeItem[]).map((tradeItem) => {
    return tradeItem.id;
  });
});

const tradeItems = computed(() => {
  return tradeItemsStore.models.where('trade_id', props.trade.id).whereIn('id', tradeItemIds.value).with(
    'trade_item_costs',
    (query) => {
      query.where('claim_id', props.claim.id);
    },
  ).get();
});

const totalCosts = computed(() => {
  const totals = {
    budget: 0,
    currentClaim: 0,
    currentCertified: 0,
    previous: 0,
    cumulative: 0,
  };

  tradeItems.value.forEach((tradeItem) => {
    totals.budget += tradeItem.budget;
    totals.previous += tradeItem.previous(props.claim);
    const tradeItemCost = tradeItem.trade_item_costs[0];

    if(tradeItemCost) {
      totals.currentClaim += tradeItemCost.current_claim;
      totals.currentCertified += tradeItemCost.current_certified;
    }
  });

  totals.cumulative = totals.previous + totals.currentCertified;

  return totals;
});

const open = ref(false);
const selectTrade = ref(false);

watch(selectTrade, (newValue) => {
  if(newValue) {
    tradeItems.value.forEach((tradeItem) => {
      tradeItemsStore.selectedTradeItems.add(tradeItem.id);
    });
  } else {
    tradeItems.value.forEach((tradeItem) => {
      tradeItemsStore.selectedTradeItems.delete(tradeItem.id);
    });
  }
});

const commentsStore = useCommentsStore();

const varianceComment = computed(() => {
  return commentsStore.models.where('commentable_id', props.trade.id).where('commentable_type', props.trade.$entity())
    .where('creator_id', props.claim.id).where('creator_type', props.claim.$entity())
    .first();
});

const hasVariance = computed(() => {
  return !falsey(totalCosts.value?.currentClaim) && !falsey(totalCosts.value?.currentCertified);
});

const variance = computed(() => {
  return totalCosts.value?.currentClaim - totalCosts.value?.currentCertified;
});
</script>

<style scoped></style>
