<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          {{ $props.assetType ? 'Update' : 'Add' }} Asset Type
        </h1>
      </div>
    </template>

    <template #body>
      <div class="h-full w-full p-4 overflow-y-auto">
        <form
          action=""
          class="space-y-4"
          @submit.prevent="saveAssetType"
        >
          <label class="flex items-center justify-between space-x-2">
            <p>
              Name
            </p>

            <AppInput
              v-model="assetTypeForm.form.name"
              name="name"
              type="text"
              :error="assetTypeForm.getErrors('name')"
              data-test="update-name"
            />
          </label>

          <label class="flex items-center justify-between space-x-2">
            <p>
              Cubes Per Load
            </p>

            <AppInput
              v-model="assetTypeForm.form.cubes_per_load"
              name="cubes_per_load"
              type="number"
              :error="assetTypeForm.getErrors('cubes_per_load')"
              data-test="update-cubes_per_load"
            />
          </label>

          <label class="flex items-center justify-between space-x-2">
            <p>
              Tonnes Per Load
            </p>

            <AppInput
              v-model="assetTypeForm.form.tonnes_per_load"
              name="tonnes_per_load"
              type="number"
              :error="assetTypeForm.getErrors('tonnes_per_load')"
              data-test="update-tonnes_per_load"
            />
          </label>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-between p-2 space-x-4">
        <button
          v-if="$props.assetType"
          type="button"
          class="btn btn--tertiary-blue"
          @click="deleteAssetType"
        >
          Delete
        </button>

        <div class="space-x-2 ml-auto">
          <button
            type="button"
            class="btn btn--secondary-blue"
            @click="$emit('close'); assetTypeForm.resetData()"
          >
            Cancel
          </button>

          <button
            type="button"
            class="btn btn--primary-blue"
            data-test="save-order"
            @click="saveAssetType"
          >
            {{ $props.assetType ? 'Update' : 'Create' }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/Modal.vue';
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import AssetType from '@/models/AssetType';
import { useAssetTypesStore } from '@/store/assetTypes';
import Swal from 'sweetalert2';
import { watch } from 'vue';

const props = defineProps<{
  assetType?: AssetType;
  projectId: Id;
}>();

const emits = defineEmits<{
  (e: 'close');
}>();

const assetTypesStore = useAssetTypesStore();

const assetTypeForm = useFormData({
  name: '',
  cubes_per_load: 0,
  tonnes_per_load: 0,
});

watch(() => props.assetType, () => {
  if(props.assetType) {
    assetTypeForm.setData(props.assetType);
  }
}, { immediate: true });

const updateAssetTypeAction = useStoreApiAction(assetTypesStore.updateAssetType);
const createAssetTypeAction = useStoreApiAction(assetTypesStore.createAssetType);

const saveAssetType = () => {
  assetTypeForm.resetErrors();

  if(props.assetType) {
    updateAssetTypeAction.request(props.assetType.id, assetTypeForm.form).then(() => {
      Swal.fire({
        icon: 'success',
        text: 'Asset Type updated',
      }).then(() => {
        emits('close');
        assetTypeForm.resetData();
      });
    }).catch((error) => {
      assetTypeForm.setErrors(error.data);
    });
  } else {
    createAssetTypeAction.request(props.projectId, assetTypeForm.form).then(() => {
      Swal.fire({
        icon: 'success',
        text: 'Asset Type created',
      }).then(() => {
        emits('close');
        assetTypeForm.resetData();
      });
    }).catch((error) => {
      assetTypeForm.setErrors(error.data);
    });
  }
};

const deleteAssetType = () => {
  Swal.fire({
    icon: 'warning',
    title: 'Remove Loader Type?',
    showConfirmButton: true,
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      assetTypesStore.deleteAssetType(props.assetType.id).then(() => {
        emits('close');
      }).catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Failed to delete',
        });
      });
    }
  });
};
</script>

<style scoped></style>
