import { route } from '@/ziggy-shim';
import { api, CastValue } from './LaravelClient';

export default {
  async index(projectId: Id) {
    return await api(route('api.v1.trade-items.index', projectId), 'get');
  },

  async create(projectId: Id, data: ApiRequests['api.v1.trade-items.store']) {
    return await api(route('api.v1.trade-items.store', projectId), 'post', {
      data,
    });
  },

  async update(id: Id, data: ApiRequests['api.v1.trade-items.update']) {
    return await api(route('api.v1.trade-items.update', id), 'patch', {
      data,
      casts: {
        awarded_value: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async delete(id: Id) {
    return await api(route('api.v1.trade-items.destroy', id), 'delete');
  },

  async updateSort(id: Id, data: ApiRequests['api.v1.trade-items.sort']) {
    return await api(route('api.v1.trade-items.sort', id), 'patch', {
      data,
    });
  },
};
