<template>
  <div class="h-full flex pb-6 justify-center">
    <canvas
      v-show="fetchPlantUtilisationMetricsAction.isNot(States.LOADING)"
      ref="plantUtilGraph"
    />

    <AppSpinner
      v-if="fetchPlantUtilisationMetricsAction.is(States.LOADING)"
      class="my-auto"
    />
  </div>
</template>

<script setup lang="ts">
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { usePlantCategoriesStore } from '@/store/plantCategories';
import { useProjectsStore } from '@/store/projects';
import { Chart } from 'chart.js';
import { computed, onMounted, ref, watch } from 'vue';

const props = defineProps<{
  projectId: number;
  selectedDate: string;
}>();

const projectsStore = useProjectsStore();
const plantUtilGraph = ref();

const data = {
  labels: [],

  datasets: [{
    label: 'Plant Utilisation',
    data: [],
    backgroundColor: '#19428d',

    borderColor: '#19428d',

    borderWidth: 1,
  }, {
    label: 'Plant Not Utilised',
    data: [],
    backgroundColor: '#ffa010',

    borderColor: '#ffa010',

    borderWidth: 1,
  }],
};

let chart: Chart | undefined;
const categoriesStore = usePlantCategoriesStore();

const updateChart = (plantMetrics) => {
  const categoryIds = [];
  const usedHours = [];
  const unusedHours = [];

  for(const key in plantMetrics) {
    categoryIds.push(parseInt(key));

    usedHours.push((plantMetrics[key].used / plantMetrics[key].total) * 100);
    unusedHours.push(((plantMetrics[key].total - plantMetrics[key].used) / plantMetrics[key].total) * 100);
  }

  const labels = categoriesStore.models.find(categoryIds).map((category) => category.name);

  chart.data.labels = labels;

  chart.data.datasets[0].data = usedHours;
  chart.data.datasets[1].data = unusedHours;

  chart.update();
};

const fetchPlantUtilisationMetricsAction = useStoreApiAction(projectsStore.selfPerformDashboard);

onMounted(() => {
  const ctx = plantUtilGraph.value.getContext('2d');

  chart = new Chart(ctx, {
    type: 'bar',
    data,
    options: {
      plugins: {
        title: {
          display: false,
        },

        legend: {
          display: true,
        },
      },

      scales: {
        y: {
          min: 0,
          max: 100,
          stacked: true,
        },

        x: {
          stacked: true,
        },
      },
    },
  });

  fetchPlantUtilisationMetricsAction.request(props.projectId, { date: props.selectedDate }).then((metrics) => {
    updateChart(metrics);
  });
});

watch<[number, string]>(() => [props.projectId, props.selectedDate], ([projectId, selectedDate]) => {
  if(projectId !== undefined) {
    fetchPlantUtilisationMetricsAction.request(props.projectId, { date: selectedDate }).then((metrics) => {
      updateChart(metrics);
    });
  }
});
</script>

<style scoped></style>
