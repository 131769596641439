<template>
  <div>
    <!--Header -->
    <div class="flex w-[100%] justify-center bg-blue-900 py-2">
      <h1 class="text-3xl text-white font-semibold">
        Labour
      </h1>
    </div>

    <!--Title Bar -->
    <div class="flex w-full justify-between bg-blue-900 p-2 text-sm">
      <h1
        class="text-white"
        data-test="labour-name"
      >
        Name
      </h1>

      <h1 class="text-white text-right pr-6">
        Hours
      </h1>
    </div>

    <LabourRecordRow
      v-for="labour in labours"
      :key="labour.id"
      :labour="labour"
      :project="project"
      :selected-date="selectedDate"
      :data-test="`labour-${labour.id}-records`"
    />

    <AppSpinner
      v-if="fetchDailyLaboursState === States.LOADING"
      class="flex w-full justify-center p-4"
    />

    <div
      v-else-if="labours.length === 0 && fetchDailyLaboursState === States.COMPLETE"
      data-test="no-labours"
    >
      <p class="font-bold text-xl w-full text-center pt-4">
        No labours found for {{ selectingDateInput }}
      </p>
    </div>

    <Teleport
      v-if="firstLoad"
      to="#footer"
    >
      <div class="w-full flex justify-center items-center h-16 space-x-4">
        <button
          type="button"
          class="px-2 py-1 bg-gray-200 rounded-full"
          data-test="prev-day"
          @click="prevDate"
        >
          <Icon
            name="chevron-left-mini"
            class="h-5 w-5"
          />
        </button>

        <AppInput
          v-model="selectingDateInput"
          class="px-4 py-1 border border-gray-200 rounded-full"
          type="date"
          name="selected_date"
          data-test="select-day-input"
          @keyup.enter="setDay"
          @change="screenSize < ScreenSize.MD && setDay()"
        />

        <button
          type="button"
          class="px-2 py-1 bg-gray-200 rounded-full"
          data-test="next-day"
          @click="nextDate"
        >
          <Icon
            name="chevron-right-mini"
            class="h-5 w-5"
          />
        </button>
      </div>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import LabourRecordRow from '@/components/Mobile/MobileLabourDashboard/LabourRecordRow.vue';
import useDayQueryParam from '@/composables/useDayQueryParam';
import useScreenSize, { ScreenSize } from '@/composables/useScreenSize';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { dateAsYMD } from '@/helpers';
import { useLaboursStore } from '@/store/labours';
import { useNavStore } from '@/store/nav';
import { useProjectsStore } from '@/store/projects';
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const props = defineProps<{
  projectId: string;
}>();

const queryParams = useRoute().query.day as string;
const selectingDateInput = ref(dateAsYMD(new Date()));
const selectedDate = useDayQueryParam();

if(queryParams) {
  const queryDate = new Date(queryParams);

  if(!isNaN(queryDate.getTime())) {
    selectingDateInput.value = dateAsYMD(queryDate);
  }
}

const projectsStore = useProjectsStore();

const project = computed(() => {
  return projectsStore.models.find(parseInt(props.projectId));
});

const laboursStore = useLaboursStore();
const labourIds = ref([]);

const labours = computed(() => {
  return laboursStore.models.query().with('records', (query) => {
    query.where('day', selectingDateInput.value).with('wbsCode');
  }).whereId(labourIds.value).get();
});

const router = useRouter();

const {
  request: fetchDailyLabours,
  state: fetchDailyLaboursState,
  firstLoad,
} = useStoreApiAction(laboursStore.fetchDailyLabours);

watch(selectingDateInput, (newValue) => {
  if(!newValue) {
    selectingDateInput.value = dateAsYMD(new Date());
    selectedDate.value = new Date();
  } else {
    selectedDate.value = new Date(newValue);
    router.replace({ query: { day: `${newValue}` } });
    labourIds.value = [];

    fetchDailyLabours(props.projectId, { day: dateAsYMD(new Date(newValue)) }).then((dailyLabours) => {
      dailyLabours.forEach((labour) => {
        labourIds.value.push(labour.id);
      });
    });
  }
}, { immediate: true });

const nextDate = () => {
  const selected = new Date(selectingDateInput.value);

  selected.setDate(selected.getDate() + 1);
  selectingDateInput.value = dateAsYMD(selected);
};

const prevDate = () => {
  const selected = new Date(selectingDateInput.value);

  selected.setDate(selected.getDate() - 1);
  selectingDateInput.value = dateAsYMD(selected);
};

const setDay = () => {
  if(!isNaN(new Date(selectingDateInput.value).getTime())) {
    selectedDate.value = new Date(selectingDateInput.value);
  }
};

// const openDateSelector = () => {
//   selectingDate.value = true;

//   nextTick(() => {
//     selectDateInput.value?.focusInput();
//   });
// };

const { screenSize } = useScreenSize();
const navStore = useNavStore();

navStore.backRoute = { name: 'MobileProjectPage' };
</script>

<style scoped></style>
