<template>
  <div>
    <RouterLink
      v-slot="{ navigate }"
      :to="{
        name: 'MobileLabourRecord',
        params: {
          labourId: labour.id
        },
        query: {
          day: `${dateAsYMD($props.selectedDate)}`
        }
      }"
      custom
    >
      <div
        class="flex justify-between w-full p-2"
        :class="[labourClass(labour)]"
        data-test="labour-row"
        @click="labour.dailyRecords($props.selectedDate).length === 0 ? navigate() : open = !open"
      >
        <div class="flex w-full text-black justify-start text-left">
          <p class="w-3/5">
            {{ labour.first_name }}
            {{ labour.last_name }}
          </p>
        </div>

        <div>
          <p class="w-1/5 text-right">
            {{
              labour.dailyHours($props.selectedDate).toLocaleString('en-AU', {
                maximumFractionDigits: 2, minimumFractionDigits: 2
              })
            }}
          </p>
        </div>

        <RouterLink
          class="fa-btn fa-btn--blue ml-2"
          :to="{
            name: 'MobileLabourRecord',
            params: {
              labourId: labour.id
            },
            query: {
              day: `${dateAsYMD($props.selectedDate)}`
            }
          }"
          data-test="add-record"
        >
          <Icon
            name="plus"
            class="w-4 h-4"
          />
        </RouterLink>
      </div>
    </RouterLink>

    <div
      v-if="open"
      class="grid bg-blue-900 text-white"
      data-test="labour-record-table"
    >
      <div class="grid grid-cols-5 gap-2 font-bold text-sm px-1.5 pt-1">
        <p>
          WBS Code
        </p>

        <p>
          Area
        </p>

        <p>
          Element
        </p>

        <p>
          Activity
        </p>
      </div>

      <RouterLink
        v-for="record in labour.dailyRecords($props.selectedDate)"
        :key="record.id"
        class="grid grid-cols-5 gap-2 text-sm p-1.5 odd:bg-blue-700"
        :to="{
          name: 'MobileLabourRecord',
          params: {
            labourId: labour.id,
            recordId: record.id
          }
        }"
      >
        <p class="truncate">
          {{ record.wbsCode.code }}
        </p>

        <p class="truncate">
          {{ record.area }}
        </p>

        <p class="truncate">
          {{ record.element }}
        </p>

        <p class="truncate">
          {{ record.activity }}
        </p>

        <p class="text-right">
          {{ record.hours.toLocaleString('en-AU', {
            maximumFractionDigits: 2, minimumFractionDigits: 2
          }) }}
        </p>
      </RouterLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { dateAsYMD } from '@/helpers';
import Labour from '@/models/Labour';
import Project from '@/models/Project';
import { ref } from 'vue';

const props = defineProps<{
  labour: Labour;
  selectedDate: Date;
  project: Project;
}>();

const labourClass = (labour: Labour) => {
  if(labour.dailyHoursWithoutBreaks(props.selectedDate) >= props.project?.labour_standard_shift) {
    return 'bg-green-300';
  } else if(labour.dailyHoursWithoutBreaks(props.selectedDate) > 0) {
    return 'bg-orange-300';
  } else {
    return 'bg-red-300';
  }
};

const open = ref(false);
</script>

<style scoped></style>
