import SurveyedQuantity from '@/models/SurveyedQuantity';
import SurveyedQuantityService from '@/services/SurveyedQuantityService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';

export const useSurveyedQuantitiesStore = defineStore('surveyedQuantities', {
  state: () => ({}),

  getters: {
    models: () => useRepo(SurveyedQuantity),
  },

  actions: {
    async fetchQuantities(projectId: Id, form: ApiRequests['api.v1.surveyed.index']) {
      const [data, error] = await SurveyedQuantityService.index(projectId, form);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async createQuantity(projectId: Id, form: ApiRequests['api.v1.surveyed.store']) {
      const [data, error] = await SurveyedQuantityService.create(projectId, form);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updateQuantity(id: Id, form: ApiRequests['api.v1.surveyed.update']) {
      const [data, error] = await SurveyedQuantityService.update(id, form);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },
  },

  persist: {
    storage: sessionStorage,
  },
});
