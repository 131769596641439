<template>
  <div class="card">
    <div class="card__header flex justify-between items-center space-x-4">
      <p>WBS Codes</p>

      <button
        type="submit"
        class="btn btn--secondary-blue"
        data-test="add-wbs-code"
        @click.prevent="addWbsCode"
      >
        Add WBS Code
      </button>
    </div>

    <div class="flex w-full p-4 space-x-4">
      <ul
        v-if="wbsCodes.length"
        class="overflow-auto space-y-4 h-64 flex-1"
      >
        <li
          v-for="(wbsCode, i) in wbsCodes"
          :key="wbsCode.id"
          class="card__list-item"
          :data-test="`wbscode-item-${i}`"
        >
          <div class="grid grid-cols-2">
            <p :title="wbsCode.code">
              {{ wbsCode.code }}
            </p>

            <div class="flex justify-end row-span-2">
              <Icon
                type="button"
                data-test="edit"
                name="edit"
                class="btn-icon h-6 w-6 cursor-pointer"
                @click="editCode(wbsCode)"
              />

              <Icon
                name="trash"
                class="w-6 h-6 hover:stroke-red-600 cursor-pointer"
                data-test="trash"
                @click="removeWbsCode(wbsCode.id)"
              />
            </div>

            <p
              class="truncate w-20 text-sm"
              :title="wbsCode.description"
            >
              {{ wbsCode.description }}
            </p>

            <p class="text-sm">
              Unit {{ wbsCode.unit }}
            </p>

            <p class="text-sm text-right">
              Quantity {{ wbsCode.tender_quantity }}
            </p>

            <p class="text-sm">
              Cost {{ wbsCode.tender_cost }}
            </p>

            <p class="text-sm text-right">
              Days {{ wbsCode.tender_production_rate }}
            </p>
          </div>
        </li>
      </ul>

      <div
        v-else
        class="text-center font-semibold w-full p-2"
      >
        No WBS Codes Found.
      </div>
    </div>

    <WbsCodeModal
      v-if="showEditWbsCode"
      :wbs-code="selectedWbsCode"
      :project-id="$props.projectId"
      @close="showEditWbsCode = false"
    />
  </div>
</template>

<script setup lang="ts">
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import WbsCode from '@/models/WbsCode';
import { useWbsCodesStore } from '@/store/wbsCodes';
import Swal from 'sweetalert2';
import { computed, ref } from 'vue';
import WbsCodeModal from './WbsCodeModal.vue';

const props = defineProps<{
  projectId: string;
}>();

const wbsCodeStore = useWbsCodesStore();

const wbsCodes = computed(() => {
  return wbsCodeStore.models.where('project_id', parseInt(props.projectId)).get();
});

const destroyWbsCodeAction = useStoreApiAction(wbsCodeStore.deleteCode);

const removeWbsCode = (id: Id) => {
  Swal.fire({
    icon: 'warning',
    title: 'Delete WBS Code?',
    text: 'Are you sure you want to delete this code?',
    showConfirmButton: true,
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      destroyWbsCodeAction.request(id).catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Failed to delete',
        });
      });
    }
  });
};

const showEditWbsCode = ref(false);
const selectedWbsCode = ref();

const editCode = (wbsCode: WbsCode) => {
  selectedWbsCode.value = wbsCode;
  showEditWbsCode.value = true;
};

const addWbsCode = () => {
  selectedWbsCode.value = undefined;
  showEditWbsCode.value = true;
};
</script>

<style scoped></style>
