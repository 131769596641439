<template>
  <Swiper
    :space-between="30"
    :free-mode="true"
    :modules="[FreeMode]"
    :breakpoints="breakpoints"
    class="h-full w-full"
  >
    <SwiperSlide class="h-full">
      <Kpi
        :value="formattedMetrics?.plant_utilisation"
        label="Plant Utilisation"
        :threshold="plantUtilisationThresholds(kpiMetrics?.plant_utilisation)"
        :title="plantUtilisationTitle(kpiMetrics?.plant_utilisation)"
      />
    </SwiperSlide>

    <SwiperSlide class="h-auto">
      <Kpi
        :value="formattedMetrics?.plant_utilisation_week"
        label="Plant Utilisation"
        :subtitle="`(week commencing ${date.toFormat('dd/MMM')})`"
        :threshold="plantUtilisationThresholds(kpiMetrics?.plant_utilisation_week)"
        :title="plantUtilisationTitle(kpiMetrics?.plant_utilisation_week)"
      />
    </SwiperSlide>

    <SwiperSlide>
      <Kpi
        :threshold="(kpiMetrics?.labour_hours > 0) ? 'high' : 'low'"
        :value="formattedMetrics?.labour_hours"
        label="Labour Hours"
      />
    </SwiperSlide>

    <SwiperSlide>
      <Kpi
        :threshold="(kpiMetrics?.plant_hours > 0) ? 'high' : 'low'"
        :value="formattedMetrics?.plant_hours"
        label="Plant Hours"
      />
    </SwiperSlide>

    <SwiperSlide>
      <Kpi
        :threshold="varianceThresholds(kpiMetrics?.total_variance)"
        :value="formattedMetrics?.total_variance"
        label="Performance"
        :title="varianceTitle(kpiMetrics?.total_variance)"
      />
    </SwiperSlide>

    <SwiperSlide>
      <Kpi
        :threshold="varianceThresholds(kpiMetrics?.variance_week)"
        :value="formattedMetrics?.variance_week"
        label="Performance"
        :subtitle="`(week commencing ${date.toFormat('dd/MMM')})`"
        :title="varianceTitle(kpiMetrics?.variance_week)"
      />
    </SwiperSlide>
  </Swiper>
</template>

<script setup lang="ts">
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
// import required modules
import Kpi from '@/components/ProjectSelector/Kpi.vue';
import { australianCurrency, australianNumber } from '@/helpers';
import { useProjectsStore } from '@/store/projects';
import { DateTime } from 'luxon';
import { FreeMode } from 'swiper/modules';
import { computed } from 'vue';

type metrics = {
  plant_utilisation_week: number;
  plant_utilisation: number;
  labour_hours: number;
  plant_hours: number;
  total_variance: number;
  variance_week: number;
};

const props = defineProps<{
  kpiMetrics?: metrics;
  date: DateTime;
}>();

const formattedMetrics = computed(() => {
  if(!props.kpiMetrics) {
    return {};
  }

  return {
    plant_utilisation_week: `${australianNumber(props.kpiMetrics.plant_utilisation_week)}%`,
    plant_utilisation: `${australianNumber(props.kpiMetrics.plant_utilisation)}%`,
    labour_hours: `${australianNumber(props.kpiMetrics.labour_hours)}`,
    plant_hours: `${australianNumber(props.kpiMetrics.plant_hours)}`,
    total_variance: `${australianCurrency(props.kpiMetrics.total_variance)}`,
    variance_week: `${australianCurrency(props.kpiMetrics.variance_week)}`,
  };
});

const projectsStore = useProjectsStore();

const totalContractValue = computed(() => {
  return projectsStore.project?.codes?.reduce((total, wbsCode) => {
    return wbsCode.tender_cost;
  }, 0) || 0;
});

const plantUtilisationThresholds = (utilisation: number | undefined) => {
  if(utilisation === undefined) {
    return 'low';
  }

  if(utilisation < 60) {
    return 'low';
  } else if(utilisation < 80) {
    return 'medium';
  }

  return 'high';
};

const plantUtilisationTitle = (utilisation: number | undefined) => {
  if(utilisation === undefined) {
    return 'Red - unknown';
  }

  if(utilisation < 60) {
    return 'Red - utilisation less than 60%';
  } else if(utilisation < 80) {
    return 'Amber - utilisation less than 80%';
  }

  return 'Green - utilisation greater than 80%';
};

const varianceThresholds = (performance: number | undefined) => {
  if(performance === undefined) {
    return 'low';
  }

  if(performance > totalContractValue.value * 0.05) {
    return 'high';
  }

  if(performance < totalContractValue.value * 0.05 && performance > totalContractValue.value * -0.05) {
    return 'medium';
  }

  return 'low';
};

const varianceTitle = (performance: number | undefined) => {
  if(performance === undefined) {
    return 'Red - unknown';
  }

  if(performance > totalContractValue.value * 0.05) {
    return 'Green - higher than 5% of contract value';
  }

  if(performance < totalContractValue.value * 0.05 && performance > totalContractValue.value * -0.05) {
    return 'Amber - less than 5% of contract value, but more than negative 5% of contract value';
  }

  return 'Red - less than negative 5% of contract value';
};

const breakpoints = {
  '1700': {
    slidesPerView: 6,
  },

  '1536': {
    slidesPerView: 4.5,
  },

  '1280': {
    slidesPerView: 4.5,
  },

  '1024': {
    slidesPerView: 4.5,
  },

  '768': {
    slidesPerView: 3.5,
  },
};
</script>

<style scoped></style>
