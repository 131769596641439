<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          {{ $props.recordId ? 'Update' : 'Create' }} Record
        </h1>
      </div>
    </template>

    <template #body>
      <div class="flex flex-col w-full my-2 p-4 h-full overflow-y-auto max-h-[30rem] min-w-md">
        <form
          action=""
          class="w-full space-y-2"
          @submit.prevent="saveRecord"
        >
          <fieldset class="flex flex-col space-y-2">
            <legend class="flex justify-between font-bold w-full">
              <span>Time Details</span>

              <span>{{ totalTime ? `${totalTime.toFixed(1)} hrs` : '' }}</span>
            </legend>

            <label>
              Date
              <AppInput
                v-model="recordForm.form.day"
                name="day"
                type="date"
                data-test="day"
                class="disabled:bg-gray-300 form-input"
                :error="recordForm.getErrors('day')"
                :disabled="$props.recordId"
              />
            </label>

            <label>
              Start Time
              <AppInput
                v-model="recordForm.form.start_at"
                name="start_at"
                type="time"
                data-test="start_at"
                class="form-input"
                :error="recordForm.getErrors('start_at')"
              />
            </label>

            <label>
              End Time
              <AppInput
                v-model="recordForm.form.end_at"
                name="end_at"
                :type="endAtType"
                data-test="end_at"
                class="form-input"
                :error="recordForm.getErrors('end_at')"
              />
            </label>

            <label
              v-if="showMinorBreak || minorBreakMarked"
              class="flex items-center justify-between"
              :class="{ 'opacity-50': minorBreakMarked }"
            >
              <div class="flex flex-col">
                <p>Smoke Break (15min)</p>

                <p class="text-sm">{{ minorBreakMarked ? 'Marked on another record' : '' }}</p>

                <span
                  v-if="recordForm.getErrors('minor_break')"
                  class="text-red-600 text-sm font-semibold"
                >{{ recordForm.getErrors('minor_break') }}</span>
              </div>

              <input
                v-if="minorBreakMarked"
                type="checkbox"
                disabled
                checked
              >

              <div
                v-else
                class="flex flex-col"
              >
                <input
                  v-model="recordForm.form.minor_break"
                  type="checkbox"
                  name="minor_break"
                  data-test="minor_break"
                >
              </div>
            </label>

            <label
              v-if="showMajorBreak || majorBreakMarked"
              class="flex items-center justify-between"
              :class="{ 'opacity-50': majorBreakMarked }"
            >
              <div class="flex flex-col">
                <p>Lunch Break (30min)</p>

                <p class="text-sm">{{ majorBreakMarked ? 'Marked on another record' : '' }}</p>

                <span
                  v-if="recordForm.getErrors('major_break')"
                  class="text-red-600 text-sm font-semibold"
                >{{ recordForm.getErrors('major_break') }}</span>
              </div>

              <input
                v-if="majorBreakMarked"
                type="checkbox"
                disabled
                checked
              >

              <div
                v-else
                class="flex flex-col"
              >

                <input
                  v-model="recordForm.form.major_break"
                  type="checkbox"
                  name="major_break"
                  data-test="major_break"
                >
              </div>
            </label>

            <label class="flex items-center justify-between">
              Apply Public Holiday Rate

              <input
                v-model="recordForm.form.is_public_holiday"
                type="checkbox"
                name="is_public_holiday"
                data-test="is_public_holiday"
              >
            </label>
          </fieldset>

          <fieldset class="flex flex-col space-y-2 max-w-md">
            <legend class="font-bold">
              Location Details
            </legend>

            <label>
              Area

              <AppSelect
                v-model="recordForm.form.area"
                data-test="area"
                name="area"
                :options="project?.areas"
                :error="recordForm.getErrors('area')"
                class="form-input"
              />

            </label>

            <label>
              Element

              <AppSelect
                v-model="recordForm.form.element"
                data-test="element"
                name="element"
                :options="project?.elements"
                :error="recordForm.getErrors('element')"
                class="form-input"
              />
            </label>

            <label>
              Activity

              <AppSelect
                v-model="recordForm.form.activity"
                data-test="activity"
                name="activity"
                :options="project?.activities"
                :error="recordForm.getErrors('activity')"
                class="form-input"
              />
            </label>

            <label>
              WBS Code

              <AppSelect
                v-model.number="recordForm.form.wbs_code_id"
                data-test="wbs_code"
                name="wbs_code_id"
                :options="project?.codes.map((code) => ({ value: code.id, label: code.code }))"
                :error="recordForm.getErrors('wbs_code_id')"
                class="form-input"
              />
            </label>

            <label>
              WBS Description

              <AppSelect
                v-model.number="recordForm.form.wbs_code_id"
                :options="project?.codes.map((code) => ({ value: code.id, label: code.description }))"
                name="wbs_description"
                data-test="wbs_description"
                class="form-input"
              />
            </label>
          </fieldset>

          <fieldset
            v-if="project?.orders.length"
            class="space-y-2"
          >
            <legend class="font-bold">
              Variation/Delay Details
            </legend>

            <label>
              <span>
                Event Number
              </span>

              <div class="flex space-x-2">
                <AppSelect
                  v-model.number="recordForm.form.variation_order_id"
                  :options="project?.orders.map((order) => ({ value: order.id, label: order.code }))"
                  name="vo_number"
                  data-test="vo_number"
                  :error="recordForm.getErrors('variation_order_id')"
                  optional
                  class="form-input"
                />

                <button
                  v-if="recordForm.form.variation_order_id"
                  class="group"
                  type="button"
                  @click.prevent="recordForm.resetData(['variation_order_id'])"
                >
                  <Icon
                    name="close"
                    class="h-6 w-6 text-red-600 m-1"
                  />
                </button>
              </div>
            </label>

            <label>
              <span>
                Event Description
              </span>

              <div>
                <AppSelect
                  v-model.number="recordForm.form.variation_order_id"
                  class="w-full form-input"
                  :options="project?.orders.map((order) => ({ value: order.id, label: order.description }))"
                  name="vo_description"
                  data-test="vo_description"
                  :error="recordForm.getErrors('variation_order_id')"
                  optional
                />
              </div>
            </label>
          </fieldset>
        </form>


        <div v-if="record?.comments.length">
          <hr class="my-2">

          <h3 class="font-bold">
            Comments
          </h3>

          <div
            v-for="comment in record.comments"
            :key="comment.id"
          >
            <p class="font-semibold">
              {{ comment.creator?.name }}
              <span class="text-sm font-normal">
                {{ comment.createdAt.toLocaleString('en-AU', {
                  hour12: true,
                  hour: "numeric",
                  minute: "numeric",
                  day: "numeric",
                  month: 'numeric',
                  year: 'numeric'

                }) }}
              </span>
            </p>
            {{ comment.body }}
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex p-4">
        <button
          v-if="props.recordId"
          type="button"
          class="btn btn--tertiary-blue"
          @click.prevent="deleteRecord"
        >
          Delete
        </button>

        <button
          v-if="props.recordId"
          type="button"
          class="btn btn--tertiary-blue"
          data-test="split-record"
          @click.prevent="$emit('split')"
        >
          Split
        </button>

        <div class="ml-auto">
          <button
            type="button"
            class="btn btn--secondary-blue"
            @click.prevent="$emit('close')"
          >
            Cancel
          </button>

          <button
            type="button"
            class="btn btn--primary-blue ml-2"
            data-test="save-record"
            @click="saveRecord"
          >
            {{ $props.recordId ? 'Update' : 'Create' }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { dateAsYMD, dateForDateInput, dateForInput, hoursToDate, timeForInput, timeZone } from '@/helpers';
import Labour from '@/models/Labour';
import { useLabourDailyRecordStore } from '@/store/labourDailyRecord';
import { useProjectsStore } from '@/store/projects';
import Swal from 'sweetalert2';
import { computed, nextTick, ref, watch } from 'vue';
import Modal from '../Modal.vue';

const props = defineProps<{
  labour: Labour;
  recordId?: Id;
}>();

const emits = defineEmits<{
  (e: 'close');
  (e: 'split');
}>();

const projectsStore = useProjectsStore();

const project = computed(() => {
  return projectsStore.project;
});

const recordForm = useFormData({
  day: '',
  start_at: '',
  end_at: '',
  area: '',
  element: '',
  wbs_code_id: undefined,
  activity: '',
  comment: '',
  variation_order_id: undefined,
  is_public_holiday: false,
  minor_break: false,
  major_break: false,
  time_zone: timeZone,
});

const labourDailyRecordsStore = useLabourDailyRecordStore();
const endAtType = ref<'time' | 'datetime-local'>('time');

watch(endAtType, (newValue, oldValue) => {
  if(
    newValue === 'datetime-local' &&
    oldValue === 'time'
  ) {
    if(recordForm.form.day) {
      recordForm.form.end_at = dateForInput(new Date(`${recordForm.form.day} ${recordForm.form.end_at}`));
    }
  } else if(
    newValue === 'time' &&
    oldValue === 'datetime-local'
  ) {
    recordForm.form.end_at = timeForInput(recordForm.form.end_at);
  }
});

const record = computed(() => {
  return labourDailyRecordsStore.models.with('comments', (query) => {
    query.with('creator');
  }).with('labour').find(props.recordId);
});

watch(() => props.recordId, (newValue) => {
  if(newValue) {
    labourDailyRecordsStore.fetchRecord(props.recordId).then(() => {
      labourDailyRecordsStore.models.with('comments', (query) => {
        query.with('creator');
      }).load([record.value]);
    });
  }
}, { immediate: true });

watch(record, (newValue) => {
  if(newValue) {
    recordForm.form.start_at = timeForInput(newValue.startAt);

    if(newValue.startAt.hour >= 17) {
      endAtType.value = 'datetime-local';

      nextTick(() => {
        recordForm.form.end_at = dateForInput(newValue.endAt);
      });
    } else {
      endAtType.value = 'time';

      nextTick(() => {
        recordForm.form.end_at = timeForInput(newValue.endAt);
      });
    }

    recordForm.setData(newValue, [
      'start_at',
      'end_at',
      'time_zone',
    ]);
  } else if(!props.recordId) {
    recordForm.form.start_at = project.value?.shift_start_at;

    const end = new Date(project.value?.shiftStartAt);

    end.setHours(end.getHours() + project.value?.labour_standard_shift);

    if(hoursToDate(project.value?.shift_start_at).getHours() >= 17) {
      endAtType.value = 'datetime-local';

      nextTick(() => {
        recordForm.form.end_at = dateForDateInput(end);
      });
    } else {
      endAtType.value = 'time';

      nextTick(() => {
        recordForm.form.end_at = timeForInput(end);
      });
    }
  }
}, { immediate: true });

watch(() => recordForm.form.start_at, (newValue) => {
  if(newValue) {
    const hourSplit = newValue.split(':');
    const hour = parseInt(hourSplit[0]);

    if(hour >= 17) {
      endAtType.value = 'datetime-local';
    } else {
      endAtType.value = 'time';
    }
  }
});

const totalTime = computed(() => {
  if(recordForm.form.start_at && recordForm.form.end_at) {
    let endAt: Date = undefined;

    if(endAtType.value === 'time') {
      if(recordForm.form.day) {
        endAt = new Date(`${recordForm.form.day} ${recordForm.form.end_at}`);
      } else {
        endAt = new Date();

        endAt
          .setHours(
            ...(recordForm.form.end_at.split(':').map((value) => parseInt(value)) as [number, number, number]),
          );
      }
    } else {
      endAt = new Date(recordForm.form.end_at);
    }

    let startAt = undefined;

    if(recordForm.form.day) {
      startAt = new Date(`${recordForm.form.day} ${recordForm.form.start_at}`);
    } else {
      startAt = new Date();

      startAt
        .setHours(
          ...(recordForm.form.start_at.split(':').map((value) => parseInt(value)) as [number, number, number]),
        );
    }

    const timeDiff = Math.abs(startAt.getTime() - endAt.getTime()) / 3600000;

    return (Math.round(timeDiff * 2) / 2);
  }

  return undefined;
});

const futureRecord = ref(false);

watch(() => recordForm.form.day, (newValue) => {
  if(newValue) {
    const dayAsDate = new Date(newValue);

    if(!isNaN(dayAsDate.getTime()) && dayAsDate.getTime() > new Date().getTime()) {
      futureRecord.value = true;
    } else {
      futureRecord.value = false;
    }
  }
});

const updateRecordAction = useStoreApiAction(labourDailyRecordsStore.updateRecord);
const createRecordAction = useStoreApiAction(labourDailyRecordsStore.createRecord);

const saveRecord = () => {
  recordForm.resetErrors();

  if(props.recordId) {
    Swal.fire({
      text: 'Why are you making this change?',
      icon: 'info',
      input: 'text',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if(result.isConfirmed) {
        recordForm.form.comment = result.value;

        updateRecordAction.request(record.value.id, recordForm.form, { day: recordForm.form.day }).then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Record Updated.',
          });

          emits('close');
        }).catch((error) => {
          console.log(error);

          recordForm.setErrors(error.data);
        });
      }
    });
  } else {
    if(futureRecord.value) {
      Swal.fire({
        icon: 'warning',
        title: 'This is a future date record',
        text: 'Are you sure you want to save this record?',
        showCancelButton: true,
      }).then((result) => {
        if(result.isConfirmed) {
          createRecordAction.request(props.labour.id, recordForm.form).then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Record Saved.',
            });

            emits('close');
          }).catch((error) => {
            recordForm.setErrors(error.data);
          });
        }
      });
    } else {
      createRecordAction.request(props.labour.id, recordForm.form).then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Record Saved.',
        });

        emits('close');
      }).catch((error) => {
        recordForm.setErrors(error.data);
      });
    }
  }
};

const showMinorBreak = computed(() => {
  let dailyTime = totalTime.value || 0;
  let minorBreak = false;

  if(record.value?.minor_break) {
    return true;
  }

  const records = labourDailyRecordsStore.models
    .where('labour_id', props.labour.id)
    .where('day', recordForm.form.day)
    .get();

  records.forEach((record) => {
    dailyTime += record.hours;

    if(record.minor_break) {
      minorBreak = record.minor_break;
    }
  });

  return dailyTime > 3 && !minorBreak;
});

const showMajorBreak = computed(() => {
  let dailyTime = totalTime.value || 0;
  let majorBreak = false;

  if(record.value?.major_break) {
    return true;
  }

  const records = labourDailyRecordsStore.models
    .where('labour_id', props.labour.id)
    .where('day', recordForm.form.day)
    .get();

  records.forEach((record) => {
    dailyTime += record.hours;

    if(record.major_break) {
      majorBreak = record.major_break;
    }
  });

  return dailyTime > 6 && !majorBreak;
});

const minorBreakMarked = computed(() => {
  let minorBreak = false;

  const records = labourDailyRecordsStore.models
    .where('labour_id', props.labour.id)
    .where('day', recordForm.form.day)
    .get();

  records.forEach((labourRecord) => {
    if(labourRecord.minor_break && labourRecord.id !== props.recordId) {
      minorBreak = labourRecord.minor_break;
    }
  });

  return minorBreak;
});

const majorBreakMarked = computed(() => {
  let majorBreak = false;

  const records = labourDailyRecordsStore.models
    .where('labour_id', props.labour.id)
    .where('day', recordForm.form.day)
    .get();

  records.forEach((labourRecord) => {
    if(labourRecord.major_break && labourRecord.id !== props.recordId) {
      majorBreak = labourRecord.major_break;
    }
  });

  return majorBreak;
});

const previousRecord = computed(() => {
  return labourDailyRecordsStore.models
    .where('day', dateAsYMD(new Date(recordForm.form.day)))
    .where('labour_id', props.labour.id)
    .orderBy('id', 'desc')
    .first() ||
    labourDailyRecordsStore.models
      .where('labour_id', props.labour.id)
      .orderBy('id', 'desc')
      .first();
});

watch(previousRecord, (newValue) => {
  if(newValue && !props.recordId) {
    recordForm.form.area = newValue.area;
    recordForm.form.element = newValue.element;
    recordForm.form.wbs_code_id = newValue.wbs_code_id;
    recordForm.form.activity = newValue.activity;
    recordForm.form.major_break = newValue.major_break;
    recordForm.form.minor_break = newValue.minor_break;
  }
}, { immediate: true });

const deleteRecordAction = useStoreApiAction(labourDailyRecordsStore.deleteRecord);

const deleteRecord = () => {
  Swal.fire({
    icon: 'warning',
    title: 'Delete Record?',
    text: `Are you sure you want to delete the record for ${new Date(record.value.day).toLocaleDateString()}?`,
    showCancelButton: true,
    showConfirmButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      deleteRecordAction.request(props.recordId).then(() => {
        Swal.fire({
          icon: 'success',
          text: 'Record deleted',
        }).then(() => {
          emits('close');
        });
      }).catch((error) => {
        if(error?.status === 422) {
          Swal.fire({
            icon: 'error',
            text: `Unable to delete record as it has been approved.`,
          });
        }
      });
    }
  });
};
</script>

<style scoped></style>
