<template>
  <tr class="even:bg-gray-100 odd:bg-white text-right border-black border-x hover:bg-blue-100 p-2 m-2">
    <td
      class="py-2 text-left pl-3"
    >
      {{ $props.variationOrder.code }}
    </td>

    <td class="pr-3">
      {{ australianCurrency($props.variationOrder.budget) }}
    </td>

    <td class="bg-green-200/50">
      {{ australianCurrency(variationOrderCost.current_claim) }}
    </td>

    <td class="bg-green-200/50">
      {{ australianCurrency(variationOrderCost.current_certified) }}
    </td>

    <td class="bg-green-200/50">
      {{ australianCurrency(variationOrder.previous(claim)) }}
    </td>

    <td class="bg-green-200/50 pr-3">
      {{ australianCurrency(variationOrder.previous(claim) + (variationOrderCost?.current_certified || 0)) }}
    </td>

    <td class="bg-blue-300/50">
      {{ variationOrderCost ? `${australianNumber((variationOrderCost.current_claim / totalCosts.budget) * 100)}%` : '' }}
    </td>

    <td class="bg-blue-300/50">
      {{ variationOrderCost ? `${australianNumber((variationOrderCost.current_certified / totalCosts.budget) * 100)}%` : '' }}
    </td>

    <td class="bg-blue-300/50">
      {{ australianNumber((variationOrder.previous(claim) / totalCosts.budget) * 100) }}%
    </td>

    <td class="bg-blue-300/50 pr-3">
      {{ australianNumber(((variationOrder.previous(claim) + (variationOrderCost?.current_certified || 0)) / totalCosts.budget) * 100) }}%
    </td>

    <td>{{ variationOrderCost ? australianCurrency(Math.abs(variationOrderCost.current_claim - variationOrderCost.current_certified)) : '' }}</td>

    <td class="pr-3">
      {{ australianCurrency(variationOrder.budget - variationOrder.previous(claim) - (variationOrderCost?.current_certified || 0)) }}
    </td>
  </tr>
</template>

<script setup lang="ts">
import { australianCurrency, australianNumber } from '@/helpers';
import Claim from '@/models/Claim';
import VariationOrder from '@/models/VariationOrder';
import VariationOrderCost from '@/models/VariationOrderCost';
import { useVariationOrdersStore } from '@/store/variationOrders';
import { computed } from 'vue';

const props = defineProps<{
  variationOrderCost: VariationOrderCost;
  variationOrder: VariationOrder;
  claim: Claim;
}>();

const variationOrdersStore = useVariationOrdersStore();

const variationOrders = computed(() => {
  return variationOrdersStore.models.where('type', props.variationOrder.type).with('costs', (query) => {
    query.where('claim_id', props.claim.id);
  }).get();
});

const totalCosts = computed(() => {
  const totals = {
    budget: 0,
    currentClaim: 0,
    currentCertified: 0,
    previous: 0,
    cumulative: 0,
  };

  variationOrders.value.forEach((variationOrder) => {
    totals.budget += variationOrder.budget;
    totals.previous += variationOrder.previous(props.claim);
    const variationOrderCost = variationOrder.costs[0];

    if(variationOrderCost) {
      totals.currentClaim += variationOrderCost.current_claim;
      totals.currentCertified += variationOrderCost.current_certified;
    }
  });

  totals.cumulative = totals.previous + totals.currentCertified;

  return totals;
});
</script>

<style scoped></style>
