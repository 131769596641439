<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl mx-10 capitalize">
          Attach {{ title.replace('_', ' ') }}
        </h1>
      </div>
    </template>

    <template #body>
      <div class="h-full w-full p-4 overflow-y-auto">
        <form
          action=""
          class="flex flex-col space-y-4"
          @submit.prevent="saveTrade"
        >
          <label>
            Current Claim
            <AppInput
              v-model="variationOrderCostForm.form.current_claim"
              name="current_claim"
              type="text"
              class="form-input"
              :error="variationOrderCostForm.getErrors('current_claim')"
              data-test="current_claim"
            />
          </label>

          <label>
            Variation Order #

            <AppSelect
              v-model.number="variationOrderCostForm.form.variation_order_id"
              :options="variationOrders.map((vo) => ({ value: vo.id, label: vo.code }))"
              name="variation_order_id"
              data-test="variation_order_id"
              class="form-input"
            />
          </label>

          <label>
            Variation Order Description

            <AppSelect
              v-model.number="variationOrderCostForm.form.variation_order_id"
              :options="variationOrders.map((vo) => ({ value: vo.id, label: vo.description }))"
              name="variation_order_id"
              data-test="variation_order_id"
              class="form-input"
            />
          </label>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-center p-2 space-x-4">
        <button
          type="button"
          class="btn btn--secondary-blue"
          @click="$emit('close'); variationOrderCostForm.resetData()"
        >
          Cancel
        </button>

        <button
          type="button"
          class="btn btn--primary-blue"
          :disabled="saveClaimAction.is(States.LOADING)"
          data-test="save-trade-item"
          @click="saveTrade"
        >
          Attach
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { useSignedData } from '@/composables/useSignedData';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { enumKeyFromValue, parseUrlParams } from '@/helpers';
import Claim from '@/models/Claim';
import { VariationOrderType } from '@/models/VariationOrder';
import { useVariationOrderCostsStore } from '@/store/variationOrderCosts';
import { useVariationOrdersStore } from '@/store/variationOrders';
import { computed } from 'vue';
import Modal from '../Modal.vue';

const props = defineProps<{
  claim: Claim;
  type: ObjectValues<typeof VariationOrderType>;
}>();

const emits = defineEmits<{
  (e: 'close');
}>();

const title = computed(() => {
  return enumKeyFromValue(VariationOrderType, props.type);
});

const variationOrdersStore = useVariationOrdersStore();

const variationOrders = computed(() => {
  return variationOrdersStore.models.where('type', props.type).where('project_id', props.claim.project_id).get();
});

const variationOrderCostForm = useFormData({
  id: undefined,
  current_claim: 0,
  variation_order_id: undefined,
});

const signedParams = useSignedData();
const variationOrderCostsStore = useVariationOrderCostsStore();
const saveClaimAction = useStoreApiAction(variationOrderCostsStore.saveClaim);

const claimUrlParms = computed(() => {
  return parseUrlParams(signedParams.meta.variation_claim_url as string);
});

const saveTrade = () => {
  saveClaimAction.request(props.claim.id, claimUrlParms.value, variationOrderCostForm.form).then(() => {
    emits('close');
  }).catch((error) => {
    console.log(error);

    variationOrderCostForm.setErrors(error.data);
  });
};
</script>

<style scoped></style>
