<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          New Hire Period
        </h1>
      </div>
    </template>

    <template #body>
      <div class="h-full w-full p-4 overflow-y-auto">
        <form
          action=""
          class="space-y-4"
          @submit.prevent="updateHirePeriod"
        >
          <label class="flex items-center justify-between">
            On Hire Date
            <AppInput
              v-model="hirePeriodForm.form.on_hired_at"
              name="on_hired_at"
              type="date"
              :error="hirePeriodForm.getErrors('on_hired_at')"
              data-test="new-hired-on-hire"
            />
          </label>

          <label class="flex items-center justify-between">
            Off Hire Date
            <AppInput
              v-model="hirePeriodForm.form.off_hired_at"
              name="off_hired_at"
              type="date"
              :error="hirePeriodForm.getErrors('off_hired_at')"
              data-test="new-hired-off-hire"
            />
          </label>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-center p-2 space-x-4">
        <button
          type="button"
          class="bg-gray-100 px-4 py-2 rounded-full"
          @click="$emit('close'); hirePeriodForm.resetData()"
        >
          Cancel
        </button>

        <button
          type="button"
          class="bg-gray-200 px-4 py-2 rounded-full disabled:bg-gray-700"
          :disabled="hireLoading"
          data-test="save-new-hire-period"
          @click="updateHirePeriod"
        >
          Save
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { usePlantsStore } from '@/store/plants';
import Modal from '../Modal.vue';

const props = defineProps<{
  plantId: Id;
}>();

const emits = defineEmits<{
  (e: 'close');
}>();

const plantsStore = usePlantsStore();

const hirePeriodForm = useFormData({
  on_hired_at: '',
  off_hired_at: '',
});

const { loading: hireLoading, request: saveHire } = useStoreApiAction(plantsStore.hire);

const updateHirePeriod = () => {
  hirePeriodForm.resetErrors();

  saveHire(props.plantId, hirePeriodForm.form).then((_data) => {
    emits('close');
    hirePeriodForm.resetData();
  }).catch((error) => {
    hirePeriodForm.setErrors(error.data);
  });
};
</script>

<style scoped></style>
