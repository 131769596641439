import { route } from '@/ziggy-shim';
import { api, apiConfig, CastValue } from './LaravelClient';

export default {
  async create(claimId: Id, tradeItemId: Id, data: ApiRequests['api.v1.trade-item-costs.store']) {
    return await api(route('api.v1.trade-item-costs.store', [claimId, tradeItemId]), 'post', {
      data,
      casts: {
        current_certified: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        current_claim: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async update(id: Id, data: ApiRequests['api.v1.trade-item-costs.update']) {
    return await api(route('api.v1.trade-item-costs.update', id), 'patch', {
      data,
      casts: {
        current_certified: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        current_claim: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async delete(id: Id) {
    return await api(route('api.v1.trade-item-costs.destroy', id), 'delete');
  },

  async certifiedSingle(claimId: Id, signedParams: unknown, data: ApiRequests['api.v1.claims.certified.store']) {
    return await apiConfig(route('api.v1.claims.certified.store', claimId), 'post', {
      params: signedParams,
      paramsSerializer: {
        serialize: (params) => {
          return new URLSearchParams(params).toString();
        },
      },
    }, {
      data,
      casts: {
        comment_trade_item: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async claimSingle(claimId: Id, signedParams: unknown, data: ApiRequests['api.v1.claims.claim.store']) {
    return await apiConfig(route('api.v1.claims.claim.store', claimId), 'post', {
      params: signedParams,
      paramsSerializer: {
        serialize: (params) => {
          return new URLSearchParams(params).toString();
        },
      },
    }, {
      data,
    });
  },
};
