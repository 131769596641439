import PlantCategory from '@/models/PlantCategory';
import PlantCategoriesService from '@/services/PlantCategoriesService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';

export const usePlantCategoriesStore = defineStore('plant_categories', {
  state: () => ({}),

  getters: {
    models: () => useRepo(PlantCategory),
  },

  actions: {
    async createPlantCategory(projectId: Id, request: ApiRequests['api.v1.plant-categories.store']) {
      const [data, error] = await PlantCategoriesService.create(projectId, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updatePlantCategory(id: Id, request: ApiRequests['api.v1.plant-categories.update']) {
      const [data, error] = await PlantCategoriesService.update(id, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async deletePlantCategory(id: Id) {
      const [data, error] = await PlantCategoriesService.delete(id);

      if(data) {
        this.models.destroy(data.id);

        return data;
      }

      throw error;
    },
  },
});
